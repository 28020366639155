import { Candidate } from '@bluebird-monorepo/types';
import { countries, languages, Country } from '@bluebird-monorepo/utils';
import { KeyboardArrowDown, Save } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Sheet,
  Snackbar,
  Switch,
  Textarea,
  Typography,
} from '@mui/joy';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUpsertCandidate } from '../../api/candidates/useUpsertCandidate';
import { CandidateResume } from './CandidateResume';
import { useForm } from 'react-hook-form';
import { InsertAutocomplete } from '@bluebird-monorepo/bluebird-forms';
import { useGetRoles } from '../../api/roles/useGetRoles';
import { upsertRole } from '../../api/roles/useUpsertRoles';
import { useGetSkills } from '../../api/skills/useGetSkills';
import { upsertSkill } from '../../api/skills/useUpsertSkills';

interface CandidateProfileTabProps {
  candidate: Candidate;
}

export const CandidateProfileTab: React.FC<CandidateProfileTabProps> = ({ candidate: initialCandidate }) => {
  const normalizeArrayOrString = (value: any): any[] => {
    if (!value) return [];
    if (typeof value === 'string') return value.split(',').map((item) => item.trim());
    if (Array.isArray(value)) {
      if (value.length === 0) return [];
      if (typeof value[0] === 'string' && value[0].includes(',')) {
        return value[0].split(',').map((item) => item.trim());
      }
      return value;
    }
    return [];
  };

  // Convert all array/string fields during initialization
  const normalizedInitialCandidate = useMemo(() => {
    return {
      ...initialCandidate,
      languagesCandidateSpeak: normalizeArrayOrString(initialCandidate.languagesCandidateSpeak),
      techStack: normalizeArrayOrString(initialCandidate.techStack)?.map((tech) =>
        typeof tech === 'object' ? tech.name : tech,
      ),
      skills: normalizeArrayOrString(initialCandidate.skills),
      potentialRoles: normalizeArrayOrString(initialCandidate.potentialRoles),
    };
  }, [initialCandidate]);

  const [candidate, setCandidate] = useState<Candidate>(normalizedInitialCandidate);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const { mutate: updateCandidateMutation } = useUpsertCandidate();
  const [roleSearch, setRoleSearch] = useState('');
  const { data: potentialRoles, isLoading: isLoadingRoles } = useGetRoles(roleSearch);
  const [skillSearch, setSkillSearch] = useState('');
  const { data: potentialSkills, isLoading: isLoadingSkills } = useGetSkills(skillSearch);

  const {
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      languagesCandidateSpeak: candidate.languagesCandidateSpeak || [],
      potentialRoles: candidate.potentialRoles || [],
      skills: candidate.skills || [],
    },
  });

  useEffect(() => {
    setCandidate(normalizedInitialCandidate);
    reset({
      languagesCandidateSpeak: normalizedInitialCandidate.languagesCandidateSpeak || [],
      potentialRoles: normalizedInitialCandidate.potentialRoles || [],
      skills: normalizedInitialCandidate.skills || [],
    });
  }, [initialCandidate, reset]);

  // Debounced save function
  const debouncedSave = useCallback(
    debounce((updatedCandidate: Candidate) => {
      updateCandidateMutation(updatedCandidate);
      setShowSaveSuccess(true);
    }, 1000),
    [],
  );

  // Handle input changes
  const handleChange = (field: string, value: any) => {
    const updatedCandidate = { ...candidate };
    // Handle nested fields (e.g., 'contactInfo.email')
    const fields = field.split('.');
    let current: any = updatedCandidate;
    for (let i = 0; i < fields.length - 1; i++) {
      if (!current[fields[i]]) {
        current[fields[i]] = {};
      }
      current = current[fields[i]];
    }
    current[fields[fields.length - 1]] = value;
    setCandidate(updatedCandidate);
    debouncedSave(updatedCandidate);
  };

  return (
    <>
      <AccordionGroup
        sx={{
          maxWidth: '100%',
          width: '100%',
          '& .MuiAccordion-root': {
            mb: 2,
          },
        }}
      >
        {/* Score Section */}
        <Accordion defaultExpanded>
          <AccordionSummary
            indicator={<KeyboardArrowDown />}
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              '&:hover': { bgcolor: 'background.level2' },
              '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'rotate(-90deg)',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(0deg)',
              },
            }}
          >
            <Typography level="title-md">Score & Assessment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Sheet
              variant="outlined"
              sx={{
                bgcolor: 'primary.softBg',
                borderRadius: 'lg',
                p: 3,
                border: '1px solid',
                borderColor: 'primary.outlinedBorder',
              }}
            >
              <Typography level="title-sm" sx={{ mb: 2 }}>
                Candidate Score (0-100)
              </Typography>

              <Grid container spacing={2} alignItems="center">
                <Grid xs={12} sm={6}>
                  <FormControl>
                    <FormLabel>Score</FormLabel>
                    <Input
                      type="number"
                      value={candidate.score ?? ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '') {
                          handleChange('score', undefined);
                        } else {
                          const numValue = parseInt(value);
                          if (numValue >= 0 && numValue <= 100) {
                            handleChange('score', numValue);
                          }
                        }
                      }}
                      slotProps={{
                        input: {
                          min: 0,
                          max: 100,
                        },
                      }}
                      endDecorator={
                        <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                          / 100
                        </Typography>
                      }
                    />
                    <FormHelperText>
                      {candidate.score !== undefined && candidate.score >= 0 && candidate.score <= 100
                        ? candidate.score >= 70
                          ? '👍 Strong candidate'
                          : candidate.score >= 40
                            ? '👋 Potential candidate'
                            : '⚠️ May need further assessment'
                        : 'Enter a score between 0 and 100'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: 'background.level1',
                      borderRadius: 'sm',
                      border: '1px solid',
                      borderColor: 'divider',
                    }}
                  >
                    <Typography level="body-sm" sx={{ mb: 1 }}>
                      Score Guidelines:
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                      <Typography level="body-xs" sx={{ color: 'success.main' }}>
                        • 70-100: Strong match for the role
                      </Typography>
                      <Typography level="body-xs" sx={{ color: 'warning.main' }}>
                        • 40-69: Potential candidate, needs discussion
                      </Typography>
                      <Typography level="body-xs" sx={{ color: 'danger.main' }}>
                        • 0-39: May not be suitable at this time
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Sheet>
          </AccordionDetails>
        </Accordion>

        {/* Compensation */}
        <Accordion defaultExpanded>
          <AccordionSummary
            indicator={<KeyboardArrowDown />}
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              '&:hover': { bgcolor: 'background.level2' },
              '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'rotate(-90deg)',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(0deg)',
              },
            }}
          >
            <Typography level="title-md">Compensation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {/* Prefer Not to Disclose Toggle */}
              <Grid xs={12}>
                <Sheet
                  variant="outlined"
                  sx={{
                    p: 2,
                    mb: 2,
                    borderRadius: 'sm',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: 'background.level1',
                  }}
                >
                  <Box>
                    <Typography level="title-sm">Prefer Not to Disclose</Typography>
                    <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                      Toggle this if the candidate prefers not to share compensation details
                    </Typography>
                  </Box>
                  <Switch
                    checked={candidate.salaryInformation?.preferNotToDisclose || false}
                    onChange={(e) => handleChange('salaryInformation.preferNotToDisclose', e.target.checked)}
                  />
                </Sheet>
              </Grid>

              {!candidate.salaryInformation?.preferNotToDisclose && (
                <>
                  {/* Base Salary Section */}
                  <Grid xs={12}>
                    <Sheet
                      variant="outlined"
                      sx={{
                        p: 2,
                        mb: 2,
                        borderRadius: 'sm',
                        bgcolor: 'background.surface',
                      }}
                    >
                      <Typography level="title-sm" sx={{ mb: 2 }}>
                        Base Salary
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Monthly Base Salary</FormLabel>
                            <Input
                              type="number"
                              value={candidate.salaryInformation?.currentSalary?.monthly || ''}
                              onChange={(e) => {
                                const monthlyValue = parseInt(e.target.value) || 0;
                                handleChange('salaryInformation.currentSalary', {
                                  ...candidate.salaryInformation?.currentSalary,
                                  monthly: monthlyValue,
                                  yearly: monthlyValue * 12,
                                });
                              }}
                              endDecorator={<Typography level="body-sm">EUR</Typography>}
                            />
                          </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Yearly Base Salary</FormLabel>
                            <Input
                              type="number"
                              value={candidate.salaryInformation?.currentSalary?.yearly || ''}
                              onChange={(e) => {
                                const yearlyValue = parseInt(e.target.value) || 0;
                                handleChange('salaryInformation.currentSalary', {
                                  ...candidate.salaryInformation?.currentSalary,
                                  yearly: yearlyValue,
                                  monthly: Math.round(yearlyValue / 12),
                                });
                              }}
                              endDecorator={<Typography level="body-sm">EUR</Typography>}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Sheet>
                  </Grid>

                  {/* Variable Compensation Section */}
                  <Grid xs={12}>
                    <Sheet
                      variant="outlined"
                      sx={{
                        p: 2,
                        mb: 2,
                        borderRadius: 'sm',
                        bgcolor: 'background.surface',
                      }}
                    >
                      <Typography level="title-sm" sx={{ mb: 2 }}>
                        Variable & Equity Compensation
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Annual Bonus</FormLabel>
                            <Input
                              type="number"
                              value={candidate.salaryInformation?.currentSalary?.bonus || ''}
                              onChange={(e) => {
                                const bonusValue = parseInt(e.target.value) || 0;
                                handleChange('salaryInformation.currentSalary.bonus', bonusValue);
                              }}
                              endDecorator={<Typography level="body-sm">EUR</Typography>}
                            />
                          </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Total OTE (Base + Bonus)</FormLabel>
                            <Input
                              type="number"
                              value={
                                Number(candidate.salaryInformation?.currentSalary?.yearly || 0) +
                                  Number(candidate.salaryInformation?.currentSalary?.bonus || 0) || ''
                              }
                              disabled
                              endDecorator={<Typography level="body-sm">EUR</Typography>}
                              sx={{ bgcolor: 'background.level1' }}
                            />
                            <FormHelperText>Automatically calculated from base salary + bonus</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid xs={12}>
                          <FormControl>
                            <FormLabel>Current Equity</FormLabel>
                            <Box sx={{ mt: 1.5 }}>
                              <Checkbox
                                label="Has company shares/stock options"
                                checked={candidate.salaryInformation?.currentSalary?.hasEquity || false}
                                onChange={(e) =>
                                  handleChange('salaryInformation.currentSalary.hasEquity', e.target.checked)
                                }
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Sheet>
                  </Grid>

                  {/* Salary Expectation Range */}
                  <Grid xs={12}>
                    <Sheet
                      variant="outlined"
                      sx={{
                        p: 2,
                        borderRadius: 'sm',
                        bgcolor: 'background.surface',
                      }}
                    >
                      <Typography level="title-sm" sx={{ mb: 1 }}>
                        Compensation Expectations
                      </Typography>
                      <Typography level="body-sm" sx={{ color: 'text.secondary', mb: 2 }}>
                        Expected total compensation including base salary, bonus, and equity preferences
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Currency</FormLabel>
                            <Select
                              value={candidate.salaryInformation?.salaryExpectationRange?.currency || 'EUR'}
                              onChange={(_, value) =>
                                value && handleChange('salaryInformation.salaryExpectationRange.currency', value)
                              }
                            >
                              <Option value="EUR">EUR</Option>
                              <Option value="USD">USD</Option>
                              <Option value="GBP">GBP</Option>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Minimum Expected OTE</FormLabel>
                            <Input
                              type="number"
                              value={candidate.salaryInformation?.salaryExpectationRange?.min || ''}
                              onChange={(e) =>
                                handleChange(
                                  'salaryInformation.salaryExpectationRange.min',
                                  parseInt(e.target.value) || 0,
                                )
                              }
                              endDecorator={
                                <Typography level="body-sm">
                                  {candidate.salaryInformation?.salaryExpectationRange?.currency || 'EUR'}
                                </Typography>
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <FormControl>
                            <FormLabel>Maximum Expected OTE</FormLabel>
                            <Input
                              type="number"
                              value={candidate.salaryInformation?.salaryExpectationRange?.max || ''}
                              onChange={(e) =>
                                handleChange(
                                  'salaryInformation.salaryExpectationRange.max',
                                  parseInt(e.target.value) || 0,
                                )
                              }
                              endDecorator={
                                <Typography level="body-sm">
                                  {candidate.salaryInformation?.salaryExpectationRange?.currency || 'EUR'}
                                </Typography>
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid xs={12}>
                          <FormControl>
                            <FormLabel>Equity Expectations</FormLabel>
                            <Box sx={{ mt: 1.5 }}>
                              <Checkbox
                                label="Expects equity compensation (shares/stock options)"
                                checked={candidate.salaryInformation?.salaryExpectationRange?.expectsEquity || false}
                                onChange={(e) =>
                                  handleChange(
                                    'salaryInformation.salaryExpectationRange.expectsEquity',
                                    e.target.checked,
                                  )
                                }
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Sheet>
                  </Grid>
                </>
              )}

              {candidate.salaryInformation?.preferNotToDisclose && (
                <Grid xs={12}>
                  <FormControl>
                    <FormLabel>Reason for Non-Disclosure</FormLabel>
                    <Textarea
                      minRows={2}
                      value={candidate.salaryInformation?.reasonForNonDisclosure || ''}
                      onChange={(e) => handleChange('salaryInformation.reasonForNonDisclosure', e.target.value)}
                      placeholder="Explain why the candidate prefers not to disclose their compensation"
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Basic Information */}
        <Accordion defaultExpanded>
          <AccordionSummary
            indicator={<KeyboardArrowDown />}
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              '&:hover': { bgcolor: 'background.level2' },
              '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'rotate(-90deg)',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(0deg)',
              },
            }}
          >
            <Typography level="title-md">Basic Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    value={candidate.firstName || ''}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>Last Name</FormLabel>
                  <Input value={candidate.lastName || ''} onChange={(e) => handleChange('lastName', e.target.value)} />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>Current Position</FormLabel>
                  <Input
                    value={candidate.currentPosition || ''}
                    onChange={(e) => handleChange('currentPosition', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>Current Organization</FormLabel>
                  <Input
                    value={candidate.currentOrganization || ''}
                    onChange={(e) => handleChange('currentOrganization', e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Contact Information */}
        <Accordion>
          <AccordionSummary
            indicator={<KeyboardArrowDown />}
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              '&:hover': { bgcolor: 'background.level2' },
              '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'rotate(-90deg)',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(0deg)',
              },
            }}
          >
            <Typography level="title-md">Contact Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={candidate.email || ''}
                    onChange={(e) => handleChange('email', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    type="tel"
                    value={candidate.phone || ''}
                    onChange={(e) => handleChange('phone', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>LinkedIn</FormLabel>
                  <Input value={candidate.linkedin || ''} onChange={(e) => handleChange('linkedin', e.target.value)} />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>Resume URL</FormLabel>
                  <Input
                    value={candidate.resumeUrl || ''}
                    onChange={(e) => handleChange('resumeUrl', e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Location & Preferences */}
        <Accordion>
          <AccordionSummary
            indicator={<KeyboardArrowDown />}
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              '&:hover': { bgcolor: 'background.level2' },
              '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'rotate(-90deg)',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(0deg)',
              },
            }}
          >
            <Typography level="title-md">Location & Preferences</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>City</FormLabel>
                  <Input value={candidate.city || ''} onChange={(e) => handleChange('city', e.target.value)} />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>Current Country</FormLabel>
                  <Autocomplete<Country>
                    options={[...countries]}
                    value={countries.find((c) => c.country === candidate.currentCountryResidingIn) || null}
                    onChange={(_, newValue) => handleChange('currentCountryResidingIn', newValue?.country || '')}
                    getOptionLabel={(option) => option.country}
                    groupBy={(option) => option.region}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <Box
                          component="span"
                          sx={{
                            position: 'sticky',
                            top: '-8px',
                            display: 'block',
                            backgroundColor: 'background.level1',
                            my: 1,
                            py: 1,
                            px: 2,
                          }}
                        >
                          {params.group}
                        </Box>
                        <Box component="ul" sx={{ p: 0, listStyle: 'none' }}>
                          {params.children}
                        </Box>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, px: 2, py: 1 }}>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/${option.code.toLowerCase()}.svg`}
                            alt=""
                          />
                          {option.country}
                        </Box>
                      </li>
                    )}
                    slotProps={{
                      input: {
                        placeholder: 'Select a country',
                        startDecorator: candidate.currentCountryResidingIn && (
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/${countries.find((c) => c.country === candidate.currentCountryResidingIn)?.code.toLowerCase()}.svg`}
                            alt=""
                          />
                        ),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>In-Office Expectations</FormLabel>
                  <Input
                    value={candidate.inOfficeExpectations || ''}
                    onChange={(e) => handleChange('inOfficeExpectations', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>Availability</FormLabel>
                  <Input
                    value={candidate.availability || ''}
                    onChange={(e) => handleChange('availability', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between' }}>
                  <div>
                    <FormLabel>Open to Remote Work</FormLabel>
                  </div>
                  <Switch
                    checked={candidate.isOpenToWorkRemotely || false}
                    onChange={(e) => handleChange('isOpenToWorkRemotely', e.target.checked)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between' }}>
                  <div>
                    <FormLabel>Open to Relocation</FormLabel>
                  </div>
                  <Switch
                    checked={candidate.isOpenToRelocate || false}
                    onChange={(e) => handleChange('isOpenToRelocate', e.target.checked)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between' }}>
                  <div>
                    <FormLabel>Requires Visa</FormLabel>
                  </div>
                  <Switch
                    checked={candidate.requiresVisa || false}
                    onChange={(e) => handleChange('requiresVisa', e.target.checked)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Professional Details */}
        <Accordion>
          <AccordionSummary
            indicator={<KeyboardArrowDown />}
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              '&:hover': { bgcolor: 'background.level2' },
              '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'rotate(-90deg)',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(0deg)',
              },
            }}
          >
            <Typography level="title-md">Professional Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {/* Skills Section */}
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>Skills</FormLabel>
                  <InsertAutocomplete<{ skills: string[] }>
                    control={control as any}
                    name="skills"
                    options={potentialSkills?.map((skill) => skill.name).filter((name): name is string => !!name) || []}
                    loading={isLoadingSkills}
                    onInputChange={(value) => {
                      if (value.length >= 2) {
                        setSkillSearch(value);
                      }
                    }}
                    onChange={async (value) => {
                      const newSkill = await upsertSkill({ name: value[value.length - 1] });
                      if (newSkill?.name) {
                        const uniqueSkills = new Set([...(candidate.skills || []), newSkill.name]);
                        handleChange('skills', Array.from(uniqueSkills));
                      }
                    }}
                    onSubmit={async (value) => {
                      const newSkill = await upsertSkill({ name: value });
                      if (newSkill?.name) {
                        const uniqueSkills = new Set([...(candidate.skills || []), newSkill.name]);
                        handleChange('skills', Array.from(uniqueSkills));
                      }
                    }}
                    onDelete={(value) => {
                      const uniqueSkills = new Set(candidate.skills?.filter((skill) => skill !== value));
                      handleChange('skills', Array.from(uniqueSkills));
                    }}
                  />
                </FormControl>
              </Grid>

              {/* Languages Section */}
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>Languages</FormLabel>
                  <InsertAutocomplete<{ languagesCandidateSpeak: string[] }>
                    control={control as any}
                    name="languagesCandidateSpeak"
                    options={languages.map((lang) => lang.name)}
                    groupBy={(option) => {
                      const language = languages.find((lang) => lang.name === option);
                      return language?.region || '';
                    }}
                    onChange={(value) => {
                      handleChange('languagesCandidateSpeak', value);
                    }}
                    onSubmit={(value) => {
                      const uniqueLanguages = new Set([...(candidate.languagesCandidateSpeak || []), value]);
                      handleChange('languagesCandidateSpeak', Array.from(uniqueLanguages));
                    }}
                    onDelete={(value) => {
                      const uniqueLanguages = new Set(
                        candidate.languagesCandidateSpeak?.filter((lang) => lang !== value),
                      );
                      handleChange('languagesCandidateSpeak', Array.from(uniqueLanguages));
                    }}
                  />
                </FormControl>
              </Grid>

              {/* Potential Roles Section */}
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>Potential Roles</FormLabel>
                  <InsertAutocomplete<{ potentialRoles: string[] }>
                    control={control as any}
                    name="potentialRoles"
                    options={potentialRoles?.map((role) => role.name) || []}
                    loading={isLoadingRoles}
                    onInputChange={(value) => {
                      if (value.length >= 2) {
                        setRoleSearch(value);
                      }
                    }}
                    onChange={async (value) => {
                      const newRole = await upsertRole({ name: value[value.length - 1] });
                      const uniqueRoles = new Set([...(candidate.potentialRoles || []), newRole?.name]);
                      handleChange('potentialRoles', Array.from(uniqueRoles));
                    }}
                    onSubmit={async (value) => {
                      const newRole = await upsertRole({ name: value });
                      const uniqueRoles = new Set([...(candidate.potentialRoles || []), newRole?.name]);
                      handleChange('potentialRoles', Array.from(uniqueRoles));
                    }}
                    onDelete={(value) => {
                      const uniqueRoles = new Set(candidate.potentialRoles?.filter((role) => role !== value));
                      handleChange('potentialRoles', Array.from(uniqueRoles));
                    }}
                  />
                </FormControl>
              </Grid>

              {/* Years of Experience */}
              <Grid xs={12} sm={6}>
                <FormControl>
                  <FormLabel>Years of Experience</FormLabel>
                  <Input
                    type="number"
                    value={candidate.workExperienceYears || ''}
                    onChange={(e) => handleChange('workExperienceYears', parseInt(e.target.value) || 0)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Resume Upload */}
        <Accordion>
          <AccordionSummary
            indicator={<KeyboardArrowDown />}
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              '&:hover': { bgcolor: 'background.level2' },
              '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'rotate(-90deg)',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(0deg)',
              },
            }}
          >
            <Typography level="title-md">Resume & Documents</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, pt: 1 }}>
            <CandidateResume candidate={candidate} />
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>

      <Snackbar
        variant="soft"
        color="success"
        open={showSaveSuccess}
        onClose={() => setShowSaveSuccess(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        startDecorator={<Save />}
      >
        Changes saved successfully
      </Snackbar>
    </>
  );
};

export default CandidateProfileTab;

import { CompanyAvatar } from '@bluebird-monorepo/companies';
import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { Box, Chip, Divider, ListItem, Typography, Skeleton } from '@mui/joy';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface AssignmentCard {
  assignment: CandidateJobAssignment;
}

export const AssignmentCard: FC<AssignmentCard> = ({ assignment }) => {
  return (
    <>
      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          py: 2,
          '&:hover': {
            bgcolor: 'background.level1',
          },
        }}
      >
        <CompanyAvatar name={assignment.companyName || ''} logoUrl={assignment.logoUrl || ''} size="md" />

        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
            <Link
              to={`/jobs/${assignment.jobId}`}
              style={{
                fontWeight: 600,
                color: 'var(--joy-palette-primary-main)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textDecoration: 'none',
              }}
            >
              {assignment.jobTitle}
            </Link>
            <Chip size="sm" variant="soft" color="neutral" sx={{ ml: 'auto' }}>
              {assignment.status}
            </Chip>
          </Box>

          <Typography
            level="body-sm"
            sx={{
              color: 'text.secondary',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {assignment.companyName}
          </Typography>
        </Box>
      </ListItem>
      <Divider component="li" />
    </>
  );
};

export const AssignmentSkeleton = () => {
  return (
    <>
      <ListItem sx={{ p: 0, my: 3 }}>
        <Skeleton sx={{ width: '100%', height: 70 }} />
      </ListItem>
      <Divider component="li" />
    </>
  );
};

import { TaskboardItem, TaskboardPriority, TaskboardStatus } from '@bluebird-monorepo/types';
import { useGetUserById } from '@bluebird-monorepo/users';
import { AccessTime as AccessTimeIcon, Circle as CircleIcon } from '@mui/icons-material';
import { Avatar, Box, Chip, ColorPaletteProp, ListItem, ListItemContent, Typography } from '@mui/joy';
import { FC } from 'react';

interface EventTaskListItemProps {
  task: TaskboardItem;
  compact?: boolean;
}

const priorityColors: Record<TaskboardPriority, ColorPaletteProp> = {
  low: 'success',
  medium: 'warning',
  high: 'danger',
};

const statusIcons: Record<TaskboardStatus, React.ReactNode> = {
  todo: <CircleIcon sx={{ fontSize: 8 }} />,
  'in-progress': <CircleIcon sx={{ fontSize: 8, color: 'primary.500' }} />,
  done: <CircleIcon sx={{ fontSize: 8, color: 'success.500' }} />,
};

const getDaysDifference = (date1: Date, date2: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round((date1.getTime() - date2.getTime()) / oneDay);
  return diffDays;
};

export const EventTaskListItem: FC<EventTaskListItemProps> = ({ task, compact = false }) => {
  const { data: assignedUser } = useGetUserById(task.assignedTo);

  const isDueDate = task.dueDate && task.dueDate < new Date();

  const formatDueDate = (date: Date) => {
    const days = getDaysDifference(date, new Date());
    if (days === 0) return 'Due today';
    if (days < 0) return `${Math.abs(days)} days overdue`;
    if (days === 1) return 'Due tomorrow';
    return `Due in ${days} days`;
  };

  return (
    <ListItem
      sx={{
        gap: 1,
        '&:hover': {
          bgcolor: 'background.level1',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 0, flex: 1 }}>
        {/* Status Icon */}
        {statusIcons[task.status]}

        <ListItemContent>
          {/* Title */}
          <Typography level={compact ? 'body-sm' : 'body-md'}>{task.title}</Typography>

          {/* Assignee */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {assignedUser ? (
              <>
                {assignedUser.photoUrl ? (
                  <Avatar size="sm" src={assignedUser.photoUrl} />
                ) : (
                  <Avatar size="sm">{assignedUser.displayName?.[0] || assignedUser.email?.[0]}</Avatar>
                )}
                <Typography level="body-xs">{assignedUser.displayName || assignedUser.email}</Typography>
              </>
            ) : (
              <Typography level="body-xs" sx={{ color: 'neutral.500' }}>
                Unassigned
              </Typography>
            )}
          </Box>
        </ListItemContent>

        {/* Priority & Due Date */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'flex-end' }}>
          <Chip
            size="sm"
            variant="soft"
            color={priorityColors[task.priority]}
            sx={{ minWidth: 60, justifyContent: 'center' }}
          >
            {task.priority}
          </Chip>

          {task.dueDate && (
            <Chip
              size="sm"
              variant="soft"
              color={isDueDate ? 'danger' : 'neutral'}
              startDecorator={<AccessTimeIcon />}
              sx={{ minWidth: 60 }}
            >
              {formatDueDate(task.dueDate)}
            </Chip>
          )}
        </Box>
      </Box>
    </ListItem>
  );
};

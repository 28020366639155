import debounce from '@mui/material/utils/debounce';
import { BluebirdTable } from '@bluebird-monorepo/bluebird-ui';
import { DataFilter, useGetLogs } from '../../api/useGetLogs';
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LogsTableFilters } from './LogsTableFilters';
import { createColumns } from './LogsTableColumns';
import { Log, LogTableName } from '@bluebird-monorepo/types';
import { LogsTimeline } from './LogsTimeline';

interface SortConfig {
  field: keyof Pick<Log, 'createdAt' | 'tableName' | 'action'> | null;
  direction: 'asc' | 'desc';
}

interface LogsTableProps {
  tableName?: string;
  entityId?: number;
  dataFilters?: DataFilter[];
  hiddenColumnIds?: string[];
  toggleColumnVisibility?: (columnId: string) => void;
  defaultViewMode?: ViewMode;
}

interface FormValues {
  filterContent: string;
  authorId: number | undefined;
  action: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

type ViewMode = 'table' | 'timeline';

export function LogsTable({
  tableName,
  entityId,
  dataFilters,
  hiddenColumnIds = [],
  toggleColumnVisibility,
  defaultViewMode = 'table',
}: LogsTableProps) {
  const columns = createColumns().filter((column) => !hiddenColumnIds.includes(column.field));
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: 'createdAt',
    direction: 'desc',
  });
  const [search, setSearch] = useState('');
  const [viewMode, setViewMode] = useState<ViewMode>(defaultViewMode);
  const { control, watch } = useForm<FormValues>({
    defaultValues: {
      filterContent: '',
      authorId: undefined,
      action: undefined,
      startDate: undefined,
      endDate: undefined,
    },
  });

  useEffect(() => {
    const debouncedCb = debounce((formValues) => setSearch(formValues.filterContent), 1000);
    const subscription = watch(debouncedCb);
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleSort = (field: string) => {
    setSortConfig((prev) => ({
      field: field as SortConfig['field'],
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const { data, isLoading } = useGetLogs({
    page,
    pageSize: rowsPerPage,
    sortField: sortConfig.field ?? undefined,
    sortDirection: sortConfig.direction,
    search,
    tableName: tableName as LogTableName,
    entityId,
    authorId: watch('authorId'),
    action: watch('action'),
    startDate: watch('startDate'),
    endDate: watch('endDate'),
    dataFilters,
  });

  const { logs, count: logsSize } = data || {
    logs: [],
    count: 0,
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!logs.length) {
    return (
      <Card variant="soft">
        <CardContent>
          <Typography level="body-sm" textAlign="center">
            No logs found
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box>
      <LogsTableFilters
        control={control}
        onResetPage={() => setPage(1)}
        hiddenColumnIds={hiddenColumnIds}
        toggleColumnVisibility={toggleColumnVisibility}
      />

      {viewMode === 'table' ? (
        <BluebirdTable
          data={logs}
          columns={columns}
          count={logsSize}
          defaultRowsPerPage={10}
          rowsPerPageOptions={[10, 25, 50]}
          onSort={handleSort}
          page={page}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
        />
      ) : (
        <LogsTimeline logs={logs} initialLimit={10} />
      )}
    </Box>
  );
}

const LoadingSkeleton = () => (
  <Box sx={{ width: '100%' }}>
    <Skeleton variant="rectangular" width="100%" height={80} />
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={400} />
    </Box>
  </Box>
);

/**
 * Logging configuration
 */
export const logging = {
  /**
   * Whether detailed query logging is enabled
   * Set VITE_ENABLE_QUERY_LOGGING=true in .env.local to enable
   */
  isQueryLoggingEnabled: import.meta.env.VITE_DISABLE_QUERY_LOGGING !== 'true',
};

/**
 * Debug configuration
 */
export const debug = {
  /**
   * Whether component rendering debug is enabled
   * Set VITE_ENABLE_RENDER_DEBUG=true in .env.local to enable
   */
  isRenderDebugEnabled: import.meta.env.VITE_DISABLE_RENDER_DEBUG !== 'true',
};

/**
 * API configuration
 */
export const api = {
  // Add api keys
};

/**
 * Feature flags
 */
export const features = {
  // Add any feature flags here
};

// Export entire config object for convenience
export const config = {
  logging,
  debug,
  api,
  features,
};

export default config;

import { Close } from '@mui/icons-material';
import { Box, Drawer, IconButton, Typography } from '@mui/joy';
import { PDFViewer } from '@bluebird-monorepo/bluebird-ui';
import { FC, useEffect, useState } from 'react';

export interface PDFPreviewDrawerProps {
  open: boolean;
  file: File | string | null;
  title: string;
  onClose: () => void;
  width?: string | number;
}

export const PDFPreviewDrawer: FC<PDFPreviewDrawerProps> = ({ open, file, title, onClose, width = '55%' }) => {
  const [pdfFile, setPdfFile] = useState<string | File | null>(null);

  useEffect(() => {
    // Only set the file if the drawer is open to prevent rendering issues
    if (open && file) {
      setPdfFile(file);
    } else if (!open) {
      // Clear the file after closure to prevent flashing
      const timeout = setTimeout(() => setPdfFile(null), 300);
      return () => clearTimeout(timeout);
    }
  }, [open, file]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      size="lg"
      slotProps={{
        content: {
          sx: {
            width,
            maxWidth: '800px',
            bgcolor: '#FFFFFF',
            p: 0,
            borderLeft: '1px solid',
            borderColor: 'divider',
            boxShadow: 'lg',
          },
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid',
            borderColor: 'divider',
            p: 2,
            bgcolor: '#F9FAFB',
          }}
        >
          <Typography level="title-lg">{title}</Typography>
          <IconButton onClick={onClose} variant="plain" color="neutral">
            <Close />
          </IconButton>
        </Box>

        {/* PDF Content */}
        <Box
          sx={{
            flex: 1,
            overflow: 'hidden',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            bgcolor: '#F6F8FA',
          }}
        >
          {pdfFile && <PDFViewer file={pdfFile} />}
        </Box>
      </Box>
    </Drawer>
  );
};

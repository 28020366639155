import { Note } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@bluebird-monorepo/types';

interface UseGetNotesOptions {
  entityType?: string;
  entityId?: number;
}

type UseGetNotesQuery = PostgrestFilterBuilder<Database['public'], Record<keyof Note, unknown>, Note>;

export function useGetNotes(options: UseGetNotesOptions) {
  return useQuery<Note[]>({
    queryKey: ['notes', options],
    queryFn: async () => {
      const query = supabase.from('notes').select('*').returns<Note>() as UseGetNotesQuery;
      applyEntityFilters(query, options);
      const { data } = await query;
      return data ? toCamelCase<Note[]>(data) : [];
    },
  });
}

function applyEntityFilters(query: UseGetNotesQuery, options: UseGetNotesOptions) {
  const { entityType, entityId } = options;
  if (entityType) {
    query.eq('entity_type', entityType);
  }
  if (entityId) {
    query.eq('entity_id', entityId);
  }
  return query;
}

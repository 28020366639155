import { TaskBoard } from '@bluebird-monorepo/tasks';
import { Box } from '@mui/joy';
import { FC } from 'react';
import { Layout } from '../../../layout';

export const TasksPage: FC = () => {
  return (
    <Layout title="Tasks">
      <Box sx={{ p: 3, height: '100%' }}>
        <TaskBoard />
      </Box>
    </Layout>
  );
};

export default TasksPage;

import { FileUpload } from '@bluebird-monorepo/bluebird-forms';
import { useUpsertStorageFile } from '@bluebird-monorepo/supabase';
import { Candidate } from '@bluebird-monorepo/types';
import { Box } from '@mui/joy';
import React from 'react';
import { CandidateFilesList } from './CandidateFilesList';

interface CandidateResumeProps {
  candidate: Candidate;
}

export const CandidateResume: React.FC<CandidateResumeProps> = ({ candidate }) => {
  const { mutate: uploadStorageFile, isPending: isUploading } = useUpsertStorageFile(
    'uploads',
    `candidates/${candidate.id}/files`,
  );

  const handleSaveFiles = async (files: File[]) => {
    for (const file of files) {
      try {
        await uploadStorageFile({ file });
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
      <Box
        sx={{
          backgroundColor: 'background.surface',
          borderRadius: 'sm',
          width: '100%',
          p: 1,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <FileUpload onSave={handleSaveFiles} disabled={isUploading} />
        </Box>

        <CandidateFilesList candidate={candidate} isUploading={isUploading} />
      </Box>
    </Box>
  );
};

import React from 'react';
import {
  deleteStorageFile,
  downloadStorageFile,
  useGetStorageFiles,
  getStorageFileSignedURL,
} from '@bluebird-monorepo/supabase';
import { Company } from '@bluebird-monorepo/types';
import { Delete, Download, InsertDriveFile, Visibility } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Typography } from '@mui/joy';
import { usePDFPreview, PDFPreviewDrawer } from '@bluebird-monorepo/shared';
import { toast } from 'react-toastify';

interface CompanyContractsListProps {
  company: Company;
  isUploading: boolean;
}

export const CompanyContractsList: React.FC<CompanyContractsListProps> = ({ company, isUploading }) => {
  const UPLOADS_PATH = `companies/${company.id}/contracts`;
  const { data: files, isLoading, refetch } = useGetStorageFiles('uploads', UPLOADS_PATH);
  const { isOpen, file, title, openPdfPreview, closePdfPreview } = usePDFPreview();

  async function handleDeleteUploadsFile(filePath: string) {
    await deleteStorageFile('uploads', filePath);
    refetch();
  }

  // Function to handle previewing PDF files
  const handlePreviewFile = async (filePath: string, fileName: string) => {
    // Check if the file is a PDF
    if (fileName.toLowerCase().endsWith('.pdf')) {
      try {
        // Get the signed URL for the file
        const fileUrl = await getStorageFileSignedURL('uploads', filePath);
        if (fileUrl) {
          // Open the PDF preview with the file URL
          openPdfPreview(fileUrl, fileName);
        }
      } catch (error) {
        console.error('Error getting file URL for preview:', error);
        toast.error('Failed to load PDF for preview');
      }
    } else {
      // For non-PDF files, download them as before
      downloadStorageFile('uploads', filePath);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!files?.length && isUploading) {
    return null;
  }

  if (!files?.length) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography level="body-md" color="neutral">
          No contracts uploaded yet
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {files.map((file) => {
        const filePath = `${UPLOADS_PATH}/${file.name}`;
        const isPdf = file.name.toLowerCase().endsWith('.pdf');

        return (
          <Box
            key={file.name}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1.5,
              borderRadius: 'sm',
              '&:hover': {
                bgcolor: 'background.level1',
              },
              cursor: 'pointer',
            }}
            onClick={() => handlePreviewFile(filePath, file.name)}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <InsertDriveFile color="primary" />
              <Typography level="body-md">{file.name}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {isPdf && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePreviewFile(filePath, file.name);
                  }}
                  variant="plain"
                  color="primary"
                  size="sm"
                  title="Preview PDF"
                >
                  <Visibility />
                </IconButton>
              )}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  downloadStorageFile('uploads', filePath);
                }}
                variant="plain"
                color="neutral"
                size="sm"
              >
                <Download />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteUploadsFile(filePath);
                }}
                variant="plain"
                color="danger"
                size="sm"
              >
                <Delete />
              </IconButton>
            </Box>
          </Box>
        );
      })}

      <PDFPreviewDrawer open={isOpen} file={file} title={title} onClose={closePdfPreview} />
    </Box>
  );
};

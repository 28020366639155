import { TaskboardItem } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertTask() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (task: Partial<TaskboardItem>) => {
      const { data, error } = await supabase
        .from('tasks')
        .upsert(toSnakeCase(task), {
          onConflict: 'id',
        })
        .select()
        .single();

      if (error) throw error;
      return toCamelCase<TaskboardItem>(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });
}

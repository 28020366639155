import { Team } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertTeam() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (team: Partial<Team>) => {
      const { data, error } = await supabase
        .from('teams')
        .upsert(toSnakeCase(team), {
          onConflict: 'id',
        })
        .select()
        .single();
      if (error) throw error;
      return toCamelCase<Team>(data);
    },
    onSuccess: (team: Team) => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['team', team.id] });
    },
  });
}

export function useAddTeamMembers() {
  const queryClient = useQueryClient();
  const { mutateAsync: upsertTeam } = useUpsertTeam();
  return useMutation({
    mutationFn: async ({ teamId, userIds }: { teamId: number; userIds: number[] }) => {
      const existingTeamMembers = await getCurrentTeamMembers(teamId);
      const uniqueNewMembers = [...new Set([...existingTeamMembers, ...userIds])];
      return upsertTeam({
        id: teamId,
        members: uniqueNewMembers,
      });
    },
    onSuccess: (team: Team) => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['team', team.id] });
    },
  });
}

export function useRemoveTeamMembers() {
  const queryClient = useQueryClient();
  const { mutateAsync: upsertTeam } = useUpsertTeam();
  return useMutation({
    mutationFn: async ({ teamId, userIds }: { teamId: number; userIds: number[] }) => {
      const existingTeamMembers = await getCurrentTeamMembers(teamId);
      const filteredMembers = existingTeamMembers.filter((id) => !userIds.includes(id));
      return upsertTeam({
        id: teamId,
        members: filteredMembers,
      });
    },
    onSuccess: (team: Team) => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['team', team.id] });
    },
  });
}

async function getCurrentTeamMembers(teamId: number) {
  const { data, error } = await supabase.from('teams').select().eq('id', teamId).single();
  if (error) throw error;
  const existingTeam = toCamelCase<Team>(data);
  const currentMembers = existingTeam.members || [];
  return currentMembers;
}

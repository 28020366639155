import { Transcript, useFireflies } from '@bluebird-monorepo/fireflies';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { Search } from '@mui/icons-material';
import { Alert, Box, Checkbox, Input, List, ListItem, ListItemButton, Sheet, Skeleton, Typography } from '@mui/joy';
import { format, isToday, isYesterday } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface SelectedConversation {
  id: string;
  content: string;
  timestamp: number;
  title: string;
  attendees: string[];
}

const LoadingSkeleton = () => (
  <Box sx={{ p: 2 }}>
    {[1, 2, 3].map((i) => (
      <Box key={i} sx={{ mb: 2, display: 'flex', gap: 2 }}>
        <Skeleton variant="rectangular" width={24} height={24} />
        <Box sx={{ flex: 1 }}>
          <Skeleton variant="text" width="60%" height={24} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="40%" height={20} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="90%" height={20} />
        </Box>
      </Box>
    ))}
  </Box>
);

const EmptyState = () => (
  <Box
    sx={{
      p: 4,
      textAlign: 'center',
      bgcolor: 'background.level1',
      borderRadius: 'lg',
    }}
  >
    <Typography level="body-lg" sx={{ mb: 1 }}>
      No conversations found
    </Typography>
    <Typography level="body-sm" sx={{ color: 'neutral.500' }}>
      Try adjusting your search or check back later for new conversations
    </Typography>
  </Box>
);

export const ConversationStep: FC = () => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const selectedConversations = (watch('conversations') as SelectedConversation[]) || [];
  const [searchQuery, setSearchQuery] = useState('');

  const { data: currentUser } = useGetCurrentUser();
  const { transcripts: conversations, isLoading, error } = useFireflies(currentUser?.email || '');

  // Group conversations by date
  const groupedConversations = useMemo(() => {
    if (!conversations) return new Map<string, Transcript[]>();

    const filtered = searchQuery.trim()
      ? conversations.filter((conversation) => {
          const searchLower = searchQuery.toLowerCase();
          return (
            conversation.title.toLowerCase().includes(searchLower) ||
            conversation.meeting_attendees.some(
              (attendee) =>
                attendee.displayName.toLowerCase().includes(searchLower) ||
                attendee.email.toLowerCase().includes(searchLower),
            ) ||
            conversation.summary?.overview.toLowerCase().includes(searchLower)
          );
        })
      : conversations;

    const groups = new Map<string, Transcript[]>();
    filtered.forEach((conversation) => {
      const date = format(new Date(conversation.date), 'yyyy-MM-dd');
      const existing = groups.get(date) || [];
      groups.set(date, [...existing, conversation]);
    });

    return groups;
  }, [conversations, searchQuery]);

  // Sort dates in reverse chronological order
  const sortedDates = useMemo(() => {
    return Array.from(groupedConversations.keys()).sort((a, b) => {
      return new Date(b).getTime() - new Date(a).getTime();
    });
  }, [groupedConversations]);

  const handleConversationToggle = (conversation: Transcript) => {
    const currentSelected = selectedConversations || [];
    const isSelected = currentSelected.some((c) => c.id === conversation.id);

    if (isSelected) {
      setValue(
        'conversations',
        currentSelected.filter((c) => c.id !== conversation.id),
        { shouldValidate: true },
      );
    } else {
      setValue(
        'conversations',
        [
          ...currentSelected,
          {
            id: conversation.id,
            content: conversation.summary?.overview || '',
            timestamp: new Date(conversation.date).getTime(),
            title: conversation.title,
            attendees: conversation.meeting_attendees.map((a) => a.displayName),
          },
        ],
        { shouldValidate: true },
      );
    }
  };

  if (error) {
    return (
      <Box sx={{ p: 2, bgcolor: 'danger.softBg', borderRadius: 'lg' }}>
        <Typography color="danger">{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography level="h4" sx={{ mb: 2 }}>
        Select Conversations
      </Typography>
      <Typography level="body-sm" sx={{ mb: 3, color: 'neutral.500' }}>
        Choose the relevant conversations to include in the introduction
      </Typography>

      {errors.conversations && (
        <Alert variant="soft" color="danger" sx={{ mb: 2 }}>
          {errors.conversations.message as string}
        </Alert>
      )}

      {/* Search Bar */}
      <Sheet
        sx={{
          display: 'flex',
          gap: 1.5,
          alignItems: 'center',
          p: 2,
          borderRadius: 'lg',
          mb: 3,
        }}
      >
        <Input
          placeholder="Search conversations..."
          startDecorator={<Search />}
          sx={{ flex: 1 }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Sheet>

      {isLoading ? (
        <LoadingSkeleton />
      ) : sortedDates.length === 0 ? (
        <EmptyState />
      ) : (
        <List>
          {sortedDates.map((date) => {
            const conversationsForDate = groupedConversations.get(date) || [];
            const dateObj = new Date(date);

            let dateLabel = format(dateObj, 'EEEE, MMMM d');
            if (isToday(dateObj)) dateLabel = 'Today';
            if (isYesterday(dateObj)) dateLabel = 'Yesterday';

            return (
              <Box key={date} sx={{ mb: 3 }}>
                <Typography
                  level="title-sm"
                  sx={{
                    px: 2,
                    py: 1,
                    bgcolor: 'background.level1',
                    borderRadius: 'sm',
                  }}
                >
                  {dateLabel}
                </Typography>
                <List>
                  {conversationsForDate.map((conversation) => {
                    const isSelected = selectedConversations.some((c) => c.id === conversation.id);

                    return (
                      <ListItem key={conversation.id}>
                        <ListItemButton
                          onClick={() => handleConversationToggle(conversation)}
                          selected={isSelected}
                          sx={{
                            borderRadius: 'sm',
                            gap: 2,
                            alignItems: 'flex-start',
                          }}
                        >
                          <Checkbox checked={isSelected} sx={{ mt: 1 }} />
                          <Box sx={{ flex: 1 }}>
                            <Typography level="title-sm">{conversation.title}</Typography>
                            <Typography level="body-sm" sx={{ color: 'neutral.500', mb: 1 }}>
                              {format(new Date(conversation.date), 'h:mm a')} • {conversation.meeting_attendees.length}{' '}
                              attendees
                            </Typography>
                            {conversation.summary?.overview && (
                              <Typography
                                level="body-sm"
                                sx={{
                                  color: 'neutral.600',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                }}
                              >
                                {conversation.summary.overview}
                              </Typography>
                            )}
                          </Box>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            );
          })}
        </List>
      )}
    </Box>
  );
};

import { Job, Vertical } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { snakeCase } from 'lodash';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@bluebird-monorepo/types';

export function useGetJobsByJobTitle(jobTitle: string) {
  return useQuery<Job[]>({
    enabled: !!jobTitle,
    queryKey: ['jobsByJobTitle', jobTitle],
    queryFn: async () => {
      const { data } = await supabase.from('jobs').select('*').ilike('job_title', `%${jobTitle}%`);
      return data ? toCamelCase<Job[]>(data) : [];
    },
  });
}

export function useGetJobsByCompanyId(companyId: number) {
  return useQuery<Job[]>({
    enabled: !!companyId,
    queryKey: ['jobsByCompanyId', companyId],
    queryFn: async () => {
      const { data } = await supabase.from('jobs').select('*').eq('company_id', companyId);
      return data ? toCamelCase<Job[]>(data) : [];
    },
  });
}

/*
  Get jobs with pagination, sorting, and filtering
*/

interface PaginatedResponse {
  jobs: Job[];
  count: number;
}

interface UseGetJobsOptions {
  page: number;
  pageSize: number;
  sortField?: keyof Pick<Job, 'jobTitle' | 'responsible' | 'status' | 'priorityOverride' | 'workingOn'>;
  sortDirection?: 'asc' | 'desc';
  search?: string;
  responsible?: string;
  statuses?: string[];
  vertical?: Vertical;
  priority?: string;
  location?: string;
  salaryGt?: number;
  salaryLt?: number;
  feeGt?: number;
  feeLt?: number;
}

type UseGetJobsQuery = PostgrestFilterBuilder<Database['public'], Record<keyof Job, unknown>, Job>;

const DEFAULT_OPTIONS: UseGetJobsOptions = {
  page: 1,
  pageSize: 10,
};

export function useGetJobs(optionsArg?: UseGetJobsOptions) {
  const options = { ...DEFAULT_OPTIONS, ...optionsArg };
  return useQuery<PaginatedResponse>({
    queryKey: ['jobs', { ...options }],
    refetchOnMount: true,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const selectQuery = getSelectQuery(options);
      const countQuery = getCountQuery(options);
      const [{ data }, { count }] = await Promise.all([selectQuery, countQuery]);
      return {
        jobs: data ? toCamelCase<Job[]>(data) : [],
        count: count || 0,
      };
    },
  });
}

// Base query for jobs
function getJobsQuery(selectOptions?: { count: 'exact' | 'planned' | 'estimated'; head: boolean }) {
  return supabase.from('jobs').select('*', selectOptions).returns<Job>() as UseGetJobsQuery;
}

function getSelectQuery(options: UseGetJobsOptions) {
  const selectQuery = getJobsQuery();
  applyFilters(selectQuery, options);
  applySorting(selectQuery, options);
  applyPagination(selectQuery, options);
  return selectQuery;
}

function getCountQuery(options: UseGetJobsOptions) {
  const countQuery = getJobsQuery({ count: 'exact', head: true });
  applyFilters(countQuery, options);
  applySorting(countQuery, options);
  return countQuery;
}

function applyFilters(query: UseGetJobsQuery, options: UseGetJobsOptions) {
  const { search, statuses, responsible, vertical, priority, location, salaryGt, salaryLt, feeGt, feeLt } = options;
  if (search) {
    query.ilike('job_title', `%${search}%`);
  }

  // Ensure statuses is an array and only apply filtering if it has items
  const statusesArray = Array.isArray(statuses) ? statuses : [];
  if (statusesArray.length > 0) {
    applyUnassignedStatus(query, statusesArray);
  }
  if (responsible) {
    query.ilike('responsible', `%${responsible}%`);
  }
  if (vertical) {
    query.in('vertical', [vertical.name, vertical.firestoreId]);
  }
  if (priority) {
    query.eq('priority_override->level', priority);
  }
  if (location) {
    query.ilike('location', `%${location}%`);
  }
  if (salaryGt) {
    query.gt('salary', salaryGt);
  }
  if (salaryLt) {
    query.lt('salary', salaryLt);
  }
  if (feeGt) {
    query.gt('fee_percentage', feeGt);
  }
  if (feeLt) {
    query.lt('fee_percentage', feeLt);
  }
  return query;
}

function applySorting(query: UseGetJobsQuery, options: UseGetJobsOptions) {
  const { sortField, sortDirection } = options;

  if (sortField && sortDirection) {
    applyJsonFields(query, sortField, sortDirection);
  }
}

function applyPagination(query: UseGetJobsQuery, options: UseGetJobsOptions) {
  const { page, pageSize } = options;
  const start = (page - 1) * pageSize;
  const end = start + pageSize - 1;
  query.range(start, end);
}

// Apply sorting to JSON array columns differently and exclude null and empty values
function applyJsonFields(query: UseGetJobsQuery, sortField: string, sortDirection: 'asc' | 'desc') {
  const field = snakeCase(sortField);
  if (sortField === 'workingOn') {
    // Sort by the first element of the array
    query.order(`${field}->0`, { ascending: sortDirection === 'asc' });
    query.not(field, 'eq', '[]');
    query.not(field, 'eq', '[null]');
  } else if (sortField === 'priorityOverride') {
    // order by priorityOverride json object field key 'level'
    query.order(`${field}->level`, { ascending: sortDirection === 'asc' });
  } else {
    query.order(field, { ascending: sortDirection === 'asc' });
    query.not(field, 'is', null);
    query.not(field, 'eq', '');
  }
}

// Apply unassigned and null status to the query
function applyUnassignedStatus(query: UseGetJobsQuery, statuses: string[]) {
  // Safety check - if statuses is not an array or empty, don't apply filtering
  if (!Array.isArray(statuses) || statuses.length === 0) {
    return;
  }

  if (statuses.includes('unassigned')) {
    query.or(`status.in.(${modifyUnassignedStatus(statuses).join(',')}),status.is.null`);
  } else {
    query.in('status', statuses);
  }
}

// Add "Unassigned" to the statuses array if "unassigned" is in the array
function modifyUnassignedStatus(statuses: string[]) {
  if (statuses.includes('unassigned')) {
    return [...statuses, 'Unassigned'];
  }
  return statuses;
}

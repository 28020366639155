import { Navigate, Outlet } from 'react-router-dom';
import { CircularProgress } from '@mui/joy';
import { Box } from '@mui/joy';
import { useAuth } from '../providers/AuthProvider';

/**
 * ProtectedRoute component that ensures a user is authenticated before rendering its children.
 * Redirects to login if no user is authenticated.
 */
const ProtectedRoute = () => {
  const { user, isLoading, isAuthenticated } = useAuth();

  // While checking authentication, show a loading indicator
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // If no user, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // User is authenticated, render the child routes
  return <Outlet />;
};

export default ProtectedRoute;

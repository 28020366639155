import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';

export function useGetTeamAssignmentsByOwner(owner: string | undefined) {
  return useQuery<CandidateJobAssignment[]>({
    enabled: !!owner,
    queryKey: ['teamAssignmentsByOwner', owner],
    queryFn: async () => {
      if (!owner) return [];
      // Get user to get their ID
      const { data: userData } = await supabase.from('users').select('*').eq('email', owner).single();
      if (!userData) return [];
      // Get team to get all member IDs
      const { data: teamData } = await supabase.from('teams').select('*').contains('members', [userData.id]).single();
      if (!teamData) return [];
      // Get all users (team members) to get their emails
      const { data: usersData } = await supabase.from('users').select('email').in('id', teamData.members);
      if (!usersData?.length) return [];
      const memberEmails = usersData.map((user) => user.email).filter(Boolean);
      // Get all assignments where owner is in member emails
      const { data: assignments } = await supabase.from('assignments').select('*').in('owner', memberEmails);
      return assignments ? toCamelCase<CandidateJobAssignment[]>(assignments) : [];
    },
  });
}

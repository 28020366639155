import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Option from '@mui/joy/Option';
import Select, { selectClasses } from '@mui/joy/Select';
import { useEffect, useRef, useState } from 'react';

import { useUpsertJob } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import './ResponsibleColumn.css'; // We'll reuse the avatar stack styles
import { parseResponsibleEmail, useResponsibles } from '@bluebird-monorepo/shared';

const responsibleMapping = {
  Dennis: {
    colorClass: 'mint-green',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U05QQC9SJ1W-8fccbf482c28-512',
    name: 'Dennis',
  },
  Jacob: {
    colorClass: 'blush-pink',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U06T7S16K6W-ab5ce9b172f2-192',
    name: 'Jacob',
  },
  Mitchel: {
    colorClass: 'pale-lavender',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U07RZ07JSDP-d3370936361e-192',
    name: 'Mitchel',
  },
  Pontus: {
    colorClass: 'powder-blue',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U075S4N9M6V-dec8ff679f17-192',
    name: 'Pontus',
  },
} as const;

type ResponsibleName = keyof typeof responsibleMapping;

export function WorkingOnColumn({ job }: { job: Job }) {
  const [isEditing, setIsEditing] = useState(false);
  const currentWorkingOn = job.workingOn || [];

  const { mutate: updateJobMutation } = useUpsertJob();
  const { responsibles } = useResponsibles();
  const ref = useRef<HTMLTableCellElement>(null);
  const selectRef = useRef<HTMLButtonElement>(null);
  const listboxRef = useRef<HTMLUListElement | null>(null);

  const handleChipClick = () => {
    setIsEditing(true);
  };

  const handleWorkingOnChange = async (_event: any, newValue: string[]) => {
    await updateJobMutation({
      id: job.id,
      workingOn: newValue,
    });
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isEditing) return;

      setTimeout(() => {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          selectRef.current &&
          !selectRef.current.contains(event.target as Node) &&
          listboxRef.current &&
          !listboxRef.current.contains(event.target as Node)
        ) {
          setIsEditing(false);
        }
      }, 100);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const listbox = document.querySelector('[role="listbox"]') as HTMLUListElement;
          if (listbox) {
            listboxRef.current = listbox;
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <span ref={ref}>
      {isEditing ? (
        <Select
          component="button"
          autoFocus
          listboxOpen={isEditing}
          multiple
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={handleWorkingOnChange}
          onClose={handleClose}
          ref={selectRef}
          size="sm"
          value={currentWorkingOn}
          slotProps={{
            listbox: {
              ref: listboxRef,
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
              },
            },
          }}
          sx={{
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
          }}
        >
          {responsibles.map((responsible) => (
            <Option key={responsible.name} value={responsible.name}>
              {responsible.name}
            </Option>
          ))}
        </Select>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, pl: '1rem' }}>
          <div className="avatar-stack">
            {currentWorkingOn.length > 0 ? (
              currentWorkingOn.map((responsible) => {
                const responsibleName = responsibleMapping[responsible as ResponsibleName]
                  ? responsible
                  : parseResponsibleEmail(responsible);
                const person = responsibleMapping[responsibleName as ResponsibleName];
                return person ? (
                  <img key={responsible} alt={person.name} onClick={handleChipClick} src={person.imgUrl} />
                ) : (
                  <Chip onClick={handleChipClick} size="sm" sx={{ cursor: 'pointer' }} variant="outlined">
                    {parseResponsibleEmail(responsible)}
                  </Chip>
                );
              })
            ) : (
              <Chip onClick={handleChipClick} size="sm" sx={{ cursor: 'pointer' }} variant="outlined">
                None
              </Chip>
            )}
          </div>
        </Box>
      )}
    </span>
  );
}

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemContent,
  Switch,
  Typography,
} from '@mui/joy';
import { LOG_TABLE_COLUMNS } from '../../logs.const';

interface LogsTableColumnsSectionProps {
  hiddenColumnIds: string[];
  toggleColumnVisibility: (columnId: string) => void;
}

export function ColumnsSection({ hiddenColumnIds, toggleColumnVisibility }: LogsTableColumnsSectionProps) {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary indicator={<ExpandMore />}>
        <Typography level="h4">
          Table Columns ({LOG_TABLE_COLUMNS.length - hiddenColumnIds.length}/{LOG_TABLE_COLUMNS.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {LOG_TABLE_COLUMNS.map((column) => (
            <ListItem key={column.id}>
              <Switch
                checked={!hiddenColumnIds.includes(column.id)}
                onChange={() => toggleColumnVisibility(column.id)}
              />
              <ListItemContent>
                <Typography>{column.name}</Typography>
              </ListItemContent>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

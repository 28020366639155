export interface StatusDocument {
  id: number;
  name: string;
  displayName: string;
}

const STATUSES: StatusDocument[] = [
  {
    id: 1,
    name: 'postponed',
    displayName: 'Postponed',
  },
  {
    id: 2,
    name: 'incoming',
    displayName: 'Incoming',
  },
  {
    id: 3,
    name: 'intros_welcome',
    displayName: "Intro's welcome",
  },
  {
    id: 4,
    name: 'verbally_accepted',
    displayName: 'Verbally accepted',
  },
  {
    id: 5,
    name: 'placed',
    displayName: 'Placed',
  },
  {
    id: 6,
    name: 'needs_work',
    displayName: 'Needs work',
  },
  {
    id: 7,
    name: 'closed',
    displayName: 'Closed',
  },
  {
    id: 8,
    name: 'working_on_it',
    displayName: 'Working on it',
  },
  {
    id: 9,
    name: 'on_hold',
    displayName: 'On hold (bb)',
  },
  {
    id: 10,
    name: 'full_pipeline',
    displayName: 'Full pipeline',
  },
  {
    id: 11,
    name: 'needs_work',
    displayName: 'Needs work',
  },
];

export const useStatuses = () => {
  return {
    statuses: STATUSES,
  };
};

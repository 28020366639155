import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { Add, FilterList, Search } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, IconButton, Input, Sheet, Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';
import { useFireflies } from './useFireflies';

const getStatusColor = (hasTranscript: boolean) => {
  return hasTranscript ? 'success' : 'neutral';
};

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('nl-NL', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }
  return `${minutes}m`;
};

const FirefliesPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: user } = useGetCurrentUser();
  const [searchQuery, setSearchQuery] = useState('');

  const { transcripts, isLoading, error } = useFireflies(user?.email || '');

  const handleUploadRecording = async () => {
    try {
      // TODO: Implement recording upload
      console.log('Upload recording');
    } catch (err) {
      console.error('Failed to upload recording:', err);
    }
  };

  const filteredTranscripts =
    searchQuery.trim() === ''
      ? transcripts
      : transcripts.filter((transcript) => {
          const searchLower = searchQuery.toLowerCase();
          return (
            transcript.meeting_attendees.some(
              (attendee) =>
                attendee.displayName.toLowerCase().includes(searchLower) ||
                attendee.email.toLowerCase().includes(searchLower),
            ) || transcript.summary?.overview.toLowerCase().includes(searchLower)
          );
        });

  if (!user) {
    return <CircularProgress />;
  }

  return (
    <Layout title="Conversations">
      <Box sx={{ p: 3 }}>
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography level="h1">Conversations</Typography>
          <Button onClick={handleUploadRecording} color="primary" startDecorator={<Add />} size="lg">
            Upload Recording
          </Button>
        </Box>

        {/* Search and Filter Bar */}
        <Sheet
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            borderRadius: 'lg',
            mb: 3,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center', flex: 1 }}>
            <Input
              placeholder="Search conversations..."
              startDecorator={<Search />}
              sx={{ flex: 1 }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IconButton variant="outlined" color="neutral">
              <FilterList />
            </IconButton>
          </Box>
        </Sheet>

        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Box sx={{ p: 2, bgcolor: 'danger.softBg', borderRadius: 'lg' }}>
            <Typography color="danger">{error.message}</Typography>
          </Box>
        )}

        {!isLoading && !error && (
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: 'lg',
              overflow: 'hidden',
            }}
          >
            <Table
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                tableLayout: 'fixed',
                '& th, & td': {
                  padding: '12px',
                },
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 200, padding: '12px' }}>Title</th>
                  <th style={{ width: 120, padding: '12px' }}>Date</th>
                  <th style={{ width: 100, padding: '12px' }}>Duration</th>
                  <th style={{ width: 250, padding: '12px' }}>Attendees</th>
                  <th style={{ width: 100, padding: '12px', textAlign: 'center' }}>Status</th>
                  <th style={{ width: 120, padding: '12px', textAlign: 'right' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredTranscripts.map((transcript) => (
                  <tr key={transcript.id}>
                    <td>
                      <Typography level="body-sm">{transcript.title}</Typography>
                    </td>
                    <td>
                      <Typography level="body-sm">{formatDate(transcript.date)}</Typography>
                    </td>
                    <td>
                      <Typography level="body-sm">{formatDuration(transcript.duration)}</Typography>
                    </td>
                    <td>
                      <Typography level="body-sm" noWrap>
                        {transcript.meeting_attendees.map((attendee) => attendee.displayName).join(', ')}
                      </Typography>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Chip
                        variant="soft"
                        size="sm"
                        color={getStatusColor(!!transcript.transcript_url)}
                        sx={{ minWidth: 70, justifyContent: 'center' }}
                      >
                        {transcript.transcript_url ? 'Available' : 'Processing'}
                      </Chip>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Button
                        size="sm"
                        variant="outlined"
                        disabled={!transcript.transcript_url}
                        onClick={() => navigate(`/fireflies/${transcript.id}`)}
                        sx={{ minWidth: 60 }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        )}
      </Box>
    </Layout>
  );
};

export default FirefliesPage;

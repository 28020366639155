import { LogsTable } from '@bluebird-monorepo/notifications';
import { useCandidateTimelineTableSettings } from './useCandidateTimelineTableSettings';
import { Typography, Card } from '@mui/joy';

export function CandidateTimelineTable({ candidateId }: { candidateId: number }) {
  const { hiddenColumnIds, toggleColumnVisibility } = useCandidateTimelineTableSettings();
  return (
    <>
      <Card variant="outlined" sx={{ mb: 2, p: 3, bgcolor: 'white' }}>
        <Typography level="title-lg">Assignments Timeline</Typography>
        <LogsTable
          tableName="assignments"
          hiddenColumnIds={hiddenColumnIds}
          toggleColumnVisibility={toggleColumnVisibility}
          dataFilters={[{ field: 'candidate_id', value: candidateId }]}
          defaultViewMode="timeline"
        />
      </Card>
      <Card variant="outlined" sx={{ p: 3, bgcolor: 'white' }}>
        <Typography level="title-lg">Candidate Timeline</Typography>
        <LogsTable
          tableName="candidates"
          entityId={candidateId}
          hiddenColumnIds={hiddenColumnIds}
          toggleColumnVisibility={toggleColumnVisibility}
          defaultViewMode="timeline"
        />
      </Card>
    </>
  );
}

import { Card, CardProps } from '@mui/joy';
import React from 'react';

type BluebirdCardProps = CardProps;

const BluebirdCard: React.FC<BluebirdCardProps> = ({ children, sx, ...props }) => {
  return (
    <Card
      sx={{
        background: 'rgba(255, 255, 255, 1)',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
        height: '100%',
        padding: '24px',
        position: 'relative',
        width: '100%',
        zIndex: 1,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Card>
  );
};

export { BluebirdCard };

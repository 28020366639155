import { CompanyHeader, TabsContentComponent, CompaniesTimelineTable } from '@bluebird-monorepo/companies';
import { CreateJobWizard } from '@bluebird-monorepo/jobs';
import { Company, Job } from '@bluebird-monorepo/types';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Tab,
  TabList,
  Tabs,
  Typography,
} from '@mui/joy';
import { useGetCompanyById, useGetCompanies } from '@bluebird-monorepo/companies';
import { useGetJobsByCompanyId } from '@bluebird-monorepo/jobs';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { memo, useCallback, useState, useEffect } from 'react';
import { Navigate, Route, Link as RouterLink, Routes, useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  Layout,
  LayoutContentHeader,
  LayoutTitleBox,
  LayoutTitle,
  LayoutSubtitle,
  LayoutActionButtonBox,
} from '../../../layout/Layout';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useCompaniesTableStore } from '@bluebird-monorepo/companies';
import { debug } from '../../config/environment';

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
  });
}

const MemoizedCreateJobWizard = memo(CreateJobWizard);

const LoadingSkeleton = () => (
  <Box sx={{ width: '100%' }}>
    <Skeleton variant="rectangular" width="100%" height={200} />
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={48} />
    </Box>
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={400} />
    </Box>
  </Box>
);

const JobsList: React.FC<{ companyId: number; company: Company }> = ({ companyId, company }) => {
  const [isCreatingJob, setIsCreatingJob] = useState(false);
  const { data: jobs, isLoading } = useGetJobsByCompanyId(companyId);

  // Memoize handlers to prevent unnecessary re-renders
  const handlers = {
    handleStartCreateJob: useCallback(() => {
      setIsCreatingJob(true);
    }, []),

    handleCompleteCreateJob: useCallback(() => {
      setIsCreatingJob(false);
    }, []),

    handleCancelCreateJob: useCallback(() => {
      setIsCreatingJob(false);
    }, []),
  };

  if (isCreatingJob) {
    return (
      <MemoizedCreateJobWizard
        initialData={{
          companyId: company.id,
          companyName: company.name,
          location: company.location,
          feePercentage: company.agreedFee,
        }}
        onComplete={handlers.handleCompleteCreateJob}
        onCancel={handlers.handleCancelCreateJob}
      />
    );
  }

  if (isLoading) return <Skeleton variant="rectangular" width="100%" height={200} />;
  if (!jobs?.length)
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography level="body-lg" sx={{ color: 'neutral.500' }}>
          No jobs found
        </Typography>
        <Button startDecorator={<AddIcon />} onClick={handlers.handleStartCreateJob} sx={{ mt: 2 }}>
          Create First Job
        </Button>
      </Box>
    );

  return (
    <Stack spacing={2}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button startDecorator={<AddIcon />} onClick={handlers.handleStartCreateJob}>
          Add Job
        </Button>
      </Box>
      {jobs.map((job: Job) => (
        <Card key={job.id} variant="outlined">
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Box>
                <Typography level="title-lg">{job.jobTitle}</Typography>
                <Stack direction="row" spacing={2} useFlexGap>
                  {job.location && <Typography level="body-sm">{job.location}</Typography>}
                  {job.contractType && <Typography level="body-sm">{job.contractType}</Typography>}
                  {job.salary && (
                    <Typography level="body-sm">
                      {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(job.salary)}
                    </Typography>
                  )}
                  {job.status && (
                    <Chip size="sm" variant="soft" color={job.status === 'active' ? 'success' : 'neutral'}>
                      {job.status}
                    </Chip>
                  )}
                </Stack>
              </Box>
              <Button component={RouterLink} to={`/jobs/${job.id}`} variant="plain" endDecorator="→">
                View Details
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

const CompanyDetailPage: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const companyIdNumber = isNaN(Number(companyId)) ? 0 : Number(companyId);
  const { data: company, isLoading, error } = useGetCompanyById(companyIdNumber);
  const { data: jobs } = useGetJobsByCompanyId(companyIdNumber);
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { page, setPage, rowsPerPage: rowsPerPageStored, sortConfig, filters, search } = useCompaniesTableStore();
  useEffect(() => {
    setRowsPerPage(rowsPerPageStored);
  }, []);

  // Fetch current page
  const { data: currentPageData } = useGetCompanies({
    page,
    pageSize: rowsPerPage,
    sortField: sortConfig.field || undefined,
    sortDirection: sortConfig.direction,
    search,
    status: filters.statusFilter,
  });

  // Fetch previous page if not on first page
  const { data: previousPageData } = useGetCompanies({
    page: page > 1 ? page - 1 : 1,
    pageSize: rowsPerPage,
    sortField: sortConfig.field || undefined,
    sortDirection: sortConfig.direction,
    search,
    status: filters.statusFilter,
  });

  // Combine companies from both pages
  const companies = [...(previousPageData?.companies || []), ...(currentPageData?.companies || [])];

  const currentIndex = companies.findIndex((c) => c.id === companyIdNumber);
  const prevCompany = currentIndex > 0 ? companies[currentIndex - 1] : null;
  const nextCompany = currentIndex < companies.length - 1 ? companies[currentIndex + 1] : null;

  useEffect(() => {
    if (!nextCompany) {
      setRowsPerPage(rowsPerPage * (page + 1));
    }
  }, [nextCompany]);

  const handleNavigate = (id: number) => {
    navigate(`/companies/${id}`);
  };

  const handlePreviousPage = useCallback(async () => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  if (isLoading)
    return (
      <Layout areCompoundComponents>
        <LoadingSkeleton />
      </Layout>
    );
  if (error)
    return (
      <Layout areCompoundComponents>
        <Typography color="danger">{error.message}</Typography>
      </Layout>
    );
  if (!company)
    return (
      <Layout areCompoundComponents>
        <Typography>Company not found</Typography>
      </Layout>
    );

  const tabs = [
    { id: 'general', label: 'General', path: `/companies/${company.id}/general` },
    { id: 'jobs', label: `Jobs (${jobs?.length || 0})`, path: `/companies/${company.id}/jobs` },
    { id: 'finance', label: 'Finance', path: `/companies/${company.id}/finance` },
    {
      id: 'people',
      label: `People (${(company.contactPersons?.length || 0) + (company.hiringManagers?.length || 0)})`,
      path: `/companies/${company.id}/people`,
    },
    { id: 'timeline', label: 'Timeline', path: `/companies/${company.id}/timeline` },
  ];

  const currentPath = location.pathname;
  const activeTab = tabs.find((tab) => currentPath.endsWith(tab.id)) || tabs[0];

  return (
    <Layout areCompoundComponents>
      <LayoutContentHeader>
        <LayoutTitleBox>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <LayoutTitle>{company.name}</LayoutTitle>
          </Box>
          <LayoutSubtitle>View and manage company details</LayoutSubtitle>
        </LayoutTitleBox>
        <LayoutActionButtonBox>
          <IconButton
            onClick={() => {
              if (prevCompany) {
                handleNavigate(prevCompany.id);
              } else if (page > 1) {
                handlePreviousPage();
              }
            }}
            disabled={!prevCompany && page <= 1}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() => nextCompany && handleNavigate(nextCompany.id)}
            disabled={!nextCompany}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <ChevronRight />
          </IconButton>
        </LayoutActionButtonBox>
      </LayoutContentHeader>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        <CompanyHeader company={company} />

        <Tabs value={activeTab.id}>
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.id} value={tab.id} component={RouterLink} to={tab.path}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Routes>
          <Route path="/" element={<Navigate to="general" replace />} />
          <Route path="general" element={<TabsContentComponent company={company} tabIndex={0} />} />
          <Route path="jobs" element={<JobsList companyId={company.id} company={company} />} />
          <Route path="finance" element={<TabsContentComponent company={company} tabIndex={1} />} />
          <Route path="people" element={<TabsContentComponent company={company} tabIndex={2} />} />
          <Route path="timeline" element={<CompaniesTimelineTable companyId={company.id} />} />
          <Route path="*" element={<Navigate to="general" replace />} />
        </Routes>
      </Box>
    </Layout>
  );
};

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  CompanyDetailPage.whyDidYouRender = true;
}

export default CompanyDetailPage;

import { User } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';

export function useGetUserById(id: number | undefined) {
  return useQuery<User | null>({
    enabled: !!id,
    queryKey: ['userById', id],
    queryFn: () => getUserById(id),
  });
}

export async function getUserById(id: number | undefined) {
  const { data } = await supabase.from('users').select('*').eq('id', id).single();
  return data ? toCamelCase<User>(data) : null;
}

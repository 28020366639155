import { ColumnDefinition } from '@bluebird-monorepo/bluebird-ui';
import { useGetJobById } from '@bluebird-monorepo/jobs';
import { useGetCandidateById } from '@bluebird-monorepo/candidates';
import { useGetUserById } from '@bluebird-monorepo/users';
import { Avatar, Box, Typography } from '@mui/joy';

export function createColumns(): ColumnDefinition[] {
  return [
    {
      field: 'content',
      headerName: 'Content',
      width: '40%',
      renderCell: ({ row }) => {
        const content = row.content;
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
      },
    },
    {
      field: 'jobId',
      headerName: 'Job',
      width: '20%',
      renderCell: ({ row }) => <JobCell jobId={row.jobId} />,
    },
    {
      field: 'candidateId',
      headerName: 'Candidate',
      width: '20%',
      renderCell: ({ row }) => <CandidateCell candidateId={row.candidateId} />,
    },
    {
      field: 'authorId',
      headerName: 'Author',
      width: '20%',
      renderCell: ({ row }) => <AuthorCell authorId={row.authorId} />,
    },
  ];
}

const JobCell = ({ jobId }: { jobId: number }) => {
  const { data: job, isLoading } = useGetJobById(jobId);
  if (isLoading) return <Typography>Loading...</Typography>;
  return <Typography>{job?.jobTitle || 'Unknown'}</Typography>;
};

const CandidateCell = ({ candidateId }: { candidateId: number }) => {
  const { data: candidate, isLoading } = useGetCandidateById(candidateId);
  if (isLoading) return <Typography>Loading...</Typography>;
  if (!candidate) return <Typography>Unknown</Typography>;
  return <Typography>{`${candidate?.firstName} ${candidate?.lastName}`}</Typography>;
};

const AuthorCell = ({ authorId }: { authorId: number }) => {
  const { data: author, isLoading } = useGetUserById(authorId);
  if (isLoading) return <Typography>Loading...</Typography>;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Avatar src={author?.photoUrl} size="sm">
        {author?.displayName?.[0] || author?.email?.[0]}
      </Avatar>
      <Typography>{author?.displayName || author?.email}</Typography>
    </Box>
  );
};

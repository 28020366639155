import { TaskboardItem, TaskboardPriority } from '@bluebird-monorepo/types';
import { useGetUserById } from '@bluebird-monorepo/users';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import { AccessTime as AccessTimeIcon, Assignment as AssignmentIcon, Flag as FlagIcon } from '@mui/icons-material';
import { Avatar, Box, Card, Chip, ColorPaletteProp, Typography } from '@mui/joy';
import { FC, useEffect, useMemo, useState } from 'react';

interface TaskCardProps {
  task: TaskboardItem;
  index: number;
}

const priorityColors: Record<TaskboardPriority, ColorPaletteProp> = {
  low: 'success',
  medium: 'warning',
  high: 'danger',
};

const getDaysDifference = (date1: Date, date2: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffDays = Math.round((date1.getTime() - date2.getTime()) / oneDay);
  return diffDays;
};

export const TaskCard: FC<TaskCardProps> = ({ task, index }) => {
  const { data: assignedUser } = useGetUserById(task.assignedTo);
  const [dueDate, setDueDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!task.dueDate) {
      setDueDate(null);
      return;
    }
    const date = task.dueDate || new Date(task.dueDate);
    setDueDate(date);
  }, [task.dueDate]);

  const isDueDate = useMemo(() => {
    if (!dueDate) return false;
    return new Date() > dueDate;
  }, [dueDate]);

  const formatDueDate = (date: Date) => {
    const days = getDaysDifference(date, new Date());
    if (days === 0) return 'Due today';
    if (days < 0) return `${Math.abs(days)} days overdue`;
    return `Due in ${days} days`;
  };

  return (
    <Draggable draggableId={String(task.id)} index={index}>
      {(provided: DraggableProvided) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          variant="outlined"
          sx={{
            mb: 1,
            cursor: 'grab',
            '&:hover': {
              bgcolor: 'background.level2',
            },
          }}
        >
          <Box>
            <Typography level="title-sm" sx={{ mb: 1 }}>
              {task.title}
            </Typography>
            <Typography level="body-sm" sx={{ mb: 2 }}>
              {task.description}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              {/* Priority */}
              <Chip variant="soft" color={priorityColors[task.priority]} size="sm" startDecorator={<FlagIcon />}>
                {task.priority}
              </Chip>

              {/* Due Date */}
              {dueDate && (
                <Chip
                  variant="soft"
                  color={isDueDate ? 'danger' : 'neutral'}
                  size="sm"
                  startDecorator={<AccessTimeIcon />}
                >
                  {formatDueDate(dueDate)}
                </Chip>
              )}

              {/* Assignee */}
              {assignedUser && (
                <Chip
                  variant="soft"
                  size="sm"
                  startDecorator={
                    assignedUser.photoUrl ? (
                      <Avatar size="sm" src={assignedUser.photoUrl} alt={assignedUser.displayName || ''} />
                    ) : (
                      <AssignmentIcon />
                    )
                  }
                >
                  {assignedUser.displayName || assignedUser.email}
                </Chip>
              )}
            </Box>
          </Box>
        </Card>
      )}
    </Draggable>
  );
};

import { CandidateCard, KanbanColumn, ReorderCardsArgs, ReorderCardsResult } from '../types';

// Helper function to reorder items in a list
function reorder<TItem>(list: TItem[], startIndex: number, endIndex: number): TItem[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export const reorderCards = ({
  destination,
  source,
  cardMap,
  selectedCardIds,
}: ReorderCardsArgs): ReorderCardsResult => {
  const current: CandidateCard[] = [...cardMap[source.droppableId]];
  const next: CandidateCard[] = [...cardMap[destination.droppableId]];
  const target: CandidateCard = current[source.index];
  // Moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered: CandidateCard[] = reorder(current, source.index, destination.index);
    const result: KanbanColumn = {
      ...cardMap,
      [source.droppableId]: reordered,
    };
    return {
      cardMap: result,
    };
  }

  // Moving to different list
  if (selectedCardIds && selectedCardIds.size > 1) {
    // Get all selected items from the source
    const selectedCards = current.filter((card) => selectedCardIds.has(card.id));

    // Remove all selected items from source
    const newCurrent = current.filter((card) => !selectedCardIds.has(card.id));

    // Insert all selected items at the destination
    const newNext = [...next];
    newNext.splice(destination.index, 0, ...selectedCards);

    const result: KanbanColumn = {
      ...cardMap,
      [source.droppableId]: newCurrent,
      [destination.droppableId]: newNext,
    };

    return {
      cardMap: result,
    };
  } else {
    // Single item move
    current.splice(source.index, 1);
    next.splice(destination.index, 0, target);

    const result: KanbanColumn = {
      ...cardMap,
      [destination.droppableId]: next,
      [source.droppableId]: current,
    };
    return {
      cardMap: result,
    };
  }
};

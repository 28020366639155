import { CssBaseline } from '@mui/joy';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import {
  THEME_ID as MATERIAL_THEME_ID,
  ThemeProvider as MaterialCssVarsProvider,
  createTheme as materialExtendTheme,
} from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { pdfjs } from 'react-pdf';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import DashboardPage from './pages/DashboardPage';
import EditJobPage from './pages/jobs/EditJobPage';
import JobDetailsPage, {
  CandidatesTab,
  EventsTab,
  GeneralTab,
  TasksTab,
  TimelineTab,
} from './pages/jobs/JobDetailPage';
import JobsPage from './pages/jobs/JobsPage';
import UserSettingsPage from './pages/settings/UserSettingsPage';
import SignInPage from './pages/SignInPage';
import ProtectedRoute from './components/ProtectedRoute';
import { logging } from './config/environment';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const materialTheme = materialExtendTheme();

import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import CalendarPage from './pages/calendar/CalendarPage';
import CandidateDetailPage from './pages/candidates/CandidateDetailPage';
import CandidatesPage from './pages/candidates/CandidatesPage';
import CompaniesPage from './pages/companies/CompaniesPage';
import CompanyDetailPage from './pages/companies/CompanyDetailPage';
import EditCompanyPage from './pages/companies/EditCompanyPage';
import FirefliesPage from './pages/fireflies/FirefliesPage';
import TranscriptDetailPage from './pages/fireflies/TranscriptDetailPage';
import { IntroductionsPage } from './pages/introductions/IntroductionsPage';
import InvoicesPage from './pages/invoices/InvoicesPage';
import MetricsPage from './pages/metrics/MetricsPage';
import PipelinePage from './pages/pipeline/PipelinePage';
import PlacementDetailPage from './pages/placements/PlacementDetailPage';
import PlacementsPage from './pages/placements/PlacementPage';
import TasksPage from './pages/tasks/TasksPage';
import TeamDetailPage from './pages/teams/TeamDetailPage';
import UserDetailPage from './pages/users/UserDetailPage';
import UsersPage from './pages/users/UsersPage';
import CreateCompanyPage from './pages/companies/CreateCompanyPage';
import CreateCandidatePage from './pages/candidates/CreateCandidatePage';
import EditCandidatePage from './pages/candidates/EditCandidatePage';
import CreateJobPage from './pages/jobs/CreateJobPage';
import LogsPage from './pages/logs/LogsPage';
const SLOW_QUERY_THRESHOLD = 1000; // 1 second

function useQueryPerformance() {
  const queryClient = useQueryClient();
  const { isQueryLoggingEnabled } = logging;

  useEffect(() => {
    // Skip if query logging is disabled
    if (!isQueryLoggingEnabled) return;

    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      if (event?.type === 'updated' && event.query.state.status === 'success') {
        const duration =
          event.query.state.fetchStatus === 'fetching' ? event.query.state.dataUpdatedAt - Date.now() : 0;
        if (duration > SLOW_QUERY_THRESHOLD) {
          console.warn(`Slow query detected: ${event.query.queryKey.join('/')} took ${duration}ms`);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient, isQueryLoggingEnabled]);
}

function App() {
  useQueryPerformance();

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline enableColorScheme />
          <Router>
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={<SignInPage />} />
              <Route path="/sign-in" element={<SignInPage />} />

              {/* Protected routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/pipeline" element={<PipelinePage />} />
                <Route path="/pipeline/my" element={<PipelinePage />} />
                <Route path="/pipeline/team" element={<PipelinePage />} />
                <Route path="/pipeline/job" element={<PipelinePage />} />
                <Route element={<JobsPage />} path="/jobs" />
                <Route element={<CreateJobPage />} path="/jobs/create" />
                <Route path="/jobs/:jobId" element={<JobDetailsPage />}>
                  <Route index element={<Navigate to="general" replace />} />
                  <Route path="general" element={<GeneralTab />} />
                  <Route path="candidates" element={<CandidatesTab />} />
                  <Route path="events" element={<EventsTab />} />
                  <Route path="timeline" element={<TimelineTab />} />
                  <Route path="tasks" element={<TasksTab />} />
                  <Route path="*" element={<Navigate to="general" replace />} />
                </Route>
                <Route path="/jobs/:jobId/edit" element={<EditJobPage />} />

                <Route element={<CompaniesPage />} path="/companies" />
                <Route element={<CreateCompanyPage />} path="/companies/create" />
                <Route element={<CreateCandidatePage />} path="/candidates/create" />
                <Route path="/companies/:companyId/*" element={<CompanyDetailPage />} />
                <Route path="/companies/:companyId/edit" element={<EditCompanyPage />} />
                <Route element={<CandidatesPage />} path="/candidates" />
                <Route element={<CandidateDetailPage />} path="/candidates/:candidateId/*" />
                <Route element={<EditCandidatePage />} path="/candidates/:candidateId/edit" />

                <Route element={<UsersPage />} path="/users" />
                <Route element={<UserDetailPage />} path="/users/:userId" />
                <Route element={<TeamDetailPage />} path="/teams/:teamId" />

                <Route element={<PlacementsPage />} path="/placements" />
                <Route element={<PlacementDetailPage />} path="/placements/:placementId" />

                <Route element={<InvoicesPage />} path="/invoices" />
                <Route element={<FirefliesPage />} path="/fireflies" />
                <Route element={<TranscriptDetailPage />} path="/fireflies/:transcriptId" />

                <Route element={<CalendarPage />} path="/calendar" />
                <Route element={<TasksPage />} path="/tasks" />

                <Route path="/user-settings/:userId" element={<UserSettingsPage />} />
                <Route path="/introductions" element={<IntroductionsPage />} />
                <Route path="/logs" element={<LogsPage />} />
                <Route path="/metrics" element={<MetricsPage />} />
              </Route>

              {/* Fallback route */}
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        </LocalizationProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;

import { BluebirdTable } from '@bluebird-monorepo/bluebird-ui';
import { useGetIntroductions } from '../../api/introductions/useGetIntroductions';
import { Box } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import IntroductionTableFilters from './IntroductionTableFilters';
import debounce from '@mui/material/utils/debounce';
import { createColumns } from './IntroductionTableColumns';
import { Introduction } from '@bluebird-monorepo/types';

interface SortConfig {
  field: keyof Pick<Introduction, 'createdAt'> | null;
  direction: 'asc' | 'desc';
}

interface IntroductionTableProps {
  candidateId?: number;
  jobId?: number;
}

export function IntroductionTable({ candidateId, jobId }: IntroductionTableProps) {
  const columns = createColumns();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: 'createdAt',
    direction: 'desc',
  });
  const [search, setSearch] = useState('');
  const { control, watch } = useForm({
    defaultValues: {
      filterContent: '',
      authorId: null,
    },
  });

  useEffect(() => {
    const debouncedCb = debounce((formValues) => setSearch(formValues.filterContent), 1000);
    const subscription = watch(debouncedCb);
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleSort = (field: string) => {
    setSortConfig((prev) => ({
      field: field as SortConfig['field'],
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const { data } = useGetIntroductions({
    page,
    pageSize: rowsPerPage,
    sortField: sortConfig.field ?? undefined,
    sortDirection: sortConfig.direction,
    search,
    candidateId,
    jobId,
    authorId: watch('authorId'),
  });
  const { introductions, count: introductionsSize } = data || {
    introductions: [],
    count: 0,
  };

  return (
    <Box>
      <IntroductionTableFilters control={control} onResetPage={() => setPage(1)} />

      <BluebirdTable
        data={introductions}
        columns={columns}
        count={introductionsSize}
        defaultRowsPerPage={10}
        rowsPerPageOptions={[10, 25, 50]}
        onSort={handleSort}
        page={page}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </Box>
  );
}

import { Box, FormControl, Input, Select, Option, FormLabel, Drawer, IconButton } from '@mui/joy';
import { Control, Controller } from 'react-hook-form';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useGetUsers } from '@bluebird-monorepo/users';
import { parseResponsibleEmail } from '@bluebird-monorepo/shared';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { ColumnsSection } from './ColumnsSection';

interface LogsTableFiltersProps {
  control: Control<any>;
  onResetPage: () => void;
  hiddenColumnIds?: string[];
  toggleColumnVisibility?: (columnId: string) => void;
}

export function LogsTableFilters({
  control,
  onResetPage,
  hiddenColumnIds,
  toggleColumnVisibility,
}: LogsTableFiltersProps) {
  const { data: users } = useGetUsers();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          mb: 2,
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'neutral.outlinedBorder',
            borderRadius: '4px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'flex-end',
            minWidth: 'min-content', // Ensures the container doesn't shrink smaller than its content
            pb: 1, // Add padding to show scrollbar without cutting content
          }}
        >
          <FormControl sx={{ width: 300, flexShrink: 0 }}>
            <Controller
              name="filterContent"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  sx={{
                    height: '56px',
                  }}
                  placeholder="Search logs..."
                  startDecorator={<SearchRoundedIcon />}
                  onChange={(e) => {
                    field.onChange(e);
                    onResetPage();
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ width: 200, flexShrink: 0 }}>
            <FormLabel>Author</FormLabel>
            <Controller
              name="authorId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{
                    height: '56px',
                  }}
                  onChange={(_, value) => {
                    field.onChange(value);
                    onResetPage();
                  }}
                >
                  <Option key="All" value={null}>
                    All
                  </Option>
                  {users?.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.displayName || parseResponsibleEmail(user.email)}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <FormControl sx={{ width: 200, flexShrink: 0 }}>
            <FormLabel>Action</FormLabel>
            <Controller
              name="action"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{
                    height: '56px',
                  }}
                  onChange={(_, value) => {
                    field.onChange(value);
                    onResetPage();
                  }}
                >
                  <Option key="All" value={null}>
                    All
                  </Option>
                  {['create', 'update', 'delete'].map((action) => (
                    <Option key={action} value={action}>
                      {action.charAt(0).toUpperCase() + action.slice(1)}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <FormControl sx={{ width: 200, flexShrink: 0 }}>
            <FormLabel>Start Date</FormLabel>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    onResetPage();
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ width: 200, flexShrink: 0 }}>
            <FormLabel>End Date</FormLabel>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    onResetPage();
                  }}
                />
              )}
            />
          </FormControl>

          <IconButton
            variant="outlined"
            color="neutral"
            onClick={() => setIsFilterDrawerOpen(true)}
            size="sm"
            sx={{
              backgroundColor: 'white',
              borderColor: 'var(--joy-palette-neutral-outlinedBorder)',
              '&:hover': {
                borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder)',
                backgroundColor: 'var(--joy-palette-background-level1)',
              },
              height: '56px',
              width: '56px',
              flexShrink: 0,
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        size="sm"
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'var(--joy-palette-background-surface)',
            boxShadow: 'var(--joy-shadow-lg)',
          },
        }}
      >
        <Box sx={{ px: 3, pb: 3 }}>
          <ColumnsSection
            hiddenColumnIds={hiddenColumnIds || []}
            toggleColumnVisibility={toggleColumnVisibility || (() => null)}
          />
        </Box>
      </Drawer>
    </>
  );
}

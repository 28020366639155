import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { Add, ArrowDownward, FilterList, Search } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, IconButton, Input, Sheet, Table, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { Layout } from '../../../layout/Layout';
import { useWeFact } from './useWeFact';

const getStatusColor = (status: string) => {
  switch (status) {
    case '0':
      return 'neutral';
    case '1':
      return 'warning';
    case '2':
      return 'success';
    case '3':
      return 'danger';
    default:
      return 'neutral';
  }
};

const getStatusLabel = (status: string) => {
  switch (status) {
    case '0':
      return 'Draft';
    case '1':
      return 'Pending';
    case '2':
      return 'Paid';
    case '3':
      return 'Expired';
    default:
      return 'Unknown';
  }
};

const formatCurrency = (amount: number | string, currency = 'EUR') => {
  const numAmount = Number(amount);
  return new Intl.NumberFormat('nl-NL', { style: 'currency', currency }).format(numAmount);
};

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('nl-NL', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const InvoicesPage: React.FC = () => {
  const { data: user } = useGetCurrentUser();
  const { invoices, isLoading, error, fetchInvoices, createInvoice, getInvoice } = useWeFact();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (user) {
      fetchInvoices();
    }
  }, [user, fetchInvoices]);

  const handleCreateInvoice = async () => {
    try {
      // TODO: Implement create invoice form
      await createInvoice({
        // Add invoice details from form
      });
    } catch (err) {
      console.error('Failed to create invoice:', err);
    }
  };

  const handleViewInvoice = async (invoiceCode: string) => {
    try {
      // TODO: Implement view/edit invoice modal or navigation
      const invoice = await getInvoice(invoiceCode);
      console.log('Invoice details:', invoice);
    } catch (err) {
      console.error('Failed to fetch invoice details:', err);
    }
  };

  const filteredInvoices = (
    searchQuery.trim() === ''
      ? invoices
      : invoices.filter((invoice) => {
          const searchLower = searchQuery.toLowerCase();
          return (
            invoice.InvoiceNumber?.toLowerCase().includes(searchLower) ||
            invoice.CompanyName?.toLowerCase().includes(searchLower) ||
            invoice.DebtorCode?.toLowerCase().includes(searchLower) ||
            false
          );
        })
  ).sort((a, b) => {
    // Sort by date in descending order (newest first)
    return new Date(b.Date).getTime() - new Date(a.Date).getTime();
  });

  if (!user) {
    return <CircularProgress />;
  }

  return (
    <Layout title="Invoices">
      <Box sx={{ p: 3 }}>
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography level="h1">Invoices</Typography>
          <Button onClick={handleCreateInvoice} color="primary" startDecorator={<Add />} size="lg">
            Create Invoice
          </Button>
        </Box>

        {/* Search and Filter Bar */}
        <Sheet
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            borderRadius: 'lg',
            mb: 3,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center', flex: 1 }}>
            <Input
              placeholder="Search invoices..."
              startDecorator={<Search />}
              sx={{ flex: 1 }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IconButton variant="outlined" color="neutral">
              <FilterList />
            </IconButton>
          </Box>
        </Sheet>

        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Box sx={{ p: 2, bgcolor: 'danger.softBg', borderRadius: 'lg' }}>
            <Typography color="danger">{error}</Typography>
          </Box>
        )}

        {!isLoading && !error && (
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: 'lg',
              overflow: 'hidden',
            }}
          >
            <Table
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                tableLayout: 'fixed',
                '& th, & td': {
                  padding: '12px',
                },
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 120, padding: '12px' }}>Invoice Num.</th>
                  <th style={{ width: 250, padding: '12px' }}>Company</th>
                  <th style={{ width: 120, padding: '12px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      Date
                      <ArrowDownward sx={{ fontSize: 16, opacity: 0.5 }} />
                    </Box>
                  </th>
                  <th style={{ width: 120, padding: '12px' }}>Due Date</th>
                  <th style={{ width: 120, padding: '12px', textAlign: 'right' }}>Amount excl.</th>
                  <th style={{ width: 120, padding: '12px', textAlign: 'right' }}>Amount</th>
                  <th style={{ width: 120, padding: '12px', textAlign: 'right' }}>Outstanding</th>
                  <th style={{ width: 100, padding: '12px', textAlign: 'center' }}>Status</th>
                  <th style={{ width: 80, padding: '12px', textAlign: 'right' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredInvoices.map((invoice) => (
                  <tr key={invoice.Identifier}>
                    <td>
                      <Typography level="body-sm">{invoice.DebtorCode}</Typography>
                    </td>
                    <td>
                      <Typography level="body-sm">{invoice.CompanyName}</Typography>
                      <Typography level="body-xs" color="neutral">
                        {invoice.Initials} {invoice.SurName}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-sm">{formatDate(invoice.Date)}</Typography>
                    </td>
                    <td>
                      <Typography level="body-sm">{formatDate(invoice.PayBefore)}</Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography level="body-sm" fontWeight="md">
                        {formatCurrency(invoice.AmountExcl, invoice.Currency)}
                      </Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography level="body-sm" fontWeight="md">
                        {formatCurrency(invoice.AmountIncl, invoice.Currency)}
                      </Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography
                        level="body-sm"
                        color={Number(invoice.AmountOutstanding) > 0 ? 'danger' : 'success'}
                        fontWeight="md"
                      >
                        {formatCurrency(invoice.AmountOutstanding, invoice.Currency)}
                      </Typography>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Chip
                        variant="soft"
                        size="sm"
                        color={getStatusColor(invoice.Status)}
                        sx={{ minWidth: 70, justifyContent: 'center' }}
                      >
                        {getStatusLabel(invoice.Status)}
                      </Chip>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Button
                        size="sm"
                        variant="outlined"
                        onClick={() => handleViewInvoice(invoice.InvoiceCode)}
                        sx={{ minWidth: 60 }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        )}
      </Box>
    </Layout>
  );
};

export default InvoicesPage;

import { supabase } from '@bluebird-monorepo/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteNote() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (noteId: number) => {
      const { error } = await supabase.from('notes').delete().eq('id', noteId);
      if (error) throw error;
      return noteId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes'] });
    },
  });
}

import { BluebirdTable } from '@bluebird-monorepo/bluebird-ui';
import { useGetCandidates } from '../../api/candidates/useGetCandidates';
import { Box } from '@mui/joy';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CandidatesTableFilters from './CandidatesTableFilters';
import debounce from '@mui/material/utils/debounce';
import { useNavigate } from 'react-router-dom';
import { useGetUsers, useGetCurrentUser } from '@bluebird-monorepo/users';
import { createColumns } from './CandidatesTableColumns';
import { useCandidatesTableStore } from '../../store/candidatesTable.store';

export function CandidatesTable() {
  const navigate = useNavigate();
  const { data: users } = useGetUsers();
  const { data: currentUser } = useGetCurrentUser();

  const {
    sortConfig,
    setSortConfig,
    rowsPerPage,
    setRowsPerPage,
    filters,
    setFilters,
    search,
    setSearch,
    showOnlyMine,
    setShowOnlyMine,
    page,
    setPage,
  } = useCandidatesTableStore();

  const columns = createColumns({
    users,
    onNavigate: navigate,
  });

  const { control, watch } = useForm({
    defaultValues: {
      filterName: search,
      showOnlyMine,
    },
  });

  const showOnlyMineForm = watch('showOnlyMine');

  useEffect(() => {
    setShowOnlyMine(showOnlyMineForm);
  }, [showOnlyMineForm, setShowOnlyMine]);

  useEffect(() => {
    const debouncedCb = debounce((formValues) => setSearch(formValues.filterName), 1000);
    const subscription = watch(debouncedCb);
    return () => subscription.unsubscribe();
  }, [watch, setSearch]);

  const { data } = useGetCandidates({
    page,
    pageSize: rowsPerPage,
    sortField: sortConfig.field ?? undefined,
    sortDirection: sortConfig.direction,
    search,
    owner: showOnlyMine ? currentUser?.email : undefined,
    filters,
  });

  const { candidates, count: candidatesSize } = data || {
    candidates: [],
    count: 0,
  };

  const handleSort = (field: string) => {
    setSortConfig({
      field: field as typeof sortConfig.field,
      direction: sortConfig.field === field && sortConfig.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <Box>
      <CandidatesTableFilters control={control} onResetPage={() => setPage(1)} onFiltersChange={setFilters} />
      <BluebirdTable
        data={candidates}
        columns={columns}
        count={candidatesSize}
        defaultRowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onSort={handleSort}
        page={page}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </Box>
  );
}

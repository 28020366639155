import { CreateCandidateWizard, useGetCandidateById } from '@bluebird-monorepo/candidates';
import { Box, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../layout';

const EditCandidatePage = () => {
  const { candidateId } = useParams<{ candidateId: string }>();
  const { data: candidate, isLoading, error } = useGetCandidateById(Number(candidateId));

  if (isLoading) {
    return (
      <Layout title="Edit Candidate">
        <Typography level="h4">Loading...</Typography>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title="Edit Candidate">
        <Typography level="h4">Error: {error.message}</Typography>
      </Layout>
    );
  }

  if (!candidate) {
    return (
      <Layout title="Edit Candidate">
        <Typography level="h4">Candidate not found</Typography>
      </Layout>
    );
  }

  return (
    <Layout title={`Edit ${candidate.firstName} ${candidate.lastName}`}>
      <Box sx={{ width: '100%' }}>
        <CreateCandidateWizard initialData={candidate} />
      </Box>
    </Layout>
  );
};

export default EditCandidatePage;

import { RawHTML } from '@bluebird-monorepo/bluebird-ui';
import { Candidate } from '@bluebird-monorepo/types';
import { Box, Chip, Divider, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

export function ReviewStep() {
  const { watch } = useFormContext<Candidate>();
  const values = watch();
  const defaultStyle = { whiteSpace: 'pre-wrap' };

  const Section = ({ title, children }: { title: string; children: React.ReactNode }) => (
    <Box sx={{ mb: 3 }}>
      <Typography level="title-md" sx={{ mb: 1 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );

  // Helper function to get label from EntityOption or string
  const getLabel = (item: any) => {
    if (typeof item === 'string') return item;
    return item?.label || item;
  };

  return (
    <Stack spacing={2}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Review Candidate Details
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Please review all information before submitting</Box>
        </Box>
      </Box>

      <Section title="Basic Information">
        <Typography level="body-md">First Name: {values.firstName}</Typography>
        <Typography level="body-md">Last Name: {values.lastName}</Typography>
        <Typography level="body-md">Email: {values.contactInfo?.email}</Typography>
        <Typography level="body-md">Phone: {values.contactInfo?.phone || 'Not provided'}</Typography>
      </Section>

      <Divider />

      <Section title="Work Experience">
        <Typography level="body-md">Current Organization: {values.currentOrganization || 'Not provided'}</Typography>
        <Typography level="body-md">Current Position: {values.currentPosition || 'Not provided'}</Typography>
        <Typography level="body-md">Years of Experience: {values.workExperienceYears || 'Not provided'}</Typography>
        {values.linkedin && <Typography level="body-md">LinkedIn: {values.linkedin}</Typography>}
      </Section>

      <Divider />

      <Section title="Skills & Qualifications">
        {values.skills && values.skills.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography level="body-md">Skills:</Typography>
            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
              {values.skills.map((skill: any, index: number) => (
                <Chip key={index} size="sm">
                  {getLabel(skill)}
                </Chip>
              ))}
            </Box>
          </Box>
        )}

        {values.techStack && values.techStack.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography level="body-md">Tech Stack:</Typography>
            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
              {values.techStack.map((tech: any, index: number) => (
                <Chip key={index} size="sm">
                  {getLabel(tech)}
                </Chip>
              ))}
            </Box>
          </Box>
        )}

        {values.languagesCandidateSpeak && values.languagesCandidateSpeak.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography level="body-md">Languages:</Typography>
            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
              {values.languagesCandidateSpeak.map((language, index) => (
                <Chip key={index} size="sm">
                  {language}
                </Chip>
              ))}
            </Box>
          </Box>
        )}
      </Section>

      <Divider />

      <Section title="Preferences">
        <Typography level="body-md">Open to Relocation: {values.isOpenToRelocate ? 'Yes' : 'No'}</Typography>
        <Typography level="body-md">Open to Remote Work: {values.isOpenToWorkRemotely ? 'Yes' : 'No'}</Typography>
        <Typography level="body-md">Requires Visa: {values.requiresVisa ? 'Yes' : 'No'}</Typography>
        {values.availability && (
          <>
            <Typography level="body-md" sx={{ mb: 1, mt: 2 }}>
              Availability:
            </Typography>
            <RawHTML children={values.availability} style={defaultStyle} />
          </>
        )}
      </Section>

      {values.salaryInformation?.currentSalary && (
        <>
          <Divider />
          <Section title="Salary Information">
            <Typography level="body-md">
              Current Monthly Salary: €{values.salaryInformation.currentSalary.monthly || 'Not provided'}
            </Typography>
            {values.salaryInformation.currentSalary.yearly && (
              <Typography level="body-md">
                Current Yearly Salary: €{values.salaryInformation.currentSalary.yearly}
              </Typography>
            )}
            {values.salaryInformation.currentSalary.bonus && (
              <Typography level="body-md">Current Bonus: €{values.salaryInformation.currentSalary.bonus}</Typography>
            )}
          </Section>
        </>
      )}
    </Stack>
  );
}

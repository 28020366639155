import { Vertical } from '@bluebird-monorepo/types';

const VERTICALS: Vertical[] = [
  {
    id: '1',
    firestoreId: 'nKC5cLRuArCwvm6t3It6',
    name: 'Engineering',
    description: 'Technical roles including software development, DevOps, and architecture',
  },
  {
    id: '2',
    firestoreId: 'JAkBgt9H7lczGihUjAP7',
    name: 'Product',
    description: 'Product management and product-related roles',
  },
  {
    id: '3',
    firestoreId: 'a9Ul3cf2Ohzanr0aTJ8W',
    name: 'Marketing',
    description: 'Marketing, communications, and brand-related positions',
  },
  {
    id: '4',
    firestoreId: 'jyM8hsdKcn84pxgKkWAW',
    name: 'Sales',
    description: 'Sales, business development, and revenue-focused roles',
  },
  {
    id: '5',
    firestoreId: 'FwdrwSLDeh7OycssmnY7',
    name: 'Customer Success',
    description: 'Customer support, success, and service roles',
  },
];

export const useVerticals = () => {
  return {
    verticals: VERTICALS,
  };
};

import { User } from '@bluebird-monorepo/types';
import { FormControl, FormHelperText, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

export function RoleStep() {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<User>();

  const currentRole = watch('role');

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('role', event.target.value as 'admin' | 'user' | 'consultant' | undefined);
  };

  return (
    <Stack spacing={3}>
      <Typography level="h4">User Role</Typography>

      <FormControl error={!!errors.role} required>
        <FormLabel>Select Role</FormLabel>
        <RadioGroup value={currentRole} onChange={handleRoleChange} sx={{ gap: 2, mt: 1 }}>
          <Radio
            value="user"
            label={
              <div>
                <Typography level="title-sm">User</Typography>
                <Typography level="body-sm">Basic access to the platform</Typography>
              </div>
            }
          />
          <Radio
            value="consultant"
            label={
              <div>
                <Typography level="title-sm">Consultant</Typography>
                <Typography level="body-sm">Access to consultant-specific features</Typography>
              </div>
            }
          />
          <Radio
            value="admin"
            label={
              <div>
                <Typography level="title-sm">Admin</Typography>
                <Typography level="body-sm">Full access to all features and settings</Typography>
              </div>
            }
          />
        </RadioGroup>
        {errors.role && <FormHelperText>{errors.role.message as string}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

import { useCallback, useState } from 'react';
import weFactService, { Invoice } from './wefact.service';

interface UseWeFactReturn {
  invoices: Invoice[];
  isLoading: boolean;
  error: string | null;
  fetchInvoices: () => Promise<void>;
  createInvoice: (invoiceData: Partial<Invoice>) => Promise<void>;
  updateInvoice: (invoiceCode: string, invoiceData: Partial<Invoice>) => Promise<void>;
  deleteInvoice: (invoiceCode: string) => Promise<void>;
  getInvoice: (invoiceCode: string) => Promise<Invoice>;
}

export const useWeFact = (): UseWeFactReturn => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchInvoices = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const fetchedInvoices = await weFactService.listInvoices();
      setInvoices(fetchedInvoices);
    } catch (err) {
      setError('Error fetching invoices');
      console.error('WeFact API Error:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const createInvoice = useCallback(async (invoiceData: Partial<Invoice>) => {
    try {
      setIsLoading(true);
      setError(null);
      const newInvoice = await weFactService.createInvoice(invoiceData);
      setInvoices((prev) => [...prev, newInvoice]);
    } catch (err) {
      setError('Error creating invoice');
      console.error('WeFact API Error:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateInvoice = useCallback(async (invoiceCode: string, invoiceData: Partial<Invoice>) => {
    try {
      setIsLoading(true);
      setError(null);
      const updatedInvoice = await weFactService.updateInvoice(invoiceCode, invoiceData);
      setInvoices((prev) => prev.map((invoice) => (invoice.InvoiceCode === invoiceCode ? updatedInvoice : invoice)));
    } catch (err) {
      setError('Error updating invoice');
      console.error('WeFact API Error:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteInvoice = useCallback(async (invoiceCode: string) => {
    try {
      setIsLoading(true);
      setError(null);
      await weFactService.deleteInvoice(invoiceCode);
      setInvoices((prev) => prev.filter((invoice) => invoice.InvoiceCode !== invoiceCode));
    } catch (err) {
      setError('Error deleting invoice');
      console.error('WeFact API Error:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getInvoice = useCallback(async (invoiceCode: string) => {
    try {
      setIsLoading(true);
      setError(null);
      return await weFactService.getInvoice(invoiceCode);
    } catch (err) {
      setError('Error fetching invoice details');
      console.error('WeFact API Error:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    invoices,
    isLoading,
    error,
    fetchInvoices,
    createInvoice,
    updateInvoice,
    deleteInvoice,
    getInvoice,
  };
};

import React from 'react';
import { CreateJobWizard } from '@bluebird-monorepo/jobs';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/joy';
import {
  Layout,
  LayoutContentHeader,
  LayoutTitleBox,
  LayoutTitle,
  LayoutSubtitle,
  LayoutActionButtonBox,
} from '../../../layout/Layout';
import { debug } from '../../config/environment';

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
  });
}

const CreateJobPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout areCompoundComponents>
      <LayoutContentHeader>
        <LayoutTitleBox>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <LayoutTitle>Create Job</LayoutTitle>
          </Box>
          <LayoutSubtitle>Create a new job</LayoutSubtitle>
        </LayoutTitleBox>
        <LayoutActionButtonBox />
      </LayoutContentHeader>
      <CreateJobWizard />
    </Layout>
  );
};

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  CreateJobPage.whyDidYouRender = true;
}

export default CreateJobPage;

import { create } from 'zustand';

interface NotificationDrawerState {
  isDrawerOpen: boolean;
  expandedId: number | null;
  activeId: number | null;
  openDrawer: () => void;
  closeDrawer: () => void;
  setExpandedId: (id: number | null) => void;
  setActiveId: (id: number | null) => void;
}

export const useNotificationDrawerStore = create<NotificationDrawerState>((set) => ({
  isDrawerOpen: false,
  expandedId: null,
  activeId: null,
  openDrawer: () => set({ isDrawerOpen: true }),
  closeDrawer: () =>
    set({
      isDrawerOpen: false,
      expandedId: null,
      activeId: null,
    }),
  setExpandedId: (id) => set({ expandedId: id }),
  setActiveId: (id) => set({ activeId: id }),
}));

import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/joy';
import React from 'react';
import { Layout } from '../../../layout/Layout';

const MetricsPage: React.FC = () => {
  return (
    <Layout title="Pipeline Metrics" subtitle="Track and analyze your recruitment pipeline">
      <Grid container spacing={2}>
        {/* Overview Section */}
        <Grid xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography level="h4" sx={{ mb: 2 }}>
                Overview
              </Typography>
              <Grid container spacing={2}>
                <Grid xs={3}>
                  <Box>
                    <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                      Active Jobs
                    </Typography>
                    <Typography level="h2">--</Typography>
                  </Box>
                </Grid>
                <Grid xs={3}>
                  <Box>
                    <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                      Total Candidates
                    </Typography>
                    <Typography level="h2">--</Typography>
                  </Box>
                </Grid>
                <Grid xs={3}>
                  <Box>
                    <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                      Avg. Time to Fill
                    </Typography>
                    <Typography level="h2">--</Typography>
                  </Box>
                </Grid>
                <Grid xs={3}>
                  <Box>
                    <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                      Pipeline Value
                    </Typography>
                    <Typography level="h2">--</Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Stage Metrics */}
        <Grid xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography level="h4" sx={{ mb: 2 }}>
                Stage Metrics
              </Typography>
              <Stack spacing={2}>
                <Box>
                  <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                    Stage Distribution
                  </Typography>
                  <Typography>Coming soon</Typography>
                </Box>
                <Box>
                  <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                    Stage Conversion Rates
                  </Typography>
                  <Typography>Coming soon</Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Activity Metrics */}
        <Grid xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography level="h4" sx={{ mb: 2 }}>
                Activity Metrics
              </Typography>
              <Stack spacing={2}>
                <Box>
                  <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                    Interviews
                  </Typography>
                  <Typography>Coming soon</Typography>
                </Box>
                <Box>
                  <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                    Team Activity
                  </Typography>
                  <Typography>Coming soon</Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Quality Metrics */}
        <Grid xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography level="h4" sx={{ mb: 2 }}>
                Quality Metrics
              </Typography>
              <Stack spacing={2}>
                <Box>
                  <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                    Pass Rates
                  </Typography>
                  <Typography>Coming soon</Typography>
                </Box>
                <Box>
                  <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                    Offer Acceptance
                  </Typography>
                  <Typography>Coming soon</Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Trend Analysis */}
        <Grid xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography level="h4" sx={{ mb: 2 }}>
                Trend Analysis
              </Typography>
              <Stack spacing={2}>
                <Box>
                  <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                    Pipeline Growth
                  </Typography>
                  <Typography>Coming soon</Typography>
                </Box>
                <Box>
                  <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                    Historical Performance
                  </Typography>
                  <Typography>Coming soon</Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default MetricsPage;

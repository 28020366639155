import React from 'react';
import { PlacementsTable, useGetPlacements } from '@bluebird-monorepo/placements';
import { Layout } from '../../../layout/Layout';

const PlacementsPage: React.FC = () => {
  const { data } = useGetPlacements();
  const placementsSize = data?.count || 0;

  return (
    <Layout title="Placements" count={placementsSize}>
      <PlacementsTable />
    </Layout>
  );
};

export default PlacementsPage;

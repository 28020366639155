import { useUpsertJob } from '@bluebird-monorepo/jobs';
import { PRIORITY_COLORS, PRIORITY_LABELS, PriorityLevel } from '../types';
import { Job } from '@bluebird-monorepo/types';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { Warning } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  Option,
  Select,
  Sheet,
  Stack,
  Tooltip,
  Typography,
} from '@mui/joy';
import { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { calculateDataIntegrityAndFlags } from '../utils/jobProperties';

// Static styles
const pulseAnimation = {
  '@keyframes pulse': {
    '0%': { opacity: 0.5 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.5 },
  },
};

const modalSheetStyles = {
  width: 400,
  borderRadius: 'md',
  p: 3,
  boxShadow: 'lg',
};

const indicatorDotStyles = {
  width: 8,
  height: 8,
  borderRadius: '50%',
  ...pulseAnimation,
};

const TooltipContent = memo(
  ({
    job,
    dataIntegrity,
    flags,
  }: {
    job: Job;
    dataIntegrity: {
      missingOpenSinceDate: boolean;
      missingCandidatesData: boolean;
      missingEventStats: boolean;
      missingTimeline: boolean;
    };
    flags: {
      noActivity: boolean;
      noCandidates: boolean;
    };
  }) => (
    <Box sx={{ p: 1 }}>
      <Typography level="body-sm" fontWeight="bold" mb={1}>
        {job.priorityOverride?.setBy
          ? `Manual Priority: ${PRIORITY_LABELS[job.priorityOverride?.level ?? 4]} (${job.priorityOverride?.level ?? 4})`
          : `Priority: ${PRIORITY_LABELS[job.priorityOverride?.level ?? 4]} (${job.priorityOverride?.level ?? 4})`}
      </Typography>
      {job.priorityOverride && (
        <Box sx={{ mb: 1 }}>
          <Typography level="body-xs" color="warning">
            Set by {job.priorityOverride?.setBy}
          </Typography>
          <Typography level="body-xs" color="warning">
            Reason: {job.priorityOverride?.reason}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography level="body-xs">
          Automatic Level: {PRIORITY_LABELS[job.priorityOverride?.automaticLevel as PriorityLevel]} (
          {job.priorityOverride?.automaticLevel})
        </Typography>
        <Typography level="body-xs" color={dataIntegrity.missingOpenSinceDate ? 'warning' : 'neutral'}>
          • Time Open: {job.priorityOverride?.timeOpenScore}/40
          {dataIntegrity.missingOpenSinceDate && ' (Missing open date)'}
        </Typography>
        <Typography
          level="body-xs"
          color={dataIntegrity.missingCandidatesData || dataIntegrity.missingEventStats ? 'warning' : 'neutral'}
        >
          • Pipeline: {job.priorityOverride?.pipelineScore}/40
          {dataIntegrity.missingCandidatesData && ' (Missing candidates data)'}
          {dataIntegrity.missingEventStats && ' (Missing events data)'}
        </Typography>
        <Typography level="body-xs" color={dataIntegrity.missingTimeline ? 'warning' : 'neutral'}>
          • Activity: {job.priorityOverride?.activityScore}/20
          {dataIntegrity.missingTimeline && ' (Missing timeline data)'}
        </Typography>
      </Box>
      {(dataIntegrity.missingOpenSinceDate ||
        dataIntegrity.missingCandidatesData ||
        dataIntegrity.missingEventStats ||
        dataIntegrity.missingTimeline) && (
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography level="body-xs" color="warning" startDecorator={<Warning sx={{ fontSize: 14 }} />}>
            Some data is missing, affecting priority accuracy
          </Typography>
        </Box>
      )}
      {(flags.noActivity || flags.noCandidates) && (
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          {flags.noActivity && (
            <Typography level="body-xs" color="danger" startDecorator={<Warning sx={{ fontSize: 14 }} />}>
              No activity in 14 days
            </Typography>
          )}
          {flags.noCandidates && (
            <Typography level="body-xs" color="danger" startDecorator={<Warning sx={{ fontSize: 14 }} />}>
              No candidates in pipeline
            </Typography>
          )}
        </Box>
      )}
      <Typography level="body-xs" sx={{ mt: 1, fontStyle: 'italic' }}>
        Click to modify priority
      </Typography>
    </Box>
  ),
);

const PriorityForm = memo(
  ({
    manualLevel,
    reason,
    level,
    onManualLevelChange,
    onReasonChange,
  }: {
    manualLevel: PriorityLevel;
    reason: string;
    level: PriorityLevel;
    onManualLevelChange: (value: PriorityLevel) => void;
    onReasonChange: (e: ChangeEvent<HTMLInputElement>) => void;
  }) => {
    return (
      <Stack spacing={2}>
        <Box>
          <Typography level="body-sm" mb={1}>
            Current: Manual - {PRIORITY_LABELS[level]} ({level})
          </Typography>
          <Typography level="body-xs" color="neutral">
            Automatic Priority: {PRIORITY_LABELS[level]} ({level})
          </Typography>
        </Box>

        <FormControl>
          <FormLabel>Priority Level</FormLabel>
          <Select value={manualLevel} onChange={(_, value) => value && onManualLevelChange(value as PriorityLevel)}>
            <Option value={1}>1 - Urgent</Option>
            <Option value={2}>2 - High</Option>
            <Option value={3}>3 - Medium</Option>
            <Option value={4}>4 - Low</Option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Reason for Override</FormLabel>
          <Input value={reason} onChange={onReasonChange} placeholder="Why are you setting a manual priority?" />
        </FormControl>
      </Stack>
    );
  },
);

export function PriorityColumn({
  job,
  onEffectiveLevelChange,
}: {
  job: Job;
  onEffectiveLevelChange?: (jobId: number, level: PriorityLevel) => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [manualLevel, setManualLevel] = useState<PriorityLevel>(
    job.priorityOverride?.level ?? (job.priorityOverride ? convertToLevel(job.priorityOverride?.score ?? 0) : 4),
  );
  const [reason, setReason] = useState(job.priorityOverride?.reason || '');
  const { mutate: updateJobMutation } = useUpsertJob();
  const { data: currentUser } = useGetCurrentUser();

  const { dataIntegrity, flags } = useMemo(() => calculateDataIntegrityAndFlags(job), [job]);

  const priorityColor = useMemo(() => PRIORITY_COLORS[job.priorityOverride?.level ?? 4], [job.priorityOverride?.level]);

  const hasDataIntegrityIssues = useMemo(
    () =>
      dataIntegrity.missingOpenSinceDate ||
      dataIntegrity.missingCandidatesData ||
      dataIntegrity.missingEventStats ||
      dataIntegrity.missingTimeline,
    [dataIntegrity],
  );

  const showWarningIndicator = useMemo(
    () => flags.noActivity || flags.noCandidates || hasDataIntegrityIssues,
    [flags, hasDataIntegrityIssues],
  );

  const priorityIndicatorStyles = useMemo(
    () => ({
      width: 32,
      height: 32,
      position: 'relative',
      cursor: 'pointer',
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'scale(1.05)',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '50%',
        padding: '2px',
        background: `conic-gradient(
          from 0deg,
          ${priorityColor} ${(5 - 1) * 25}%,
          transparent ${(5 - 1) * 25}%
        )`,
        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        inset: '1%',
        backgroundColor: priorityColor,
        borderRadius: '50%',
        opacity: 0.2,
        witdth: '100%',
      },
    }),
    [priorityColor],
  );

  const handleSetOverride = async () => {
    await updateJobMutation({
      ...job,
      priorityOverride: {
        level: Number(manualLevel) as PriorityLevel,
        score: convertToLegacyScore(Number(manualLevel) as PriorityLevel),
        reason,
        setBy: currentUser?.email || 'unknown',
        setAt: new Date(),
      },
    });
    setIsModalOpen(false);
  };

  const handleResetToAutomatic = async () => {
    await updateJobMutation({
      id: job.id,
      priorityOverride: {
        setBy: 'clearOverride',
        // Is overriden by sql function in supabase
        score: job.priorityOverride?.score,
        level: job.priorityOverride?.level,
        reason: '',
        setAt: new Date(),
      },
    });
    setIsModalOpen(false);
  };

  // Call onEffectiveLevelChange whenever effectiveLevel changes
  useEffect(() => {
    if (onEffectiveLevelChange && job.priorityOverride?.level) {
      onEffectiveLevelChange(job.id, job.priorityOverride?.level);
    }
  }, [job.priorityOverride?.level, job.id, onEffectiveLevelChange]);

  return (
    <>
      <Tooltip
        title={<TooltipContent job={job} dataIntegrity={dataIntegrity} flags={flags} />}
        placement="right"
        variant="outlined"
      >
        <Box
          onClick={() => setIsModalOpen(true)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box sx={priorityIndicatorStyles}>
            <Typography
              level="body-xs"
              sx={{
                position: 'absolute',
                inset: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: priorityColor,
                fontWeight: 'bold',
                zIndex: 1,
              }}
            >
              {job.priorityOverride?.level ?? 4}
              {job.priorityOverride && '*'}
            </Typography>
          </Box>
          {showWarningIndicator && (
            <Box
              sx={{
                ...indicatorDotStyles,
                bgcolor: hasDataIntegrityIssues ? 'warning.300' : priorityColor,
              }}
            />
          )}
        </Box>
      </Tooltip>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet variant="outlined" sx={modalSheetStyles}>
          <Typography level="h4" mb={2}>
            Set Priority
          </Typography>

          <PriorityForm
            manualLevel={manualLevel}
            reason={reason}
            level={job.priorityOverride?.level ?? 4}
            onManualLevelChange={setManualLevel}
            onReasonChange={(e: ChangeEvent<HTMLInputElement>) => setReason(e.target.value)}
          />

          <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="soft" color="neutral" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            {job.priorityOverride && (
              <Button variant="soft" color="primary" onClick={handleResetToAutomatic}>
                Reset to Automatic
              </Button>
            )}
            <Button onClick={handleSetOverride}>Set Manual Priority</Button>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

// Helper functions
function convertToLevel(score: number): PriorityLevel {
  if (score >= 75) return 1;
  if (score >= 50) return 2;
  if (score >= 25) return 3;
  return 4;
}

function convertToLegacyScore(level: PriorityLevel): number {
  switch (level) {
    case 1:
      return 87.5; // Midpoint of 75-100
    case 2:
      return 62.5; // Midpoint of 50-74
    case 3:
      return 37.5; // Midpoint of 25-49
    case 4:
      return 12.5; // Midpoint of 0-24
  }
}

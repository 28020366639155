import { Team, VerticalName } from '@bluebird-monorepo/types';
import { FormControl, FormHelperText, FormLabel, Input, Option, Select, Stack, Textarea, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

const VERTICALS: VerticalName[] = ['Engineering', 'Product', 'Marketing', 'Sales', 'Customer Success'];

export function TeamDetailsStep() {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<Team>();

  const currentVerticals = watch('verticals') || [];

  const handleVerticalsChange = (event: any, value: VerticalName[]) => {
    setValue('verticals', value);
  };

  return (
    <Stack spacing={3}>
      <Typography level="title-lg">Team Details</Typography>

      <FormControl error={!!errors.name}>
        <FormLabel>Team Name</FormLabel>
        <Input {...register('name', { required: 'Team name is required' })} placeholder="Enter team name" />
        {errors.name && <FormHelperText>{errors.name.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.description}>
        <FormLabel>Description (Optional)</FormLabel>
        <Textarea {...register('description')} minRows={3} placeholder="Enter team description" />
        {errors.description && <FormHelperText>{errors.description.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.verticals}>
        <FormLabel>Verticals (Optional)</FormLabel>
        <Select
          multiple
          value={currentVerticals}
          onChange={handleVerticalsChange}
          placeholder="Select verticals"
          slotProps={{
            listbox: {
              sx: { width: '100%' },
            },
          }}
        >
          {VERTICALS.map((vertical) => (
            <Option key={vertical} value={vertical}>
              {vertical}
            </Option>
          ))}
        </Select>
        {errors.verticals && <FormHelperText>{errors.verticals.message as string}</FormHelperText>}
      </FormControl>

      <Typography level="body-sm" textColor="text.secondary">
        Create a team to organize and manage groups of users effectively.
      </Typography>
    </Stack>
  );
}

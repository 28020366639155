import { Log, LogDb } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { keepPreviousData, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { snakeCase } from 'lodash';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@bluebird-monorepo/types';

interface PaginatedResponse {
  logs: Log[];
  count: number;
}

// New interface for infinite query response
interface InfiniteLogsResponse {
  logs: Log[];
  nextCursor: number | null;
  totalCount: number;
}

export interface DataFilter {
  field: string;
  value: string | number | boolean;
}

interface UseGetLogsOptions {
  page: number;
  pageSize: number;
  sortField?: keyof Pick<Log, 'createdAt' | 'tableName' | 'action'>;
  sortDirection?: 'asc' | 'desc';
  search?: string;
  tableName?: string;
  entityId?: number;
  action?: string;
  authorId?: number;
  startDate?: Date;
  endDate?: Date;
  dataFilters?: DataFilter[];
}

type UseGetLogsQuery = PostgrestFilterBuilder<Database['public'], Record<keyof Log, unknown>, Log[]>;

const DEFAULT_OPTIONS: UseGetLogsOptions = {
  page: 1,
  pageSize: 10,
  sortField: 'createdAt',
  sortDirection: 'desc',
};

export function useGetLogs(optionsArg?: UseGetLogsOptions) {
  const options = { ...DEFAULT_OPTIONS, ...optionsArg };
  return useQuery<PaginatedResponse>({
    queryKey: ['logs', { ...options }],
    refetchOnMount: true,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const selectQuery = getSelectQuery(options);
      const countQuery = getCountQuery(options);
      const [{ data }, { count }] = await Promise.all([selectQuery, countQuery]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Type instantiation is excessively deep and possibly infinite
      const dataWithAuthor = data?.map((log) => ({
        ...log,
        author: toCamelCase(log?.author),
        old_data: toCamelCase((log as unknown as LogDb)?.old_data as Record<string, any>),
        new_data: toCamelCase((log as unknown as LogDb)?.new_data as Record<string, any>),
      }));
      return {
        logs: dataWithAuthor ? toCamelCase<Log[]>(dataWithAuthor) : [],
        count: count || 0,
      };
    },
  });
}

// New hook for infinite scrolling in the notification drawer
export function useGetLogsInfinite(optionsArg?: Omit<UseGetLogsOptions, 'page'>) {
  const baseOptions = { ...DEFAULT_OPTIONS, ...optionsArg };
  return useInfiniteQuery<InfiniteLogsResponse>({
    queryKey: ['logsInfinite', { ...baseOptions }],
    initialPageParam: 1,
    queryFn: async ({ pageParam }) => {
      const currentPage = typeof pageParam === 'number' ? pageParam : 1;
      const options = { ...baseOptions, page: currentPage };
      const selectQuery = getSelectQuery(options);
      const countQuery = getCountQuery(options);
      const [{ data }, { count }] = await Promise.all([selectQuery, countQuery]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Type instantiation is excessively deep and possibly infinite
      const dataWithAuthor = data?.map((log) => ({
        ...log,
        author: toCamelCase(log?.author),
        old_data: toCamelCase((log as unknown as LogDb)?.old_data as Record<string, any>),
        new_data: toCamelCase((log as unknown as LogDb)?.new_data as Record<string, any>),
      }));
      const logs = dataWithAuthor ? toCamelCase<Log[]>(dataWithAuthor) : [];
      const hasNextPage = logs.length === options.pageSize;
      return {
        logs,
        nextCursor: hasNextPage ? currentPage + 1 : null,
        totalCount: count || 0,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    refetchOnMount: true,
  });
}

// New hook for infinite scrolling with both regular and related logs
export function useGetLogsWithRelated(optionsArg?: Omit<UseGetLogsOptions, 'page'>) {
  const baseOptions = { ...DEFAULT_OPTIONS, ...optionsArg };
  return useInfiniteQuery<InfiniteLogsResponse>({
    queryKey: ['logsWithRelated', { ...baseOptions }],
    initialPageParam: 1,
    queryFn: async ({ pageParam }) => {
      const currentPage = typeof pageParam === 'number' ? pageParam : 1;
      const options = { ...baseOptions, page: currentPage };
      const selectQuery = getSelectQuery(options);
      const countQuery = getCountQuery(options);
      const [{ data: regularLogsDb }, { count }] = await Promise.all([selectQuery, countQuery]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Type instantiation is excessively deep and possibly infinite
      const dataWithAuthor = regularLogsDb?.map((log) => ({
        ...log,
        author: toCamelCase(log?.author),
        old_data: toCamelCase((log as unknown as LogDb)?.old_data as Record<string, any>),
        new_data: toCamelCase((log as unknown as LogDb)?.new_data as Record<string, any>),
      }));
      const regularLogs = dataWithAuthor ? toCamelCase<Log[]>(dataWithAuthor) : [];
      const oldestLogTimestamp =
        regularLogs.length > 0 ? regularLogs[regularLogs.length - 1].createdAt : new Date().toISOString();
      // Fetch related logs only for the current page's time range
      const { data: relatedLogsDb } = await supabase
        .from('logs_related')
        .select('*')
        .lte('created_at', regularLogs[0]?.createdAt || new Date().toISOString())
        .gte('created_at', oldestLogTimestamp)
        .order('created_at', { ascending: false });
      const formattedRelatedLogs =
        relatedLogsDb?.map((log) => ({
          ...log,
          author: toCamelCase(log?.author),
          old_data: toCamelCase((log?.old_data as Record<string, unknown>) || {}),
          new_data: toCamelCase((log?.new_data as Record<string, unknown>) || {}),
        })) || [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Type instantiation is excessively deep and possibly infinite
      const relatedLogs = formattedRelatedLogs ? toCamelCase<RelatedLog[]>(formattedRelatedLogs) : [];
      const relatedLogIds = new Set(relatedLogs.flatMap((log) => log.logIds || []));
      const filteredRegularLogs = regularLogs?.filter((log) => !relatedLogIds.has(log.id)) || [];
      const allLogs = [...filteredRegularLogs, ...relatedLogs].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
      const hasNextPage = regularLogs.length === options.pageSize;
      return {
        logs: allLogs,
        nextCursor: hasNextPage ? currentPage + 1 : null,
        totalCount: count || 0,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    refetchOnMount: true,
  });
}

function getLogsQuery(selectOptions?: { count: 'exact' | 'planned' | 'estimated'; head: boolean }) {
  return supabase.from('logs').select('*', selectOptions).returns<Log[]>() as UseGetLogsQuery;
}

function getSelectQuery(options: UseGetLogsOptions) {
  const selectQuery = getLogsQuery();
  applyFilters(selectQuery, options);
  applySorting(selectQuery, options);
  applyPagination(selectQuery, options);
  return selectQuery;
}

function getCountQuery(options: UseGetLogsOptions) {
  const countQuery = getLogsQuery({ count: 'exact', head: true });
  applyFilters(countQuery, options);
  return countQuery;
}

function applyFilters(query: UseGetLogsQuery, options: UseGetLogsOptions) {
  const { search, tableName, entityId, action, authorId, startDate, endDate, dataFilters } = options;

  if (search) {
    query.or(`content.ilike.%${search}%,entity_id.ilike.%${search}%`);
  }

  if (tableName) {
    query.eq('table_name', tableName);
  }

  if (entityId) {
    query.eq('entity_id', entityId);
  }

  if (action) {
    query.eq('action', action);
  }

  if (authorId) {
    query.eq('author->>id', authorId.toString());
  }

  if (startDate) {
    query.gte('created_at', startDate.toISOString());
  }

  if (endDate) {
    query.lte('created_at', endDate.toISOString());
  }

  if (dataFilters) {
    dataFilters.forEach((filter) => {
      query.eq(`new_data->>${filter.field}`, filter.value as string);
    });
  }

  return query;
}

function applySorting(query: UseGetLogsQuery, options: UseGetLogsOptions) {
  const { sortField, sortDirection } = options;

  if (sortField && sortDirection) {
    const field = snakeCase(sortField);
    query.order(field, { ascending: sortDirection === 'asc' });
  } else {
    // Default sorting by created_at in descending order if no sort specified
    query.order('created_at', { ascending: false });
  }
}

function applyPagination(query: UseGetLogsQuery, options: UseGetLogsOptions) {
  const { page, pageSize } = options;
  const start = (page - 1) * pageSize;
  const end = start + pageSize - 1;
  query.range(start, end);
}

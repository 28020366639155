import { Candidate, Database } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { snakeCase } from 'lodash';
import { CandidateFiltersGroup } from '../../store/candidatesTable.store';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';

export function useGetCandidatesByJobId(jobId: number) {
  return useQuery<number>({
    enabled: !!jobId,
    queryKey: ['candidatesByJob', jobId],
    queryFn: async () => {
      const { count } = await supabase
        .from('assignments')
        .select('candidate_id', { count: 'exact' })
        .eq('job_id', jobId);
      return count || 0;
    },
  });
}

/*
  Get candidates with pagination, sorting, and filtering
*/

interface PaginatedResponse {
  candidates: Candidate[];
  count: number;
}

interface UseGetCandidatesOptions {
  page: number;
  pageSize: number;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
  search?: string;
  status?: string;
  skills?: string[];
  techStack?: string[];
  owner?: string;
  filters?: CandidateFiltersGroup[];
}

type UseGetCandidatesQuery = PostgrestFilterBuilder<Database['public'], Record<keyof Candidate, unknown>, Candidate>;

const DEFAULT_OPTIONS: UseGetCandidatesOptions = {
  page: 1,
  pageSize: 10,
  sortField: 'id',
  sortDirection: 'desc',
};

export function useGetCandidates(optionsArg?: UseGetCandidatesOptions) {
  const options = { ...DEFAULT_OPTIONS, ...optionsArg };
  return useQuery<PaginatedResponse>({
    queryKey: ['candidates', { ...options }],
    queryFn: () => getCandidates(options),
    refetchOnMount: true,
    placeholderData: keepPreviousData,
  });
}

async function getCandidates(options: UseGetCandidatesOptions): Promise<PaginatedResponse> {
  const selectQuery = getSelectQuery(options);
  const countQuery = getCountQuery(options);
  const [{ data }, { count }] = await Promise.all([selectQuery, countQuery]);
  return {
    candidates: data ? toCamelCase<Candidate[]>(data) : [],
    count: count || 0,
  };
}

// Base query for candidates
function getCandidatesQuery(selectOptions?: { count: 'exact' | 'planned' | 'estimated'; head: boolean }) {
  return supabase.from('candidates').select('*', selectOptions).returns<Candidate>() as UseGetCandidatesQuery;
}

function getSelectQuery(options: UseGetCandidatesOptions) {
  const selectQuery = getCandidatesQuery();
  applyFilters(selectQuery, options);
  applySearch(selectQuery, options);
  applyStatus(selectQuery, options);
  applySkills(selectQuery, options);
  applyTechStack(selectQuery, options);
  applyOwner(selectQuery, options);
  applySorting(selectQuery, options);
  applyPagination(selectQuery, options);
  return selectQuery;
}

function getCountQuery(options: UseGetCandidatesOptions) {
  const countQuery = getCandidatesQuery({ count: 'exact', head: true });
  applyFilters(countQuery, options);
  applySearch(countQuery, options);
  applyStatus(countQuery, options);
  applySkills(countQuery, options);
  applyTechStack(countQuery, options);
  applyOwner(countQuery, options);
  return countQuery;
}

function applySearch(query: UseGetCandidatesQuery, options: UseGetCandidatesOptions) {
  if (options.search) {
    query.or(`first_name.ilike.%${options.search}%,last_name.ilike.%${options.search}%`);
  }
}

function applyStatus(query: UseGetCandidatesQuery, options: UseGetCandidatesOptions) {
  if (options.status) {
    query.eq('status', getStatusFilter(options.status));
  }
}

function applySkills(query: UseGetCandidatesQuery, options: UseGetCandidatesOptions) {
  if (options.skills?.length) {
    query.contains('skills', options.skills);
  }
}

function applyTechStack(query: UseGetCandidatesQuery, options: UseGetCandidatesOptions) {
  if (options.techStack?.length) {
    query.contains('tech_stack', options.techStack);
  }
}

function applyOwner(query: UseGetCandidatesQuery, options: UseGetCandidatesOptions) {
  if (options.owner) {
    query.eq('owner', options.owner);
  }
}

function applyPagination(query: UseGetCandidatesQuery, options: UseGetCandidatesOptions) {
  const start = (options.page - 1) * options.pageSize;
  const end = start + options.pageSize - 1;
  query.range(start, end);
}

function applySorting(query: UseGetCandidatesQuery, options: UseGetCandidatesOptions) {
  if (options.sortField && options.sortDirection) {
    query.order(snakeCase(options.sortField), { ascending: options.sortDirection === 'asc' });
  }
}

function applyFilters(query: UseGetCandidatesQuery, options: UseGetCandidatesOptions) {
  if (options.filters?.length) {
    options.filters.forEach((group) => {
      applyFilterGroup(query, group);
    });
  }
}

function applyFilterGroup(query: UseGetCandidatesQuery, group: CandidateFiltersGroup) {
  if (group.operator === 'and') {
    // AND operator - use direct query methods
    group.conditions.forEach((condition) => {
      const column = snakeCase(condition.field);
      switch (condition.operator) {
        case 'equals':
          query.eq(column, condition.value);
          break;
        case 'contains':
          query.ilike(column, `%${condition.value}%`);
          break;
        case 'startsWith':
          query.ilike(column, `${condition.value}%`);
          break;
        case 'greaterThan':
          query.gt(column, condition.value);
          break;
        case 'lessThan':
          query.lt(column, condition.value);
          break;
      }
    });
  } else {
    // OR operator - combine all conditions in a single OR
    const orConditions = group.conditions
      .map((condition) => {
        const column = snakeCase(condition.field);
        switch (condition.operator) {
          case 'equals':
            return `${column}.eq.${condition.value}`;
          case 'contains':
            return `${column}.ilike.%${condition.value}%`;
          case 'startsWith':
            return `${column}.ilike.${condition.value}%`;
          case 'greaterThan':
            return `${column}.gt.${condition.value}`;
          case 'lessThan':
            return `${column}.lt.${condition.value}`;
          default:
            return null;
        }
      })
      .filter(Boolean);

    if (orConditions.length > 0) {
      query.or(orConditions.join(','));
    }
  }

  // Apply subgroups recursively
  group.subgroups?.forEach((subgroup) => {
    applyFilterGroup(query, subgroup);
  });
}

function getStatusFilter(status: string) {
  return status ? `"${status}"` : '';
}

import { Job } from '@bluebird-monorepo/types';
import { Edit, Euro, LocationOn, Percent } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Chip, Stack, Tooltip, Typography } from '@mui/joy';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PriorityColumn } from '../JobsTable/columns/PriorityColumn';
import { ResponsibleColumn } from '../JobsTable/columns/ResponsibleColumn';
import { WorkingOnColumn } from '../JobsTable/columns/WorkingOnColumn';
import { CompanyAvatar } from '@bluebird-monorepo/companies';

// Vertical mapping
const VERTICAL_MAPPING: Record<string, string> = {
  nKC5cLRuArCwvm6t3It6: 'Engineering',
};

interface JobHeaderProps {
  job: Job;
}

export const JobHeader: React.FC<JobHeaderProps> = ({ job }) => {
  // const [isNoteDrawerOpen, setIsNoteDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const potentialFee = job.salaryRange?.max ? job.salaryRange.max * (job.feePercentage / 100) : null;
  const formattedPotentialFee = potentialFee
    ? new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(potentialFee)
    : null;

  const verticalName = job.vertical ? VERTICAL_MAPPING[job.vertical] || job.vertical : null;

  const formatSalaryRange = (min?: number, max?: number) => {
    if (!min && !max) return null;
    const formatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 });
    if (min && max) {
      return `${formatter.format(min)} - ${formatter.format(max)}`;
    }
    return formatter.format(min || max || 0);
  };

  const formattedSalaryRange = formatSalaryRange(job.salaryRange?.min, job.salaryRange?.max);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          mb: 2,
          background:
            'linear-gradient(to right, var(--joy-palette-background-level1), var(--joy-palette-background-surface))',
          boxShadow: 'sm',
          '&:hover': {
            boxShadow: 'md',
          },
          transition: 'box-shadow 0.2s',
        }}
      >
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          {/* Main Content */}
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'flex-start' }}>
            {/* Left Side */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {/* Reference Number */}

              {/* Title */}
              <Typography
                level="h1"
                sx={{
                  fontSize: { xs: 'xl', sm: 'xl2' },
                  fontWeight: 'lg',
                }}
              >
                {job.jobTitle}
              </Typography>

              {/* Edit Button */}
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => navigate(`/jobs/${job.id}/edit`)}
                startDecorator={<Edit />}
                size="sm"
              >
                Edit
              </Button>

              {/* Add Note Button */}
              {/*  <Button
                variant="outlined"
                color="neutral"
                onClick={() => setIsNoteDrawerOpen(true)}
                startDecorator={<CommentIcon />}
                size="sm"
              >
                Add Note
              </Button> */}
            </Box>

            {/* Right Side */}
            <Box sx={{ display: 'flex', gap: 3, ml: 'auto', alignItems: 'center' }}>
              {/* Fee Info */}
              {potentialFee && (
                <Tooltip
                  title={`Based on max salary of €${job.salaryRange?.max.toLocaleString('nl-NL')}`}
                  placement="left"
                  variant="soft"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      background:
                        'linear-gradient(45deg, var(--joy-palette-success-100), var(--joy-palette-success-200))',
                      px: 1.5,
                      py: 0.75,
                      borderRadius: 'md',
                      border: '1px solid',
                      borderColor: 'success.outlinedBorder',
                    }}
                  >
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Chip
                        size="sm"
                        variant="soft"
                        color="success"
                        startDecorator={<Percent sx={{ fontSize: 14 }} />}
                        sx={{
                          '--Chip-minHeight': '20px',
                          '--Chip-paddingInline': '6px',
                        }}
                      >
                        {job.feePercentage}%
                      </Chip>
                      <Euro sx={{ fontSize: 16, color: 'success.600' }} />
                      <Typography
                        level="body-sm"
                        sx={{
                          fontWeight: 'md',
                          color: 'success.700',
                        }}
                      >
                        {formattedPotentialFee}
                      </Typography>
                    </Stack>
                  </Box>
                </Tooltip>
              )}

              {/* Priority Score */}
              <Box>
                <PriorityColumn job={job} />
              </Box>
            </Box>
          </Box>

          {/* Bottom Row */}
          <Box sx={{ display: 'flex', mt: 2, gap: 3 }}>
            {/* Left Side: Company Info & Metadata */}
            <Box sx={{ display: 'flex', gap: 3, flex: 1, alignItems: 'center' }}>
              {/* Company and Vertical */}
              <Stack direction="row" spacing={2} alignItems="center">
                <CompanyAvatar name={job.companyName || ''} logoUrl={job.companyLogo || ''} size="md" />
                <Typography
                  component={RouterLink}
                  to={`/companies/${job.companyId}`}
                  level="title-lg"
                  sx={{
                    color: 'primary.600',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                      color: 'primary.700',
                    },
                    transition: 'color 0.2s',
                  }}
                >
                  {job.companyName}
                </Typography>
                {verticalName && (
                  <Tooltip title={`Vertical ID: ${job.vertical}`} placement="top">
                    <Chip
                      variant="soft"
                      size="sm"
                      color="primary"
                      sx={{
                        '--Chip-minHeight': '20px',
                        '--Chip-paddingInline': '8px',
                        fontSize: 'sm',
                      }}
                    >
                      {verticalName}
                    </Chip>
                  </Tooltip>
                )}
              </Stack>

              {/* Location */}
              {job.location && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: 'primary.700',
                  }}
                >
                  <LocationOn sx={{ fontSize: 20 }} />
                  <Typography
                    level="title-sm"
                    sx={{
                      fontWeight: 'md',
                    }}
                  >
                    {job.location}
                  </Typography>
                </Box>
              )}

              {/* Salary Range */}
              {formattedSalaryRange && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    px: 1.5,
                    py: 0.75,
                    borderRadius: 'md',
                    bgcolor: 'background.level2',
                  }}
                >
                  <Euro sx={{ fontSize: 18, color: 'text.secondary' }} />
                  <Typography
                    level="body-sm"
                    sx={{
                      fontWeight: 'md',
                      color: 'text.primary',
                    }}
                  >
                    {formattedSalaryRange}
                  </Typography>
                </Box>
              )}

              {/* Contract Type */}
              {job.contractType && (
                <Chip
                  variant="soft"
                  size="sm"
                  color="neutral"
                  sx={{
                    '--Chip-minHeight': '20px',
                    '--Chip-paddingInline': '8px',
                    fontSize: 'xs',
                    opacity: 0.8,
                  }}
                >
                  {job.contractType}
                </Chip>
              )}
            </Box>

            {/* Right Side: People */}
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              {/* Responsible */}
              <Box>
                <Typography
                  level="body-sm"
                  sx={{
                    color: 'text.secondary',
                    mb: 0.5,
                    fontWeight: 600,
                  }}
                >
                  Responsible
                </Typography>
                <ResponsibleColumn job={job} />
              </Box>

              {/* Working On */}
              <Box>
                <Typography
                  level="body-sm"
                  sx={{
                    color: 'text.secondary',
                    mb: 0.5,
                    fontWeight: 600,
                  }}
                >
                  Working On
                </Typography>
                <WorkingOnColumn job={job} />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {/* {isNoteDrawerOpen && (
        <AddNoteDrawer open={isNoteDrawerOpen} onClose={() => setIsNoteDrawerOpen(false)} jobId={job.id} />
      )} */}
    </>
  );
};

// Helper function to convert field keys to readable format
export function formatFieldName(field: string): string {
  return (
    field
      // Convert snake_case and camelCase to spaces
      .replace(/_/g, ' ')
      .replace(/([A-Z])/g, ' $1')
      // Trim extra spaces and capitalize first letter of each word
      .trim()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  );
}

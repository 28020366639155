import { GraphQLClient } from 'graphql-request';
import { Transcript } from '../types';

export type { Transcript };

interface TranscriptsResponse {
  transcripts: Transcript[];
}

const FIREFLIES_API_URL = 'https://api.fireflies.ai/graphql';

export class FirefliesService {
  private client: GraphQLClient;

  constructor(apiKey: string) {
    this.client = new GraphQLClient(FIREFLIES_API_URL, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
  }

  async getTranscripts(variables: {
    hostEmail?: string;
    fromDate?: string;
    toDate?: string;
    organizerEmail?: string;
    title?: string;
    participantEmail?: string;
  }): Promise<Transcript[]> {
    const query = `
      query Transcripts($hostEmail: String, $fromDate: DateTime, $toDate: DateTime, $organizerEmail: String, $title: String, $participantEmail: String) {
        transcripts(
          host_email: $hostEmail, 
          fromDate: $fromDate, 
          toDate: $toDate, 
          organizer_email: $organizerEmail 
          title: $title 
          participant_email: $participantEmail
        ) {
          id
          title
          date
          duration
          meeting_attendees {
            displayName
            email
            phoneNumber
            name
            location
          }
          host_email
          summary {
            overview
            keywords
            action_items
            outline
            shorthand_bullet
            bullet_gist
            gist
            short_summary
            short_overview
            meeting_type
            topics_discussed
            transcript_chapters
          }
          participants
          organizer_email
          transcript_url
          sentences {
            index
            speaker_name
            speaker_id
            text
            raw_text
            start_time
            end_time
          }
          speakers {
            id
            name
          }
          audio_url
          video_url
        }
      }
    `;

    try {
      const response = await this.client.request<TranscriptsResponse>(query, variables);
      return response.transcripts;
    } catch (error) {
      console.error('Error fetching transcripts:', error);
      throw error;
    }
  }
}

export const firefliesService = new FirefliesService(import.meta.env.VITE_FIREFLIES_API_KEY || '');

import { useCallback, useState } from 'react';

export interface PDFPreviewState {
  isOpen: boolean;
  file: File | string | null;
  title: string;
}

export const usePDFPreview = () => {
  const [state, setState] = useState<PDFPreviewState>({
    isOpen: false,
    file: null,
    title: '',
  });

  const openPdfPreview = useCallback((file: File | string, title = 'PDF Preview') => {
    setState({
      isOpen: true,
      file,
      title,
    });
  }, []);

  const closePdfPreview = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false }));
  }, []);

  return {
    isOpen: state.isOpen,
    file: state.file,
    title: state.title,
    openPdfPreview,
    closePdfPreview,
  };
};

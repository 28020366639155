// Define the type for the country object
export type Country = {
  code: string;
  country: string;
  languages: string[];
  region:
    | 'Western Europe'
    | 'Northern Europe'
    | 'Southern Europe'
    | 'Eastern Europe'
    | 'North America'
    | 'South America'
    | 'Asia'
    | 'Africa'
    | 'Oceania'
    | 'Other Regions';
};

export const countries: Country[] = [
  // Western Europe
  {
    code: 'AT',
    country: 'Austria',
    languages: ['de'],
    region: 'Western Europe',
  },
  {
    code: 'BE',
    country: 'Belgium',
    languages: ['nl', 'fr', 'de'],
    region: 'Western Europe',
  },
  {
    code: 'DE',
    country: 'Germany',
    languages: ['de'],
    region: 'Western Europe',
  },
  {
    code: 'FR',
    country: 'France',
    languages: ['fr'],
    region: 'Western Europe',
  },
  {
    code: 'GB',
    country: 'United Kingdom',
    languages: ['en'],
    region: 'Western Europe',
  },
  {
    code: 'IE',
    country: 'Ireland',
    languages: ['en', 'ga'],
    region: 'Western Europe',
  },
  {
    code: 'IT',
    country: 'Italy',
    languages: ['it'],
    region: 'Western Europe',
  },
  {
    code: 'LU',
    country: 'Luxembourg',
    languages: ['fr', 'de', 'lb'],
    region: 'Western Europe',
  },
  {
    code: 'NL',
    country: 'Netherlands',
    languages: ['nl'],
    region: 'Western Europe',
  },
  {
    code: 'PT',
    country: 'Portugal',
    languages: ['pt'],
    region: 'Western Europe',
  },
  {
    code: 'ES',
    country: 'Spain',
    languages: ['es'],
    region: 'Western Europe',
  },
  {
    code: 'CH',
    country: 'Switzerland',
    languages: ['de', 'fr', 'it', 'rm'],
    region: 'Western Europe',
  },

  // Northern Europe
  {
    code: 'DK',
    country: 'Denmark',
    languages: ['da'],
    region: 'Northern Europe',
  },
  {
    code: 'EE',
    country: 'Estonia',
    languages: ['et'],
    region: 'Northern Europe',
  },
  {
    code: 'FI',
    country: 'Finland',
    languages: ['fi', 'sv'],
    region: 'Northern Europe',
  },
  {
    code: 'IS',
    country: 'Iceland',
    languages: ['is'],
    region: 'Northern Europe',
  },
  {
    code: 'LV',
    country: 'Latvia',
    languages: ['lv'],
    region: 'Northern Europe',
  },
  {
    code: 'LT',
    country: 'Lithuania',
    languages: ['lt'],
    region: 'Northern Europe',
  },
  {
    code: 'NO',
    country: 'Norway',
    languages: ['no'],
    region: 'Northern Europe',
  },
  {
    code: 'SE',
    country: 'Sweden',
    languages: ['sv'],
    region: 'Northern Europe',
  },

  // Southern Europe
  {
    code: 'CY',
    country: 'Cyprus',
    languages: ['el', 'tr'],
    region: 'Southern Europe',
  },
  {
    code: 'GR',
    country: 'Greece',
    languages: ['el'],
    region: 'Southern Europe',
  },
  {
    code: 'MT',
    country: 'Malta',
    languages: ['mt', 'en'],
    region: 'Southern Europe',
  },

  // Eastern Europe
  {
    code: 'AL',
    country: 'Albania',
    languages: ['sq'],
    region: 'Eastern Europe',
  },
  {
    code: 'BA',
    country: 'Bosnia and Herzegovina',
    languages: ['bs', 'hr', 'sr'],
    region: 'Eastern Europe',
  },
  {
    code: 'BG',
    country: 'Bulgaria',
    languages: ['bg'],
    region: 'Eastern Europe',
  },
  {
    code: 'BY',
    country: 'Belarus',
    languages: ['be', 'ru'],
    region: 'Eastern Europe',
  },
  {
    code: 'CZ',
    country: 'Czech Republic',
    languages: ['cs'],
    region: 'Eastern Europe',
  },
  {
    code: 'HR',
    country: 'Croatia',
    languages: ['hr'],
    region: 'Eastern Europe',
  },
  {
    code: 'HU',
    country: 'Hungary',
    languages: ['hu'],
    region: 'Eastern Europe',
  },
  {
    code: 'MD',
    country: 'Moldova',
    languages: ['ro'],
    region: 'Eastern Europe',
  },
  {
    code: 'ME',
    country: 'Montenegro',
    languages: ['sr', 'bs', 'sq', 'hr'],
    region: 'Eastern Europe',
  },
  {
    code: 'MK',
    country: 'North Macedonia',
    languages: ['mk'],
    region: 'Eastern Europe',
  },
  {
    code: 'PL',
    country: 'Poland',
    languages: ['pl'],
    region: 'Eastern Europe',
  },
  {
    code: 'RO',
    country: 'Romania',
    languages: ['ro'],
    region: 'Eastern Europe',
  },
  {
    code: 'RS',
    country: 'Serbia',
    languages: ['sr'],
    region: 'Eastern Europe',
  },
  {
    code: 'SK',
    country: 'Slovakia',
    languages: ['sk'],
    region: 'Eastern Europe',
  },
  {
    code: 'SI',
    country: 'Slovenia',
    languages: ['sl'],
    region: 'Eastern Europe',
  },
  {
    code: 'UA',
    country: 'Ukraine',
    languages: ['uk'],
    region: 'Eastern Europe',
  },
  {
    code: 'XK',
    country: 'Kosovo',
    languages: ['sq', 'sr'],
    region: 'Eastern Europe',
  },

  // North America
  {
    code: 'CA',
    country: 'Canada',
    languages: ['en', 'fr'],
    region: 'North America',
  },
  {
    code: 'MX',
    country: 'Mexico',
    languages: ['es'],
    region: 'North America',
  },
  {
    code: 'US',
    country: 'United States',
    languages: ['en'],
    region: 'North America',
  },

  // South America
  {
    code: 'AR',
    country: 'Argentina',
    languages: ['es'],
    region: 'South America',
  },
  {
    code: 'BO',
    country: 'Bolivia',
    languages: ['es', 'ay', 'qu'],
    region: 'South America',
  },
  {
    code: 'BR',
    country: 'Brazil',
    languages: ['pt'],
    region: 'South America',
  },
  {
    code: 'CL',
    country: 'Chile',
    languages: ['es'],
    region: 'South America',
  },
  {
    code: 'CO',
    country: 'Colombia',
    languages: ['es'],
    region: 'South America',
  },
  {
    code: 'EC',
    country: 'Ecuador',
    languages: ['es'],
    region: 'South America',
  },
  {
    code: 'GY',
    country: 'Guyana',
    languages: ['en'],
    region: 'South America',
  },
  {
    code: 'PE',
    country: 'Peru',
    languages: ['es'],
    region: 'South America',
  },
  {
    code: 'PY',
    country: 'Paraguay',
    languages: ['es', 'gn'],
    region: 'South America',
  },
  {
    code: 'SR',
    country: 'Suriname',
    languages: ['nl'],
    region: 'South America',
  },
  {
    code: 'UY',
    country: 'Uruguay',
    languages: ['es'],
    region: 'South America',
  },
  {
    code: 'VE',
    country: 'Venezuela',
    languages: ['es'],
    region: 'South America',
  },

  // Asia
  {
    code: 'AF',
    country: 'Afghanistan',
    languages: ['ps', 'uz', 'tk'],
    region: 'Asia',
  },
  {
    code: 'AM',
    country: 'Armenia',
    languages: ['hy'],
    region: 'Asia',
  },
  {
    code: 'AZ',
    country: 'Azerbaijan',
    languages: ['az'],
    region: 'Asia',
  },
  {
    code: 'BH',
    country: 'Bahrain',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'BD',
    country: 'Bangladesh',
    languages: ['bn'],
    region: 'Asia',
  },
  {
    code: 'BT',
    country: 'Bhutan',
    languages: ['dz'],
    region: 'Asia',
  },
  {
    code: 'BN',
    country: 'Brunei',
    languages: ['ms'],
    region: 'Asia',
  },
  {
    code: 'KH',
    country: 'Cambodia',
    languages: ['km'],
    region: 'Asia',
  },
  {
    code: 'CN',
    country: 'China',
    languages: ['zh'],
    region: 'Asia',
  },
  {
    code: 'GE',
    country: 'Georgia',
    languages: ['ka'],
    region: 'Asia',
  },
  {
    code: 'IN',
    country: 'India',
    languages: ['hi', 'en'],
    region: 'Asia',
  },
  {
    code: 'ID',
    country: 'Indonesia',
    languages: ['id'],
    region: 'Asia',
  },
  {
    code: 'IR',
    country: 'Iran',
    languages: ['fa'],
    region: 'Asia',
  },
  {
    code: 'IQ',
    country: 'Iraq',
    languages: ['ar', 'ku'],
    region: 'Asia',
  },
  {
    code: 'IL',
    country: 'Israel',
    languages: ['he', 'ar'],
    region: 'Asia',
  },
  {
    code: 'JP',
    country: 'Japan',
    languages: ['ja'],
    region: 'Asia',
  },
  {
    code: 'JO',
    country: 'Jordan',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'KZ',
    country: 'Kazakhstan',
    languages: ['kk', 'ru'],
    region: 'Asia',
  },
  {
    code: 'KP',
    country: 'North Korea',
    languages: ['ko'],
    region: 'Asia',
  },
  {
    code: 'KR',
    country: 'South Korea',
    languages: ['ko'],
    region: 'Asia',
  },
  {
    code: 'KW',
    country: 'Kuwait',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'KG',
    country: 'Kyrgyzstan',
    languages: ['ky', 'ru'],
    region: 'Asia',
  },
  {
    code: 'LA',
    country: 'Laos',
    languages: ['lo'],
    region: 'Asia',
  },
  {
    code: 'LB',
    country: 'Lebanon',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'MY',
    country: 'Malaysia',
    languages: ['ms'],
    region: 'Asia',
  },
  {
    code: 'MV',
    country: 'Maldives',
    languages: ['dv'],
    region: 'Asia',
  },
  {
    code: 'MN',
    country: 'Mongolia',
    languages: ['mn'],
    region: 'Asia',
  },
  {
    code: 'MM',
    country: 'Myanmar',
    languages: ['my'],
    region: 'Asia',
  },
  {
    code: 'NP',
    country: 'Nepal',
    languages: ['ne'],
    region: 'Asia',
  },
  {
    code: 'OM',
    country: 'Oman',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'PK',
    country: 'Pakistan',
    languages: ['ur', 'en'],
    region: 'Asia',
  },
  {
    code: 'PH',
    country: 'Philippines',
    languages: ['tl', 'en'],
    region: 'Asia',
  },
  {
    code: 'QA',
    country: 'Qatar',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'SA',
    country: 'Saudi Arabia',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'SG',
    country: 'Singapore',
    languages: ['en', 'ms', 'zh', 'ta'],
    region: 'Asia',
  },
  {
    code: 'LK',
    country: 'Sri Lanka',
    languages: ['si', 'ta'],
    region: 'Asia',
  },
  {
    code: 'SY',
    country: 'Syria',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'TW',
    country: 'Taiwan',
    languages: ['zh'],
    region: 'Asia',
  },
  {
    code: 'TJ',
    country: 'Tajikistan',
    languages: ['tg'],
    region: 'Asia',
  },
  {
    code: 'TH',
    country: 'Thailand',
    languages: ['th'],
    region: 'Asia',
  },
  {
    code: 'TR',
    country: 'Turkey',
    languages: ['tr'],
    region: 'Asia',
  },
  {
    code: 'TM',
    country: 'Turkmenistan',
    languages: ['tk'],
    region: 'Asia',
  },
  {
    code: 'AE',
    country: 'United Arab Emirates',
    languages: ['ar'],
    region: 'Asia',
  },
  {
    code: 'UZ',
    country: 'Uzbekistan',
    languages: ['uz'],
    region: 'Asia',
  },
  {
    code: 'VN',
    country: 'Vietnam',
    languages: ['vi'],
    region: 'Asia',
  },
  {
    code: 'YE',
    country: 'Yemen',
    languages: ['ar'],
    region: 'Asia',
  },

  // Africa
  {
    code: 'DZ',
    country: 'Algeria',
    languages: ['ar'],
    region: 'Africa',
  },
  {
    code: 'AO',
    country: 'Angola',
    languages: ['pt'],
    region: 'Africa',
  },
  {
    code: 'BJ',
    country: 'Benin',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'BW',
    country: 'Botswana',
    languages: ['en', 'tn'],
    region: 'Africa',
  },
  {
    code: 'BF',
    country: 'Burkina Faso',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'BI',
    country: 'Burundi',
    languages: ['fr', 'rn'],
    region: 'Africa',
  },
  {
    code: 'CM',
    country: 'Cameroon',
    languages: ['fr', 'en'],
    region: 'Africa',
  },
  {
    code: 'CV',
    country: 'Cape Verde',
    languages: ['pt'],
    region: 'Africa',
  },
  {
    code: 'CF',
    country: 'Central African Republic',
    languages: ['fr', 'sg'],
    region: 'Africa',
  },
  {
    code: 'TD',
    country: 'Chad',
    languages: ['fr', 'ar'],
    region: 'Africa',
  },
  {
    code: 'KM',
    country: 'Comoros',
    languages: ['ar', 'fr'],
    region: 'Africa',
  },
  {
    code: 'CG',
    country: 'Congo',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'CD',
    country: 'DR Congo',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'CI',
    country: "Côte d'Ivoire",
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'DJ',
    country: 'Djibouti',
    languages: ['fr', 'ar'],
    region: 'Africa',
  },
  {
    code: 'EG',
    country: 'Egypt',
    languages: ['ar'],
    region: 'Africa',
  },
  {
    code: 'GQ',
    country: 'Equatorial Guinea',
    languages: ['es', 'fr', 'pt'],
    region: 'Africa',
  },
  {
    code: 'ER',
    country: 'Eritrea',
    languages: ['ti', 'ar', 'en'],
    region: 'Africa',
  },
  {
    code: 'ET',
    country: 'Ethiopia',
    languages: ['am'],
    region: 'Africa',
  },
  {
    code: 'GA',
    country: 'Gabon',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'GM',
    country: 'Gambia',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'GH',
    country: 'Ghana',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'GN',
    country: 'Guinea',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'GW',
    country: 'Guinea-Bissau',
    languages: ['pt'],
    region: 'Africa',
  },
  {
    code: 'KE',
    country: 'Kenya',
    languages: ['en', 'sw'],
    region: 'Africa',
  },
  {
    code: 'LS',
    country: 'Lesotho',
    languages: ['en', 'st'],
    region: 'Africa',
  },
  {
    code: 'LR',
    country: 'Liberia',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'LY',
    country: 'Libya',
    languages: ['ar'],
    region: 'Africa',
  },
  {
    code: 'MG',
    country: 'Madagascar',
    languages: ['fr', 'mg'],
    region: 'Africa',
  },
  {
    code: 'MW',
    country: 'Malawi',
    languages: ['en', 'ny'],
    region: 'Africa',
  },
  {
    code: 'ML',
    country: 'Mali',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'MR',
    country: 'Mauritania',
    languages: ['ar'],
    region: 'Africa',
  },
  {
    code: 'MU',
    country: 'Mauritius',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'MA',
    country: 'Morocco',
    languages: ['ar'],
    region: 'Africa',
  },
  {
    code: 'MZ',
    country: 'Mozambique',
    languages: ['pt'],
    region: 'Africa',
  },
  {
    code: 'NA',
    country: 'Namibia',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'NE',
    country: 'Niger',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'NG',
    country: 'Nigeria',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'RW',
    country: 'Rwanda',
    languages: ['rw', 'fr', 'en'],
    region: 'Africa',
  },
  {
    code: 'ST',
    country: 'São Tomé and Príncipe',
    languages: ['pt'],
    region: 'Africa',
  },
  {
    code: 'SN',
    country: 'Senegal',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'SC',
    country: 'Seychelles',
    languages: ['fr', 'en'],
    region: 'Africa',
  },
  {
    code: 'SL',
    country: 'Sierra Leone',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'SO',
    country: 'Somalia',
    languages: ['so'],
    region: 'Africa',
  },
  {
    code: 'ZA',
    country: 'South Africa',
    languages: ['af', 'en', 'nr', 'st', 'ss', 'tn', 'ts', 've', 'xh', 'zu'],
    region: 'Africa',
  },
  {
    code: 'SS',
    country: 'South Sudan',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'SD',
    country: 'Sudan',
    languages: ['ar', 'en'],
    region: 'Africa',
  },
  {
    code: 'SZ',
    country: 'Eswatini',
    languages: ['en', 'ss'],
    region: 'Africa',
  },
  {
    code: 'TZ',
    country: 'Tanzania',
    languages: ['sw', 'en'],
    region: 'Africa',
  },
  {
    code: 'TG',
    country: 'Togo',
    languages: ['fr'],
    region: 'Africa',
  },
  {
    code: 'TN',
    country: 'Tunisia',
    languages: ['ar'],
    region: 'Africa',
  },
  {
    code: 'UG',
    country: 'Uganda',
    languages: ['en', 'sw'],
    region: 'Africa',
  },
  {
    code: 'ZM',
    country: 'Zambia',
    languages: ['en'],
    region: 'Africa',
  },
  {
    code: 'ZW',
    country: 'Zimbabwe',
    languages: ['en', 'sn', 'nd'],
    region: 'Africa',
  },

  // Oceania
  {
    code: 'AU',
    country: 'Australia',
    languages: ['en'],
    region: 'Oceania',
  },
  {
    code: 'FJ',
    country: 'Fiji',
    languages: ['en', 'fj', 'hi'],
    region: 'Oceania',
  },
  {
    code: 'KI',
    country: 'Kiribati',
    languages: ['en'],
    region: 'Oceania',
  },
  {
    code: 'MH',
    country: 'Marshall Islands',
    languages: ['en', 'mh'],
    region: 'Oceania',
  },
  {
    code: 'FM',
    country: 'Micronesia',
    languages: ['en'],
    region: 'Oceania',
  },
  {
    code: 'NR',
    country: 'Nauru',
    languages: ['en', 'na'],
    region: 'Oceania',
  },
  {
    code: 'NZ',
    country: 'New Zealand',
    languages: ['en', 'mi'],
    region: 'Oceania',
  },
  {
    code: 'PW',
    country: 'Palau',
    languages: ['en'],
    region: 'Oceania',
  },
  {
    code: 'PG',
    country: 'Papua New Guinea',
    languages: ['en', 'ho', 'tpi'],
    region: 'Oceania',
  },
  {
    code: 'WS',
    country: 'Samoa',
    languages: ['sm', 'en'],
    region: 'Oceania',
  },
  {
    code: 'SB',
    country: 'Solomon Islands',
    languages: ['en'],
    region: 'Oceania',
  },
  {
    code: 'TO',
    country: 'Tonga',
    languages: ['en', 'to'],
    region: 'Oceania',
  },
  {
    code: 'TV',
    country: 'Tuvalu',
    languages: ['en'],
    region: 'Oceania',
  },
  {
    code: 'VU',
    country: 'Vanuatu',
    languages: ['bi', 'en', 'fr'],
    region: 'Oceania',
  },

  // Other Regions
  {
    code: 'AQ',
    country: 'Antarctica',
    languages: [],
    region: 'Other Regions',
  },
];

// Define the type for the language object
export type Language = {
  code: string;
  name: string;
  region:
    | 'Western Europe'
    | 'Northern Europe'
    | 'Southern Europe'
    | 'Eastern Europe'
    | 'North America'
    | 'South America'
    | 'Asia'
    | 'Africa'
    | 'Oceania'
    | 'Other Regions';
};

export const languages: Language[] = [
  // Western Europe
  { code: 'de', name: 'German', region: 'Western Europe' },
  { code: 'en', name: 'English', region: 'Western Europe' },
  { code: 'fr', name: 'French', region: 'Western Europe' },
  { code: 'it', name: 'Italian', region: 'Western Europe' },
  { code: 'nl', name: 'Dutch', region: 'Western Europe' },
  { code: 'pt', name: 'Portuguese', region: 'Western Europe' },
  { code: 'es', name: 'Spanish', region: 'Western Europe' },
  { code: 'lb', name: 'Luxembourgish', region: 'Western Europe' },
  { code: 'rm', name: 'Romansh', region: 'Western Europe' },
  { code: 'ga', name: 'Irish', region: 'Western Europe' },

  // Northern Europe
  { code: 'da', name: 'Danish', region: 'Northern Europe' },
  { code: 'et', name: 'Estonian', region: 'Northern Europe' },
  { code: 'fi', name: 'Finnish', region: 'Northern Europe' },
  { code: 'is', name: 'Icelandic', region: 'Northern Europe' },
  { code: 'lv', name: 'Latvian', region: 'Northern Europe' },
  { code: 'lt', name: 'Lithuanian', region: 'Northern Europe' },
  { code: 'nb', name: 'Norwegian', region: 'Northern Europe' },
  { code: 'sv', name: 'Swedish', region: 'Northern Europe' },

  // Southern Europe
  { code: 'el', name: 'Greek', region: 'Southern Europe' },
  { code: 'mt', name: 'Maltese', region: 'Southern Europe' },
  { code: 'tr', name: 'Turkish', region: 'Southern Europe' },

  // Eastern Europe
  { code: 'sq', name: 'Albanian', region: 'Eastern Europe' },
  { code: 'be', name: 'Belarusian', region: 'Eastern Europe' },
  { code: 'bs', name: 'Bosnian', region: 'Eastern Europe' },
  { code: 'bg', name: 'Bulgarian', region: 'Eastern Europe' },
  { code: 'hr', name: 'Croatian', region: 'Eastern Europe' },
  { code: 'cs', name: 'Czech', region: 'Eastern Europe' },
  { code: 'hu', name: 'Hungarian', region: 'Eastern Europe' },
  { code: 'mk', name: 'Macedonian', region: 'Eastern Europe' },
  { code: 'pl', name: 'Polish', region: 'Eastern Europe' },
  { code: 'ro', name: 'Romanian', region: 'Eastern Europe' },
  { code: 'sr', name: 'Serbian', region: 'Eastern Europe' },
  { code: 'sk', name: 'Slovak', region: 'Eastern Europe' },
  { code: 'sl', name: 'Slovenian', region: 'Eastern Europe' },
  { code: 'uk', name: 'Ukrainian', region: 'Eastern Europe' },

  // North America
  { code: 'en', name: 'English', region: 'North America' },
  { code: 'fr', name: 'French', region: 'North America' },
  { code: 'es', name: 'Spanish', region: 'North America' },

  // South America
  { code: 'es', name: 'Spanish', region: 'South America' },
  { code: 'pt', name: 'Portuguese', region: 'South America' },
  { code: 'nl', name: 'Dutch', region: 'South America' },
  { code: 'en', name: 'English', region: 'South America' },
  { code: 'ay', name: 'Aymara', region: 'South America' },
  { code: 'qu', name: 'Quechua', region: 'South America' },
  { code: 'gn', name: 'Guaraní', region: 'South America' },

  // Asia
  { code: 'ar', name: 'Arabic', region: 'Asia' },
  { code: 'bn', name: 'Bengali', region: 'Asia' },
  { code: 'zh', name: 'Chinese', region: 'Asia' },
  { code: 'hi', name: 'Hindi', region: 'Asia' },
  { code: 'id', name: 'Indonesian', region: 'Asia' },
  { code: 'ja', name: 'Japanese', region: 'Asia' },
  { code: 'ko', name: 'Korean', region: 'Asia' },
  { code: 'ms', name: 'Malay', region: 'Asia' },
  { code: 'fa', name: 'Persian', region: 'Asia' },
  { code: 'ru', name: 'Russian', region: 'Asia' },
  { code: 'th', name: 'Thai', region: 'Asia' },
  { code: 'tr', name: 'Turkish', region: 'Asia' },
  { code: 'ur', name: 'Urdu', region: 'Asia' },
  { code: 'vi', name: 'Vietnamese', region: 'Asia' },
  { code: 'ka', name: 'Georgian', region: 'Asia' },
  { code: 'hy', name: 'Armenian', region: 'Asia' },
  { code: 'az', name: 'Azerbaijani', region: 'Asia' },
  { code: 'kk', name: 'Kazakh', region: 'Asia' },
  { code: 'ky', name: 'Kyrgyz', region: 'Asia' },
  { code: 'lo', name: 'Lao', region: 'Asia' },
  { code: 'my', name: 'Burmese', region: 'Asia' },
  { code: 'ne', name: 'Nepali', region: 'Asia' },
  { code: 'si', name: 'Sinhala', region: 'Asia' },
  { code: 'ta', name: 'Tamil', region: 'Asia' },
  { code: 'tg', name: 'Tajik', region: 'Asia' },
  { code: 'tk', name: 'Turkmen', region: 'Asia' },
  { code: 'uz', name: 'Uzbek', region: 'Asia' },
  { code: 'ps', name: 'Pashto', region: 'Asia' },
  { code: 'ku', name: 'Kurdish', region: 'Asia' },
  { code: 'he', name: 'Hebrew', region: 'Asia' },
  { code: 'tl', name: 'Tagalog', region: 'Asia' },
  { code: 'dv', name: 'Maldivian', region: 'Asia' },
  { code: 'mn', name: 'Mongolian', region: 'Asia' },

  // Africa
  { code: 'af', name: 'Afrikaans', region: 'Africa' },
  { code: 'am', name: 'Amharic', region: 'Africa' },
  { code: 'en', name: 'English', region: 'Africa' },
  { code: 'fr', name: 'French', region: 'Africa' },
  { code: 'pt', name: 'Portuguese', region: 'Africa' },
  { code: 'so', name: 'Somali', region: 'Africa' },
  { code: 'sw', name: 'Swahili', region: 'Africa' },
  { code: 'ti', name: 'Tigrinya', region: 'Africa' },
  { code: 'tn', name: 'Tswana', region: 'Africa' },
  { code: 'nr', name: 'Southern Ndebele', region: 'Africa' },
  { code: 'st', name: 'Southern Sotho', region: 'Africa' },
  { code: 'ss', name: 'Swati', region: 'Africa' },
  { code: 'ts', name: 'Tsonga', region: 'Africa' },
  { code: 've', name: 'Venda', region: 'Africa' },
  { code: 'xh', name: 'Xhosa', region: 'Africa' },
  { code: 'zu', name: 'Zulu', region: 'Africa' },
  { code: 'ny', name: 'Chichewa', region: 'Africa' },
  { code: 'sn', name: 'Shona', region: 'Africa' },
  { code: 'nd', name: 'Northern Ndebele', region: 'Africa' },
  { code: 'sg', name: 'Sango', region: 'Africa' },
  { code: 'rn', name: 'Kirundi', region: 'Africa' },
  { code: 'rw', name: 'Kinyarwanda', region: 'Africa' },
  { code: 'bi', name: 'Bislama', region: 'Africa' },

  // Oceania
  { code: 'en', name: 'English', region: 'Oceania' },
  { code: 'mi', name: 'Māori', region: 'Oceania' },
  { code: 'fj', name: 'Fijian', region: 'Oceania' },
  { code: 'hi', name: 'Hindi', region: 'Oceania' },
  { code: 'mh', name: 'Marshallese', region: 'Oceania' },
  { code: 'na', name: 'Nauruan', region: 'Oceania' },
  { code: 'sm', name: 'Samoan', region: 'Oceania' },
  { code: 'to', name: 'Tongan', region: 'Oceania' },
  { code: 'ho', name: 'Hiri Motu', region: 'Oceania' },
  { code: 'tpi', name: 'Tok Pisin', region: 'Oceania' },

  // Other Regions
  { code: 'en', name: 'English', region: 'Other Regions' },
];

// Create a map for backward compatibility
export const languageMap: Record<string, string> = languages.reduce(
  (acc, lang) => ({
    ...acc,
    [lang.code]: lang.name,
  }),
  {},
);

export const languageCountryMap = countries.flatMap((country) =>
  country.languages.map((language) => ({
    countryCode: country.code,
    countryName: country.country,
    languageCode: language,
    languageName: languageMap[language as keyof typeof languageMap],
  })),
);

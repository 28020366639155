import { Company } from '@bluebird-monorepo/types';
import { Box, Tab, tabClasses, TabList, Tabs } from '@mui/joy';
import React from 'react';
import { FinanceTab } from './Finance';
import { GeneralTab } from './General/GeneralTab';
import { PeopleTab } from './People';
import { CompaniesTimelineTable } from './Timeline/CompanyTimelineTable';

interface TabsContentProps {
  company: Company;
  tabIndex: number;
}

interface TabListProps {
  company: Company;
  onTabChange: (newValue: number) => void;
  tabIndex: number;
}

export const TabListComponent: React.FC<TabListProps> = ({ company, onTabChange, tabIndex }) => {
  return (
    <Tabs onChange={(_, newValue) => onTabChange(newValue as number)} sx={{ width: 'fit-content' }} value={tabIndex}>
      <TabList
        disableUnderline
        sx={{
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            bgcolor: 'background.surface',
            boxShadow: 'sm',
          },
          bgcolor: 'background.level1',
          borderRadius: 'xl',
          gap: 0.5,
          p: 0.5,
        }}
      >
        <Tab disableIndicator value={0}>
          General
        </Tab>
        <Tab disableIndicator value={1}>
          Finance
        </Tab>
        <Tab disableIndicator value={2}>
          People (
          {company &&
            company.contactPersons &&
            company.hiringManagers &&
            company.contactPersons?.length + company.hiringManagers?.length}
          )
        </Tab>
        <Tab disableIndicator value={3}>
          Timeline
        </Tab>
      </TabList>
    </Tabs>
  );
};

export const TabsContentComponent: React.FC<TabsContentProps> = ({ company, tabIndex }) => {
  return (
    <Box>
      {tabIndex === 0 && <GeneralTab company={company} />}
      {tabIndex === 1 && <FinanceTab company={company} />}
      {tabIndex === 2 && <PeopleTab company={company} />}
      {tabIndex === 3 && <CompaniesTimelineTable companyId={company.id} />}
    </Box>
  );
};

import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { Placement } from '@bluebird-monorepo/types';
import { Business, CalendarToday, Euro, Person } from '@mui/icons-material';
import { Box, CardContent, Link, Stack, Typography } from '@mui/joy';
import { formatDate, formatFee, responsibleMapping } from '..';
import { useGetCandidateByFirstAndLastName } from '@bluebird-monorepo/candidates';

interface PlacementDetailsProps {
  placement: Placement;
}

export function PlacementDetails({ placement }: PlacementDetailsProps) {
  const [firstName, lastName] = placement.candidate.split(' ');
  const { data: candidate } = useGetCandidateByFirstAndLastName(firstName, lastName);
  return (
    <BluebirdCard>
      <CardContent>
        <Stack spacing={3}>
          <Box>
            <Typography level="h3" sx={{ mb: 2 }}>
              Placement Information
            </Typography>

            <Stack spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Person />
                <Typography level="title-md">Candidate:</Typography>
                {candidate?.id ? (
                  <Link href={`/candidates/${candidate?.id}`}>{placement.candidate}</Link>
                ) : (
                  <Typography>{placement.candidate}</Typography>
                )}
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Business />
                <Typography level="title-md">Company:</Typography>
                <Link href={`/companies/${placement.company}`}>{placement.company}</Link>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CalendarToday />
                <Typography level="title-md">Placement Date:</Typography>
                <Typography>{formatDate(placement.placementdate)}</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Euro />
                <Typography level="title-md">Fee:</Typography>
                <Typography>{formatFee(placement.fee)}</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Person />
                <Typography level="title-md">Representative:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <img
                    src={responsibleMapping[placement.rep as keyof typeof responsibleMapping]?.imgUrl}
                    alt={placement.rep}
                    style={{ width: 24, height: 24, borderRadius: '50%' }}
                  />
                  <Typography>{placement.rep}</Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </BluebirdCard>
  );
}

import { StyledEngineProvider } from '@mui/joy';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/App';
import { AuthProvider } from './app/providers/AuthProvider';
import { logging, debug } from './app/config/environment';
import './index.css';

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
    collapseGroups: true,
  });
}

// Use the centralized config for query logging
const { isQueryLoggingEnabled } = logging;

// Configure React Query client with debug options controlled by environment
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      gcTime: 1000 * 60 * 30, // 30 minutes
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      // Disable noisy React Query logging in development mode unless explicitly enabled
      meta: {
        __DEV__logQuery: isQueryLoggingEnabled,
      },
    },
  },
  queryCache: new QueryCache({
    onSuccess: (data, query) => {
      if (isQueryLoggingEnabled) {
        console.log('🔄 Query Success:', {
          queryKey: query.queryKey,
          timestamp: new Date().toISOString(),
          cacheTime: query.state.dataUpdatedAt,
        });
      }
    },
    onError: (error, query) => {
      if (isQueryLoggingEnabled) {
        console.error('❌ Query Error:', {
          queryKey: query.queryKey,
          query: JSON.stringify(query),
          error: JSON.stringify(error),
          timestamp: new Date().toISOString(),
        });
      }
    },
  }),
});

// Add a global query observer
if (isQueryLoggingEnabled) {
  queryClient.getQueryCache().subscribe((event) => {
    if (event?.type === 'updated') {
      console.group(`🔍 Query Update: ${event.query.queryHash}`);
      console.log('Query Key:', event.query.queryKey);
      console.log('Status:', event.query.state.status);
      console.log('Fetch Count:', event.query.state.dataUpdateCount);
      console.log('Last Updated:', new Date(event.query.state.dataUpdatedAt).toLocaleString());
      console.log('Is Fetching:', event.query.state.fetchStatus === 'fetching');
      console.groupEnd();
    }
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <App />
        </AuthProvider>
        {isQueryLoggingEnabled && <ReactQueryDevtools initialIsOpen={false} />}
        <ToastContainer />
      </QueryClientProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

import { Box, FormControl, Input, Select, Option, FormLabel } from '@mui/joy';
import { Control, Controller } from 'react-hook-form';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useGetUsers } from '@bluebird-monorepo/users';
import { parseResponsibleEmail } from '@bluebird-monorepo/shared';

interface IntroductionTableFiltersProps {
  control: Control<any>;
  onResetPage: () => void;
}

export function IntroductionTableFilters({ control, onResetPage }: IntroductionTableFiltersProps) {
  const { data: users } = useGetUsers();

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'flex-end' }}>
      <FormControl sx={{ minWidth: 300 }}>
        <Controller
          name="filterContent"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Search introductions..."
              startDecorator={<SearchRoundedIcon />}
              onChange={(e) => {
                field.onChange(e);
                onResetPage();
              }}
            />
          )}
        />
      </FormControl>

      <FormControl sx={{ minWidth: 200 }}>
        <FormLabel>Author</FormLabel>
        <Controller
          name="authorId"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
                onResetPage();
              }}
            >
              <Option key="All" value={null}>
                All
              </Option>
              {users?.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.displayName || parseResponsibleEmail(user.email)}
                </Option>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  );
}

export default IntroductionTableFilters;

import { CalendarEvent, CalendarEventDb, CalendarEventDuration } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@bluebird-monorepo/types';
import { useCalendarStore } from '../store/calendar.store';

interface UseGetCalendarEventsOptions {
  startDate: Date;
  endDate?: Date;
}

type UseGetCalendarEventsQuery = PostgrestFilterBuilder<
  Database['public'],
  Record<keyof CalendarEventDb, unknown>,
  CalendarEventDb[]
>;

const DEFAULT_OPTIONS: UseGetCalendarEventsOptions = {
  startDate: new Date(),
  endDate: getEndOfWeekFromStartOfWeek(new Date()),
};

function getEndOfWeekFromStartOfWeek(startOfWeek?: Date | null) {
  if (!startOfWeek) return;
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);
  return endOfWeek;
}

export function useGetCalendarEvents(optionsArg?: UseGetCalendarEventsOptions) {
  const options = {
    ...DEFAULT_OPTIONS,
    ...{ endDate: getEndOfWeekFromStartOfWeek(optionsArg?.startDate) || DEFAULT_OPTIONS.endDate },
    ...optionsArg,
  };
  const { showNoAttendees } = useCalendarStore();
  return useQuery<CalendarEvent[]>({
    queryKey: ['calendarEvents', options, showNoAttendees],
    queryFn: async () => {
      const { data } = await getCalendarEvents(options, showNoAttendees);
      console.log('useGetCalendarEvents data', transformData(data));
      return data ? transformData(data) : ([] as CalendarEvent[]);
    },
  });
}

async function getCalendarEvents(options: UseGetCalendarEventsOptions, showNoAttendees: boolean) {
  const query = supabase.from('calendar_events').select('*').returns<CalendarEventDb[]>() as UseGetCalendarEventsQuery;
  query.overlaps('duration', `[${options.startDate.toISOString()}, ${options.endDate?.toISOString()}]`);
  if (!showNoAttendees) query.not('attendees', 'is', null).gt('attendees', '[]');
  const { data, error } = await query;
  if (error) throw error;
  return { data };
}

function transformData(data: CalendarEventDb[] | null): CalendarEvent[] {
  if (!data) return [];
  return data.map((event) => ({
    ...toCamelCase<CalendarEvent>(event),
    duration: parseDuration(event.duration as string | null),
  }));
}

function parseDuration(durationString: string | null): CalendarEventDuration {
  if (!durationString) return undefined;
  // Remove the brackets and split the string
  const cleaned = durationString.replace(/[\[\]"]/g, '');
  // remove last character if it's a ")"
  const cleaned2 = cleaned.replace(/\s*\)\s*$/, '');
  const [start, end] = cleaned2.split(',').map((d) => new Date(d.trim()));
  return { start, end };
}

import { KanbanBoard, KanbanColumn, KanbanSettingsDrawer, useKanbanSettings } from '@bluebird-monorepo/kanban';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { CircularProgress } from '@mui/joy';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';
import {
  createStagePipelineMap,
  mapToPipeline,
  useGetAssignmentsByOwner,
  usePipelineQueryInvalidation,
} from '@bluebird-monorepo/assignments';
import { useStages } from '@bluebird-monorepo/shared';
import { useGetTeamAssignmentsByOwner } from '@bluebird-monorepo/assignments';

const PipelinePage: React.FC = () => {
  const { stages } = useStages();
  const location = useLocation();
  const isPipelineRoot = location.pathname === '/pipeline';
  const isMyPipeline = location.pathname === '/pipeline/my';
  const isTeamPipeline = location.pathname === '/pipeline/team';
  // const isJobPipeline = location.pathname === '/pipeline/job';
  const { data: user } = useGetCurrentUser();

  // Use the pipeline query invalidation hook
  usePipelineQueryInvalidation();

  // Use either personal assignments or team assignments based on route
  const {
    data: myAssignments,
    error: myError,
    isLoading: myLoading,
  } = useGetAssignmentsByOwner(isMyPipeline ? user?.email || '' : undefined);

  const {
    data: teamAssignments,
    error: teamError,
    isLoading: teamLoading,
  } = useGetTeamAssignmentsByOwner(isTeamPipeline ? user?.email || '' : undefined);

  // Combine errors and loading states
  const error = myError || teamError;
  const isLoading = myLoading || teamLoading;

  // Use appropriate assignments based on route
  const rawAssignments = useMemo(() => {
    if (isTeamPipeline) return teamAssignments || [];
    return myAssignments || [];
  }, [isTeamPipeline, teamAssignments, myAssignments]);

  const assignments = useMemo(() => mapToPipeline(rawAssignments), [rawAssignments]);
  const stageMap = useMemo(() => createStagePipelineMap(stages, assignments), [stages, assignments]);

  // Kanban Settings
  const { selectedJobIds, hiddenStageIds } = useKanbanSettings();
  const [open, setOpen] = useState(false);

  // Active Job IDs Calculation
  const activeJobIds = useMemo(() => {
    if (!assignments) return [];
    return [
      ...new Set(assignments.map((assignment) => assignment.jobId).filter((id): id is number => id !== undefined)),
    ];
  }, [assignments]);

  // Filter Stage Map based on settings
  const filteredStageMap = useMemo(() => {
    if (!stageMap || !selectedJobIds || !user?.email) return {};

    const result: KanbanColumn = {};
    Object.entries(stageMap).forEach(([stageId, candidates]) => {
      if (hiddenStageIds.includes(Number(stageId))) return;

      result[Number(stageId)] = candidates
        .filter((candidate) => {
          if (selectedJobIds.length === 0) return true;
          return candidate.jobId && selectedJobIds.includes(candidate.jobId);
        })
        .map((candidate) => ({
          id: candidate.id,
          jobId: candidate.jobId || 0,
          candidateId: candidate.candidateId || 0,
          candidateFirstName: candidate.candidateFirstName || '',
          candidateLastName: candidate.candidateLastName || '',
          currentPosition: candidate.currentPosition || '',
          currentOrganization: candidate.currentOrganization || '',
          avatar: candidate.avatar || '',
          link: candidate.link || '',
          jobTitle: candidate.jobTitle || '',
          companyName: candidate.companyName || '',
          logoUrl: candidate.logoUrl || '',
        }));
    });

    return result;
  }, [stageMap, selectedJobIds, hiddenStageIds, user?.email]);

  // Error and Loading States
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading || !stageMap) {
    return (
      <Layout title="Pipeline">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </div>
      </Layout>
    );
  }

  return (
    <Layout
      title={
        isPipelineRoot
          ? 'Pipeline Overview'
          : isMyPipeline
            ? 'My Pipeline'
            : isTeamPipeline
              ? 'Team Pipeline'
              : 'Pipeline per Job'
      }
      actionButton={{
        label: 'Settings for Kanban Board',
        onClick: () => setOpen(true),
        variant: 'dashed',
      }}
    >
      <div style={{ position: 'relative' }}>
        <KanbanSettingsDrawer activeJobIds={activeJobIds} open={open} setOpen={setOpen} />
        {Object.keys(filteredStageMap).length > 0 && (
          <KanbanBoard
            initial={filteredStageMap}
            withScrollableColumns={true}
            containerStyle={{ height: 'calc(100vh - 200px)' }}
          />
        )}
      </div>
    </Layout>
  );
};

export default PipelinePage;

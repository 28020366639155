import { Company } from '@bluebird-monorepo/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SortField = keyof Pick<Company, 'name' | 'owner' | 'status' | 'jobCount'>;

export interface SortConfig {
  field: SortField;
  direction: 'asc' | 'desc';
}

export interface CompaniesTableFilters {
  filterName: string;
  statusFilter: string;
}

export interface CompaniesTableState {
  sortConfig: SortConfig;
  setSortConfig: (sortConfig: SortConfig) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  search: string;
  setSearch: (search: string) => void;
  page: number;
  setPage: (page: number) => void;
  filters: CompaniesTableFilters;
  setFilters: (filters: CompaniesTableFilters) => void;
}

export const useCompaniesTableStore = create<CompaniesTableState>()(
  persist(
    (set) => ({
      sortConfig: {
        field: 'name',
        direction: 'asc',
      },
      setSortConfig: (sortConfig: SortConfig) => set({ sortConfig }),
      rowsPerPage: 10,
      setRowsPerPage: (rowsPerPage: number) => set({ rowsPerPage }),
      search: '',
      setSearch: (search: string) => set({ search }),
      page: 1,
      setPage: (page: number) => set({ page }),
      filters: {
        filterName: '',
        statusFilter: '',
      },
      setFilters: (filters) => set({ filters }),
    }),
    {
      name: 'companies-table',
    },
  ),
);

import { useState } from 'react';
import { aiService } from '../services/ai.service';

export const useAI = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateIntroduction = async (params: {
    provider: 'openai' | 'anthropic';
    prompt: string;
    calendarEvent?: any;
    conversations?: any[];
    resumeUrl?: string;
  }) => {
    setIsGenerating(true);
    setError(null);

    try {
      const content = await aiService.generateIntroduction(params);
      return content;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to generate introduction';
      setError(errorMessage);
      return null;
    } finally {
      setIsGenerating(false);
    }
  };

  return {
    generateIntroduction,
    isGenerating,
    error,
  };
};

import { type Candidate } from '@bluebird-monorepo/types';
import { Autocomplete, Box, Button, Divider, List, Snackbar, Stack, Typography } from '@mui/joy';
import { FC, useMemo, useState, useCallback } from 'react';
import { AssignmentCard, AssignmentSkeleton } from './AssignmentCard';
import { useGetJobsByJobTitle } from '@bluebird-monorepo/jobs';
import debounce from 'lodash/debounce';
import {
  useUpsertAssignment,
  useGetAssignmentsByCandidateId,
  useGetAssignmentsByJobId,
  useGetAssignmentsByOwner,
} from '@bluebird-monorepo/assignments';
import { useGetCurrentUser } from '@bluebird-monorepo/users';

interface JobOption {
  id: number;
  label: string;
  company: string;
  logoUrl: string;
}

interface JobAssignmentSectionProps {
  candidate: Candidate;
}

export const JobAssignmentSection: FC<JobAssignmentSectionProps> = ({ candidate }) => {
  const createAssignment = useUpsertAssignment();
  const { data: assignments } = useGetAssignmentsByCandidateId(Number(candidate.id));
  const [selectedJob, setSelectedJob] = useState<JobOption | null>(null);
  const { data: loggedInUser } = useGetCurrentUser();
  const { refetch: refetchAssignmentsByJobId } = useGetAssignmentsByJobId(Number(selectedJob?.id));
  const { refetch: refetchAssignmentsByCandidateId } = useGetAssignmentsByCandidateId(Number(candidate.id));
  const { refetch: refetchAssignmentsByOwner } = useGetAssignmentsByOwner(loggedInUser?.email || '');
  const [search, setSearch] = useState('');
  const { data: jobs } = useGetJobsByJobTitle(search);
  const [errorMessage, setErrorMessage] = useState('');

  const debouncedSetSearch = useCallback(
    debounce((searchValue: string) => {
      setSearch(searchValue);
    }, 300),
    [setSearch],
  );

  const jobOptions = useMemo(() => {
    if (!jobs) return [];
    return jobs.map((job) => ({
      id: job.id,
      label: job.jobTitle,
      company: job.companyName,
      logoUrl: job.companyLogo || '',
    }));
  }, [jobs]);

  const isCandidateAlreadyAssignedToJob = useMemo(() => {
    if (!assignments || !selectedJob?.id) return false;
    return assignments.some((assignment) => {
      // TODO: Remove cast to number once we have a proper job id in the database
      return assignment.jobId === selectedJob.id || Number(assignment.jobId) === Number(selectedJob.id);
    });
  }, [assignments, selectedJob]);

  const handleAssignToJob = async () => {
    if (!selectedJob?.id) return;

    if (isCandidateAlreadyAssignedToJob) {
      setErrorMessage('Candidate is already assigned to this job.');
      return;
    }

    try {
      await createAssignment.mutateAsync({
        jobId: selectedJob.id,
        candidateId: candidate.id,
        status: 'Applied',
        statusId: 1,
        owner: loggedInUser?.email || '',
        jobTitle: selectedJob.label,
        companyName: selectedJob.company,
        logoUrl: selectedJob.logoUrl || '',
        currentPosition: candidate.currentPosition,
        currentOrganization: candidate.currentOrganization,
        candidateFirstName: candidate.firstName,
        candidateLastName: candidate.lastName,
        candidateEmail: candidate.contactInfo?.email,
      });
      setSelectedJob(null);
      // Invalidation does not seem to work, so we need to refetch manually
      await refetchAssignmentsByJobId();
      await refetchAssignmentsByCandidateId();
      await refetchAssignmentsByOwner();
    } catch (error) {
      setErrorMessage(
        `Failed to assign candidate: ${error instanceof Error ? error.message : 'An unknown error occurred.'}`,
      );
    }
  };

  return (
    <Box>
      <Stack direction="row" gap={1} my={2} width="100%">
        <Autocomplete
          onChange={(_, value) => setSelectedJob(value)}
          onInputChange={(_, value) => debouncedSetSearch(value)}
          options={jobOptions}
          getOptionLabel={(option) => `${option.label} - ${option.company}`}
          placeholder="Search for a job..."
          noOptionsText="Type to search for a job..."
          sx={{ width: '100%' }}
          value={selectedJob}
        />
        <Button disabled={!selectedJob} onClick={handleAssignToJob} sx={{ whiteSpace: 'nowrap' }} variant="outlined">
          Assign to Job
        </Button>
      </Stack>

      <Divider />
      {assignments && assignments.length > 0 ? (
        <>
          <Typography level="h4" pt={2}>
            List of Assignments
          </Typography>
          <List>
            {assignments.map((assignment) => {
              if (assignment.jobTitle === undefined) {
                // Return loading skeleton
                return <AssignmentSkeleton key={assignment.id} />;
              }
              return <AssignmentCard assignment={assignment} key={assignment.id} />;
            })}
          </List>
        </>
      ) : (
        <Typography level="body-md" mt={4} pb={2} textAlign="center">
          No jobs assigned
        </Typography>
      )}

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
        color="danger"
        onClose={() => setErrorMessage('')}
        open={!!errorMessage}
      >
        {errorMessage}
      </Snackbar>
    </Box>
  );
};

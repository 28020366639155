import { Job, PriorityOverride } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertJob() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      job: Partial<Omit<Job, 'priorityOverride'> & { priorityOverride?: Partial<PriorityOverride> }>,
    ) => {
      const { data, error } = await supabase
        .from('jobs')
        .upsert(toSnakeCase(job), {
          onConflict: 'id',
        })
        .select()
        .single();

      if (error) throw error;
      return toCamelCase<Job>(data);
    },
    onSuccess: (job: Job) => {
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
      queryClient.invalidateQueries({ queryKey: ['jobById', job.id] });
      queryClient.invalidateQueries({ queryKey: ['jobsByCompanyId', job.companyId] });
    },
  });
}

import { Candidate } from '@bluebird-monorepo/types';
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
  Switch,
  Select,
  Option,
} from '@mui/joy';
import { useFormContext, Controller } from 'react-hook-form';

export const PreferencesStep = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<Candidate>();

  return (
    <Box>
      <Typography level="h4" sx={{ mb: 2 }}>
        Preferences & Requirements
      </Typography>
      <Stack spacing={2}>
        <Stack spacing={2} width="100%">
          <Stack direction="row" spacing={2} width="100%">
            <FormControl sx={{ width: '50%' }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name="isOpenToRelocate"
                  control={control}
                  render={({ field }) => (
                    <Checkbox checked={field.value} onChange={(event) => field.onChange(event.target.checked)} />
                  )}
                />
                <Typography>Open to Relocate</Typography>
              </Stack>
            </FormControl>

            <FormControl sx={{ width: '50%' }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name="isOpenToWorkRemotely"
                  control={control}
                  render={({ field }) => (
                    <Checkbox checked={field.value} onChange={(event) => field.onChange(event.target.checked)} />
                  )}
                />
                <Typography>Open to Remote Work</Typography>
              </Stack>
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={2} width="100%">
            <FormControl sx={{ width: '50%' }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name="requiresVisa"
                  control={control}
                  render={({ field }) => (
                    <Checkbox checked={field.value} onChange={(event) => field.onChange(event.target.checked)} />
                  )}
                />
                <Typography>Requires Visa</Typography>
              </Stack>
            </FormControl>
          </Stack>
        </Stack>

        <FormControl error={Boolean(errors.inOfficeExpectations)}>
          <FormLabel>In-Office Expectations</FormLabel>
          <Input {...register('inOfficeExpectations')} />
          <FormHelperText>{errors.inOfficeExpectations?.message}</FormHelperText>
        </FormControl>

        <FormControl error={Boolean(errors.availability)}>
          <FormLabel>Availability</FormLabel>
          <Input {...register('availability')} />
          <FormHelperText>{errors.availability?.message}</FormHelperText>
        </FormControl>

        <Typography level="title-md" sx={{ mt: 2, mb: 1 }}>
          Compensation
        </Typography>

        <Stack spacing={2}>
          <Typography level="title-sm">Current Compensation</Typography>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>Monthly Base Salary</FormLabel>
              <Input
                type="number"
                {...register('salaryInformation.currentSalary.monthly')}
                endDecorator={<Typography level="body-sm">EUR</Typography>}
              />
            </FormControl>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>Yearly Base Salary</FormLabel>
              <Input
                type="number"
                {...register('salaryInformation.currentSalary.yearly')}
                endDecorator={<Typography level="body-sm">EUR</Typography>}
              />
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={2}>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>Annual Bonus</FormLabel>
              <Input
                type="number"
                {...register('salaryInformation.currentSalary.bonus')}
                endDecorator={<Typography level="body-sm">EUR</Typography>}
              />
            </FormControl>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>&nbsp;</FormLabel>
              <Stack direction="row" spacing={1} alignItems="center" sx={{ height: '100%' }}>
                <Controller
                  name="salaryInformation.currentSalary.hasEquity"
                  control={control}
                  render={({ field }) => (
                    <Switch checked={field.value} onChange={(event) => field.onChange(event.target.checked)} />
                  )}
                />
                <Typography>Has Equity</Typography>
              </Stack>
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={2}>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>Total OTE (Base + Bonus)</FormLabel>
              <Input
                type="number"
                value={
                  Number(control._formValues.salaryInformation?.currentSalary?.yearly || 0) +
                    Number(control._formValues.salaryInformation?.currentSalary?.bonus || 0) || ''
                }
                disabled
                endDecorator={<Typography level="body-sm">EUR</Typography>}
                sx={{ bgcolor: 'background.level1' }}
              />
              <FormHelperText>Automatically calculated from base salary + bonus</FormHelperText>
            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography level="title-sm">Compensation Expectations</Typography>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>Currency</FormLabel>
              <Controller
                name="salaryInformation.salaryExpectationRange.currency"
                control={control}
                defaultValue="EUR"
                render={({ field }) => (
                  <Select value={field.value || 'EUR'} onChange={(_, value) => field.onChange(value)}>
                    <Option value="EUR">EUR</Option>
                    <Option value="USD">USD</Option>
                    <Option value="GBP">GBP</Option>
                  </Select>
                )}
              />
            </FormControl>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>Minimum Expected OTE</FormLabel>
              <Input
                type="number"
                {...register('salaryInformation.salaryExpectationRange.min')}
                endDecorator={
                  <Typography level="body-sm">
                    {control._formValues.salaryInformation?.salaryExpectationRange?.currency || 'EUR'}
                  </Typography>
                }
              />
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={2}>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>Maximum Expected OTE</FormLabel>
              <Input
                type="number"
                {...register('salaryInformation.salaryExpectationRange.max')}
                endDecorator={
                  <Typography level="body-sm">
                    {control._formValues.salaryInformation?.salaryExpectationRange?.currency || 'EUR'}
                  </Typography>
                }
              />
            </FormControl>
            <FormControl sx={{ width: '50%' }}>
              <FormLabel>&nbsp;</FormLabel>
              <Stack direction="row" spacing={1} alignItems="center" sx={{ height: '100%' }}>
                <Controller
                  name="salaryInformation.salaryExpectationRange.expectsEquity"
                  control={control}
                  render={({ field }) => (
                    <Switch checked={field.value} onChange={(event) => field.onChange(event.target.checked)} />
                  )}
                />
                <Typography>Expects Equity</Typography>
              </Stack>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

import { LogsTable } from '@bluebird-monorepo/notifications';
import { useCompaniesTimelineTableSettings } from './useCompanyTimelineTableSettings';
import { Typography, Card } from '@mui/joy';

export function CompaniesTimelineTable({ companyId }: { companyId: number }) {
  const { hiddenColumnIds, toggleColumnVisibility } = useCompaniesTimelineTableSettings();
  return (
    <Card variant="outlined" sx={{ p: 3, bgcolor: 'white' }}>
      <Typography level="title-lg">Company Timeline</Typography>
      <LogsTable
        tableName="companies"
        entityId={companyId}
        hiddenColumnIds={hiddenColumnIds}
        toggleColumnVisibility={toggleColumnVisibility}
        defaultViewMode="timeline"
      />
    </Card>
  );
}

import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Step, StepIndicator, Stepper, Typography } from '@mui/joy';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useUpsertIntroduction } from '../../api/introductions/useUpsertIntroduction';
import { AIPromptStep, CalendarStep, ConversationStep, ResumeStep } from './steps';

// Placeholder component until implemented
const ReviewStep: FC = () => <div>Review Step</div>;

export interface IntroductionFormData {
  calendarEvent?: {
    id: string;
    title: string;
    when: {
      startTime: number;
      endTime: number;
    };
    attendees: {
      email: string;
      responseStatus?: 'accepted' | 'declined' | 'tentative';
    }[];
  };
  conversations?: {
    id: string;
    content: string;
    timestamp: number;
    title: string;
    attendees: string[];
  }[];
  resumeUrl?: string;
  aiConfig?: {
    provider: 'openai' | 'anthropic';
    customPrompt?: string;
  };
  generatedContent?: string;
  finalContent?: string;
}

interface IntroductionWizardProps {
  candidateId: number;
  jobId: number;
  onComplete: () => void;
  onCancel: () => void;
}

const steps = [
  {
    label: 'Calendar',
    component: CalendarStep,
  },
  {
    label: 'Conversations',
    component: ConversationStep,
  },
  {
    label: 'Resume',
    component: ResumeStep,
  },
  {
    label: 'AI Prompt',
    component: AIPromptStep,
  },
  {
    label: 'Review',
    component: ReviewStep,
  },
];

export const IntroductionWizard: FC<IntroductionWizardProps> = ({ candidateId, jobId, onComplete, onCancel }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { mutate: upsertIntroduction } = useUpsertIntroduction();

  const methods = useForm<IntroductionFormData>({
    defaultValues: {
      conversations: [],
      aiConfig: {
        provider: 'openai',
      },
    },
  });

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      const formData = methods.getValues();
      if (formData.finalContent) {
        await upsertIntroduction({
          candidateId,
          jobId,
          content: formData.finalContent,
        });
        onComplete();
      }
      return;
    }

    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const CurrentStepComponent = steps[activeStep].component;

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.surface',
          borderRadius: 'lg',
          boxShadow: 'sm',
          overflow: 'hidden',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            px: 3,
            py: 2,
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: 'divider',
            position: 'relative',
          }}
        >
          <Typography level="h4" sx={{ flex: 1 }}>
            Create Introduction
          </Typography>
          <IconButton
            onClick={onCancel}
            variant="plain"
            sx={{
              position: 'absolute',
              right: 8,
              color: 'text.secondary',
              '&:hover': { color: 'text.primary' },
            }}
          >
            <Close />
          </IconButton>
        </Box>

        {/* Content */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          {/* Left side - Stepper */}
          <Box
            sx={{
              width: 280,
              borderRight: '1px solid',
              borderColor: 'divider',
              bgcolor: 'background.level1',
              p: 3,
            }}
          >
            <Stepper
              orientation="vertical"
              sx={{
                '--Stepper-spacing': '1rem',
                '--StepIndicator-size': '2rem',
                '--Step-gap': '1rem',
                '--Step-connectorThickness': '2px',
                '--Step-connectorRadius': '1px',
                minHeight: 400,
              }}
            >
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  completed={index < activeStep}
                  indicator={
                    <StepIndicator
                      variant={activeStep === index ? 'solid' : 'soft'}
                      color={activeStep === index ? 'primary' : 'neutral'}
                    >
                      {index + 1}
                    </StepIndicator>
                  }
                >
                  <Box>
                    <Typography fontWeight="lg">{step.label}</Typography>
                  </Box>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* Right side - Content */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            {/* Step Content */}
            <Box
              sx={{
                flex: 1,
                overflow: 'auto',
                p: 3,
              }}
            >
              <CurrentStepComponent onNext={handleNext} />
            </Box>

            {/* Navigation */}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                p: 3,
                borderTop: '1px solid',
                borderColor: 'divider',
                bgcolor: 'background.surface',
              }}
            >
              <Button variant="outlined" color="neutral" onClick={onCancel} sx={{ minWidth: 100 }}>
                Cancel
              </Button>
              {activeStep > 0 && (
                <Button variant="outlined" onClick={handleBack} sx={{ minWidth: 100 }}>
                  Back
                </Button>
              )}
              <Button onClick={handleNext} sx={{ minWidth: 100 }}>
                {activeStep === steps.length - 1 ? 'Create Introduction' : 'Next'}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
};

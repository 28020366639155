import { Log } from '@bluebird-monorepo/types';
import { Box, Button, Card, CardContent, Chip, Stack, Typography, styled, Divider } from '@mui/joy';
import { format } from 'date-fns';
import { Add, Delete, Edit, Settings, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Avatar } from '@mui/joy';
import { useState } from 'react';
import { formatFieldName } from '../../utils/log.utils';

// Styled components for the timeline
const TimelineDot = styled('div')(({ theme }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: theme.vars.palette.primary[500],
  position: 'absolute',
  left: -5,
  top: 24,
}));

const TimelineLine = styled('div')(({ theme }) => ({
  width: 2,
  backgroundColor: theme.vars.palette.divider,
  position: 'absolute',
  left: -1,
  top: 0,
  bottom: 0,
}));

// Get icon based on action type
const getIconByAction = (action: string) => {
  switch (action) {
    case 'INSERT':
      return <Add />;
    case 'UPDATE':
      return <Edit />;
    case 'DELETE':
      return <Delete />;
    default:
      return <Settings />;
  }
};

// Get color based on action type
const getColorByAction = (action: string) => {
  switch (action) {
    case 'INSERT':
      return 'success';
    case 'UPDATE':
      return 'primary';
    case 'DELETE':
      return 'danger';
    default:
      return 'neutral';
  }
};

interface LogsTimelineProps {
  logs: Log[];
  initialLimit?: number;
}

export function LogsTimeline({ logs, initialLimit = 10 }: LogsTimelineProps) {
  const [showAll, setShowAll] = useState(false);

  // Sort logs by createdAt in descending order (newest first)
  const sortedLogs = [...logs].sort((a, b) => {
    const dateA = a.createdAt ? new Date(a.createdAt) : new Date();
    const dateB = b.createdAt ? new Date(b.createdAt) : new Date();
    return dateB.getTime() - dateA.getTime();
  });

  const displayedLogs = showAll ? sortedLogs : sortedLogs.slice(0, initialLimit);
  const hasMore = sortedLogs.length > initialLimit;

  if (!logs.length) {
    return (
      <Card variant="soft">
        <CardContent>
          <Typography level="body-sm" textAlign="center">
            No logs found
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Stack spacing={1}>
      <Box>
        {displayedLogs.map((log, index) => (
          <LogTimelineEntry key={log.id} log={log} isLast={index === displayedLogs.length - 1 && !hasMore} />
        ))}
      </Box>

      {hasMore && !showAll && (
        <Button variant="plain" color="neutral" onClick={() => setShowAll(true)} sx={{ alignSelf: 'center' }}>
          Show all {sortedLogs.length} entries
        </Button>
      )}
    </Stack>
  );
}

interface LogTimelineEntryProps {
  log: Log;
  isLast?: boolean;
}

function LogTimelineEntry({ log, isLast }: LogTimelineEntryProps) {
  const [showContent, setShowContent] = useState(false);

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'Unknown date';
    return format(new Date(dateString), 'MMM d, yyyy HH:mm');
  };

  const formatArrayValue = (value: any): string => {
    if (Array.isArray(value)) {
      return value
        .map((item) => item.replace(/^"(.*)"$/, '$1')) // Remove quotes from items
        .join(', ');
    }
    return value;
  };

  const formatContent = (content: string) => {
    return content
      .split(',')
      .map((item) => item.trim().replace(/^"(.*)"$/, '$1')) // Remove quotes
      .filter((item) => {
        // Filter out empty arrays, booleans
        if (item === '[]' || item === 'false' || item === 'true') return false;
        return true;
      })
      .map((item) => (
        <Typography key={item} level="body-sm">
          {item}
        </Typography>
      ));
  };

  const isHtmlContent = (value: string): boolean => {
    // Basic check for HTML content - looks for opening and closing tags
    return /<[a-z][\s\S]*>/i.test(value);
  };

  const formatObjectValue = (obj: Record<string, any>, indent = 0): React.ReactNode => {
    return (
      <Stack spacing={0.5} sx={{ pl: indent }}>
        {Object.entries(obj).map(([key, val]) => {
          const formattedKey = formatFieldName(key);

          // Handle nested objects
          if (val && typeof val === 'object' && !Array.isArray(val)) {
            return (
              <Box key={key}>
                <Typography level="body-sm" fontWeight="bold">
                  {formattedKey}:
                </Typography>
                {formatObjectValue(val, indent + 2)}
              </Box>
            );
          }

          // Handle arrays
          if (Array.isArray(val)) {
            return (
              <Typography key={key} level="body-sm">
                <Typography component="span" fontWeight="bold">
                  {formattedKey}:
                </Typography>{' '}
                {formatArrayValue(val)}
              </Typography>
            );
          }

          // Handle primitive values
          return (
            <Typography key={key} level="body-sm">
              <Typography component="span" fontWeight="bold">
                {formattedKey}:
              </Typography>{' '}
              {String(val)}
            </Typography>
          );
        })}
      </Stack>
    );
  };

  const formatValue = (value: any): React.ReactNode => {
    // Handle null/undefined
    if (value == null) {
      return 'null';
    }

    // Handle objects
    if (typeof value === 'object' && !Array.isArray(value)) {
      return formatObjectValue(value);
    }

    // Handle arrays
    if (Array.isArray(value)) {
      return formatArrayValue(value);
    }

    // Handle HTML strings
    if (typeof value === 'string' && isHtmlContent(value)) {
      return (
        <Box
          component="span"
          dangerouslySetInnerHTML={{ __html: value }}
          sx={{
            '& p': {
              display: 'inline',
              m: 0,
            },
          }}
        />
      );
    }

    // Handle other values
    return String(value);
  };

  return (
    <Box sx={{ position: 'relative', pl: 3, pb: isLast ? 0 : 3 }}>
      <TimelineLine />
      <TimelineDot />
      <Card
        variant="outlined"
        sx={{
          overflowX: 'auto',
          // Prevent horizontal scrollbar from being hidden under the timeline dot
          position: 'relative',
          zIndex: 1,
        }}
      >
        <CardContent
          sx={{
            // Ensure minimum width to prevent content from becoming too squished
            minWidth: 'min-content',
            // Add some padding for the scrollbar
            pb: '1px !important', // Override MUI's default last-child padding
          }}
        >
          <Stack spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{
                // Ensure the header row doesn't wrap
                flexShrink: 0,
                minWidth: 'min-content',
              }}
            >
              <Avatar size="sm" src={log.author?.photoUrl} alt={log.author?.displayName}>
                {log.author?.displayName?.charAt(0) || '?'}
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography level="title-sm">{log.author?.displayName || 'Unknown User'}</Typography>
                <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                  {formatDate(log.createdAt)}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Chip size="sm" variant="soft">
                  {formatFieldName(log.tableName)}
                </Chip>
                <Chip size="sm" variant="soft">
                  ID: {log.entityId}
                </Chip>
                <Chip
                  size="sm"
                  color={getColorByAction(log.action)}
                  // startDecorator={getIconByAction(log.action)}
                  sx={{ p: 0.5, px: 1 }}
                >
                  {log.action}
                </Chip>
              </Stack>
            </Stack>

            <Stack
              spacing={2}
              sx={{
                // Allow content to be wider than the card
                minWidth: 'min-content',
                // Ensure long URLs and text can break
                wordBreak: 'break-word',
                // But don't break URLs in the middle
                overflowWrap: 'break-word',
              }}
            >
              {log.changedFields && Object.keys(log.changedFields).length > 0 && (
                <Box>
                  <Typography
                    level="h4"
                    sx={{
                      mb: 1.5,
                      fontSize: 'md',
                      fontWeight: 400,
                    }}
                  >
                    Changes
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    {Object.entries(log.changedFields).map(([field, value]) => {
                      let oldValue, newValue;
                      try {
                        oldValue = JSON.parse(value[0]);
                        newValue = JSON.parse(value[1]);
                      } catch {
                        oldValue = value[0];
                        newValue = value[1];
                      }

                      return (
                        <Box key={field}>
                          <Typography level="body-sm" fontWeight="bold" sx={{ mb: 0.5 }}>
                            {formatFieldName(field)}:
                          </Typography>
                          <Stack direction="row" spacing={1} alignItems="flex-start">
                            <Box sx={{ flex: 1 }}>{formatValue(oldValue)}</Box>
                            <Typography level="body-sm">→</Typography>
                            <Box sx={{ flex: 1 }}>{formatValue(newValue)}</Box>
                          </Stack>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}

              {log.changedFields && log.content && <Divider sx={{ my: 1 }} />}

              {log.content && (
                <Box>
                  <Button
                    variant="plain"
                    color="neutral"
                    size="sm"
                    onClick={() => setShowContent(!showContent)}
                    endDecorator={showContent ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    sx={{
                      p: 0,
                      fontSize: 'md',
                      fontWeight: 400,
                      '&:hover': {
                        bgcolor: 'transparent',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Show content
                  </Button>
                  {showContent && (
                    <Stack spacing={0.5} sx={{ mt: 1.5 }}>
                      {formatContent(log.content)}
                    </Stack>
                  )}
                </Box>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

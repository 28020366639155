import { Team } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';

export function useGetTeams() {
  return useQuery<Team[]>({
    queryKey: ['teams'],
    queryFn: async () => {
      const { data } = await supabase.from('teams').select('*');
      return data ? toCamelCase<Team[]>(data) : [];
    },
  });
}

export function useGetTeamByUserId(userId: number | undefined) {
  return useQuery<Team | null>({
    queryKey: ['teamByUserId', userId],
    queryFn: async () => {
      const { data } = await supabase.from('teams').select('*').contains('members', [userId]).single();
      return data ? toCamelCase<Team>(data) : null;
    },
  });
}

import { useGetAssignmentsByJobId, createStagePipelineMap, mapToPipeline } from '@bluebird-monorepo/assignments';
import { KanbanBoard } from '@bluebird-monorepo/kanban';
import { Job } from '@bluebird-monorepo/types';
import { Box, Typography } from '@mui/joy';
import React, { useMemo } from 'react';
import { useStages } from '@bluebird-monorepo/shared';

const CandidatesInJob: React.FC<{ job: Job }> = ({ job }) => {
  const { stages } = useStages();
  const { data: rawAssignments, isLoading, error } = useGetAssignmentsByJobId(Number(job.id));

  const assignments = useMemo(() => mapToPipeline(rawAssignments || []), [rawAssignments]);
  const stageMap = useMemo(() => createStagePipelineMap(stages, assignments), [stages, assignments]);

  if (isLoading) return <Typography>Loading assignments...</Typography>;
  if (error) return <Typography>Error loading assignments: {error.message}</Typography>;

  return (
    <Box>
      {stageMap && Object.keys(stageMap).length > 0 ? (
        <KanbanBoard
          initial={stageMap}
          withScrollableColumns={true}
          containerStyle={{ height: 'calc(100vh - 470px)' }}
        />
      ) : (
        <Typography>No candidates assigned to this job yet.</Typography>
      )}
    </Box>
  );
};

export { CandidatesInJob };

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemContent,
  Switch,
  Typography,
} from '@mui/joy';
import { JOB_TABLE_COLUMNS, useJobTableSettings } from '../../store/useJobTableSettings';

export function ColumnsSection() {
  const { hiddenColumnIds, toggleColumnVisibility } = useJobTableSettings();

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary indicator={<ExpandMore />}>
        <Typography level="h4">
          Table Columns ({JOB_TABLE_COLUMNS.length - hiddenColumnIds.length}/{JOB_TABLE_COLUMNS.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {JOB_TABLE_COLUMNS.map((column) => (
            <ListItem key={column.id}>
              <Switch
                checked={!hiddenColumnIds.includes(column.id)}
                onChange={() => toggleColumnVisibility(column.id)}
              />
              <ListItemContent>
                <Typography>{column.name}</Typography>
              </ListItemContent>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

import { Placement } from '@bluebird-monorepo/types';
import { Autocomplete, Button, ButtonGroup } from '@mui/joy';
import { useMemo } from 'react';

interface FilterBarProps {
  selectedQuarter: string;
  selectedRep: string;
  onQuarterChange: (quarter: string) => void;
  onRepChange: (rep: string) => void;
  placements: Placement[];
}

export function FilterBar({ selectedQuarter, selectedRep, onQuarterChange, onRepChange, placements }: FilterBarProps) {
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

  const allReps = useMemo(() => {
    const reps = new Set(placements.map((placement) => placement.rep));
    return ['All', ...Array.from(reps)].filter(Boolean);
  }, [placements]);

  return (
    <>
      <ButtonGroup variant="outlined">
        <Button onClick={() => onQuarterChange('All')} variant={selectedQuarter === 'All' ? 'solid' : 'outlined'}>
          All Quarters
        </Button>
        {quarters.map((quarter) => (
          <Button
            key={quarter}
            onClick={() => onQuarterChange(quarter)}
            variant={selectedQuarter === quarter ? 'solid' : 'outlined'}
          >
            {quarter}
          </Button>
        ))}
      </ButtonGroup>

      <Autocomplete
        placeholder="Search Rep..."
        value={selectedRep}
        onChange={(_, value) => onRepChange(value || 'All')}
        options={allReps}
        sx={{
          minWidth: 200,
          ml: 4,
        }}
        slotProps={{
          input: {
            'aria-label': 'Search Rep',
          },
          listbox: {
            sx: { maxHeight: '300px' },
          },
        }}
      />
    </>
  );
}

import { useQuery } from '@tanstack/react-query';
import { getUser, supabase } from '@bluebird-monorepo/supabase';
import { User } from '@bluebird-monorepo/types';
import { toCamelCase } from '@bluebird-monorepo/utils';

export function useGetCurrentUser() {
  return useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const user = await getUser();
      if (!user) return null;
      const { data } = await supabase.from('users').select('*').eq('email', user.email).single();
      return data ? toCamelCase<User>(data) : null;
    },
  });
}

export function parseResponsibleEmail(responsible: string) {
  let parsedResponsible = responsible?.split('@')[0];
  parsedResponsible = parsedResponsible?.split('+')[0];
  parsedResponsible = parsedResponsible?.replace(/\./g, ' ');
  parsedResponsible = capitalizeFirstLetter(parsedResponsible);
  return parsedResponsible;
}

export function capitalizeFirstLetter(string: string) {
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

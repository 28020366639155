import { LoadingOverlay } from '@bluebird-monorepo/bluebird-ui';
import {
  CalendarFilter,
  EventDetailDialog,
  groupEventsByDate,
  useCalendarStore,
  useGetCalendarEvents,
  useUpsertCalendarEvent,
  WeekNavigation,
  WeekView,
} from '@bluebird-monorepo/calendar';
import { useGetJobs } from '@bluebird-monorepo/jobs';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { SyncRounded } from '@mui/icons-material';
import { Box, Button, Modal, ModalDialog } from '@mui/joy';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Layout } from '../../../layout/Layout';
import { useGetCompanies } from '@bluebird-monorepo/companies';

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] as const;

function WeekViewContainer({ onEventClick }: { onEventClick: (event: CalendarEvent) => void }) {
  const { data } = useGetJobs({ page: 1, pageSize: 10000 });
  const { jobs = [] } = data || {};
  const { currentWeek, currentMonday, currentFriday, removeEvent } = useCalendarStore();
  const { data: events = [] } = useGetCalendarEvents({
    startDate: currentMonday.toDate(),
    endDate: currentFriday.toDate(),
  });

  console.log('WeekViewContainer events:', events);
  console.log('WeekViewContainer currentWeek:', currentWeek?.format('YYYY-MM-DD'));

  // Group events by weekday
  const groupedEvents = useMemo(() => {
    const initial = WEEKDAYS.reduce(
      (acc, day) => {
        acc[day] = [];
        return acc;
      },
      {} as Record<string, CalendarEvent[]>,
    );
    const grouped = groupEventsByDate(events, 'when.startTime', 'dddd');
    // Only keep weekdays and ensure all weekdays exist
    return WEEKDAYS.reduce((acc, day) => {
      acc[day] = grouped[day] || [];
      return acc;
    }, initial);
  }, [events]);

  // Sort events within each day by start time
  const sortedGroupedEvents = useMemo(() => {
    const sorted = { ...groupedEvents };
    Object.keys(sorted).forEach((day) => {
      sorted[day].sort((a, b) => (a.duration?.start as Date)?.getTime() - (b.duration?.start as Date)?.getTime());
    });
    return sorted;
  }, [groupedEvents]);

  const handleRemoveEvent = useCallback(
    (e: React.MouseEvent, event: CalendarEvent) => {
      e.stopPropagation();
      removeEvent(event.id);
    },
    [removeEvent],
  );

  const getJobTitle = useCallback(
    (jobId: number) => {
      const job = jobs.find((j) => j.id === jobId);
      return job?.jobTitle;
    },
    [jobs],
  );

  return (
    <WeekView
      currentWeek={currentWeek}
      groupedEvents={sortedGroupedEvents}
      onEventClick={onEventClick}
      onRemoveEvent={handleRemoveEvent}
      getJobTitle={getJobTitle}
    />
  );
}

export function CalendarPage() {
  const { currentWeek, setCurrentWeek } = useCalendarStore();
  const queryClient = useQueryClient();

  console.log('Current Week:', currentWeek?.format('YYYY-MM-DD'));

  const { mutate: upsertEvent } = useUpsertCalendarEvent();
  const { isLoading: isLoadingEvents, refetch: refetchEvents } = useGetCalendarEvents({
    startDate: currentWeek.startOf('week').toDate(),
    endDate: currentWeek.endOf('week').toDate(),
  });
  const { data: companiesData } = useGetCompanies({ page: 1, pageSize: 10000 });
  const companies = companiesData?.companies || [];
  const { data: jobsData } = useGetJobs({ page: 1, pageSize: 10000 });
  const jobs = jobsData?.jobs || [];

  const [isSyncing, setIsSyncing] = useState(false);

  const handleSync = async () => {
    console.log('Syncing calendar...');
    setIsSyncing(true);
    try {
      const response = await refetchEvents();
      console.log('Sync response:', response);
      await queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
    } catch (error) {
      console.error('Error syncing calendar:', error);
    } finally {
      setIsSyncing(false);
    }
  };

  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);

  const handleEventUpdate = async (params: { id: number; data: Partial<CalendarEvent> }) => {
    await upsertEvent(params);
  };

  const handleNextWeek = useCallback(() => {
    console.log('Next week clicked');
    const nextWeek = currentWeek.add(1, 'week');
    console.log('New week will be:', nextWeek.format('YYYY-MM-DD'));
    setCurrentWeek(nextWeek);
  }, [currentWeek, setCurrentWeek]);

  const handlePreviousWeek = useCallback(() => {
    console.log('Previous week clicked');
    const prevWeek = currentWeek.subtract(1, 'week');
    console.log('New week will be:', prevWeek.format('YYYY-MM-DD'));
    setCurrentWeek(prevWeek);
  }, [currentWeek, setCurrentWeek]);

  const handleEventClick = useCallback((event: CalendarEvent) => {
    setSelectedEvent(event);
    setOpen(true);
  }, []);

  return (
    <Layout
      title="Calendar"
      subtitle="If you see no events, connect your Google Calendar - if you don't see updated events, click on the sync button."
    >
      {(isLoadingEvents || isSyncing) && <LoadingOverlay />}
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <WeekNavigation currentWeek={currentWeek} onNextWeek={handleNextWeek} onPreviousWeek={handlePreviousWeek} />
            <Button
              color="primary"
              onClick={handleSync}
              startDecorator={<SyncRounded />}
              loading={isSyncing}
              variant="soft"
              size="sm"
            >
              Sync Calendar
            </Button>
          </Box>
          <CalendarFilter />
        </Box>

        <WeekViewContainer onEventClick={handleEventClick} />

        <Modal onClose={() => setOpen(false)} open={open}>
          <ModalDialog size="lg" sx={{ width: '25%' }}>
            {selectedEvent && (
              <EventDetailDialog
                companies={companies}
                event={selectedEvent}
                jobs={jobs}
                onClose={() => setOpen(false)}
                onEventUpdate={handleEventUpdate}
                open={open}
              />
            )}
          </ModalDialog>
        </Modal>
      </Box>
    </Layout>
  );
}

export default CalendarPage;

import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { CreateUserWizard, useGetUserById, useGetTeams } from '@bluebird-monorepo/users';
import { Box, Grid, Stack, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';

const UserDetailPage: React.FC = () => {
  const { userId: userIdString } = useParams<{ userId: string }>();
  const userId = Number(userIdString);
  const { data: user, isLoading: isLoadingUser, error: userError } = useGetUserById(userId);
  const { data: teams, isLoading: isLoadingTeams } = useGetTeams();
  const [isEditing, setIsEditing] = useState(false);

  const userTeam = teams?.find((team) => {
    return team.members?.includes(userId);
  });

  useEffect(() => {
    if (teams && userId) {
      console.log('Current userId:', userId);
      console.log('Available teams:', teams);
      console.log('Found team:', userTeam);
    }
  }, [teams, userId, userTeam]);

  if (isLoadingUser || isLoadingTeams) {
    return (
      <Layout title="Loading...">
        <Box>Loading user details...</Box>
      </Layout>
    );
  }

  if (userError || !user) {
    return (
      <Layout title="Error">
        <Box>Error loading user: {userError?.message || 'User not found'}</Box>
      </Layout>
    );
  }

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleCompleteEdit = () => {
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <Layout title="Edit User">
        <CreateUserWizard mode="edit" initialData={user} onComplete={handleCompleteEdit} onCancel={handleCancelEdit} />
      </Layout>
    );
  }

  return (
    <Layout
      title={user.displayName || 'User Details'}
      subtitle={user.email}
      actionButton={{
        label: 'Edit User',
        onClick: handleStartEdit,
        variant: 'create',
      }}
    >
      <Grid container spacing={2} sx={{ width: '100%' }}>
        {/* Basic Information Card */}
        <Grid xs={12} md={6}>
          <BluebirdCard>
            <Typography level="h4" sx={{ mb: 2 }}>
              Basic Information
            </Typography>
            <Stack spacing={2}>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Display Name
                </Typography>
                <Typography>{user.displayName || 'Not set'}</Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Email
                </Typography>
                <Typography>{user.email}</Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Phone Number
                </Typography>
                <Typography>{user.phoneNumber || 'Not set'}</Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Title
                </Typography>
                <Typography>{user.title || 'Not set'}</Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Recruit CRM ID
                </Typography>
                <Typography>{user.recruitCrmId || 'Not set'}</Typography>
              </Box>
            </Stack>
          </BluebirdCard>
        </Grid>

        {/* Role & Access Card */}
        <Grid xs={12} md={6}>
          <BluebirdCard>
            <Typography level="h4" sx={{ mb: 2 }}>
              Role & Access
            </Typography>
            <Stack spacing={2}>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Role
                </Typography>
                <Typography sx={{ textTransform: 'capitalize' }}>{user.role || 'Not set'}</Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Team
                </Typography>
                <Typography>
                  {userTeam ? (
                    <>
                      {userTeam.name}
                      {userTeam.leaderId === userId && ' (Team Leader)'}
                    </>
                  ) : (
                    'No team assigned'
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Status
                </Typography>
                <Box
                  sx={{
                    display: 'inline-block',
                    px: 1,
                    py: 0.5,
                    borderRadius: 'sm',
                    bgcolor: user.isActive ? 'success.softBg' : 'neutral.softBg',
                    color: user.isActive ? 'success.solidColor' : 'neutral.solidColor',
                  }}
                >
                  {user.isActive ? 'Active' : 'Inactive'}
                </Box>
              </Box>
            </Stack>
          </BluebirdCard>
        </Grid>

        {/* Activity Card */}
        <Grid xs={12} md={6}>
          <BluebirdCard>
            <Typography level="h4" sx={{ mb: 2 }}>
              Recent Activity
            </Typography>
            <Stack spacing={2}>
              <Typography level="body-sm" textColor="text.secondary">
                No recent activity
              </Typography>
            </Stack>
          </BluebirdCard>
        </Grid>

        {/* Statistics Card */}
        <Grid xs={12} md={6}>
          <BluebirdCard>
            <Typography level="h4" sx={{ mb: 2 }}>
              Statistics
            </Typography>
            <Stack spacing={2}>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Created At
                </Typography>
                <Typography>
                  {user.createdAt ? new Date(user.createdAt).toLocaleDateString() : 'Not available'}
                </Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Last Updated
                </Typography>
                <Typography>
                  {user.updatedAt ? new Date(user.updatedAt).toLocaleDateString() : 'Not available'}
                </Typography>
              </Box>
            </Stack>
          </BluebirdCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default UserDetailPage;

import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';
import { Role } from '@bluebird-monorepo/types';

export function useGetRoles(search: string) {
  return useQuery<Role[]>({
    enabled: !!search,
    queryKey: ['roles', search],
    queryFn: async () => {
      const { data } = await supabase.from('roles').select('*').ilike('name', `%${search}%`).order('name');
      return data ? toCamelCase<Role[]>(data) : [];
    },
  });
}

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface JobTableColumn {
  id: string;
  name: string;
}

// Define all available columns
export const JOB_TABLE_COLUMNS: JobTableColumn[] = [
  { id: 'priority', name: 'Priority' },
  { id: 'responsible', name: 'Responsible' },
  { id: 'workingOn', name: 'Working On' },
  { id: 'status', name: 'Status' },
  { id: 'jobTitle', name: 'Job Title' },
  { id: 'companyName', name: 'Company' },
  { id: 'location', name: 'Location' },
  { id: 'potentialFee', name: 'Potential Fee' },
  { id: 'candidates', name: 'Candidates' },
  { id: 'events', name: 'Events' },
];

interface JobTableSettings {
  hiddenColumnIds: string[];
  toggleColumnVisibility: (columnId: string) => void;
  resetSettings: () => void;
}

export const useJobTableSettings = create<JobTableSettings>()(
  persist(
    (set) => ({
      hiddenColumnIds: [],
      toggleColumnVisibility: (columnId) =>
        set((state) => ({
          hiddenColumnIds: state.hiddenColumnIds.includes(columnId)
            ? state.hiddenColumnIds.filter((id) => id !== columnId)
            : [...state.hiddenColumnIds, columnId],
        })),
      resetSettings: () =>
        set(() => ({
          hiddenColumnIds: [],
        })),
    }),
    {
      name: 'job-table-settings',
    },
  ),
);

import { useQuery } from '@tanstack/react-query';
import { firefliesService, Transcript } from './fireflies.service';

export const useFireflies = (organizerEmail: string) => {
  const {
    data: transcripts,
    isLoading,
    error,
  } = useQuery<Transcript[], Error>({
    queryKey: ['transcripts', organizerEmail],
    queryFn: () =>
      firefliesService.getTranscripts({
        organizerEmail,
        fromDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(), // Last 30 days
        toDate: new Date().toISOString(),
      }),
  });

  return {
    transcripts: transcripts || [],
    isLoading,
    error,
  };
};

import { LogsTable } from '@bluebird-monorepo/notifications';
import { Typography, Card } from '@mui/joy';
import { useJobsTimelineTableSettings } from './useJobsTimelineTableSettings';

export function JobsTimelineTable({ jobId }: { jobId: number }) {
  const { hiddenColumnIds, toggleColumnVisibility } = useJobsTimelineTableSettings();
  return (
    <>
      <Card variant="outlined" sx={{ mb: 2, p: 3, bgcolor: 'white' }}>
        <Typography level="title-lg">Assignments Timeline</Typography>
        <LogsTable
          tableName="assignments"
          hiddenColumnIds={hiddenColumnIds}
          toggleColumnVisibility={toggleColumnVisibility}
          dataFilters={[{ field: 'job_id', value: jobId }]}
          defaultViewMode="timeline"
        />
      </Card>
      <Card variant="outlined" sx={{ p: 3, bgcolor: 'white' }}>
        <Typography level="title-lg">Job Timeline</Typography>
        <LogsTable
          tableName="jobs"
          entityId={jobId}
          hiddenColumnIds={hiddenColumnIds}
          toggleColumnVisibility={toggleColumnVisibility}
          defaultViewMode="timeline"
        />
      </Card>
    </>
  );
}

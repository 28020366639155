import { FileUpload } from '@bluebird-monorepo/bluebird-forms';
import { UploadedFile, useUpsertStorageFile } from '@bluebird-monorepo/supabase';
import { Box, Typography } from '@mui/joy';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export const ResumeStep: FC = () => {
  const { setValue } = useFormContext();
  const UPLOADS_PATH = `introductions/resumes`;
  const { mutate: uploadStorageFile } = useUpsertStorageFile('uploads', UPLOADS_PATH);

  const handleSave = async (files: File[]) => {
    if (files.length === 0) return;

    const file = files[0]; // Only use the first file
    const path = `${UPLOADS_PATH}/${Date.now()}-${file.name}`;

    try {
      const fileData = (await uploadStorageFile({ file, fileName: path })) as unknown as UploadedFile;
      setValue('resumeUrl', fileData.fullPath);
    } catch (error) {
      console.error('Failed to upload resume:', error);
    }
  };

  return (
    <Box>
      <Typography level="h4" sx={{ mb: 2 }}>
        Upload Resume
      </Typography>
      <Typography level="body-sm" sx={{ mb: 3, color: 'neutral.500' }}>
        Upload a resume in PDF or DOCX format
      </Typography>

      <FileUpload onSave={handleSave} />
    </Box>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
  Divider,
  List,
  ListItem,
  IconButton,
} from '@mui/joy';
import { nanoid } from 'nanoid';
import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { AboutStep } from './steps/AboutStep';
import { ContactsStep } from './steps/ContactsStep';
import { InvoicingStep } from './steps/InvoicingStep';
import { ReviewStep } from './steps/ReviewStep';
import { Company } from '@bluebird-monorepo/types';
import { useNavigate } from 'react-router-dom';
import { useUpsertCompany } from '../../api/companies/useUpsertCompany';
import { useCompanyWebsiteAndLogo } from '../../hooks/useCompanyWebsiteAndLogo';
import { HoverCard } from '@bluebird-monorepo/bluebird-ui';
import { Close } from '@mui/icons-material';

const companySchema = z.object({
  name: z.string().min(1, 'Company name is required'),
  industry: z.string().optional(),
  location: z.string().optional(),
  website: z
    .string()
    .optional()
    .transform((val) => {
      if (!val) return val;
      if (!val.startsWith('http://') && !val.startsWith('https://')) {
        return `https://${val}`;
      }
      return val;
    }),
  status: z.enum(['client', 'prospect', 'partner']).default('prospect'),
  agreedFee: z.number(),
  contactPersons: z.array(
    z.object({
      name: z.string().min(1),
      email: z.string().email(),
      phone: z.string().optional(),
      position: z.string().optional(),
    }),
  ),
  hiringManagers: z.array(
    z.object({
      name: z.string().min(1),
      email: z.string().email(),
      phone: z.string().optional(),
      position: z.string().optional(),
    }),
  ),
  invoicingDetails: z
    .object({
      registeredName: z.string().optional(),
      address: z.string().optional(),
      city: z.string().optional(),
      zipCode: z.string().optional(),
      country: z.string().optional(),
      kvkNumber: z.string().optional(),
      taxNumber: z.string().optional(),
    })
    .optional(),
  // Required fields for Company type compatibility
  bdStage: z.string().default('new'),
  financeContact: z.string().default(''),
  jobCount: z.coerce.number().default(0),
  owner: z.string().default(''),
  activeJobs: z.number().default(0),
  id: z.union([z.string(), z.number()]).optional(),
});

//type CompanyFormData = z.infer<typeof companySchema>;

const steps = [
  {
    label: 'About',
    description: 'Basic company information',
    component: AboutStep,
  },
  {
    label: 'Contacts',
    description: 'Contact persons and hiring managers',
    component: ContactsStep,
  },
  {
    label: 'Invoicing',
    description: 'Optional invoicing details',
    component: InvoicingStep,
  },
  {
    label: 'Review',
    description: 'Review and create company',
    component: ReviewStep,
  },
];

const stepValidationFields = {
  0: ['name', 'industry', 'location', 'website', 'status', 'agreedFee'] as Array<keyof Company>,
  1: [] as Array<keyof Company>, // Create company wizard is working without contact persons and hiring managers
  2: [] as Array<keyof Company>, // Invoicing step is now optional, no required fields
  3: [] as Array<keyof Company>, // Review step doesn't have its own fields
} as const;

interface CreateCompanyWizardProps {
  initialData?: Partial<Company>;
}

export function CreateCompanyWizard({ initialData }: CreateCompanyWizardProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const navigate = useNavigate();
  const { mutate: createCompany } = useUpsertCompany();

  const methods = useForm<Company>({
    resolver: zodResolver(companySchema),
    defaultValues: initialData
      ? initialData
      : {
          name: '',
          industry: '',
          location: '',
          website: '',
          status: 'prospect',
          agreedFee: undefined,
          contactPersons: [],
          hiringManagers: [],
          invoicingDetails: undefined,
        },
    mode: 'onChange', // Enable onChange validation mode
  });

  // Watch for field changes to trigger validation
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (name) {
        // Validate the changed field
        methods.trigger(name as keyof Company);
        // Hide errors when user starts typing
        setShowErrors(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  // Watch for website changes
  const formWebsite = methods.watch('website');

  // Initialize the logo hook with either the existing company or a temporary one
  const { fetchLogoAndSaveDomain } = useCompanyWebsiteAndLogo({
    company:
      initialData && initialData.id && initialData.name
        ? {
            id: initialData.id,
            name: initialData.name,
            website: formWebsite || initialData.website, // Use latest website value from form
          }
        : {
            id: -1, // Temporary ID for new companies
            name: methods.watch('name') || 'New Company',
            website: formWebsite,
          },
  });

  const onComplete = () => {
    if (initialData) {
      navigate(`/companies/${initialData.id}`);
    } else {
      navigate('/companies');
    }
  };

  const onSubmit = async (data: Company) => {
    try {
      // Try to fetch the logo first if website is provided
      let logoUrl = null;

      // Check if website has changed during editing
      const websiteChanged = initialData && initialData.website !== data.website;

      if (data.website && (websiteChanged || !initialData)) {
        try {
          logoUrl = await fetchLogoAndSaveDomain(true); // Skip company update since we're about to create/update it
        } catch (error) {
          console.error('Error fetching logo:', error);
          // Continue without logo
        }
      }

      if (!initialData) {
        const companyData = {
          ...data,
          lastPlacementDate: new Date(),
          peoplePlaced: [],
          invoicingDetails: data.invoicingDetails
            ? {
                registeredName: data.invoicingDetails.registeredName || '',
                address: data.invoicingDetails.address || '',
                city: data.invoicingDetails.city || '',
                zipCode: data.invoicingDetails.zipCode || '',
                country: data.invoicingDetails.country || '',
                kvkNumber: data.invoicingDetails.kvkNumber || '',
                taxNumber: data.invoicingDetails.taxNumber || '',
              }
            : undefined,
          contactPersons: data.contactPersons?.map((person) => ({ ...person, id: nanoid() })) || [],
          hiringManagers:
            data.hiringManagers?.map((manager) => ({
              ...manager,
              id: nanoid(),
              responsibleForJobs: [],
            })) || [],
          industry: data.industry || 'Unknown',
          location: data.location || 'Unknown',
          agreedFee: data.agreedFee || 0,
          financeContact: {
            id: nanoid(),
            name: data.invoicingDetails?.registeredName || '',
            email: '',
          },
          bdStage: data.status === 'prospect' ? 'lead' : 'client',
          jobCount: 0,
          owner: '',
          activeJobs: 0,
          revenueGenerated: 0,
          slug: data.name.toLowerCase().replace(/\s+/g, '-'),
          nameLowerCase: data.name.toLowerCase(),
          logoUrl: logoUrl || 'noLogo', // Set the logo URL if found
        };
        createCompany(companyData);
        onComplete();
      } else {
        if (!initialData?.id) throw new Error('Company ID is required for updates');

        // When editing, only update logoUrl if website changed and logo was found,
        // or explicitly set to null/'noLogo' if website changed but no logo found
        const updatedCompany = {
          ...data,
        };

        if (websiteChanged) {
          // If website changed, always update the logo URL
          updatedCompany.logoUrl = logoUrl || 'noLogo';
          console.log('Website changed, new logo URL:', updatedCompany.logoUrl);
        } else {
          // If website didn't change, preserve the existing logo URL
          updatedCompany.logoUrl = initialData.logoUrl || 'noLogo';
        }

        createCompany(updatedCompany);
        onComplete();
      }
    } catch (error) {
      console.error('Failed to create company:', error);
    }
  };

  const handleNext = async (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    setShowErrors(true); // Show errors when Next is clicked

    const fieldsToValidate = stepValidationFields[activeStep as keyof typeof stepValidationFields];
    const isStepValid = await methods.trigger(fieldsToValidate as any);

    if (isStepValid) {
      if (activeStep === steps.length - 1) {
        const formData = methods.getValues();
        await onSubmit(formData as Company);
        return;
      }
      setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
      setShowErrors(false); // Hide errors when moving to next step
    }
  };

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    setActiveStep((prev) => Math.max(prev - 1, 0));
    setShowErrors(false); // Hide errors when going back
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    if (initialData?.id) {
      navigate(`/companies/${initialData.id}`);
    } else {
      navigate('/companies');
    }
  };

  const handleStepClick = (index: number) => {
    // Only allow navigation to steps that come before the current step
    if (index < activeStep) {
      setActiveStep(index);
    }
  };

  const getAllErrorMessages = () => {
    const messages: string[] = [];
    const errors = methods.formState.errors;
    Object.entries(errors).forEach(([field, error]) => {
      if (error?.message) {
        messages.push(`${field}: ${error.message}`);
      }
    });
    return messages;
  };

  const getCurrentStepErrors = () => {
    const currentStepFields = stepValidationFields[activeStep as keyof typeof stepValidationFields];
    const errors = methods.formState.errors;
    const stepErrors: string[] = [];

    Object.entries(errors).forEach(([field, error]) => {
      if (currentStepFields.includes(field as keyof Company) && error?.message) {
        stepErrors.push(`${field}: ${error.message}`);
      }
    });

    return stepErrors;
  };

  const ActiveStepComponent = steps[activeStep].component;

  // Add a new handler for form submission
  const handleSubmit = async () => {
    setShowErrors(true);
    const isValid = await methods.trigger();
    if (isValid) {
      const formData = methods.getValues();
      await onSubmit(formData as Company);
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          p: 3,
          borderRadius: 'sm',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: 4,
          }}
        >
          {/* Left side - Stepper */}
          <Box
            sx={{
              width: '280px',
              borderRight: '1px solid',
              borderColor: 'divider',
              pr: 3,
            }}
          >
            <Stepper
              component="nav"
              orientation="vertical"
              sx={{
                '--Stepper-spacing': '1rem',
                '--StepIndicator-size': '2rem',
                '--Step-gap': '1rem',
                '--Step-connectorThickness': '2px',
                '--Step-connectorRadius': '1px',
                minHeight: 400,
              }}
            >
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  completed={index < activeStep}
                  indicator={
                    <StepIndicator
                      variant={activeStep === index ? 'solid' : 'soft'}
                      color={activeStep === index ? 'primary' : 'neutral'}
                    >
                      {index + 1}
                    </StepIndicator>
                  }
                  slotProps={{
                    root: {
                      sx: {
                        cursor: index < activeStep ? 'pointer' : 'default',
                        '&:hover': {
                          bgcolor: index < activeStep ? 'background.level1' : undefined,
                        },
                      },
                      onClick: () => handleStepClick(index),
                    },
                  }}
                >
                  <Box>
                    <Box sx={{ fontWeight: 'bold' }}>{step.label}</Box>
                    <Box sx={{ color: 'text.secondary', fontSize: 'sm' }}>{step.description}</Box>
                  </Box>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* Right side - Content */}
          <Box sx={{ flex: 1, maxWidth: '800px' }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNext();
              }}
            >
              {/* Navigation buttons */}
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" onClick={handleBack} sx={{ minWidth: 100 }} disabled={activeStep === 0}>
                    Back
                  </Button>
                  <HoverCard
                    open={showErrors && getCurrentStepErrors().length > 0}
                    onOpenChange={(open) => {
                      if (!open) {
                        setShowErrors(false);
                      }
                    }}
                    content={
                      <Box>
                        <Typography level="title-sm" sx={{ mb: 1 }}>
                          Please fix the following errors in this step:
                        </Typography>
                        <List size="sm">
                          {getCurrentStepErrors().map((message, index) => (
                            <ListItem key={index}>• {message}</ListItem>
                          ))}
                        </List>
                      </Box>
                    }
                  >
                    <Button onClick={handleNext} disabled={activeStep === steps.length - 1} sx={{ minWidth: 100 }}>
                      Next
                    </Button>
                  </HoverCard>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" color="neutral" onClick={handleCancel} sx={{ minWidth: 100 }}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setShowErrors(true);
                      handleSubmit();
                    }}
                    variant="solid"
                    color="primary"
                    sx={{ minWidth: 100 }}
                  >
                    {initialData ? 'Save' : 'Save'}
                  </Button>
                  <IconButton
                    onClick={handleCancel}
                    sx={{
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'text.primary',
                      },
                    }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider sx={{ my: 4 }} />
              <ActiveStepComponent />
            </form>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}

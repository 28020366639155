import { formatTimestamp, isPastTimestamp, toMillis } from '@bluebird-monorepo/calendar';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { Person, Schedule } from '@mui/icons-material';
import { Box, Chip, Sheet, Table, Typography } from '@mui/joy';
import { useSearchParams } from 'react-router-dom';

interface EventListProps {
  events: CalendarEvent[];
  stats?: { total: number; scheduled: number };
  onEventClick?: (event: CalendarEvent) => void;
}

export const EventList: React.FC<EventListProps> = ({ events, stats, onEventClick }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter') || 'all';

  const filteredEvents = events.filter((event) => {
    const eventTime = toMillis(event?.duration?.start as Date);
    const now = Date.now();

    switch (filter) {
      case 'scheduled':
        return eventTime > now;
      case 'past':
        return eventTime < now;
      default:
        return true;
    }
  });

  return (
    <Sheet variant="outlined" sx={{ borderRadius: 'md' }}>
      <Table
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          'thead th:first-child': { width: '100px' },
        }}
      >
        <thead>
          <tr>
            <th
              colSpan={5}
              style={{
                padding: 0,
                borderBottom: '1px solid var(--joy-palette-divider)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  p: 1,
                  bgcolor: 'background.level1',
                }}
              >
                {[
                  { key: 'all', label: `All (${stats?.total || 0})` },
                  { key: 'scheduled', label: `Scheduled (${stats?.scheduled || 0})` },
                  { key: 'past', label: `Past (${(stats?.total || 0) - (stats?.scheduled || 0)})` },
                ].map(({ key, label }) => (
                  <Box
                    key={key}
                    onClick={() => setSearchParams({ filter: key })}
                    sx={{
                      px: 2,
                      py: 1,
                      cursor: 'pointer',
                      borderBottom:
                        filter === key ? '2px solid var(--joy-palette-primary-500)' : '2px solid transparent',
                      color: filter === key ? 'primary.500' : 'text.secondary',
                      '&:hover': { bgcolor: 'background.level2' },
                    }}
                  >
                    <Typography level="body-sm">{label}</Typography>
                  </Box>
                ))}
              </Box>
            </th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Title</th>
            <th>Type</th>
            <th>Participants</th>
          </tr>
        </thead>
        <tbody>
          {filteredEvents.map((event) => (
            <tr
              key={event.id}
              style={{
                opacity: isPastTimestamp(event?.duration?.start as Date) ? 0.7 : 1,
                cursor: onEventClick ? 'pointer' : 'default',
              }}
              onClick={() => onEventClick?.(event)}
            >
              <td>
                <Typography level="body-sm">
                  {formatTimestamp(event?.duration?.start as Date, 'MMM D, YYYY')}
                </Typography>
              </td>
              <td>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Schedule sx={{ fontSize: 'small' }} />
                  <Typography level="body-sm">
                    {formatTimestamp(event?.duration?.start as Date)} - {formatTimestamp(event?.duration?.end as Date)}
                  </Typography>
                </Box>
              </td>
              <td>
                <Typography level="body-sm" fontWeight="md">
                  {event.title}
                </Typography>
              </td>
              <td>
                {event.typeOfEvent && (
                  <Chip
                    size="sm"
                    variant="soft"
                    color={isPastTimestamp(event?.duration?.start as Date) ? 'neutral' : 'primary'}
                  >
                    {event.typeOfEvent}
                  </Chip>
                )}
              </td>
              <td>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Person sx={{ fontSize: 'small' }} />
                  <Typography level="body-sm">{event.attendees?.length || 0}</Typography>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
};

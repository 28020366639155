import { Box, Divider, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

function Section({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <Stack spacing={2}>
      <Typography level="title-md">{title}</Typography>
      {children}
    </Stack>
  );
}

export function ReviewStep() {
  const { getValues } = useFormContext();
  const values = getValues();

  return (
    <Stack spacing={3}>
      <Typography level="h4">Review Company Information</Typography>

      <Section title="Basic Information">
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
          <Typography level="body-sm">Company Name:</Typography>
          <Typography>{values['name']}</Typography>

          <Typography level="body-sm">Industry:</Typography>
          <Typography>{values['industry']}</Typography>

          <Typography level="body-sm">Location:</Typography>
          <Typography>{values['location']}</Typography>

          <Typography level="body-sm">Website:</Typography>
          <Typography>{values['website'] || 'Not provided'}</Typography>

          <Typography level="body-sm">Client Status:</Typography>

          <Typography level="body-sm">Agreed Fee:</Typography>
          <Typography>{values['agreedFee']}%</Typography>
        </Box>
      </Section>

      <Divider />

      <Section title="Contact Persons">
        {values['contactPersons'].map((contact: any, index: number) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>
              Contact Person {index + 1}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                gap: 1,
                ml: 2,
              }}
            >
              <Typography level="body-sm">Name:</Typography>
              <Typography>{contact.name}</Typography>
              <Typography level="body-sm">Email:</Typography>
              <Typography>{contact.email}</Typography>
              {contact.phone && (
                <>
                  <Typography level="body-sm">Phone:</Typography>
                  <Typography>{contact.phone}</Typography>
                </>
              )}
              {contact.position && (
                <>
                  <Typography level="body-sm">Position:</Typography>
                  <Typography>{contact.position}</Typography>
                </>
              )}
            </Box>
          </Box>
        ))}
      </Section>

      <Divider />

      <Section title="Hiring Managers">
        {values['hiringManagers'].map((manager: any, index: number) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>
              Hiring Manager {index + 1}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                gap: 1,
                ml: 2,
              }}
            >
              <Typography level="body-sm">Name:</Typography>
              <Typography>{manager.name}</Typography>
              <Typography level="body-sm">Email:</Typography>
              <Typography>{manager.email}</Typography>
              {manager.phone && (
                <>
                  <Typography level="body-sm">Phone:</Typography>
                  <Typography>{manager.phone}</Typography>
                </>
              )}
              {manager.position && (
                <>
                  <Typography level="body-sm">Position:</Typography>
                  <Typography>{manager.position}</Typography>
                </>
              )}
            </Box>
          </Box>
        ))}
      </Section>

      <Divider />

      <Section title="Invoicing Details">
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
          <Typography level="body-sm">Registered Name:</Typography>
          <Typography>{values['invoicingDetails']['registeredName']}</Typography>

          <Typography level="body-sm">Address:</Typography>
          <Typography>{values['invoicingDetails']['address']}</Typography>

          <Typography level="body-sm">City:</Typography>
          <Typography>{values['invoicingDetails']['city']}</Typography>

          <Typography level="body-sm">Postal Code:</Typography>
          <Typography>{values['invoicingDetails']['zipCode']}</Typography>

          <Typography level="body-sm">Country:</Typography>
          <Typography>{values['invoicingDetails']['country']}</Typography>

          <Typography level="body-sm">KvK Number:</Typography>
          <Typography>{values['invoicingDetails']['kvkNumber']}</Typography>

          <Typography level="body-sm">Tax Number:</Typography>
          <Typography>{values['invoicingDetails']['taxNumber']}</Typography>
        </Box>
      </Section>
    </Stack>
  );
}

import { FileUpload } from '@bluebird-monorepo/bluebird-forms';
import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { useUpsertStorageFile } from '@bluebird-monorepo/supabase';
import { Company } from '@bluebird-monorepo/types';
import { AttachFile } from '@mui/icons-material';
import { Box, CardContent, CircularProgress, Divider, Typography } from '@mui/joy';
import React from 'react';
import { CompanyFilesList } from './CompanyFilesList';

interface CompanyFilesCardProps {
  company: Company;
}

export const CompanyFilesCard: React.FC<CompanyFilesCardProps> = ({ company }) => {
  const { mutate: uploadStorageFile, isPending: isUploading } = useUpsertStorageFile(
    'uploads',
    `companies/${company.id}/files`,
  );

  const handleSaveFiles = async (files: File[]) => {
    for (const file of files) {
      try {
        await uploadStorageFile({ file });
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  return (
    <BluebirdCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
        <Typography level="h4" startDecorator={<AttachFile />}>
          Files & Documents
        </Typography>
        {isUploading && <CircularProgress size="sm" />}
      </Box>
      <Divider inset="none" />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FileUpload onSave={handleSaveFiles} disabled={isUploading} />
          <CompanyFilesList company={company} isUploading={isUploading} />
        </Box>
      </CardContent>
    </BluebirdCard>
  );
};

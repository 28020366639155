import { FC } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { Stack, Button } from '@mui/joy';
import ConditionRow from './ConditionRow';
import { nanoid } from 'nanoid';

interface ConditionField {
  id: string;
  field: string;
  operator: string;
  value: string;
}

const GroupForm: FC<{
  control: Control<any>;
  groupIndex: number;
}> = ({ control, groupIndex }) => {
  const groupPath = `groups.${groupIndex}` as const;
  const conditionsArray = useFieldArray({
    control,
    keyName: 'key',
    name: `${groupPath}.conditions`,
  });

  return (
    <Stack
      gap={2}
      padding={1}
      sx={{
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Controller
        control={control}
        name={`${groupPath}.operator`}
        render={({ field: { onChange, value } }) => (
          <Button
            color="neutral"
            onClick={() => onChange(value === 'and' ? 'or' : 'and')}
            sx={{ textTransform: 'uppercase', width: 'fit-content' }}
            variant="soft"
          >
            {value}
          </Button>
        )}
      />
      {conditionsArray.fields.map((condition, index) => (
        <ConditionRow
          conditionId={(condition as unknown as ConditionField).id}
          control={control}
          groupPath={groupPath}
          index={index}
          key={(condition as unknown as ConditionField).id}
          onRemove={() => conditionsArray.remove(index)}
        />
      ))}
      <Button
        onClick={() =>
          conditionsArray.append({
            field: '',
            id: nanoid(),
            operator: 'equals',
            value: '',
          })
        }
        variant="plain"
      >
        Add Condition
      </Button>
    </Stack>
  );
};

export default GroupForm;

import { Avatar, Box, Tooltip, Typography } from '@mui/joy';
import { FC, useMemo } from 'react';
import { Log, LogRelated } from '@bluebird-monorepo/types';
import { formatDistanceToNow } from 'date-fns';
import { formatFieldName } from '../../utils/log.utils';
import { useGetLogsRead } from '../../api/useGetLogsRead';
import { CompanyAvatar } from '@bluebird-monorepo/companies';

interface NotificationCardProps {
  notification: Log;
  expanded: boolean;
  active: boolean;
  onClick?: () => void;
}

// Add these type definitions at the top of the file
type NotificationData = {
  jobTitle?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  content?: string;
  role?: string;
  companyLogo?: string;
  logoUrl?: string;
  avatar?: string;
  [key: string]: any;
};

const getSingularTableName = (tableName: string): string => {
  const tableMap: Record<string, string> = {
    jobs: 'Job',
    candidates: 'Candidate',
    companies: 'Company',
    teams: 'Team',
    tasks: 'Task',
    calendar_events: 'Calendar Event',
    introductions: 'Introduction',
    placements: 'Placement',
    assignments: 'Assignment',
  };
  return tableMap[tableName] || toSentenceCase(tableName.replace(/_/g, ' '));
};

const toSentenceCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const getActionInPastTense = (action: string): string => {
  const actionMap: Record<string, string> = {
    UPDATE: 'Updated',
    CREATE: 'Created',
    INSERT: 'Created',
    DELETE: 'Deleted',
  };
  return actionMap[action.toUpperCase()] || toSentenceCase(action);
};

const getEntityName = (tableName: string, newData: any): string => {
  if (!newData) return '';
  switch (tableName) {
    case 'jobs':
      return newData['jobTitle'] || newData['title'] || '';
    case 'candidates':
      return [newData['firstName'] || '', newData['lastName'] || ''].filter(Boolean).join(' ');
    case 'companies':
      return newData['name'] || '';
    case 'teams':
      return newData['name'] || '';
    case 'tasks':
      return newData['title'] || '';
    case 'calendar_events':
      return newData['title'] || '';
    case 'introductions':
      return newData['content'] || '';
    case 'placements':
      return newData['role'] || '';
    case 'assignments':
      return `${newData['candidateFirstName'] || ''} ${newData['candidateLastName'] || ''} for ${newData['jobTitle'] || ''}`;
    default:
      return '';
  }
};

export const NotificationCard: FC<NotificationCardProps> = ({ notification, expanded, active, onClick }) => {
  const { data: logsReadData } = useGetLogsRead();

  const isJobNotification = notification.tableName === 'jobs';
  const isCompanyNotification = notification.tableName === 'companies';
  const isCandidateNotification = notification.tableName === 'candidates';

  // Use explicit type casting to prevent deep analysis
  const tableName = notification.tableName;
  const action = notification.action;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Type instantiation is excessively deep and possibly infinite
  const newData = notification.newData as NotificationData;
  const id = notification.id;
  const createdAt = notification.createdAt;
  const changedFields = notification.changedFields as Record<string, [any, any]>;

  // Get the appropriate logo/avatar URL based on notification type
  const logoUrl = useMemo(() => {
    if (isJobNotification && notification.newData?.companyLogo) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Type instantiation is excessively deep and possibly infinite
      return notification.newData.companyLogo;
    }
    if (isCompanyNotification && notification.newData?.logoUrl) {
      return notification.newData.logoUrl;
    }
    if (isCandidateNotification && notification.newData?.avatar) {
      return notification.newData.avatar;
    }
    if (notification.tableName === 'assignments' && notification.newData?.logoUrl) {
      return notification.newData.logoUrl;
    }
    return null;
  }, [isJobNotification, isCompanyNotification, isCandidateNotification, notification.tableName, notification.newData]);

  // Get the fallback letter for the avatar
  const logoFallback = useMemo(() => {
    if (!notification.newData) return notification.tableName.charAt(0).toUpperCase();

    const data = notification.newData as NotificationData;

    switch (notification.tableName) {
      case 'assignments':
        return (data.jobTitle || 'A').charAt(0).toUpperCase();
      case 'jobs':
        return (data.jobTitle || data.title || 'J').charAt(0).toUpperCase();
      case 'companies':
        return (data.name || 'C').charAt(0).toUpperCase();
      case 'candidates':
        return (data.firstName || data.lastName || 'C').charAt(0).toUpperCase();
      default:
        return notification.tableName.charAt(0).toUpperCase();
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Type instantiation is excessively deep and possibly infinite
  }, [notification.tableName, notification.newData]);

  const title = useMemo(() => {
    try {
      const tableNameFormatted = getSingularTableName(tableName);
      const entityName = newData && typeof newData === 'object' ? getEntityName(tableName, newData) : '';
      const actionFormatted = getActionInPastTense(action);
      // If we have an entity name, include it in the title
      if (entityName) {
        return `${tableNameFormatted} ${entityName} ${actionFormatted}`;
      }
      // Fallback to generic title if no entity name is found
      return `${tableNameFormatted} ${actionFormatted}`;
    } catch (error) {
      console.error('Error getting notification title:', error);
      return 'Notification';
    }
  }, [tableName, newData, action]);

  const timestampDistance = useMemo(() => {
    if (!createdAt) return '';
    return formatDistanceToNow(new Date(createdAt), { addSuffix: false });
  }, [createdAt]);

  const timestamp = useMemo(() => {
    try {
      if (!createdAt) return '';
      const distance = formatDistanceToNow(new Date(createdAt), { addSuffix: false });
      // Replace patterns like "5 minutes" -> "5m", "2 hours" -> "2h", "3 days" -> "3d"
      return distance
        .replace(/less than a minute?/, 'now')
        .replace(/ minutes?/, 'm')
        .replace(/ hours?/, 'h')
        .replace(/ days?/, 'd')
        .replace(/ weeks?/, 'w')
        .replace(/ months?/, 'mo')
        .replace(/ years?/, 'y')
        .replace('about', '');
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return '';
    }
  }, [createdAt]);

  const isRead = useMemo(() => {
    if (!id || !logsReadData) return false;
    let isRead = false;
    // Check if notification is older than logsReadBefore
    if (logsReadData.logsReadBefore && createdAt) {
      const notificationDate = new Date(createdAt);
      const readBeforeDate = new Date(logsReadData.logsReadBefore);
      isRead = notificationDate < readBeforeDate;
    }
    // Check if notification is in related logs
    if ((notification as LogRelated).logIds) {
      isRead = !!(notification as LogRelated)?.logIds?.every((logId: number) => logsReadData.logsRead?.includes(logId));
    }
    // Check if notification is in individually read logs
    if (logsReadData.logsRead?.includes(id)) {
      isRead = true;
    }
    return isRead;
  }, [id, createdAt, logsReadData]);

  const handleCardClick = () => {
    onClick?.();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 88,
        height: 'auto',
        py: 2,
        px: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        cursor: 'pointer',
        opacity: isRead && !active ? 0.6 : 1,
        '&:hover': {
          bgcolor: !active ? 'background.level1' : 'primary.softHover',
        },
        bgcolor: active ? 'primary.softHover' : isRead && !active ? 'background.level1' : 'background.surface',
        transition: 'all 0.2s ease',
        position: 'relative',
        borderLeft: '4px solid',
        borderLeftColor: 'transparent',
        ...(active && {
          borderLeftColor: 'primary.main',
        }),
      }}
      onClick={handleCardClick}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
          alignItems: 'flex-start',
        }}
      >
        <EntityAvatarSection
          logoUrl={logoUrl}
          logoFallback={logoFallback}
          notification={notification}
          isJobNotification={isJobNotification}
          isCompanyNotification={isCompanyNotification}
        />
        <ContentSection isRead={isRead} expanded={expanded} title={title} changedFields={changedFields} />
        <AuthorAvatarSection notification={notification} timestamp={timestamp} timestampDistance={timestampDistance} />
      </Box>
    </Box>
  );
};

function EntityAvatarSection({
  logoUrl,
  logoFallback,
  notification,
  isJobNotification,
  isCompanyNotification,
}: {
  logoUrl: string;
  logoFallback: string;
  notification: Log;
  isJobNotification: boolean;
  isCompanyNotification: boolean;
}) {
  const logoTooltip = useMemo(() => {
    if (!notification.newData) return '';
    const data = notification.newData as Record<string, any>;
    switch (notification.tableName) {
      case 'jobs':
        return `${data['jobTitle'] || data['title'] || 'Job'} at ${data['companyName'] || ''}`;
      case 'companies':
        return data['name'] || 'Company';
      case 'candidates':
        return `${data['firstName'] || ''} ${data['lastName'] || ''}`.trim() || 'Candidate';
      default:
        return '';
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Type instantiation is excessively deep and possibly infinite
  }, [notification.tableName, notification.newData]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        pt: 0.5,
        width: 32,
      }}
    >
      <Tooltip title={logoTooltip}>
        {logoUrl ? (
          isJobNotification ? (
            <div>
              <CompanyAvatar
                name={notification.newData['companyName'] || ''}
                logoUrl={notification.newData['companyLogo'] || ''}
                size="md"
              />
            </div>
          ) : isCompanyNotification ? (
            <CompanyAvatar
              name={notification.newData['name'] || ''}
              logoUrl={notification.newData['logoUrl'] || ''}
              size="md"
            />
          ) : (
            <Avatar
              size="sm"
              src={logoUrl}
              sx={{
                bgcolor: 'background.level2',
              }}
            />
          )
        ) : (
          <Avatar
            size="sm"
            sx={{
              bgcolor: 'neutral.200',
              color: 'neutral.700',
            }}
          >
            {logoFallback}
          </Avatar>
        )}
      </Tooltip>
    </Box>
  );
}

function ContentSection({
  isRead,
  expanded,
  title,
  changedFields,
}: {
  isRead: boolean;
  expanded: boolean;
  title: string;
  changedFields: Record<string, [any, any]>;
}) {
  return (
    <Box sx={{ flex: 1, minWidth: 0, display: 'flex', flexDirection: 'column' }}>
      <Tooltip title={title}>
        <Typography
          level="title-sm"
          sx={{
            fontWeight: isRead ? 'normal' : 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            mb: 0.5,
          }}
        >
          {title}
        </Typography>
      </Tooltip>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        {changedFields && Object.keys(changedFields).length > 0 && (
          <>
            {Object.entries(changedFields)
              .slice(0, expanded || Object.keys(changedFields).length <= 3 ? undefined : 2)
              .map(([field, value]) => (
                <Typography
                  key={field}
                  level="body-sm"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  <Typography component="span" fontWeight="bold" level="body-sm">
                    {formatFieldName(field)}
                  </Typography>
                  : {JSON.stringify((value as [any, any])[1])}
                </Typography>
              ))}
            {!expanded && Object.keys(changedFields).length > 3 && (
              <Typography level="body-sm" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                +{Object.keys(changedFields).length - 2} more changes...
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

function AuthorAvatarSection({
  notification,
  timestamp,
  timestampDistance,
}: {
  notification: Log;
  timestamp: string;
  timestampDistance: string;
}) {
  const authorTooltip = useMemo(() => {
    if (!notification.author) return '';
    return `Edited by ${notification.author.displayName || notification.author.email || 'User'}`;
  }, [notification.author]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, minWidth: 'fit-content' }}>
      {notification.author && (
        <Tooltip title={authorTooltip}>
          <Avatar
            size="sm"
            src={notification.author.photoUrl}
            sx={{
              bgcolor: 'neutral.200',
              color: 'neutral.700',
              width: 24,
              height: 24,
            }}
          >
            {notification.author?.displayName?.charAt(0) || notification.author?.email?.charAt(0) || 'U'}
          </Avatar>
        </Tooltip>
      )}
      <Tooltip title={`${timestampDistance} ago`}>
        <Typography
          level="body-xs"
          sx={{
            color: 'text.tertiary',
            whiteSpace: 'nowrap',
          }}
        >
          {timestamp}
        </Typography>
      </Tooltip>
    </Box>
  );
}

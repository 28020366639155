import { Box } from '@mui/joy';
import { usePlacementsFilters } from '../hooks/usePlacementFilters';
import { FilterBar } from './FilterBar';
import { PlacementsTableAll } from './PlacementsTableAll';
import { PlacementsTableByQuarter } from './PlacementsTableByQuarter';
import { useGetPlacements } from '../api/useGetPlacements';

function PlacementsTable() {
  const { data, isLoading, error } = useGetPlacements();
  const placements = data?.placements || [];
  const {
    filteredPlacements,
    selectedQuarter,
    selectedRep,
    setSelectedQuarter,
    setSelectedRep,
    totalFeeForYear,
    setTotalFeeForYear,
  } = usePlacementsFilters(placements);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data: {error.message}</div>;

  return (
    <Box>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, mb: 2 }}>
        <FilterBar
          selectedQuarter={selectedQuarter}
          selectedRep={selectedRep}
          onQuarterChange={setSelectedQuarter}
          onRepChange={setSelectedRep}
          placements={placements}
        />
        {/* <TotalFeeCard totalFee={totalFeeForYear} /> */}
      </Box>
      {selectedQuarter === 'All' ? (
        <PlacementsTableAll placements={filteredPlacements} />
      ) : (
        <PlacementsTableByQuarter placements={filteredPlacements} onFeeCalculated={setTotalFeeForYear} />
      )}
    </Box>
  );
}

export { PlacementsTable };

export type PriorityLevel = 1 | 2 | 3 | 4;

export const PRIORITY_LABELS: Record<PriorityLevel, string> = {
  1: 'Urgent',
  2: 'High',
  3: 'Medium',
  4: 'Low',
};

export const PRIORITY_COLORS: Record<PriorityLevel, string> = {
  1: 'var(--joy-palette-success-500)', // Green
  2: 'var(--joy-palette-primary-500)', // Blue
  3: 'var(--joy-palette-warning-500)', // Orange
  4: 'var(--joy-palette-neutral-500)', // Gray
};
export interface JobsTableFiltersForm {
  companyName: string;
  feeGt: string;
  feeLt: string;
  filterName: string;
  location: string;
  responsible: string | null;
  salaryGt: string;
  salaryLt: string;
  statusName: string[];
  workingOn: string[];
  vertical: string;
  priority: string;
}

import { Introduction } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertIntroduction() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (introduction: Partial<Introduction>) => {
      console.log('upsertIntroduction introduction', introduction);
      const { data, error } = await supabase
        .from('introductions')
        .upsert(toSnakeCase(introduction), {
          onConflict: 'candidate_id,job_id',
        })
        .select()
        .single();

      if (error) throw error;
      return toCamelCase<Introduction>(data);
    },
    onSuccess: (introduction: Introduction) => {
      // Invalidate relevant queries
      queryClient.invalidateQueries({ queryKey: ['introductions'] });
      queryClient.invalidateQueries({ queryKey: ['introduction', introduction.id] });
      // Invalidate related queries based on relationships
      if (introduction.candidateId) {
        queryClient.invalidateQueries({
          queryKey: ['introductions', { candidateId: introduction.candidateId }],
        });
      }
      if (introduction.jobId) {
        queryClient.invalidateQueries({
          queryKey: ['introductions', { jobId: introduction.jobId }],
        });
      }
      if (introduction.authorId) {
        queryClient.invalidateQueries({
          queryKey: ['introductions', { authorId: introduction.authorId }],
        });
      }
    },
  });
}

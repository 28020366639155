import { useGetCandidates } from '@bluebird-monorepo/candidates';
import { type Job } from '@bluebird-monorepo/types';
import { Autocomplete, Avatar, Box, Button, Chip, Divider, Snackbar, Stack, Typography } from '@mui/joy';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetAssignmentsByJobId } from '../api/useGetAssignments';
import { useUpsertAssignment } from '../api/useUpsertAssignment';
import { useGetCurrentUser } from '@bluebird-monorepo/users';

interface CandidateOption {
  id: number;
  label: string;
  position: string;
  avatar?: string;
  firstName: string;
  lastName: string;
  currentOrganization?: string;
  email: string;
}

interface CandidateAssignmentSectionProps {
  job: Job;
}

export const CandidateAssignmentSection: FC<CandidateAssignmentSectionProps> = React.memo(({ job }) => {
  const { data } = useGetCandidates({
    page: 1,
    pageSize: 100000,
    sortField: 'updatedAt',
    sortDirection: 'desc',
  });
  const { candidates } = data || { candidates: [] };
  const { data: assignments } = useGetAssignmentsByJobId(Number(job.id));
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateOption | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const searchDebounceTimeout = useRef<NodeJS.Timeout>();
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const { mutate: createAssignment } = useUpsertAssignment();
  const { data: user } = useGetCurrentUser();
  const assignedCandidates: number[] = useMemo(() => {
    return [];
  }, []);
  // const assignedCandidates = useMemo(() => {
  //   if (!assignments) return [];
  //   return assignments;
  //   return assignments
  //     .filter((assignment: CandidateJobAssignmentInPipeline) => assignment.jobId === job.id)
  //     .map((assignment: CandidateJobAssignmentInPipeline) => assignment.candidateId)
  //     .filter((candidateId): candidateId is string => candidateId !== undefined);
  // }, [assignments, job.id]);

  const isCandidateAlreadyAssigned = useMemo(
    () => selectedCandidate?.id && assignedCandidates.includes(selectedCandidate.id),
    [selectedCandidate, assignedCandidates],
  );

  // Transform candidates once when they load
  const allOptions = useMemo(
    () =>
      candidates?.map((candidate) => ({
        id: candidate.id,
        label: `${candidate.firstName} ${candidate.lastName}`,
        position: candidate.currentPosition || 'No position',
        avatar: candidate.avatar,
        firstName: candidate.firstName || '',
        lastName: candidate.lastName || '',
        currentOrganization: candidate.currentOrganization || '',
        // TODO: Remove contactInfo once we have a proper typing for candidate email on the client
        email: candidate.contactInfo?.email || '',
      })) || [],
    [candidates],
  );

  // Filter the already-transformed data
  const getCandidateOptions = useMemo(() => {
    if (!debouncedInputValue) {
      return allOptions.slice(0, 20);
    }
    const searchLower = debouncedInputValue.toLowerCase();
    return allOptions
      .filter(
        (option) =>
          option.label.toLowerCase().includes(searchLower) ||
          option.position.toLowerCase().includes(searchLower) ||
          option.currentOrganization?.toLowerCase().includes(searchLower),
      )
      .slice(0, 20); // Always limit to 20 results
  }, [allOptions, debouncedInputValue]);

  const handleAssignCandidate = async () => {
    if (!selectedCandidate?.id) return;

    if (isCandidateAlreadyAssigned) {
      setErrorMessage('This candidate is already assigned to the job.');
      return;
    }

    try {
      createAssignment({
        jobId: job.id,
        candidateId: selectedCandidate.id,
        status: 'Applied',
        statusId: 1,
        owner: user?.email || '',
        candidateEmail: selectedCandidate.email,
        companyName: job.companyName,
        jobTitle: job.jobTitle,
        candidateLastName: selectedCandidate.lastName,
        currentOrganization: selectedCandidate.currentOrganization,
        candidateFirstName: selectedCandidate.firstName,
      });
      setSelectedCandidate(null);
    } catch (error) {
      setErrorMessage('Failed to assign candidate to job');
    }
  };

  // Debounced input change handler
  const handleInputChange = useCallback((_: any, newInputValue: string) => {
    setIsSearching(true);

    if (searchDebounceTimeout.current) {
      clearTimeout(searchDebounceTimeout.current);
    }

    searchDebounceTimeout.current = setTimeout(() => {
      setDebouncedInputValue(newInputValue);
      setIsSearching(false);
    }, 300);
  }, []);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (searchDebounceTimeout.current) {
        clearTimeout(searchDebounceTimeout.current);
      }
    };
  }, []);

  // Memoize the render option function to prevent recreating on every render
  const renderOption = useCallback(
    (props: any, option: CandidateOption) => {
      const { key, ownerState, component, ...otherProps } = props;

      return (
        <li key={key} {...otherProps}>
          <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'flex-start', gap: 2, py: 1.5, width: '100%' }}>
            {!isSearching ? (
              <Avatar
                src={option.avatar}
                alt={option.label}
                sx={{
                  width: 48,
                  height: 48,
                  ml: 0.5,
                  border: '2px solid',
                  borderColor: 'background.level2',
                }}
              >
                {option.firstName[0]}
                {option.lastName[0]}
              </Avatar>
            ) : (
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  ml: 0.5,
                  borderRadius: '50%',
                  bgcolor: 'background.level2',
                }}
              />
            )}
            <Box sx={{ flexGrow: 1, minWidth: 0 }}>
              <Typography
                level="title-md"
                sx={{
                  fontWeight: 600,
                  color: 'text.primary',
                  mb: 0.5,
                }}
              >
                {option.label}
              </Typography>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography
                  level="body-sm"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                  }}
                >
                  {option.position.slice(0, 20)}
                </Typography>
                {option?.currentOrganization && (
                  <>
                    <Divider orientation="vertical" sx={{ mx: 1, my: 0.25 }} />
                    <Typography
                      level="body-sm"
                      sx={{
                        color: 'text.tertiary',
                        fontStyle: 'italic',
                      }}
                    >
                      {option?.currentOrganization.slice(0, 20)}
                    </Typography>
                  </>
                )}
              </Stack>
              {assignedCandidates.includes(option.id) && (
                <Chip size="sm" variant="soft" color="warning" sx={{ mt: 0.5 }}>
                  Already assigned
                </Chip>
              )}
            </Box>
          </Box>
        </li>
      );
    },
    [assignedCandidates, isSearching],
  );

  // Add performance monitoring
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // console.log('CandidateAssignmentSection rendered', {
      //   candidatesCount: candidates?.length,
      //   assignmentsCount: assignments?.length,
      //   optionsCount: getCandidateOptions.length,
      //   isSearching,
      //   hasSelectedCandidate: !!selectedCandidate,
      // });
    }
  });

  if (!assignments) return null;

  return (
    <Stack direction="row" gap={1} width="100%">
      <Autocomplete
        onChange={(_, value) => setSelectedCandidate(value)}
        onInputChange={handleInputChange}
        options={getCandidateOptions}
        getOptionLabel={(option) => `${option.label} - ${option.position}`}
        filterOptions={(x) => x}
        renderOption={renderOption}
        slotProps={{
          listbox: {
            component: 'ul',
            sx: {
              '--ListItem-paddingY': '4px',
              '--ListItem-paddingX': '12px',
              '& > li:hover': {
                backgroundColor: 'background.level2',
              },
            },
          },
        }}
        placeholder="Search candidates..."
        sx={{
          width: '100%',
          '--ListItem-radius': '8px',
          '--ListItem-minHeight': '76px',
          '& .MuiAutocomplete-listbox': {
            p: 1,
            gap: 0.5,
            maxHeight: '400px',
          },
        }}
        value={selectedCandidate}
      />
      <Button
        disabled={!selectedCandidate}
        onClick={handleAssignCandidate}
        sx={{ whiteSpace: 'nowrap' }}
        variant="outlined"
      >
        Add Candidate
      </Button>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
        color="danger"
        onClose={() => setErrorMessage('')}
        open={!!errorMessage}
      >
        {errorMessage}
      </Snackbar>
    </Stack>
  );
});

CandidateAssignmentSection.displayName = 'CandidateAssignmentSection';

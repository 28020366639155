import { useQuery } from '@tanstack/react-query';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useGetCurrentUser } from '@bluebird-monorepo/users';

interface LogsRead {
  logsRead: number[] | null;
  logsReadBefore: string | null;
}

export function useGetLogsRead() {
  const { data: currentUser } = useGetCurrentUser();
  return useQuery({
    enabled: !!currentUser?.email,
    queryKey: ['logsRead', currentUser?.email],
    queryFn: async () => {
      if (!currentUser?.email) return null;
      const { data } = await supabase
        .from('users')
        .select('logs_read, logs_read_before')
        .eq('id', currentUser.id)
        .single();
      return data ? toCamelCase<LogsRead>(data) : null;
    },
  });
}

export function useGetUnreadLogsCount() {
  const { data: currentUser } = useGetCurrentUser();
  const { data: logsReadData } = useGetLogsRead();
  return useQuery({
    enabled: !!currentUser?.email,
    queryKey: ['unreadLogsCount', currentUser?.email, logsReadData],
    queryFn: async () => {
      if (!currentUser?.email) return 0;
      let query = supabase.from('logs').select('*', { count: 'exact', head: true });
      // If user has logsReadBefore timestamp, only count newer logs
      if (logsReadData?.logsReadBefore) {
        query = query.gt('created_at', logsReadData.logsReadBefore);
      }
      // Exclude logs that are marked as read
      if (logsReadData?.logsRead && logsReadData.logsRead.length > 0) {
        query = query.not('id', 'in', `(${logsReadData.logsRead.join(',')})`);
      }
      const { count } = await query;
      return count || 0;
    },
  });
}

import { supabase } from '../services/supabase.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getStorageFilePublicURL, getStorageFileSignedURL, uploadStorageFile } from './supabaseStorage';

export const useGetStorageFiles = (bucketName: string, path: string) => {
  return useQuery({
    queryKey: ['getStorageFiles', bucketName, path],
    queryFn: async () => {
      const { data, error } = await supabase.storage.from(bucketName).list(path);
      if (error) throw error;
      return data;
    },
  });
};

export function useGetStorageFileSignedUrl(bucketName: string, fileName: string) {
  return useQuery({
    queryKey: ['getStorageFileSignedUrl', bucketName, fileName],
    queryFn: async () => {
      const signedUrl = await getStorageFileSignedURL(bucketName, fileName);
      return signedUrl;
    },
  });
}

export function useGetStorageFilePublicUrl(bucketName: string, fileName: string) {
  return useQuery({
    enabled: !!bucketName && !!fileName,
    queryKey: ['getStorageFilePublicUrl', bucketName, fileName],
    queryFn: async () => {
      const publicUrl = await getStorageFilePublicURL(bucketName, fileName);
      return publicUrl;
    },
  });
}

export type UploadedFile = {
  id: string;
  path: string;
  fullPath: string;
};

export function useUpsertStorageFile(bucketName: string, path: string) {
  const queryClient = useQueryClient();
  return useMutation<UploadedFile, Error, { file: File; fileName?: string }>({
    mutationFn: async ({ file, fileName }: { file: File; fileName?: string }) => {
      const { data, error } = await uploadStorageFile(bucketName, `${path}/${fileName || file.name}`, file, {
        cacheControl: '3600',
        upsert: true,
      });
      if (error) throw error;
      console.log('UPLOADED FILE', data);
      return data as UploadedFile;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getStorageFiles', bucketName, path] });
    },
  });
}

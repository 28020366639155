import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/joy';
import { Job } from '@bluebird-monorepo/types';
import { useUpsertJob } from '@bluebird-monorepo/jobs';

export function PotentialFeeColumn({ job }: { job: Job }) {
  const { mutate: upsertJob } = useUpsertJob();
  const [displayValue, setDisplayValue] = useState<string | number>(
    job.salaryRange?.max && job.feePercentage ? job.salaryRange.max * (job.feePercentage / 100) : 'N/A',
  );
  const inputRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setDisplayValue(
      job.salaryRange?.max && job.feePercentage ? job.salaryRange.max * (job.feePercentage / 100) : 'N/A',
    );
  }, [job]);

  const handleUpdate = (value: string) => {
    const cleanValue = value.replace(/[^0-9]/g, '');
    const newValue = cleanValue ? parseFloat(cleanValue) : 0;

    if (!isNaN(newValue)) {
      const salaryRangeMax = newValue / (job.feePercentage / 100);
      setDisplayValue(newValue);
      const updatedJob = {
        ...job,
        salaryRange: {
          min: job.salaryRange?.min !== undefined ? job.salaryRange.min : 0,
          max: salaryRangeMax,
        },
      };
      upsertJob(updatedJob);
    } else {
      console.error('Invalid input for potential fee');
      setDisplayValue(job.salaryRange?.max ? job.salaryRange.max * (job.feePercentage / 100) : 'N/A');
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {displayValue !== 'N/A' && displayValue !== 0 && <Typography level="body-sm">€</Typography>}
      <Typography
        ref={inputRef}
        contentEditable
        suppressContentEditableWarning
        onInput={(e) => {
          const input = e.currentTarget.textContent || '';
          const filteredInput = input.replace(/[^0-9]/g, '');
          e.currentTarget.textContent = filteredInput;
        }}
        onBlur={(e) => {
          handleUpdate(e.currentTarget.textContent || '');
          if (e.currentTarget.textContent === '' || e.currentTarget.textContent === '0') {
            e.currentTarget.textContent = 'N/A';
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleUpdate(e.currentTarget.textContent || '');
            if (e.currentTarget.textContent === '' || e.currentTarget.textContent === '0') {
              e.currentTarget.textContent = 'N/A';
            }
            inputRef.current?.blur();
          }
        }}
        sx={{
          flexGrow: 1,
          cursor: 'text',
          borderRadius: 'sm',
          '&:focus, &:focus-visible': {
            outline: 'none',
            border: '1px solid rgba(0, 0, 0, 0.2)',
          },
          px: 0.5,
        }}
      >
        {displayValue}
      </Typography>
    </Box>
  );
}

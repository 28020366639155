import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { ArrowBack, Download } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  Sheet,
  Stack,
  Typography,
} from '@mui/joy';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';
import { useFireflies } from './useFireflies';

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }
  return `${minutes}m`;
};

const ensureArray = (value: unknown): any[] => {
  if (Array.isArray(value)) {
    return value;
  }
  if (typeof value === 'string') {
    try {
      const parsed = JSON.parse(value);
      return Array.isArray(parsed) ? parsed : [];
    } catch {
      return [];
    }
  }
  return [];
};

const TranscriptDetailPage: React.FC = () => {
  const { transcriptId } = useParams<{ transcriptId: string }>();
  const navigate = useNavigate();
  const { data: user } = useGetCurrentUser();
  const { transcripts, isLoading, error } = useFireflies(user?.email || '');

  if (!user || isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box sx={{ p: 2, bgcolor: 'danger.softBg', borderRadius: 'lg' }}>
        <Typography color="danger">{error.message}</Typography>
      </Box>
    );
  }

  const transcript = transcripts.find((t) => t.id === transcriptId);

  if (!transcript) {
    return (
      <Box sx={{ p: 2, bgcolor: 'warning.softBg', borderRadius: 'lg' }}>
        <Typography color="warning">Transcript not found</Typography>
      </Box>
    );
  }

  // Debug logging
  console.log('Summary data:', {
    summary: transcript.summary,
    actionItems: transcript.summary?.action_items,
    topicsDiscussed: transcript.summary?.topics_discussed,
  });

  const actionItems = ensureArray(transcript.summary?.action_items);
  const topicsDiscussed = ensureArray(transcript.summary?.topics_discussed);
  const overview = transcript.summary?.overview || 'No overview available';

  return (
    <Layout title={transcript.title}>
      <Box sx={{ p: 3 }}>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <IconButton onClick={() => navigate('/fireflies')} variant="outlined">
            <ArrowBack />
          </IconButton>
          <Typography level="h1">{transcript.title}</Typography>
        </Box>

        {/* Meeting Info */}
        <Card variant="outlined" sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Stack spacing={1}>
              <Typography level="title-lg">Meeting Details</Typography>
              <Typography level="body-sm">Duration: {formatDuration(transcript.duration)}</Typography>
              <Typography level="body-sm">Host: {transcript.host_email}</Typography>
            </Stack>
            {(transcript.audio_url || transcript.video_url) && (
              <Button
                startDecorator={<Download />}
                onClick={() => window.open(transcript.audio_url || transcript.video_url, '_blank')}
              >
                Download Recording
              </Button>
            )}
          </Box>

          <Divider />

          <Box sx={{ mt: 2 }}>
            <Typography level="title-sm" sx={{ mb: 1 }}>
              Attendees
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {transcript.meeting_attendees.map((attendee) => (
                <Chip key={attendee.email} size="sm">
                  {attendee.displayName || attendee.email}
                </Chip>
              ))}
            </Box>
          </Box>
        </Card>

        {/* Summary */}
        <Card variant="outlined" sx={{ mb: 3 }}>
          <Typography level="title-lg" sx={{ mb: 2 }}>
            Summary
          </Typography>
          <Stack spacing={2}>
            <Box>
              <Typography level="title-sm">Overview</Typography>
              <Typography level="body-sm">{overview}</Typography>
            </Box>
            {actionItems.length > 0 && (
              <Box>
                <Typography level="title-sm">Action Items</Typography>
                <List marker="disc">
                  {actionItems.map((item, index) => (
                    <ListItem key={index}>{item}</ListItem>
                  ))}
                </List>
              </Box>
            )}
            {topicsDiscussed.length > 0 && (
              <Box>
                <Typography level="title-sm">Topics Discussed</Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {topicsDiscussed.map((topic, index) => (
                    <Chip key={index} size="sm">
                      {topic}
                    </Chip>
                  ))}
                </Box>
              </Box>
            )}
          </Stack>
        </Card>

        {/* Full Transcript */}
        <Sheet variant="outlined" sx={{ borderRadius: 'lg', p: 3 }}>
          <Typography level="title-lg" sx={{ mb: 2 }}>
            Full Transcript
          </Typography>
          <Stack spacing={2}>
            {(transcript.sentences || []).map((sentence) => (
              <Box
                key={sentence.index}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '100px 150px 1fr',
                  gap: 2,
                  alignItems: 'start',
                }}
              >
                <Typography level="body-xs" sx={{ color: 'neutral.500' }}>
                  {formatTime(sentence.start_time)}
                </Typography>
                <Typography level="body-sm" sx={{ fontWeight: 600 }}>
                  {sentence.speaker_name}:
                </Typography>
                <Typography level="body-sm">{sentence.text}</Typography>
              </Box>
            ))}
          </Stack>
        </Sheet>
      </Box>
    </Layout>
  );
};

export default TranscriptDetailPage;

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, ButtonGroup, IconButton, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useCalendarStore } from '../store/calendar.store';

interface WeekNavigationProps {
  currentWeek: dayjs.Dayjs;
  onNextWeek: () => void;
  onPreviousWeek: () => void;
}

export const WeekNavigation = ({ currentWeek, onNextWeek, onPreviousWeek }: WeekNavigationProps) => {
  const { setCurrentWeek } = useCalendarStore();

  const handleCurrentWeek = useCallback(() => {
    setCurrentWeek(dayjs());
  }, [setCurrentWeek]);

  // Format the date range to show Monday-Friday
  const startOfWorkWeek = currentWeek.day(1); // Monday
  const endOfWorkWeek = currentWeek.day(5); // Friday

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <ButtonGroup>
        <IconButton onClick={onPreviousWeek} variant="outlined">
          <ChevronLeft />
        </IconButton>
        <Button onClick={handleCurrentWeek} variant="outlined">
          Today
        </Button>
        <IconButton onClick={onNextWeek} variant="outlined">
          <ChevronRight />
        </IconButton>
      </ButtonGroup>
      <Typography level="h4">
        {startOfWorkWeek.format('MMMM D')} - {endOfWorkWeek.format('MMMM D, YYYY')}
      </Typography>
    </Box>
  );
};

import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd';

import { Dot } from '@bluebird-monorepo/bluebird-ui';
import styled from '@emotion/styled';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Divider } from '@mui/joy';
import { memo } from 'react';

import { CandidateCard, Stage } from '../types';
import CandidateAssignedToJobList from './CandidateAssignedToJobList';
import Title from './Title';

const Container = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 15px;
`;

interface HeaderProps {
  bgColor: string;
  isDragging: boolean;
}

const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 8px;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: 'blue';
  }
  border-radius: 0;
  text-align: center;
  color: var(--joy-palette-neutral-800);
  text-decoration-color: ${({ bgColor }) => bgColor};
`;

interface Props {
  bgColor: string;
  index: number;
  isCombineEnabled?: boolean;
  isScrollable?: boolean;
  isDraggingSelected?: boolean;
  cards: CandidateCard[];
  stage?: Stage;
  title: string;
  useClone?: boolean;
}

const Column = memo(function Column({
  title,
  cards,
  index,
  bgColor,
  stage,
  isDraggingSelected = false,
  isScrollable,
  isCombineEnabled,
  useClone,
}: Props) {
  return (
    <Draggable draggableId={title?.toString()} index={index} isDragDisabled={true}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Header bgColor={bgColor} isDragging={snapshot.isDragging}>
            <Title
              {...provided.dragHandleProps}
              aria-label={`${title} list`}
              style={{
                alignItems: 'center',
                display: 'flex',
                gap: '0.5rem',
                justifyContent: 'center',
                textDecorationColor: bgColor,
                textDecorationLine: 'underline',
              }}
            >
              {title} <Dot bgColor={bgColor} height={8} width={8} />
            </Title>
          </Header>
          <Divider inset="none" sx={{ mx: 2, my: 0 }}></Divider>
          <Droppable droppableId={String(stage?.id || 0)} type="CARD">
            {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
              <div
                ref={dropProvided.innerRef}
                {...dropProvided.droppableProps}
                style={{ overflow: 'auto', borderRadius: '15px' }}
              >
                <CandidateAssignedToJobList
                  internalScroll={isScrollable}
                  isCombineEnabled={Boolean(isCombineEnabled)}
                  listId={stage?.id || 0}
                  listType="CARD"
                  cards={cards}
                  style={{
                    backgroundColor: dropSnapshot.isDraggingOver ? 'rgba(0, 0, 0, 0.1)' : undefined,
                    opacity: isDraggingSelected ? 0.5 : 1,
                    transition: 'background-color 0.2s ease',
                  }}
                  useClone={Boolean(useClone)}
                  isDraggingSelected={isDraggingSelected}
                />
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  );
});

export default Column;

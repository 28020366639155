import { create } from 'zustand';

interface SelectionState {
  // Set of selected items in format "jobId_candidateId"
  selectedItems: Set<number>;
  // Last selected item for range selection
  lastSelected: number | null;
  // Selection order for maintaining drag grouping
  selectionOrder: number[];
  // Current column items for range selection
  columnItems: Record<number, number[]>;
  // Current column ID for range selection
  currentColumnId: number | null;

  // Actions
  toggleSelection: (id: number, isCtrlPressed: boolean, isShiftPressed: boolean, columnId: number) => void;
  clearSelection: () => void;
  isSelected: (id: number) => boolean;
  setColumnItems: (columnId: number, items: number[]) => void;
}

export const useSelectionStore = create<SelectionState>((set, get) => ({
  selectedItems: new Set(),
  lastSelected: null,
  selectionOrder: [],
  columnItems: {},
  currentColumnId: null,

  setColumnItems: (columnId: number, items: number[]) => {
    set((state) => ({
      columnItems: {
        ...state.columnItems,
        [columnId]: items,
      },
    }));
  },

  toggleSelection: (id: number, isCtrlPressed: boolean, isShiftPressed: boolean, columnId: number) => {
    const state = get();

    const newSelectedItems = new Set(state.selectedItems);
    let newSelectionOrder = [...state.selectionOrder];

    if (isShiftPressed && state.lastSelected && state.columnItems[columnId]) {
      // Only allow range selection within the same column
      const columnItems = state.columnItems[columnId] || [];

      // Get indices from the column's visual order
      const start = columnItems.indexOf(state.lastSelected);
      const end = columnItems.indexOf(id);

      if (start !== -1 && end !== -1) {
        // Clear existing selection if not using Ctrl/Cmd key
        if (!isCtrlPressed) {
          newSelectedItems.clear();
          newSelectionOrder = [];
        }

        // Select all items in range based on column order
        const range = columnItems.slice(Math.min(start, end), Math.max(start, end) + 1);

        range.forEach((itemId) => {
          newSelectedItems.add(itemId);
          if (!newSelectionOrder.includes(itemId)) {
            newSelectionOrder.push(itemId);
          }
        });
      }
    } else if (isCtrlPressed) {
      console.log('⌘ Ctrl/Cmd pressed - toggling selection');
      // Toggle individual selection
      if (newSelectedItems.has(id)) {
        console.log('➖ Removing from selection:', id);
        newSelectedItems.delete(id);
        const index = newSelectionOrder.indexOf(id);
        if (index !== -1) {
          newSelectionOrder.splice(index, 1);
        }
      } else {
        console.log('➕ Adding to selection:', id);
        newSelectedItems.add(id);
        if (!newSelectionOrder.includes(id)) {
          newSelectionOrder.push(id);
        }
      }
    } else {
      console.log('🔄 Single selection:', id);
      // Single selection
      newSelectedItems.clear();
      newSelectedItems.add(id);
      newSelectionOrder = [id];
    }

    console.log('✨ New state will be:', {
      selectedItems: Array.from(newSelectedItems),
      lastSelected: id,
      selectionOrder: newSelectionOrder,
      currentColumnId: columnId,
    });

    set({
      selectedItems: newSelectedItems,
      lastSelected: id,
      selectionOrder: newSelectionOrder,
      currentColumnId: columnId,
    });
  },

  clearSelection: () => {
    console.log('🧹 Clearing all selection');
    set({
      selectedItems: new Set(),
      lastSelected: null,
      selectionOrder: [],
      currentColumnId: null,
    });
  },

  isSelected: (id: number) => {
    return get().selectedItems.has(id);
  },
}));

import { Candidate } from '@bluebird-monorepo/types';
import { Box, FormControl, FormHelperText, FormLabel, Input, Stack, Typography, Autocomplete } from '@mui/joy';
import { useFormContext } from 'react-hook-form';
import { countries, Country } from '@bluebird-monorepo/utils';

export const BasicInfoStep = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<Candidate>();

  const currentCountry = watch('currentCountryResidingIn');

  return (
    <Box>
      <Typography level="h4" sx={{ mb: 2 }}>
        Basic Information
      </Typography>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <FormControl error={Boolean(errors.firstName)} sx={{ width: '50%' }}>
            <FormLabel>
              First Name <Typography color="danger">*</Typography>
            </FormLabel>
            <Input {...register('firstName', { required: 'First name is required' })} />
            <FormHelperText>{errors.firstName?.message}</FormHelperText>
          </FormControl>

          <FormControl error={Boolean(errors.lastName)} sx={{ width: '50%' }}>
            <FormLabel>
              Last Name <Typography color="danger">*</Typography>
            </FormLabel>
            <Input {...register('lastName', { required: 'Last name is required' })} />
            <FormHelperText>{errors.lastName?.message}</FormHelperText>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={2}>
          <FormControl error={Boolean(errors.email)} sx={{ width: '50%' }}>
            <FormLabel>
              Email Address <Typography color="danger">*</Typography>
            </FormLabel>
            <Input
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Enter a valid email',
                },
              })}
            />
            <FormHelperText>{errors.email?.message}</FormHelperText>
          </FormControl>

          <FormControl error={Boolean(errors.phone)} sx={{ width: '50%' }}>
            <FormLabel>Phone Number</FormLabel>
            <Input {...register('phone')} />
            <FormHelperText>{errors.phone?.message}</FormHelperText>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={2}>
          <FormControl error={Boolean(errors.city)} sx={{ width: '50%' }}>
            <FormLabel>City</FormLabel>
            <Input {...register('city')} />
            <FormHelperText>{errors.city?.message}</FormHelperText>
          </FormControl>

          <FormControl error={Boolean(errors.currentCountryResidingIn)} sx={{ width: '50%' }}>
            <FormLabel>Country</FormLabel>
            <Autocomplete<Country>
              {...register('currentCountryResidingIn')}
              options={[...countries]}
              value={countries.find((c) => c.country === currentCountry) || null}
              getOptionLabel={(option) => option.country}
              groupBy={(option) => option.region}
              renderGroup={(params) => (
                <li key={params.key}>
                  <Box
                    component="span"
                    sx={{
                      position: 'sticky',
                      top: '-8px',
                      display: 'block',
                      backgroundColor: 'background.level1',
                      my: 1,
                      py: 1,
                      px: 2,
                    }}
                  >
                    {params.group}
                  </Box>
                  <Box component="ul" sx={{ p: 0, listStyle: 'none' }}>
                    {params.children}
                  </Box>
                </li>
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, px: 2, py: 1 }}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/${option.code.toLowerCase()}.svg`}
                      alt=""
                    />
                    {option.country}
                  </Box>
                </li>
              )}
              slotProps={{
                input: {
                  placeholder: 'Select a country',
                  startDecorator: currentCountry ? (
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/${countries.find((c) => c.country === currentCountry)?.code.toLowerCase()}.svg`}
                      alt=""
                    />
                  ) : null,
                },
              }}
            />
            <FormHelperText>{errors.currentCountryResidingIn?.message}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
};

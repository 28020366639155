import { CalendarEvent, TaskboardItem } from '@bluebird-monorepo/types';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, List, Typography } from '@mui/joy';
import { FC, useState } from 'react';
import { CompactCreateTaskModal } from './CompactCreateTaskModal';
import { EventTaskListItem } from './EventTaskListItem';
import { useGetTasks } from '@bluebird-monorepo/tasks';

interface EventTasksListProps {
  event: CalendarEvent;
  compact?: boolean;
}

export const EventTasksList: FC<EventTasksListProps> = ({ event, compact = false }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { data: tasks, isLoading } = useGetTasks({ entityType: 'event', entityId: Number(event.id) });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography level="title-sm">Tasks ({tasks?.length || 0})</Typography>
        <Button
          size="sm"
          variant="soft"
          color="primary"
          startDecorator={<AddIcon />}
          onClick={() => setIsCreateModalOpen(true)}
        >
          Create Task
        </Button>
      </Box>

      {tasks?.length === 0 ? (
        <Typography level="body-sm" sx={{ textAlign: 'center', color: 'neutral.500' }}>
          No tasks yet
        </Typography>
      ) : (
        <List>
          {tasks?.map((task: TaskboardItem) => <EventTaskListItem key={task.id} task={task} compact={compact} />)}
        </List>
      )}

      {isCreateModalOpen && (
        <CompactCreateTaskModal
          open={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          entityType="event"
          // TODO: fix this when migrating calendar to supabase
          entityId={Number(event.id)}
        />
      )}
    </Box>
  );
};

import { IntroductionModal } from '@bluebird-monorepo/candidates';
import SelectionActionMenu from './SelectionActionMenu';
import { NoteTimelineEntry } from '@bluebird-monorepo/types';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import type { DragStart, DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSelectionStore } from '../store/useSelectionStore';
import { KanbanColumn, Stage } from '../types';
import { reorderCards } from '../utils/reorder';
import Column from './Column';
import { getAssignmentById, useGetAssignmentsByOwner, useUpsertAssignment } from '@bluebird-monorepo/assignments';
import { useUpsertIntroduction } from '@bluebird-monorepo/candidates';

interface KanbanBoardProps {
  isCombineEnabled?: boolean;
  stages?: Stage[];
  useClone?: boolean;
  withScrollableColumns?: boolean;
  initial: KanbanColumn;
  onIntroductionNeeded?: (candidateId: number, jobId: number) => void;
  containerStyle?: React.CSSProperties;
}

export const KanbanBoard: React.FC<KanbanBoardProps> = React.memo(
  ({
    isCombineEnabled,
    stages = [],
    useClone,
    withScrollableColumns,
    initial,
    onIntroductionNeeded,
    containerStyle,
  }) => {
    const [columns, setColumns] = useState<KanbanColumn>(initial || {});
    const [isDraggingSelected, setIsDraggingSelected] = useState(false);
    const [showIntroModal, setShowIntroModal] = useState(false);
    const [introModalData, setIntroModalData] = useState<{
      candidateId: number;
      jobId: number;
      assignmentId: number;
    } | null>(null);
    const { mutate: upsertIntroduction } = useUpsertIntroduction();
    const { data: currentUser } = useGetCurrentUser();
    const { refetch: refetchAssignmentsByOwner } = useGetAssignmentsByOwner(currentUser?.email || '');
    const { mutate: updateAssignment } = useUpsertAssignment();
    const selectedItems = useSelectionStore((state) => state.selectedItems);
    const [isCardDragging, setIsCardDragging] = useState(false);
    const [isDraggingBoard, setIsDraggingBoard] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const boardRef = useRef<HTMLDivElement | null>(null);
    const dragTimeoutRef = useRef<NodeJS.Timeout>();
    const initialMousePosRef = useRef<{ x: number; y: number } | null>(null);

    // Update columns when initial prop changes
    useEffect(() => {
      setColumns(initial || {});
      return () => {
        useSelectionStore.getState().clearSelection();
      };
    }, [initial]);

    // Memoize ordered stages
    const ordered = useMemo(() => {
      return stages
        .sort((a, b) => {
          return a.order - b.order;
        })
        .map((stage) => {
          return stage.id;
        });
    }, [stages]);

    // Memoize complete columns
    const completeColumns = useMemo(() => {
      const complete = { ...columns };
      stages.forEach((stage) => {
        if (!complete[stage.id]) {
          complete[stage.id] = [];
        }
      });
      return complete;
    }, [columns, stages]);

    const camelize = useCallback((str: string) => {
      const result = str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
        .replace(/\s+/g, '');
      return result;
    }, []);

    const handleDragStart = useCallback((initial: DragStart) => {
      if (dragTimeoutRef.current) {
        clearTimeout(dragTimeoutRef.current);
      }
      setIsCardDragging(true);
      const selectedItems = useSelectionStore.getState().selectedItems;
      setIsDraggingSelected(selectedItems.has(Number(initial.draggableId)));
    }, []);

    const handleDragEnd = useCallback(
      async (result: DropResult) => {
        setIsCardDragging(false);
        setIsDraggingSelected(false);

        const selectedItems = useSelectionStore.getState().selectedItems;
        const destination = result.destination;
        const source = result.source;

        if (!destination) {
          console.log('⚠️ No destination, cancelling drag');
          return;
        }

        if (source.droppableId === destination.droppableId && source.index === destination.index) {
          return;
        }

        // Handle multi-drag
        if (isDraggingSelected && selectedItems.size > 1) {
          const tempDestination = camelize(destination.droppableId);
          const stageId: string = tempDestination?.charAt(0)?.toUpperCase() + tempDestination?.slice(1) || '';
          const stage = stages?.find((s) => s.id.toString() === stageId);
          const statusId = Number(stage?.id);

          selectedItems.forEach(async (itemId) => {
            await updateAssignment({
              id: Number(itemId),
              statusId,
              status: stage?.name || '',
            });
          });

          const data = reorderCards({
            destination: { droppableId: Number(destination.droppableId), index: destination.index },
            source: { droppableId: Number(source.droppableId), index: source.index },
            cardMap: columns,
            selectedCardIds: selectedItems,
          });

          await refetchAssignmentsByOwner();

          setColumns(data.cardMap);
          return;
        }

        // Handle single drag
        const assignmentId = result.draggableId;
        const assignment = await getAssignmentById(Number(assignmentId));
        const tempDestination = camelize(destination.droppableId);
        const stageId: string = tempDestination?.charAt(0)?.toUpperCase() + tempDestination?.slice(1) || '';
        const stage = stages?.find((s) => s.id.toString() === stageId);
        const statusId = Number(stage?.id);

        // Check if the destination is "Introduced to Client"
        if (stage?.name === 'Introduced to Client') {
          setIntroModalData({
            candidateId: assignment?.candidateId || 0,
            jobId: assignment?.jobId || 0,
            assignmentId: assignment?.id || 0,
          });
          setShowIntroModal(true);
          return;
        }

        await updateAssignment({
          id: Number(result.draggableId),
          statusId,
          status: stage?.name || '',
        });

        const data = reorderCards({
          destination: { droppableId: Number(destination.droppableId), index: destination.index },
          source: { droppableId: Number(source.droppableId), index: source.index },
          cardMap: columns,
        });

        setColumns(data.cardMap);
      },
      [columns, isDraggingSelected, stages, camelize, updateAssignment],
    );

    const handleStatusChange = useCallback(
      (newStatus: string) => {
        const selectedItems = useSelectionStore.getState().selectedItems;
        const stage = stages?.find((s) => s.name === newStatus);
        if (stage) {
          selectedItems.forEach((itemId) => {
            updateAssignment({
              id: Number(itemId),
              statusId: Number(stage.id),
              status: stage.name,
            });
          });
        }
      },
      [stages, updateAssignment],
    );

    const handleIntroModalClose = useCallback(() => {
      setShowIntroModal(false);
      setIntroModalData(null);
    }, []);

    const handleIntroModalSave = useCallback(
      async (content: string) => {
        if (!introModalData) return;

        const { candidateId, jobId, assignmentId } = introModalData;
        const stage = stages.find((s) => s.name === 'Introduced to Client');
        if (!stage) return;

        try {
          // First create the introduction
          await upsertIntroduction({
            candidateId,
            jobId,
            authorId: currentUser?.id,
            content,
            createdAt: new Date(),
          });

          // Then update the status
          // await onStatusUpdate?.(candidateId, Number(stage.id), stage.name, jobId);

          await updateAssignment({
            id: assignmentId,
            statusId: Number(stage.id),
            status: stage.name,
          });

          // Add timeline entry
          const timelineEntry: Omit<NoteTimelineEntry, 'id'> = {
            type: 'note',
            content,
            visibility: 'team',
            timestamp: new Date(),
            createdBy: {
              id: currentUser?.id || 0,
              name: currentUser?.displayName || currentUser?.email || '',
              avatar: currentUser?.photoUrl,
            },
            entityType: 'job',
            entityId: String(jobId),
          };

          // await addTimelineEntryMutation({
          //   id: jobId,
          //   ...timelineEntry,
          // });

          // Update columns
          const source = Object.entries(columns).find(([_, cards]) =>
            cards.some((card: any) => card.candidateId === candidateId && card.jobId === jobId),
          );

          if (source) {
            const [sourceId] = source;
            const data = reorderCards({
              source: { droppableId: Number(sourceId), index: 0 },
              destination: { droppableId: stage.id, index: 0 },
              cardMap: columns,
            });

            setColumns(data.cardMap);
          }

          handleIntroModalClose();
        } catch (error) {
          console.error('Failed to save introduction:', error);
          // TODO: Add error handling UI
        }
      },
      [
        columns,
        stages,
        updateAssignment,
        handleIntroModalClose,
        introModalData,
        upsertIntroduction,
        // addTimelineEntryMutation,
        currentUser,
      ],
    );

    // Add mouse event handlers
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      if (isCardDragging || !boardRef.current) return;

      // Store initial mouse position
      initialMousePosRef.current = { x: e.pageX, y: e.pageY };

      // Clear any existing timeout
      if (dragTimeoutRef.current) {
        clearTimeout(dragTimeoutRef.current);
      }

      // Set a timeout to start horizontal dragging
      dragTimeoutRef.current = setTimeout(() => {
        if (!isCardDragging && boardRef.current) {
          setIsDraggingBoard(true);
          setStartX(e.pageX - boardRef.current.offsetLeft);
          setScrollLeft(boardRef.current.scrollLeft);
        }
      }, 150); // 150ms delay before horizontal drag starts
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isDraggingBoard || isCardDragging || !boardRef.current) return;

      // Check if mouse has moved significantly before initial drag timeout
      if (!isDraggingBoard && initialMousePosRef.current) {
        const deltaX = Math.abs(e.pageX - initialMousePosRef.current.x);
        const deltaY = Math.abs(e.pageY - initialMousePosRef.current.y);

        // If vertical movement is greater than horizontal, cancel horizontal drag
        if (deltaY > deltaX) {
          clearTimeout(dragTimeoutRef.current);
          initialMousePosRef.current = null;
          return;
        }
      }

      e.preventDefault();
      const x = e.pageX - boardRef.current.offsetLeft;
      const walk = (x - startX) * 2;
      boardRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      // Clear the timeout if mouse is released before horizontal drag starts
      if (dragTimeoutRef.current) {
        clearTimeout(dragTimeoutRef.current);
      }
      setIsDraggingBoard(false);
      initialMousePosRef.current = null;
    };

    // Clean up timeout on unmount
    useEffect(() => {
      return () => {
        if (dragTimeoutRef.current) {
          clearTimeout(dragTimeoutRef.current);
        }
      };
    }, []);

    return (
      <>
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {(provided) => (
              <div
                ref={(el) => {
                  provided.innerRef(el);
                  if (boardRef.current === null) {
                    boardRef.current = el;
                  }
                }}
                {...provided.droppableProps}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  padding: '1rem',
                  overflowX: 'auto',
                  cursor: isDraggingBoard ? 'grabbing' : isCardDragging ? 'default' : 'grab',
                  userSelect: 'none',
                  ...containerStyle,
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                {ordered.map((stageId: number, index: number) => {
                  const stage = stages?.find((s) => s.id === stageId);
                  return (
                    <Column
                      key={stageId}
                      cards={completeColumns[stageId] || []}
                      stage={stage}
                      title={stage?.name || ''}
                      useClone={useClone}
                      isDraggingSelected={isDraggingSelected}
                      isCombineEnabled={isCombineEnabled}
                      isScrollable={withScrollableColumns}
                      index={index}
                      bgColor={stage?.color || '#ddd'}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {selectedItems.size > 0 && (
          <SelectionActionMenu
            selectedCount={selectedItems.size}
            onStatusChange={handleStatusChange}
            columnTitles={stages.map((stage) => stage.name)}
          />
        )}
        {showIntroModal && introModalData && (
          <IntroductionModal
            isOpen={showIntroModal}
            onClose={handleIntroModalClose}
            onSave={handleIntroModalSave}
            candidateId={introModalData.candidateId}
            jobId={introModalData.jobId}
          />
        )}
      </>
    );
  },
);

KanbanBoard.displayName = 'KanbanBoard';

export default KanbanBoard;

import { supabase } from '../services/supabase.service';
import { FileOptions } from '@supabase/storage-js';
import { toast } from 'react-toastify';

export const uploadStorageFile = async (bucket: string, path: string, file: File, options: FileOptions) => {
  const { data, error } = await supabase.storage.from(bucket).upload(path, file, options);
  return { data, error };
};

export const downloadStorageFile = async (bucket: string, fileName: string) => {
  try {
    const { data, error } = await supabase.storage.from(bucket).download(fileName);
    if (error) throw error;
    // Create blob URL and trigger download
    if (data) {
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName.split('/').pop() || 'download';
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
    return { data, error: null };
  } catch (error) {
    toast.error(`Error downloading file: ${(error as unknown as Error).message}`);
    return { data: null, error: error as Error };
  }
};

export async function deleteStorageFile(bucket: string, fileName: string) {
  try {
    const { error } = await supabase.storage.from(bucket).remove([`${fileName}`]);
    if (error) throw error;
  } catch (error) {
    toast.error(`Error deleting file: ${(error as unknown as Error).message}`);
  }
}

export async function getStorageFilePublicURL(bucket: string, fileName: string): Promise<string> {
  try {
    const { data } = supabase.storage.from(bucket).getPublicUrl(fileName);
    return data.publicUrl;
  } catch (error) {
    toast.error(`Error getting file URL: ${(error as unknown as Error).message}`);
    throw error;
  }
}

export async function getStorageFileSignedURL(bucket: string, fileName: string, expiresIn = 3600): Promise<string> {
  try {
    const { data, error } = await supabase.storage.from(bucket).createSignedUrl(fileName, expiresIn);

    if (error) throw error;

    return data.signedUrl;
  } catch (error) {
    toast.error(`Error getting signed URL: ${(error as unknown as Error).message}`);
    throw error;
  }
}

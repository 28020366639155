import {
  CalendarEvent,
  CalendarEventAttendee,
  Company,
  EventType,
  EventTypeConfig,
  Job,
} from '@bluebird-monorepo/types';
import { extractID } from '@bluebird-monorepo/utils';
import { Box, Button, CircularProgress, Divider, List, ListItem, ListItemDecorator, Typography } from '@mui/joy';
import { useState } from 'react';
import { CompanySearchAutocomplete } from '../CompanySearchAutocomplete/CompanySearchAutocomplete';
import CustomSelectCalendar from '../CustomSelectCalendar';
import { EventTasksList } from '../EventTasksList/EventTasksList';
import { JobSearchAutocomplete } from '../JobSearchAutocomplete/JobSearchAutocomplete';

export const EVENT_TYPES: EventTypeConfig[] = [
  {
    description: 'Assign to a job',
    domain: 'Candidate',
    name: 'Candidate interview',
  },
  {
    description: 'Assign to a company',
    domain: 'Client',
    name: 'BD Call',
  },
  {
    description: 'Assign to a company and job',
    domain: 'Client',
    name: 'Weekly update',
  },
  {
    description: '',
    domain: 'Internal',
    name: 'Internal meeting',
  },
] as const;

export const EVENT_COLORS = {
  Candidate: 'success',
  Client: 'primary',
  Internal: 'warning',
} as const;

interface EventDetailDialogProps {
  companies: Company[];
  event: CalendarEvent;
  jobs: Job[];
  onClose: () => void;
  onEventUpdate: (params: { id: number; data: Partial<CalendarEvent> }) => Promise<void>;
  open: boolean;
}

export function EventDetailDialog({ companies, event, jobs, onClose, onEventUpdate, open }: EventDetailDialogProps) {
  const [typeOfEvent, setTypeOfEvent] = useState<EventType | undefined | null>(
    event?.typeOfEvent as EventType | undefined | null,
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedEvent, setUpdatedEvent] = useState<CalendarEvent>(event);

  const handleTypeChange = (value: string) => {
    setTypeOfEvent(value as EventType);
    setUpdatedEvent((prev) => ({ ...prev, typeOfEvent: value as EventType }));
  };

  const handleCompanyChange = (company: Company | null) => {
    setUpdatedEvent((prev) => ({
      ...prev,
      companyId: company?.id || undefined,
    }));
  };

  const handleJobChange = (job: Job | null) => {
    setUpdatedEvent((prev) => ({
      ...prev,
      jobId: job?.id || undefined,
    }));
  };

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      console.log('Saving event:', {
        id: event.id,
        updates: updatedEvent,
      });

      await onEventUpdate({
        id: event.id,
        data: updatedEvent,
      });

      console.log('Event saved successfully');
      onClose();
    } catch (error) {
      console.error('Failed to update event:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  if (!event) return null;

  return (
    <>
      {isUpdating && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: event.description || '' }} />
      </Box>

      <Box>
        <Typography level="title-sm">Extracted ID:</Typography>
        <Typography level="body-sm">
          {event.description ? extractID(event.description) || 'No ID found' : 'No description available'}
        </Typography>
      </Box>

      <Box>
        <Typography level="title-sm">Attendees:</Typography>
        {event.attendees && event.attendees.length > 0 && (
          <List>
            {event.attendees.map((participant: CalendarEventAttendee, index: number) => (
              <ListItem key={index}>
                <ListItemDecorator>{participant.responseStatus === 'accepted' ? '✅' : '❌'}</ListItemDecorator>
                {participant.email}
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      <CustomSelectCalendar
        colors={EVENT_COLORS}
        defaultValue={event.typeOfEvent || undefined}
        items={EVENT_TYPES.map((type) => ({ ...type, id: type.name }))}
        label="Type of event"
        onChange={handleTypeChange}
      />

      {(typeOfEvent === 'Candidate interview' || typeOfEvent === 'Weekly update') && (
        <JobSearchAutocomplete
          label="Assign to job"
          initialJob={jobs.find((j) => j.id === updatedEvent.jobId) || null}
          onChange={handleJobChange}
          disabled={isUpdating}
        />
      )}

      {typeOfEvent === 'BD Call' && (
        <CompanySearchAutocomplete
          label="Assign to company"
          initialCompany={companies.find((c) => c.id === updatedEvent.companyId) || null}
          onChange={handleCompanyChange}
          disabled={isUpdating}
        />
      )}

      <Divider sx={{ mt: 2, mb: 2 }} />

      <EventTasksList event={event} compact />

      <Divider sx={{ mt: 2 }} />
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
        <Button color="neutral" disabled={isUpdating} onClick={onClose} variant="plain">
          Close
        </Button>
        <Button color="success" disabled={isUpdating} onClick={handleSave} variant="soft">
          Save
        </Button>
      </Box>
    </>
  );
}

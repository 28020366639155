import { Delete, Upload } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface FileUploadProps {
  onSave: (files: File[]) => Promise<void>;
  disabled?: boolean;
}

const FileUpload: FC<FileUploadProps> = ({ onSave, disabled = false }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isDragActive, setIsDragActive] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    const newFiles = Array.from(event.target.files || []);
    setFiles((prevFiles) => {
      const uniqueNewFiles = newFiles.filter((newFile) => !prevFiles.some((file) => file.name === newFile.name));
      return [...prevFiles, ...uniqueNewFiles].slice(0, 5);
    });
  };

  const handleFileRemove = (fileName: string) => {
    if (disabled) return;
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleSave = useCallback(async () => {
    if (disabled) return;
    await onSave(files);
    setFiles([]);
  }, [disabled, files, onSave]);

  useEffect(() => {
    if (files.length > 0) {
      handleSave();
    }
  }, [files, handleSave]);

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    disabled,
    multiple: true,
    onDragEnter: () => !disabled && setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDrop: (acceptedFiles) => {
      if (disabled) return;
      setFiles((prevFiles) => {
        const uniqueNewFiles = acceptedFiles.filter((newFile) => !prevFiles.some((file) => file.name === newFile.name));
        return [...prevFiles, ...uniqueNewFiles].slice(0, 5);
      });
    },
    onDropAccepted: () => setIsDragActive(false),
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box
        sx={{
          '&:hover': { borderColor: disabled ? 'neutral.outlinedBorder' : 'primary.solidBg' },
          backgroundColor: isDragActive ? 'neutral.200' : 'neutral.softBg',
          border: '2px dashed',
          borderColor: isDragActive ? 'primary.solidBg' : 'neutral.outlinedBorder',
          borderRadius: 'md',
          boxShadow: isDragActive ? '0 0 10px rgba(0, 0, 0, 0.1)' : 'none',
          opacity: disabled ? 0.7 : 1,
          padding: 2,
          textAlign: 'center',
          transition: 'all 0.3s ease',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} hidden />
        <Typography level="body-lg" mb={1} pt={1}>
          {isDragActive ? 'Release to drop files' : 'Drag and drop files here, or click to select'}
        </Typography>
        <Button component="label" disabled={disabled} startDecorator={<Upload />} variant="outlined">
          Upload Files
          {/* <input hidden multiple onChange={handleFileChange} type="file" /> */}
        </Button>
      </Box>

      {/* {files.length > 0 && (
        <>
          <Box>
            <Typography level="h4" mb={1}>
              Uploaded Files:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {files.map((file) => (
                <Box
                  key={file.name}
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'neutral.200',
                    borderRadius: 15,
                    display: 'flex',
                    gap: 1,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <Box>{file.name}</Box>
                  <Delete
                    onClick={() => handleFileRemove(file.name)}
                    sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <Button
            color="primary"
            disabled={disabled}
            onClick={handleSave}
            sx={{ alignSelf: 'flex-start' }}
            variant="solid"
          >
            Save Files
          </Button>
        </>
      )} */}
    </Box>
  );
};

export { FileUpload };

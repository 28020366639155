/**
 * Fetches a company logo from logo.dev and downloads it
 *
 * @param companyId The ID of the company (for the filename)
 * @param websiteUrl Website URL to search and match against
 * @param uploadFileFn Function to upload the downloaded file
 * @returns A promise that resolves to an object with logo path and match result
 */
export async function fetchAndDownloadCompanyLogo(
  companyId: number,
  websiteUrl: string,
  uploadFileFn: (params: { file: File; fileName?: string }) => Promise<any>,
): Promise<{ logoPath: string | null; domainMatched: boolean }> {
  try {
    if (!websiteUrl) {
      return { logoPath: null, domainMatched: false };
    }

    // Extract domain from website URL
    const url = websiteUrl.startsWith('http') ? websiteUrl : `https://${websiteUrl}`;
    const targetDomain = new URL(url).hostname.toLowerCase();
    const domainForFileName = targetDomain.replace(/\./g, '_');

    // Search directly with the domain (without protocol)
    const searchResponse = await fetch(`https://api.logo.dev/search?q=${encodeURIComponent(targetDomain)}`, {
      headers: {
        // TODO: move to env
        Authorization: `Bearer: sk_b_NetEdwSTeWzDrH2OZhmQ`,
      },
    });

    if (!searchResponse.ok) throw new Error('Failed to search for company logo');

    const searchData = await searchResponse.json();
    console.log('SEARCH RESPONSE', searchData);

    if (!searchData || !Array.isArray(searchData) || searchData.length === 0) {
      return { logoPath: null, domainMatched: false };
    }

    // Find exact domain match (case-insensitive)
    const bestMatch = searchData.find(
      (item) => item.domain && item.domain.toLowerCase() === targetDomain.toLowerCase(),
    );
    console.log('BEST MATCH', bestMatch);

    if (!bestMatch?.logo_url) {
      return { logoPath: null, domainMatched: false };
    }

    // Download and process the logo
    try {
      const imageResponse = await fetch(bestMatch.logo_url);
      if (!imageResponse.ok) throw new Error('Failed to download logo image');

      const imageBlob = await imageResponse.blob();
      const fileName = `${domainForFileName}_logo_${companyId}.png`;
      const imageFile = new File([imageBlob], fileName, { type: imageBlob.type });

      const uploadResult = await uploadFileFn({
        file: imageFile,
        fileName,
      });

      return { logoPath: uploadResult.fullPath, domainMatched: true };
    } catch (downloadError) {
      console.error('Error downloading or saving logo:', downloadError);
      return { logoPath: null, domainMatched: false };
    }
  } catch (error) {
    console.error(`Error fetching logo for ${websiteUrl}:`, error);
    return { logoPath: null, domainMatched: false };
  }
}

import { supabase } from '../services/supabase.service';

export function signInWithGoogle() {
  supabase.auth.signInWithOAuth({
    provider: 'google',
  });
}

export async function signOut() {
  // Return the promise so we can await it
  return supabase.auth.signOut();
}

export async function getUser() {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  return user;
}

import { BluebirdTable, ColumnDefinition } from '@bluebird-monorepo/bluebird-ui';
import { useGetCompanies } from '@bluebird-monorepo/companies';
import { Company } from '@bluebird-monorepo/types';
import { Avatar, Box, Typography } from '@mui/joy';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CompaniesTableFilters from './CompaniesTableFilters';
import { CompanyName } from './components/CompanyName';
import { JobCountLink } from './components/JobCountLink';
import { StatusChip } from './components/StatusChip';
import debounce from '@mui/material/utils/debounce';
import { useCompaniesTableStore } from '../../store/companiesTable.store';

export function CompaniesTable() {
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortConfig,
    setSortConfig,
    search,
    setSearch,
    filters,
    setFilters,
  } = useCompaniesTableStore();

  const { control, watch } = useForm({
    defaultValues: {
      filterName: filters.filterName,
      statusFilter: filters.statusFilter,
    },
  });

  const statusFilter = watch('statusFilter');
  const filterName = watch('filterName');

  const { data } = useGetCompanies({
    page,
    pageSize: rowsPerPage,
    sortField: sortConfig.field || undefined,
    sortDirection: sortConfig.direction,
    search,
    status: statusFilter,
  });

  const { companies, count: companiesSize } = data || {
    companies: [],
    count: 0,
  };

  // Debounce search input and update filters
  useEffect(() => {
    const debouncedCb = debounce((formValues) => {
      setSearch(formValues.filterName);
      setFilters({
        filterName: formValues.filterName,
        statusFilter: formValues.statusFilter,
      });
    }, 1000);
    const subscription = watch(debouncedCb);
    return () => subscription.unsubscribe();
  }, [watch, setSearch, setFilters]);

  // Sort by name when filterName changes
  useEffect(() => {
    if (filterName) {
      setSortConfig({
        field: 'name',
        direction: 'asc',
      });
    }
  }, [filterName, setSortConfig]);

  const handleSort = (field: string, direction: 'asc' | 'desc') => {
    if (filterName) {
      console.log('🔄 CompanyTable: Sorting:', { field, direction, isSearchActive: true });
      return;
    }
    console.log('🔄 CompanyTable: Sorting:', { field, direction, isSearchActive: false });
    setPage(1);
    setSortConfig({
      field: field as typeof sortConfig.field,
      direction,
    });
  };

  const columns: ColumnDefinition<Company>[] = [
    {
      field: 'name',
      headerName: 'Company',
      width: '35%',
      sortable: true,
      renderCell: ({ row }) => (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
          <Avatar
            alt={row.name}
            src={row.logoUrl}
            size="md"
            variant="outlined"
            sx={{
              width: '32px',
              height: '32px',
              '--Avatar-size': '32px',
              border: '1px solid',
              borderColor: 'divider',
            }}
          />
          <CompanyName company={row} />
        </Box>
      ),
    },
    {
      field: 'owner',
      headerName: 'Owner',
      width: '25%',
      sortable: true,
      renderCell: ({ row }) => <Typography level="body-sm">{row.owner || 'Unassigned'}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: '20%',
      sortable: true,
      renderCell: ({ row }) => <StatusChip status={row.status ?? 'inactive'} />,
    },
    {
      field: 'jobCount',
      headerName: 'Jobs',
      width: '15%',
      sortable: true,
      renderCell: ({ row }) => <JobCountLink company={row} />,
    },
  ];

  return (
    <>
      <CompaniesTableFilters control={control} onResetPage={() => setPage(1)} />
      <BluebirdTable
        data={companies}
        columns={columns}
        count={companiesSize}
        defaultRowsPerPage={10}
        rowsPerPageOptions={[10, 25, 50]}
        onSort={handleSort}
        page={page}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
        tableProps={{
          variant: 'outlined',
          sx: {
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '12px',
            '--TableCell-paddingX': '8px',
          },
        }}
      />
    </>
  );
}

import { BluebirdTable, ColumnDefinition } from '@bluebird-monorepo/bluebird-ui';
import { User } from '@bluebird-monorepo/types';
import { Avatar, Box, Button, Checkbox, Stack, Typography } from '@mui/joy';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetTeams } from '../api/teams/useGetTeams';
import { TeamAssignmentDialog } from './TeamAssignmentDialog';

interface UsersTableProps {
  users: User[];
  isLoading?: boolean;
  onEditTeam?: (teamId: number) => void;
}

export function UsersTable({ users, isLoading, onEditTeam }: UsersTableProps) {
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<Set<number>>(new Set());
  const [isTeamDialogOpen, setIsTeamDialogOpen] = useState(false);
  const { data: teams } = useGetTeams();

  // Prepare data with computed fields for sorting
  const tableData = useMemo(() => {
    return users.map((user) => ({
      ...user,
      displayNameSortable: user.displayName || user.email || '',
      teamName: teams?.find((t) => t.members?.includes(user.id))?.name || 'No team',
    }));
  }, [users, teams]);

  const handleUserSelect = (userId: number) => {
    const newSelected = new Set(selectedUsers);
    if (newSelected.has(userId)) {
      newSelected.delete(userId);
    } else {
      newSelected.add(userId);
    }
    setSelectedUsers(newSelected);
  };

  const handleSelectAll = () => {
    if (selectedUsers.size === users.length) {
      setSelectedUsers(new Set());
    } else {
      setSelectedUsers(new Set(users.map((user) => user.id)));
    }
  };

  const handleTeamAssign = () => {
    setIsTeamDialogOpen(true);
  };

  const handleTeamAssignComplete = () => {
    setIsTeamDialogOpen(false);
    setSelectedUsers(new Set());
  };

  const handleUserClick = (userId: number) => {
    navigate(`/users/${userId}`);
  };

  const columns: ColumnDefinition<(typeof tableData)[0]>[] = [
    {
      field: 'selection',
      headerName: '',
      width: '40px',
      renderHeader: () => (
        <Checkbox
          checked={selectedUsers.size === users.length}
          indeterminate={selectedUsers.size > 0 && selectedUsers.size < users.length}
          onChange={handleSelectAll}
        />
      ),
      renderCell: ({ row }) => (
        <Checkbox checked={selectedUsers.has(row.id)} onChange={() => handleUserSelect(row.id)} />
      ),
    },
    {
      field: 'displayNameSortable',
      headerName: 'Name',
      width: '250',
      sortable: true,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={row.photoUrl} size="sm">
            {row.displayName?.[0] || row.email[0]}
          </Avatar>
          <Box>
            <Typography
              onClick={() => handleUserClick(row.id)}
              sx={{
                cursor: 'pointer',
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {row.displayName || row.email}
            </Typography>
            <Typography level="body-sm" textColor="text.secondary">
              {row.title}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: '250',
      sortable: true,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: '150',
      sortable: true,
      renderCell: ({ row }) => <Typography sx={{ textTransform: 'capitalize' }}>{row.role || 'Not set'}</Typography>,
    },
    {
      field: 'teamName',
      headerName: 'Team',
      width: '200',
      sortable: true,
      renderCell: ({ row }) => {
        const team = teams?.find((t) => t.members?.includes(row.id));
        if (!team) return <Typography>No team</Typography>;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar src={team.avatar} size="sm">
              {team.name[0]}
            </Avatar>
            <Typography>{team.name}</Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {selectedUsers.size > 0 && (
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <Button onClick={handleTeamAssign} variant="outlined" color="neutral">
            Assign to Team ({selectedUsers.size} selected)
          </Button>
        </Stack>
      )}

      <TeamAssignmentDialog
        open={isTeamDialogOpen}
        onClose={() => setIsTeamDialogOpen(false)}
        onComplete={handleTeamAssignComplete}
        selectedUserIds={Array.from(selectedUsers)}
      />

      <BluebirdTable
        data={tableData}
        columns={columns}
        defaultRowsPerPage={10}
        rowsPerPageOptions={[10, 25, 50]}
        tableProps={{
          variant: 'outlined',
          sx: {
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '12px',
            '--TableCell-paddingX': '12px',
          },
        }}
      />
    </>
  );
}

import { useGetJobs } from '@bluebird-monorepo/jobs';
import { Job, Vertical } from '@bluebird-monorepo/types';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { CalendarToday } from '@mui/icons-material';
import { Box, Link, Stack, Tooltip, Typography } from '@mui/joy';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { JobsTableFilters as JobsTableFiltersComponent } from './JobsTableFilters';
import { LocationColumn } from './columns/LocationColumn';
import { PriorityColumn } from './columns/PriorityColumn';
import { ResponsibleColumn } from './columns/ResponsibleColumn';
import { StatusColumn } from './columns/StatusColumn';
import { WorkingOnColumn } from './columns/WorkingOnColumn';
import debounce from '@mui/material/utils/debounce';
import { CandidateCountColumn } from './columns/CandidateCountColumn';
import { PotentialFeeColumn } from './columns/PotentialFeeColumn';
import { BluebirdTable, ColumnDefinition } from '@bluebird-monorepo/bluebird-ui';
import { useJobTableSettings } from '../../store/useJobTableSettings';
import { SortConfig, useJobsTableStore, JobsTableFilters } from '../../store/jobsTable.store';
import { PriorityLevel } from './types';
import { useVerticals } from '@bluebird-monorepo/shared';

interface JobsTableProps {
  onFilteredCountChange?: (count: number) => void;
  onCountChange?: (count: number) => void;
}

function applyFilter({
  companyName,
  feeGt,
  feeLt,
  filterName,
  inputData,
  location,
  responsible,
  salaryGt,
  salaryLt,
  statusName,
  workingOn,
  vertical,
  priority,
  verticals,
}: JobsTableFilters & { inputData: Job[]; verticals?: Vertical[] }) {
  let filteredData = inputData;

  if (filterName) {
    filteredData = filteredData.filter(
      (job: Job) =>
        job.jobTitle?.toLowerCase().includes(filterName.toLowerCase()) ||
        job.responsible?.toLowerCase().includes(filterName.toLowerCase()) ||
        job.companyName?.toLowerCase().includes(filterName.toLowerCase()),
    );
  }

  // Ensure statusName is an array
  const statusNameArray = Array.isArray(statusName) ? statusName : [];

  if (statusNameArray.length > 0) {
    filteredData = filteredData.filter((job: Job) => {
      if (statusNameArray.includes('unassigned') && !job.status) {
        return true;
      }
      return statusNameArray.includes(job.status?.toLowerCase() ?? '');
    });
  }

  if (vertical && vertical !== '') {
    const selectedVertical = verticals?.find((v: Vertical) => v.name === vertical);
    if (selectedVertical) {
      filteredData = filteredData.filter((job: Job) => job.vertical === selectedVertical.name);
    }
  }

  if (companyName) {
    filteredData = filteredData.filter((job: Job) =>
      job.companyName?.toLowerCase().includes(companyName.toLowerCase()),
    );
  }

  if (responsible) {
    filteredData = filteredData.filter((job: Job) =>
      job.responsible?.toLowerCase().includes(responsible.toLowerCase()),
    );
  }
  if (location) {
    filteredData = filteredData.filter((job: Job) => job.location?.toLowerCase().includes(location.toLowerCase()));
  }

  if (workingOn.length > 0) {
    filteredData = filteredData.filter((job: Job) => {
      const jobWorkingOn = job.workingOn || [];
      return workingOn.some((person: string) => jobWorkingOn.includes(person));
    });
  }

  if (salaryGt) {
    filteredData = filteredData.filter((job: Job) => (job.salary ?? 0) > parseFloat(salaryGt));
  }

  if (salaryLt) {
    filteredData = filteredData.filter((job: Job) => (job.salary ?? 0) < parseFloat(salaryLt));
  }

  if (feeGt) {
    filteredData = filteredData.filter((job: Job) => job.feePercentage > parseFloat(feeGt));
  }

  if (feeLt) {
    filteredData = filteredData.filter((job: Job) => job.feePercentage < parseFloat(feeLt));
  }

  if (priority) {
    const targetLevel = parseInt(priority, 10);
    filteredData = filteredData.filter((job: Job) => {
      return job.priorityOverride?.level === targetLevel;
    });
  }

  return filteredData;
}

export function JobsTable({ onFilteredCountChange, onCountChange }: JobsTableProps) {
  const navigate = useNavigate();
  const { data: currentUser } = useGetCurrentUser();
  const [copiedId, setCopiedId] = useState<number | null>(null);
  const [effectiveLevels, setEffectiveLevels] = useState<Record<number, number>>({});
  const { verticals } = useVerticals();
  const {
    sortConfig,
    setSortConfig,
    rowsPerPage,
    setRowsPerPage,
    search,
    setSearch,
    page,
    setPage,
    filters,
    setFilters,
  } = useJobsTableStore();

  const { control, watch, reset } = useForm<JobsTableFilters>({
    defaultValues: filters,
  });

  const handleResetToDefault = () => {
    const defaultFilters: JobsTableFilters = {
      companyName: '',
      feeGt: '',
      feeLt: '',
      filterName: '',
      location: '',
      responsible: '',
      salaryGt: '',
      salaryLt: '',
      statusName: [],
      workingOn: [],
      vertical: '',
      priority: '',
    };
    reset(defaultFilters);
    setFilters(defaultFilters);
    setSearch('');
  };

  const filterName = watch('filterName');
  const statusName = watch('statusName');
  const companyName = watch('companyName');
  const responsible = watch('responsible');
  const location = watch('location');
  const workingOn = watch('workingOn');
  const salaryGt = watch('salaryGt');
  const salaryLt = watch('salaryLt');
  const feeGt = watch('feeGt');
  const feeLt = watch('feeLt');
  const vertical = watch('vertical');
  const priority = watch('priority');

  const { data } = useGetJobs({
    page,
    pageSize: rowsPerPage,
    sortField: sortConfig.field,
    sortDirection: sortConfig.direction,
    search,
    responsible,
    statuses: Array.isArray(statusName) ? statusName : [],
    vertical: vertical && vertical !== '' ? verticals?.find((v: Vertical) => v.name === vertical) : undefined,
    priority: priority || undefined,
    location,
    salaryGt: salaryGt ? parseFloat(salaryGt) : undefined,
    salaryLt: salaryLt ? parseFloat(salaryLt) : undefined,
    feeGt: feeGt ? parseFloat(feeGt) : undefined,
    feeLt: feeLt ? parseFloat(feeLt) : undefined,
  });

  const { jobs, count: jobsSize } = data || {
    jobs: [],
    count: 0,
  };

  const filteredData = useMemo(() => {
    return applyFilter({
      companyName,
      feeGt,
      feeLt,
      filterName,
      inputData: jobs,
      location,
      responsible,
      salaryGt,
      salaryLt,
      statusName,
      workingOn,
      vertical,
      priority,
      verticals,
    });
  }, [
    jobs,
    companyName,
    feeGt,
    feeLt,
    filterName,
    location,
    responsible,
    salaryGt,
    salaryLt,
    statusName,
    workingOn,
    vertical,
    priority,
    verticals,
  ]);

  useEffect(() => {
    onCountChange?.(jobsSize);
  }, [jobsSize, onCountChange]);

  // Debounce search input
  useEffect(() => {
    const debouncedCb = debounce((formValues: JobsTableFilters) => {
      setSearch(formValues.filterName);
      setPage(1);
    }, 1000);

    const subscription = watch((value) => {
      if (value) {
        debouncedCb(value as JobsTableFilters);
      }
    });

    return () => {
      if (subscription && typeof subscription.unsubscribe === 'function') {
        subscription.unsubscribe();
      }
    };
  }, [watch, setSearch, setPage]);

  useEffect(() => {
    if (onFilteredCountChange) {
      onFilteredCountChange(filteredData.length);
    }
  }, [filteredData.length, onFilteredCountChange]);

  // Update filters in store when form values change
  useEffect(() => {
    const subscription = watch((value) => {
      if (value) {
        setFilters(value as JobsTableFilters);
      }
    });

    return () => {
      if (subscription && typeof subscription.unsubscribe === 'function') {
        subscription.unsubscribe();
      }
    };
  }, [watch, setFilters]);

  const handleRowClick = (e: React.MouseEvent, jobId: number) => {
    // If middle click or ctrl/cmd + click, let the default behavior handle it
    if (e.button === 1 || e.ctrlKey || e.metaKey) {
      return;
    }

    e.preventDefault();
    navigate(`/jobs/${jobId}`);
  };

  const handleCopyReference = async (e: React.MouseEvent, job: Job) => {
    e.stopPropagation();
    try {
      const copyText = `${currentUser?.calendlyUrl || ''}?${currentUser?.answerNumber || ''}=${job.referenceNumber}`;
      await navigator.clipboard.writeText(copyText);
      setCopiedId(job.id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('Failed to copy reference number:', err);
    }
  };

  const handleCompanyClick = (e: React.MouseEvent, companyId: string) => {
    e.stopPropagation();
    navigate(`/companies/${companyId}`);
  };

  const handleEffectiveLevelChange = (jobId: number, level: PriorityLevel) => {
    setEffectiveLevels((prev) => ({ ...prev, [jobId]: level }));
  };

  const handleSort = (field: string, direction: 'asc' | 'desc') => {
    let fieldFixed = field;
    if (field === 'priority') {
      fieldFixed = 'priorityOverride';
    }
    console.log('🔄 CompanyTable: Sorting:', { fieldFixed, direction, isSearchActive: false });
    setPage(1);
    setSortConfig({
      field: fieldFixed as SortConfig['field'],
      direction,
    });
  };

  const { hiddenColumnIds } = useJobTableSettings();

  const columns: ColumnDefinition<Job>[] = useMemo(() => {
    const allColumns = [
      {
        field: 'priority',
        headerName: 'Priority',
        renderHeader: () => (
          <Tooltip
            sx={{
              maxWidth: '350px',
            }}
            title={
              <Box sx={{ p: 1 }}>
                <Typography level="body-sm" fontWeight="bold" mb={1}>
                  What does this mean?
                </Typography>
                <Typography level="body-sm">
                  The priority is a measure of how urgent and important this job is.
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}>
                  <Typography level="body-sm">
                    🟢 1 → Urgent priority. Need more introductions! Whole team should work this.
                  </Typography>
                  <Typography level="body-sm">🔵 2 → High priority. Need a bit of extra work.</Typography>
                  <Typography level="body-sm">🟠 3 → Medium priority. Not urgent, but should be worked on.</Typography>
                  <Typography level="body-sm">
                    ⬜️ 4 → Low priority, backburner, not urgent, don't work unless you have time 😴
                  </Typography>
                </Box>
              </Box>
            }
            placement="top-start"
            variant="outlined"
          >
            <Link
              color="primary"
              component="button"
              fontWeight="lg"
              underline="none"
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            >
              Priority ❓
            </Link>
          </Tooltip>
        ),
        width: '10%',
        sortable: true,
        renderCell: ({ row }: { row: Job }) => (
          <PriorityColumn job={row} onEffectiveLevelChange={handleEffectiveLevelChange} />
        ),
      },
      {
        field: 'responsible',
        headerName: 'Responsible',
        width: '12.5%',
        sortable: true,
        renderHeader: () => (
          <Tooltip
            sx={{
              maxWidth: '350px',
            }}
            title={
              <Box sx={{ p: 1 }}>
                <Typography level="body-sm" fontWeight="bold" mb={1}>
                  What does this mean?
                </Typography>
                <Typography level="body-sm">
                  The responsible person is the primary owner of this job and is accountable for:
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}>
                  <Typography level="body-sm">• Managing the overall recruitment process</Typography>
                  <Typography level="body-sm">• Ensuring timely communication with the client</Typography>
                  <Typography level="body-sm">
                    • Making sure there are <b>enough</b> introductions
                  </Typography>
                  <Typography level="body-sm">
                    • Engaging within TeamIT to make sure the job is moving forward
                  </Typography>
                  <Typography level="body-sm">
                    • <b>You are fully accountable for the must-haves, nice-to-haves and all other requirements!</b>
                  </Typography>
                </Box>
              </Box>
            }
            placement="top-start"
            variant="outlined"
          >
            <Link
              color="primary"
              component="button"
              fontWeight="lg"
              underline="none"
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            >
              Responsible ℹ
            </Link>
          </Tooltip>
        ),
        renderCell: ({ row }: { row: Job }) => <ResponsibleColumn job={row} />,
      },
      {
        field: 'workingOn',
        headerName: 'Working On',
        width: '15%',
        sortable: true,
        renderCell: ({ row }: { row: Job }) => <WorkingOnColumn job={row} />,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: '15%',
        sortable: true,
        renderCell: ({ row }: { row: Job }) => <StatusColumn job={row} />,
      },
      {
        field: 'jobTitle',
        headerName: 'Job Title',
        width: '25%',
        sortable: true,
        renderCell: ({ row }: { row: Job }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Tooltip title={copiedId === row.id ? 'Copied!' : 'Click to copy'} placement="top">
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  sx={{
                    backgroundColor: 'background.level1',
                    px: 1,
                    py: 0.5,
                    borderRadius: 'sm',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'background.level2',
                    },
                  }}
                  onClick={(e) => handleCopyReference(e, row)}
                >
                  <Typography
                    level="body-xs"
                    sx={{
                      fontWeight: '600',
                      color: 'text.secondary',
                    }}
                  >
                    #{row.id}
                  </Typography>
                  <CalendarToday
                    sx={{
                      fontSize: '14px',
                      color: 'text.secondary',
                    }}
                  />
                </Stack>
              </Tooltip>
              <Typography
                component="a"
                href={`/jobs/${row.id}`}
                onClick={(e) => handleRowClick(e, row.id)}
                level="body-sm"
                sx={{
                  fontWeight: '600',
                  color: 'primary.main',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {row.jobTitle}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: 'companyName',
        headerName: 'Company',
        width: '15%',
        sortable: true,
        renderCell: ({ row }: { row: Job }) => (
          <Typography
            level="body-sm"
            sx={{
              fontWeight: '500',
              color: 'primary.main',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={(e) => handleCompanyClick(e, String(row.companyId))}
          >
            {row.companyName}
          </Typography>
        ),
      },
      {
        field: 'location',
        headerName: 'Location',
        width: '15%',
        sortable: true,
        renderCell: ({ row }: { row: Job }) => <LocationColumn job={row} />,
      },
      {
        field: 'potentialFee',
        headerName: 'Potential Fee',
        width: '15%',
        renderCell: ({ row }: { row: Job }) => <PotentialFeeColumn job={row} />,
      },
      {
        field: 'candidates',
        headerName: 'Candidates',
        width: '5%',
        renderCell: ({ row }: { row: Job }) => <CandidateCountColumn jobId={Number(row.id)} />,
      },
      {
        field: 'events',
        headerName: 'Events',
        width: '10%',
        renderCell: ({ row }: { row: Job }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CalendarToday sx={{ fontSize: 'small', color: 'text.secondary' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                {row.eventStats?.totalEvents || 0} total
              </Typography>
              {row.eventStats?.scheduledEvents && row.eventStats.scheduledEvents > 0 && (
                <Typography level="body-sm" startDecorator={<CalendarToday />}>
                  {row.eventStats.scheduledEvents} upcoming
                </Typography>
              )}
            </Box>
          </Box>
        ),
      },
    ];

    // Filter out hidden columns
    return allColumns.filter((column) => !hiddenColumnIds.includes(column.field));
  }, [hiddenColumnIds]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <JobsTableFiltersComponent control={control} onResetPage={() => setPage(1)} />
        <button
          onClick={handleResetToDefault}
          style={{
            backgroundColor: 'var(--joy-palette-primary-500)',
            color: 'white',
            border: 'none',
            padding: '8px 16px',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
        >
          Reset Filters
        </button>
      </Box>
      <BluebirdTable
        data={jobs}
        columns={columns}
        count={jobsSize}
        defaultRowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onSort={handleSort}
        page={page}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
        tableProps={{
          variant: 'outlined',
          sx: {
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '12px',
            '--TableCell-paddingX': '8px',
          },
        }}
      />
    </>
  );
}

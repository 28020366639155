import { TaskBoard } from '@bluebird-monorepo/tasks';
import { Candidate } from '@bluebird-monorepo/types';
import { FC } from 'react';

interface CandidateTasksTabProps {
  candidate: Candidate;
}

export const CandidateTasksTab: FC<CandidateTasksTabProps> = ({ candidate }) => {
  return <TaskBoard entityType="candidate" entityId={candidate.id} />;
};

export default CandidateTasksTab;

import { CreateJobWizard, useGetJobById } from '@bluebird-monorepo/jobs';
import { Box, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../layout';

const EditJobPage = () => {
  const { jobId } = useParams<{ jobId: string }>();
  // Convert string ID to number and handle the case where jobId might be invalid
  const numericJobId = jobId ? parseInt(jobId, 10) : NaN;
  const { data: job, isLoading, error } = useGetJobById(numericJobId);

  if (isNaN(numericJobId)) {
    return (
      <Layout title="Edit Job">
        <Typography level="h4">Invalid job ID</Typography>
      </Layout>
    );
  }

  if (isLoading) {
    return (
      <Layout title="Edit Job">
        <Typography level="h4">Loading...</Typography>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title="Edit Job">
        <Typography level="h4">Error: {(error as Error).message}</Typography>
      </Layout>
    );
  }

  if (!job) {
    return (
      <Layout title="Edit Job">
        <Typography level="h4">Job not found</Typography>
      </Layout>
    );
  }

  return (
    <Layout title={`Edit ${job.jobTitle}`} subtitle={job.companyName}>
      <Box sx={{ width: '100%' }}>
        <CreateJobWizard initialData={job} />
      </Box>
    </Layout>
  );
};

export default EditJobPage;

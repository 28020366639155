import { User } from '@bluebird-monorepo/types';
import { FormControl, FormHelperText, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

export function AboutStep() {
  const {
    register,
    formState: { errors },
  } = useFormContext<User>();

  return (
    <Stack spacing={3}>
      <Typography level="h4">Basic Information</Typography>

      <FormControl error={!!errors.email} required>
        <FormLabel>Email</FormLabel>
        <Input {...register('email')} type="email" placeholder="user@example.com" />
        {errors.email && <FormHelperText>{errors.email.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.displayName} required>
        <FormLabel>Display Name</FormLabel>
        <Input {...register('displayName')} placeholder="Full name" />
        {errors.displayName && <FormHelperText>{errors.displayName.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.phoneNumber}>
        <FormLabel>Phone Number (Optional)</FormLabel>
        <Input {...register('phoneNumber')} placeholder="+1 234 567 8900" />
        {errors.phoneNumber && <FormHelperText>{errors.phoneNumber.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.title}>
        <FormLabel>Title (Optional)</FormLabel>
        <Input {...register('title')} placeholder="Job title" />
        {errors.title && <FormHelperText>{errors.title.message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors.recruitCrmId}>
        <FormLabel>Recruit CRM ID (Optional)</FormLabel>
        <Input {...register('recruitCrmId')} placeholder="Recruit CRM ID" />
        {errors.recruitCrmId && <FormHelperText>{errors.recruitCrmId.message as string}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

import { Team } from '@bluebird-monorepo/types';
import { useGetUsers } from '@bluebird-monorepo/users';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

export function ReviewStep() {
  const { watch } = useFormContext<Team>();
  const values = watch();
  const { data: users } = useGetUsers();

  const selectedLeader = users?.find((user) => user.id === values.leaderId);

  const InfoRow = ({ label, value }: { label: string; value?: string | string[] }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
      <Typography level="body-sm" textColor="text.secondary">
        {label}
      </Typography>
      {Array.isArray(value) ? (
        <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="flex-end">
          {value.length > 0 ? (
            value.map((v) => (
              <Typography
                key={v}
                level="body-sm"
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 'sm',
                  bgcolor: 'background.level2',
                }}
              >
                {v}
              </Typography>
            ))
          ) : (
            <Typography level="body-sm">None selected</Typography>
          )}
        </Stack>
      ) : (
        <Typography level="body-sm">{value || 'Not provided'}</Typography>
      )}
    </Box>
  );

  return (
    <Stack spacing={3}>
      <Typography level="title-lg">Review Team Information</Typography>

      <Box sx={{ bgcolor: 'background.level1', p: 2, borderRadius: 'sm' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Avatar
            src={values.avatar}
            sx={{
              '--Avatar-size': '64px',
            }}
          />
          <Box>
            <Typography level="title-md">{values.name}</Typography>
            <Typography level="body-sm" textColor="text.secondary">
              {values.description || 'No description provided'}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography level="title-sm" sx={{ mb: 1 }}>
          Team Details
        </Typography>
        <InfoRow label="Team Name" value={values.name} />
        <InfoRow label="Description" value={values.description} />
        <InfoRow label="Verticals" value={values.verticals} />

        <Divider sx={{ my: 2 }} />

        <Typography level="title-sm" sx={{ mb: 1 }}>
          Leadership
        </Typography>
        <InfoRow
          label="Team Leader"
          value={selectedLeader ? selectedLeader.displayName || selectedLeader.email : undefined}
        />
      </Box>

      <Typography level="body-sm" textColor="text.secondary">
        Please review the information above before {values.id ? 'saving changes to' : 'creating'} the team.
      </Typography>
    </Stack>
  );
}

import { useUpsertCompany } from '@bluebird-monorepo/companies';
import { Company } from '@bluebird-monorepo/types';
import { AccessTime, Edit, Euro, Group, Person, Work } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import React, { useEffect, useRef, useState } from 'react';

export interface CompanyMetricsProps {
  company: Company;
}

const STATUS_OPTIONS = [
  {
    value: 'client',
    label: 'Client',
    description: 'Active business relationship',
  },
  {
    value: 'prospect',
    label: 'Prospect',
    description: 'Potential client',
  },
  {
    value: 'partner',
    label: 'Partner',
    description: 'Strategic partner',
  },
  {
    value: 'none',
    label: 'None',
    description: 'No specific status',
  },
] as const;

export const CompanyMetrics: React.FC<CompanyMetricsProps> = ({ company }) => {
  const [isEditingStatus, setIsEditingStatus] = useState(false);
  const { mutate: updateCompany } = useUpsertCompany();
  const totalContacts = (company.contactPersons?.length || 0) + (company.hiringManagers?.length || 0);
  const lastPlacementDate = company.lastPlacementDate ? new Date(company.lastPlacementDate) : null;
  const selectRef = useRef<HTMLDivElement>(null);

  const handleStatusChange = (newStatus: Company['status']) => {
    if (newStatus) {
      updateCompany({
        status: newStatus,
      });
      setIsEditingStatus(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        const listbox = document.querySelector('[role="listbox"]');
        if (listbox && !listbox.contains(event.target as Node)) {
          setIsEditingStatus(false);
        }
      }
    };

    if (isEditingStatus) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditingStatus]);

  const getStatusColor = (status: Company['status']) => {
    switch (status) {
      case 'client':
        return 'success';
      case 'prospect':
        return 'warning';
      case 'partner':
        return 'primary';
      case 'none':
        return 'neutral';
      default:
        return 'neutral';
    }
  };

  const getStatusLabel = (status: Company['status']) => {
    return STATUS_OPTIONS.find((option) => option.value === status)?.label || 'None';
  };

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid xs={12}>
        <Card variant="soft" sx={{ bgcolor: 'background.level1' }}>
          <CardContent>
            <Grid container spacing={2}>
              {/* Status */}
              <Grid xs={3}>
                <Box sx={{ p: 2, height: '100%' }}>
                  <Typography level="body-sm" sx={{ mb: 1, color: 'text.secondary' }}>
                    Status
                  </Typography>
                  {isEditingStatus ? (
                    <FormControl size="sm" sx={{ minWidth: '100%' }} ref={selectRef}>
                      <Select
                        value={company.status || ''}
                        onChange={(_, newStatus) => handleStatusChange(newStatus as Company['status'])}
                        onClose={() => setIsEditingStatus(false)}
                        defaultListboxOpen
                        size="sm"
                        placeholder="Set Status"
                        variant="outlined"
                        sx={{ paddingY: '10px' }}
                      >
                        {STATUS_OPTIONS.map((option) => (
                          <Option key={option.value} value={option.value}>
                            <Stack>
                              <Typography>{option.label} </Typography>
                              <Typography level="body-xs"> - {option.description}</Typography>
                            </Stack>
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography level="h3" sx={{ color: `${getStatusColor(company.status)}.plainColor` }}>
                        {getStatusLabel(company.status)}
                      </Typography>
                      <IconButton variant="plain" color="neutral" size="sm" onClick={() => setIsEditingStatus(true)}>
                        <Edit />
                      </IconButton>
                    </Stack>
                  )}
                </Box>
              </Grid>

              {/* Quick Stats */}
              <Grid xs={3}>
                <Box sx={{ p: 2, height: '100%' }}>
                  <Typography level="body-sm" sx={{ mb: 1, color: 'text.secondary' }}>
                    Active Jobs
                  </Typography>
                  <Typography level="h2" startDecorator={<Work />} sx={{ alignItems: 'center', gap: 1 }}>
                    {company.jobCount || 0}
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={3}>
                <Box sx={{ p: 2, height: '100%' }}>
                  <Typography level="body-sm" sx={{ mb: 1, color: 'text.secondary' }}>
                    Contacts
                  </Typography>
                  <Typography level="h2" startDecorator={<Group />} sx={{ alignItems: 'center', gap: 1 }}>
                    {totalContacts}
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={3}>
                <Box sx={{ p: 2, height: '100%' }}>
                  <Typography level="body-sm" sx={{ mb: 1, color: 'text.secondary' }}>
                    Revenue
                  </Typography>
                  <Typography level="h2" startDecorator={<Euro />} sx={{ alignItems: 'center', gap: 1 }}>
                    {company.revenueGenerated
                      ? new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(
                          company.revenueGenerated,
                        )
                      : '€0'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={2}>
              {/* Last Activity */}
              <Grid xs={6}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <AccessTime sx={{ color: 'text.secondary' }} />
                  <Box>
                    <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                      Last Placement
                    </Typography>
                    <Typography level="body-md">
                      {lastPlacementDate
                        ? lastPlacementDate.toLocaleDateString('nl-NL', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        : 'No placements yet'}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Company Owner */}
              <Grid xs={6}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Person sx={{ color: 'text.secondary' }} />
                  <Box>
                    <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                      Account Owner
                    </Typography>
                    <Typography level="body-md">{company.owner || 'Unassigned'}</Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

import { Box } from '@mui/joy';
import { LogsTable } from '@bluebird-monorepo/notifications';
import { Layout } from '../../../layout';
import { useLogsTableSettings } from './useLogsTableSettings';

export default function LogsPage() {
  const { hiddenColumnIds, toggleColumnVisibility } = useLogsTableSettings();
  return (
    <Layout title="Logs">
      <Box sx={{ p: 3, height: '100%' }}>
        <LogsTable hiddenColumnIds={hiddenColumnIds} toggleColumnVisibility={toggleColumnVisibility} />
      </Box>
    </Layout>
  );
}

import { Check, Close } from '@mui/icons-material';
import { Box, Checkbox } from '@mui/material';

interface CustomCheckboxProps {
  checked: boolean;
  color?: string;
  label: string;
  onChange?: (checked: boolean) => void;
}

export function CustomCheckbox({ checked, color = '#000', label, onChange }: CustomCheckboxProps) {
  return (
    <Box
      sx={{
        ':hover .MuiCheckbox-root': {
          borderColor: '#aaa', // Hover effect on checkbox
        },
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        padding: '8px 0',
      }}
    >
      <Checkbox
        checked={checked}
        checkedIcon={color === 'red' ? <Close /> : <Check />}
        onChange={(e) => onChange?.(e.target.checked)}
        sx={{
          '&.Mui-checked': {
            border: color === 'red' ? '1px solid var(--red-5)' : '1px solid blue',
            borderRadius: 0,
            color: color === 'red' ? 'var(--red-7)' : color,
            padding: 0,
          },
          '& .MuiSvgIcon-root': {
            '::after': {
              content: '""',
              display: checked && color === 'red' ? 'block' : 'none',
              height: '2px',
              left: '50%',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%) rotate(-45deg)',
              width: '12px',
            },
            '::before': {
              content: '""',
              display: checked && color === 'red' ? 'block' : 'none',
              height: '2px',
              left: '50%',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%) rotate(45deg)',
              width: '12px',
            },
            borderRadius: '4px',
            height: '24px',
            position: 'relative',
            width: '24px',
          },
          color: '#ccc',
        }}
      />
      <span
        style={{
          color: '#333',
          fontSize: '14px',
          marginLeft: '8px',
        }}
      >
        {label}
      </span>
    </Box>
  );
}

import { useUpsertStorageFile } from '@bluebird-monorepo/supabase';
import { Company } from '@bluebird-monorepo/types';
import { AttachFile, Upload } from '@mui/icons-material';
import { Box, CircularProgress, Divider, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';
import React, { useRef } from 'react';
import { CompanyContractsList } from './CompanyContractsList';

interface CompanyContractsUploadProps {
  company: Company;
  open: boolean;
  onClose: () => void;
}

export const CompanyContractsUpload: React.FC<CompanyContractsUploadProps> = ({ company, open, onClose }) => {
  const { mutate: uploadStorageFile, isPending: isUploading } = useUpsertStorageFile(
    'uploads',
    `companies/${company.id}/contracts`,
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSaveFiles = async (files: File[]) => {
    for (const file of files) {
      try {
        await uploadStorageFile({ file });
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  const handleCustomUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const fileArray: File[] = Array.from(files);
      handleSaveFiles(fileArray);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        sx={{
          width: '100%',
          maxWidth: 600,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <ModalClose />
        <Typography level="h4" sx={{ mb: 2 }}>
          Company Contracts
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AttachFile fontSize="small" />
            <Typography level="title-sm">Contracts & Documents</Typography>
            {isUploading && <CircularProgress size="sm" sx={{ ml: 'auto' }} />}
          </Box>

          <Box
            sx={{
              backgroundColor: 'background.surface',
              borderRadius: 'sm',
              width: '100%',
              p: 1,
            }}
          >
            <Box
              sx={{
                border: '1px dashed',
                borderColor: 'divider',
                borderRadius: 'sm',
                p: 3,
                bgcolor: 'background.level1',
                textAlign: 'center',
                mb: 2,
                cursor: 'pointer',
              }}
              onClick={handleCustomUploadClick}
            >
              <Typography level="body-md" sx={{ mb: 0.5 }}>
                Drag and drop files here, or click to select
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    bgcolor: 'primary.solidBg',
                    color: 'primary.solidColor',
                    px: 2,
                    py: 1,
                    borderRadius: 'sm',
                    fontSize: 'sm',
                    fontWeight: 'md',
                  }}
                >
                  <Upload fontSize="small" sx={{ mr: 1 }} />
                  Upload Files
                </Box>
              </Box>
              <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
            </Box>

            <Divider sx={{ mb: 2 }} />

            <CompanyContractsList company={company} isUploading={isUploading} />
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

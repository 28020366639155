import {
  AccountTree,
  Assignment,
  BarChart,
  BusinessCenterOutlined,
  CalendarMonth,
  Chat,
  ExpandLess,
  ExpandMore,
  Home,
  History,
  MoneyTwoTone,
  PeopleOutlineSharp,
  ReceiptLong,
  RecordVoiceOver,
  SettingsRounded,
  SupervisorAccount,
} from '@mui/icons-material';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCurrentUser } from '@bluebird-monorepo/users';

const menuItems = [
  { path: '/', label: 'Home', icon: <Home /> },
  {
    path: '/pipeline',
    label: 'Pipeline',
    icon: <AccountTree />,
    subitems: [
      { path: '/pipeline/my', label: 'My Pipeline' },
      { path: '/pipeline/team', label: 'Team Pipeline' },
      { path: '/pipeline/job', label: 'Pipeline per Job' },
    ],
  },
  { path: '/jobs', label: 'Jobs', icon: <PeopleOutlineSharp /> },
  { path: '/companies', label: 'Companies', icon: <BusinessCenterOutlined /> },
  { path: '/candidates', label: 'Candidates', icon: <PeopleOutlineSharp /> },
  { path: '/users', label: 'Users', icon: <SupervisorAccount /> },
  { path: '/placements', label: 'Placements', icon: <MoneyTwoTone /> },
  { path: '/invoices', label: 'Invoices', icon: <ReceiptLong /> },
  { path: '/fireflies', label: 'Conversations', icon: <RecordVoiceOver /> },
  { path: '/introductions', label: 'Introductions', icon: <Chat /> },
  { path: '/calendar', label: 'Calendar', icon: <CalendarMonth /> },
  { path: '/tasks', label: 'Tasks', icon: <Assignment /> },
  { path: '/metrics', label: 'Metrics', icon: <BarChart /> },
  { path: '/logs', label: 'Logs', icon: <History /> },
];

interface SidebarProps {
  isCollapsed: boolean;
}

export const Sidebar = React.memo(({ isCollapsed }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: currentUser } = useGetCurrentUser();
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

  const isActive = (path: string) => location.pathname === path;
  const isSubitemActive = (path: string) => location.pathname.startsWith(path);

  const toggleExpanded = (path: string) => {
    setExpandedItems((prev) => (prev.includes(path) ? prev.filter((p) => p !== path) : [...prev, path]));
  };

  const renderMenuItem = (item: (typeof menuItems)[0]) => {
    // Only show users page if user is Dennis
    if (
      (item.path === '/users' ||
        item.path === '/placements' ||
        item.path === '/invoices' ||
        item.path === '/fireflies' ||
        item.path === '/introductions' ||
        item.path === '/calendar' ||
        item.path === '/tasks' ||
        item.path === '/logs' ||
        item.path === '/metrics' ||
        item.path === '/settings') &&
      currentUser?.email !== 'dennis@bluebirdrecruitment.com' &&
      currentUser?.email !== 'igor@bluebirdrecruitment.com'
    ) {
      return null;
    }

    const isItemExpanded = expandedItems.includes(item.path);
    const hasSubitems = 'subitems' in item && Array.isArray(item.subitems) && item.subitems.length > 0;

    const button = (
      <ListItemButton
        onClick={() => {
          if (hasSubitems) {
            toggleExpanded(item.path);
          } else {
            navigate(item.path);
          }
        }}
        selected={hasSubitems ? isSubitemActive(item.path) : isActive(item.path)}
        sx={{
          gap: 1.5,
          p: 2,
          minHeight: 44,
          color: isActive(item.path) ? 'primary.plainColor' : 'neutral.plainColor',
          '&:hover': {
            bgcolor: 'background.level1',
          },
          '&.Mui-selected': {
            bgcolor: 'primary.softBg',
            '&:hover': {
              bgcolor: 'primary.softHoverBg',
            },
          },
          justifyContent: isCollapsed ? 'center' : 'flex-start',
          transition: 'padding 0.2s ease-in-out, justify-content 0.2s ease-in-out',
        }}
      >
        {item.icon}
        {!isCollapsed && (
          <ListItemContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              level="title-sm"
              sx={{
                color: isActive(item.path) ? 'primary.plainColor' : 'inherit',
                whiteSpace: 'nowrap',
              }}
            >
              {item.label}
            </Typography>
            {hasSubitems && (isItemExpanded ? <ExpandLess /> : <ExpandMore />)}
          </ListItemContent>
        )}
      </ListItemButton>
    );

    return (
      <React.Fragment key={item.path}>
        <ListItem>
          {isCollapsed ? (
            <Tooltip title={item.label} placement="right">
              {button}
            </Tooltip>
          ) : (
            button
          )}
        </ListItem>
        {hasSubitems && isItemExpanded && !isCollapsed && (
          <List
            size="sm"
            sx={{
              '--ListItem-radius': (theme) => theme.vars.radius.sm,
              '--List-gap': '4px',
              pl: 4,
            }}
          >
            {item.subitems?.map((subitem) => (
              <ListItem key={subitem.path}>
                <ListItemButton
                  onClick={() => navigate(subitem.path)}
                  selected={isActive(subitem.path)}
                  sx={{
                    gap: 1.5,
                    p: 2,
                    minHeight: 44,
                    color: isActive(subitem.path) ? 'primary.plainColor' : 'neutral.plainColor',
                    '&:hover': {
                      bgcolor: 'background.level1',
                    },
                    '&.Mui-selected': {
                      bgcolor: 'primary.softBg',
                      '&:hover': {
                        bgcolor: 'primary.softHoverBg',
                      },
                    },
                  }}
                >
                  <Typography
                    level="title-sm"
                    sx={{
                      color: isActive(subitem.path) ? 'primary.plainColor' : 'inherit',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {subitem.label}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </React.Fragment>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        p: 2,
        pt: 1,
        overflowY: 'auto',
      }}
    >
      <List
        size="sm"
        sx={{
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '--List-gap': '4px',
        }}
      >
        {menuItems.map(renderMenuItem)}
      </List>

      <List
        size="sm"
        sx={{
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '--List-gap': '8px',
          mt: 'auto',
          mb: 2,
        }}
      >
        <ListItem>
          {isCollapsed ? (
            <Tooltip title="My settings" placement="right">
              <ListItemButton
                onClick={() => navigate(`/user-settings/${currentUser?.uid}`)}
                selected={isActive('/settings')}
                sx={{
                  gap: 1.5,
                  p: 2,
                  minHeight: 44,
                  color: isActive('/settings') ? 'primary.plainColor' : 'neutral.plainColor',
                  '&:hover': {
                    bgcolor: 'background.level1',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'primary.softBg',
                    '&:hover': {
                      bgcolor: 'primary.softHoverBg',
                    },
                  },
                  justifyContent: 'center',
                }}
              >
                <SettingsRounded />
              </ListItemButton>
            </Tooltip>
          ) : (
            <ListItemButton
              onClick={() => navigate(`/user-settings/${currentUser?.uid}`)}
              selected={isActive('/settings')}
              sx={{
                gap: 1.5,
                p: 2,
                minHeight: 44,
                color: isActive('/settings') ? 'primary.plainColor' : 'neutral.plainColor',
                '&:hover': {
                  bgcolor: 'background.level1',
                },
                '&.Mui-selected': {
                  bgcolor: 'primary.softBg',
                  '&:hover': {
                    bgcolor: 'primary.softHoverBg',
                  },
                },
              }}
            >
              <SettingsRounded />
              <Typography
                level="title-sm"
                sx={{
                  color: isActive('/settings') ? 'primary.plainColor' : 'inherit',
                }}
              >
                My settings
              </Typography>
            </ListItemButton>
          )}
        </ListItem>
      </List>
    </Box>
  );
});

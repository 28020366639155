import { FC, useState, useEffect } from 'react';
import { Badge, IconButton, Tooltip } from '@mui/joy';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useNotificationDrawerStore } from '../../store/notification-drawer.store';
import { useGetUnreadLogsCount } from '../../api/useGetLogsRead';
import { useSubscribeLogs } from '../../api/useSubscribeLogs';

export const NotificationBell: FC = () => {
  const [lastCount, setLastCount] = useState(0);
  const { isDrawerOpen, openDrawer, closeDrawer } = useNotificationDrawerStore();
  const { data: unreadCount = 0, isLoading } = useGetUnreadLogsCount();
  useSubscribeLogs();

  // TODO: Fix UI flash when initial load is first showing all notifications as unread
  // console.log('unreadCount isLoading', isLoading, unreadCount);

  useEffect(() => {
    if (!isLoading) setLastCount(unreadCount);
  }, [unreadCount]);

  return (
    <Tooltip title="Notifications" placement="bottom">
      <IconButton
        variant="plain"
        color="neutral"
        onClick={isDrawerOpen ? closeDrawer : openDrawer}
        sx={{
          position: 'relative',
          '&:hover': { bgcolor: 'background.level1' },
        }}
      >
        <Badge badgeContent={!isLoading ? unreadCount : lastCount} color="danger" invisible={false}>
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

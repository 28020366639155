import { Company } from '@bluebird-monorepo/types';
import { TrendingUp } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/joy';
import React from 'react';
import { CompanyMetrics } from '../../CompanyMetrics';

interface GeneralTabProps {
  company: Company;
}

const MetricsCard: React.FC<{ title: string; value: string | number; icon: React.ReactNode; trend?: string }> = ({
  title,
  value,
  icon,
  trend,
}) => (
  <Card variant="soft" sx={{ height: '100%' }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box sx={{ mr: 1 }}>{icon}</Box>
        <Typography level="body-sm">{title}</Typography>
      </Box>
      <Typography level="h3" sx={{ mb: trend ? 1 : 0 }}>
        {value}
      </Typography>
      {trend && (
        <Typography level="body-sm" color="success" startDecorator={<TrendingUp />}>
          {trend}
        </Typography>
      )}
    </CardContent>
  </Card>
);

export const GeneralTab: React.FC<GeneralTabProps> = ({ company }) => {
  return <CompanyMetrics company={company} />;
};

import { RichTextEditor } from '@bluebird-monorepo/bluebird-forms';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
} from '@mui/joy';
import { FC, useCallback, useState } from 'react';

interface IntroductionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (content: string) => Promise<void>;
  initialContent?: string;
  candidateId: number;
  jobId: number;
}

export const IntroductionModal: FC<IntroductionModalProps> = ({
  isOpen,
  onClose,
  onSave,
  initialContent = '',
  candidateId,
  jobId,
}) => {
  const [content, setContent] = useState(initialContent);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = useCallback(async () => {
    if (!content.trim()) return;

    try {
      setIsSaving(true);
      await onSave(content);
      onClose();
    } catch (error) {
      console.error('Failed to save introduction:', error);
      // TODO: Add error handling UI
    } finally {
      setIsSaving(false);
    }
  }, [content, onSave, onClose]);

  return (
    <Modal onClose={onClose} open={isOpen} sx={{ maxWidth: 800 }}>
      <ModalOverflow>
        <ModalDialog layout="fullscreen" sx={{ padding: 3 }} variant="outlined">
          <ModalClose sx={{ m: 1 }} variant="plain" />
          <Typography level="h3">Introduce Candidate to Client</Typography>
          <Divider inset="none" />
          <Box sx={{ position: 'relative', minHeight: 400 }}>
            {isSaving && (
              <Box
                sx={{
                  alignItems: 'center',
                  bgcolor: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  left: 0,
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  zIndex: 1300,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <RichTextEditor content={content} onChange={setContent} showMenuBar />
          </Box>
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button color="neutral" onClick={onClose} variant="plain" disabled={isSaving}>
              Cancel
            </Button>
            <Button onClick={handleSave} disabled={!content.trim() || isSaving} loading={isSaving}>
              Save Introduction
            </Button>
          </Stack>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

import { ColumnDefinition } from '@bluebird-monorepo/bluebird-ui';
import { Avatar, Box, Typography, Stack, Link, Tooltip } from '@mui/joy';
import { format } from 'date-fns';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatFieldName } from '../../utils/log.utils';
import { Log } from '@bluebird-monorepo/types';

export function createColumns(): ColumnDefinition[] {
  return [
    {
      field: 'content',
      headerName: 'Content',
      width: '20%',
      renderCell: ({ row }) => <ContentCell content={row.content} />,
    },
    {
      field: 'changedFields',
      headerName: 'Changed Fields',
      width: '20%',
      renderCell: ({ row }) => {
        if (!row.changedFields || Object.keys(row.changedFields).length === 0) {
          if (row.action === 'INSERT') {
            if (row.tableName === 'assignments') {
              return (
                <Typography>
                  Assigned {row.newData?.candidateFirstName} {row.newData?.candidateLastName}
                </Typography>
              );
            }
            return <Typography>Created</Typography>;
          }
          return null;
        }
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {Object.entries(row.changedFields as Log['changedFields']).map(([field, value]) => {
              const formattedChange = `${JSON.stringify(value[0])} -> ${JSON.stringify(value[1])}`;
              return (
                <Tooltip title={`${formatFieldName(field)}: ${formattedChange}`} arrow>
                  <Typography
                    key={field}
                    level="body-sm"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%',
                    }}
                  >
                    <Typography component="span" fontWeight="bold" level="body-sm">
                      {formatFieldName(field)}
                    </Typography>
                    {(() => {
                      if (formattedChange.length > 50) {
                        return (
                          <>
                            <Typography component="span" level="body-sm" display="block" pl={1}>
                              From: {JSON.stringify(value[0])}
                            </Typography>
                            <Typography component="span" level="body-sm" display="block" pl={1}>
                              To: {JSON.stringify(value[1])}
                            </Typography>
                          </>
                        );
                      }
                      return `: ${formattedChange}`;
                    })()}
                  </Typography>
                </Tooltip>
              );
            })}
          </Box>
        );
      },
    },
    {
      field: 'tableName',
      headerName: 'Table',
      width: '10%',
      renderCell: ({ row }) => <Typography>{row.tableName}</Typography>,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: '10%',
      renderCell: ({ row }) => <Typography>{row.action}</Typography>,
    },
    {
      field: 'entityId',
      headerName: 'Entity ID',
      width: '10%',
      renderCell: ({ row }) => <Typography>{row.entityId}</Typography>,
    },
    {
      field: 'authorId',
      headerName: 'Changed By',
      width: '20%',
      renderCell: ({ row }) => <AuthorCell author={row.author} />,
    },
    {
      field: 'createdAt',
      headerName: 'Changed At',
      width: '15%',
      renderCell: ({ row }) => <Typography>{format(new Date(row.createdAt), 'MMM d, yyyy HH:mm')}</Typography>,
    },
  ];
}

const ContentCell = ({ content }: { content: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box>
      <Typography
        level="body-sm"
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? undefined : 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          whiteSpace: 'pre-wrap',
          mb: 0.5,
        }}
      >
        {content}
      </Typography>
      <Link
        component="button"
        level="body-xs"
        onClick={() => setIsExpanded(!isExpanded)}
        endDecorator={isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: 'primary.500',
          '&:hover': {
            textDecoration: 'none',
            color: 'primary.600',
          },
        }}
      >
        {isExpanded ? 'Show less' : 'Show more'}
      </Link>
    </Box>
  );
};

const AuthorCell = ({ author }: { author: any }) => {
  console.log('AuthorCell', author);
  return (
    <Stack
      direction="row"
      spacing={1.5}
      alignItems="center"
      sx={{
        p: 0.5,
        borderRadius: 'sm',
        '&:hover': {
          bgcolor: 'background.level1',
        },
      }}
    >
      <Avatar
        src={author?.photoUrl}
        alt={author?.displayName}
        variant="soft"
        size="sm"
        sx={{
          '--Avatar-size': '28px',
        }}
      />
      <Stack spacing={0}>
        <Typography
          level="body-sm"
          fontWeight="bold"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {author?.displayName || 'Unknown'}
        </Typography>
        {author?.email && (
          <Typography
            level="body-xs"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {author.email}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

import { Candidate } from '@bluebird-monorepo/types';
import { Edit } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

interface CandidateHeaderProps {
  candidate: Candidate;
}

export const CandidateHeader: React.FC<CandidateHeaderProps> = ({ candidate }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        bgcolor: 'primary.softBg',
        borderRadius: 'lg',
        p: 3,
        mb: 3,
        border: '1px solid',
        borderColor: 'primary.outlinedBorder',
        display: 'flex',
        gap: 3,
        justifyContent: 'space-between',
      }}
    >
      {/* Left side - Avatar and basic info */}
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box
          component="img"
          src={candidate.avatar}
          sx={{
            width: 80,
            height: 80,
            borderRadius: '50%',
            border: '4px solid white',
            boxShadow: 'sm',
          }}
        />
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
            <Typography level="h2">
              {candidate.firstName} {candidate.lastName}
            </Typography>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => navigate(`/candidates/${candidate.id}/edit`)}
              startDecorator={<Edit />}
              size="sm"
            >
              Edit
            </Button>
          </Box>
          <Typography level="body-lg" sx={{ color: 'text.secondary' }}>
            {candidate.currentPosition} {candidate.currentOrganization && `@ ${candidate.currentOrganization}`}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
            {candidate.score !== undefined && (
              <Typography
                level="body-sm"
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 'sm',
                  bgcolor:
                    candidate.score >= 70
                      ? 'success.softBg'
                      : candidate.score >= 40
                        ? 'warning.softBg'
                        : 'neutral.softBg',
                  color:
                    candidate.score >= 70
                      ? 'success.solidBg'
                      : candidate.score >= 40
                        ? 'warning.solidBg'
                        : 'neutral.solidBg',
                }}
              >
                Score: {candidate.score}/100
              </Typography>
            )}
            {candidate.city && (
              <Typography level="body-sm" sx={{ px: 1, py: 0.5, borderRadius: 'sm', bgcolor: 'neutral.softBg' }}>
                {candidate.city}
              </Typography>
            )}
            {candidate.createdBy && (
              <Typography level="body-sm" sx={{ px: 1, py: 0.5, borderRadius: 'sm', bgcolor: 'neutral.softBg' }}>
                Owner: {parseCreatedBy(candidate.createdBy)}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      {/* Right side - Timeline */}
      <Box sx={{ width: '400px' }}>
        <Typography level="body-sm" sx={{ color: 'neutral.500', mb: 1 }}>
          Recent Activity
        </Typography>
        {/* <TimelineCompact entityType="candidates" timeline={candidate.timeline} /> */}
      </Box>
    </Box>
  );
};

const parseCreatedBy = (createdBy: string): string => {
  if (createdBy.includes('@')) {
    const namePart = createdBy.split('@')[0];
    return namePart
      .split(/[.\s]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  } else {
    return createdBy
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
};

import { ArrowLeft, ArrowRight, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/joy';
import { FC, useRef, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

interface PDFViewerProps {
  file: File | string;
  initialHeight?: number;
}

const PDFViewer: FC<PDFViewerProps> = ({ file, initialHeight = 800 }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageHeight, setPageHeight] = useState(initialHeight);
  const [scale, setScale] = useState(1.0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setLoading(false);
  };

  const onDocumentLoadError = (err: Error) => {
    setError(err);
    setLoading(false);
  };

  const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  // const zoomIn = () => {
  //   setScale((prevScale) => Math.min(prevScale + 0.2, 3.0));
  // };

  // const zoomOut = () => {
  //   setScale((prevScale) => Math.max(prevScale - 0.2, 0.5));
  // };

  // Ensure container is scrolled to top on page changes
  useEffect(() => {
    if (containerRef.current && !loading) {
      containerRef.current.scrollTop = 0;
    }
  }, [pageNumber, loading]);

  // Adjust page height based on container size
  useEffect(() => {
    if (containerRef.current && !loading) {
      const updatePageHeight = () => {
        const containerHeight = containerRef.current?.clientHeight || 0;
        // Set the page height to be slightly smaller than container to leave minimal padding
        setPageHeight(containerHeight > 100 ? containerHeight - 40 : initialHeight);
      };

      updatePageHeight();
      window.addEventListener('resize', updatePageHeight);

      return () => {
        window.removeEventListener('resize', updatePageHeight);
      };
    }
  }, [loading, initialHeight]);

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', p: 3, color: 'danger.500' }}>
        <Typography level="title-lg">Failed to load PDF</Typography>
        <Typography level="body-sm">{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        position: 'relative',
        backgroundColor: '#FFFFFF',
      }}
    >
      {/* Controls Bar */}
      {!loading && (
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            bgcolor: '#F9FAFB',
            borderBottom: '1px solid',
            borderColor: 'divider',
            p: 1.5,
            boxShadow: 'sm',
          }}
        >
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography level="body-md" sx={{ fontWeight: 'md' }}>
                Page {pageNumber} of {numPages}
              </Typography>
              <Stack direction="row" gap={1}>
                <IconButton onClick={goToPrevPage} variant="soft" color="neutral" size="sm" disabled={pageNumber <= 1}>
                  <ArrowLeft />
                </IconButton>
                <IconButton
                  onClick={goToNextPage}
                  variant="soft"
                  color="neutral"
                  size="sm"
                  disabled={pageNumber >= numPages}
                >
                  <ArrowRight />
                </IconButton>
              </Stack>
            </Stack>
            {/* <Stack direction="row" spacing={1}>
              <IconButton onClick={zoomOut} variant="soft" color="neutral" size="sm">
                <ZoomOut />
              </IconButton>
              <IconButton onClick={zoomIn} variant="soft" color="neutral" size="sm">
                <ZoomIn />
              </IconButton>
            </Stack> */}
          </Stack>
        </Box>
      )}

      {/* PDF Container */}
      <Box
        ref={containerRef}
        sx={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 2,
          bgcolor: '#F6F8FA',
          height: '100%',
          pt: 1,
          pb: 1,
          '& .react-pdf__Document': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 0,
            margin: '0 auto',
            height: '100%',
            justifyContent: 'center',
          },
          '& .react-pdf__Page': {
            maxWidth: '100%',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
            backgroundColor: 'white',
            margin: 0,
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
          },
          '& .react-pdf__Page__canvas': {
            width: '100% !important',
            height: 'auto !important',
            maxWidth: '100%',
            display: 'block',
            outline: 'none',
            borderRadius: '4px',
          },
          '& .pdf-page': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'auto',
            maxHeight: '100%',
          },
        }}
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                minHeight: '300px',
                width: '100%',
                gap: 2,
              }}
            >
              <CircularProgress size="lg" color="neutral" />
              <Typography level="body-sm" color="neutral">
                Loading PDF...
              </Typography>
            </Box>
          }
          error={
            <Box sx={{ textAlign: 'center', p: 3, color: 'danger.500' }}>
              <Typography color="danger" level="body-md">
                Error loading PDF
              </Typography>
            </Box>
          }
        >
          {!loading && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Page
                height={pageHeight}
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                className="pdf-page"
                scale={scale}
              />
            </Box>
          )}
        </Document>
      </Box>
    </Box>
  );
};

export { PDFViewer };

import { InsertAutocomplete } from '@bluebird-monorepo/bluebird-forms';
import { useGetCompanyById } from '@bluebird-monorepo/companies';
import { Job } from '@bluebird-monorepo/types';
import { languages } from '@bluebird-monorepo/utils';
import { Box, Checkbox, FormControl, FormHelperText, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

type JobFormData = Omit<Job, 'openSinceDate'> & {
  openSinceDate?: Date | undefined;
};

export function DetailsStep() {
  const {
    register,
    setValue,
    watch,
    clearErrors,
    control,
    formState: { errors, dirtyFields },
    getValues,
  } = useFormContext<JobFormData>();

  const [jobLanguages, setJobLanguages] = useState<string[]>(getValues('preferredLanguages') || []);

  const openSinceDate = watch('openSinceDate');
  const companyId = watch('companyId');
  const { data: company } = useGetCompanyById(companyId);

  const relocation = watch('relocation');
  const visaSponsorship = watch('visaSponsorship');

  // Prefill fee percentage from company when creating a new job
  useEffect(() => {
    if (company && !getValues('feePercentage')) {
      setValue('feePercentage', company.agreedFee);
    }
  }, [company, setValue, getValues]);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date && date.isValid()) {
      clearErrors('openSinceDate');
      const timestamp = date.startOf('day').toDate();
      setValue('openSinceDate', timestamp);
    } else {
      setValue('openSinceDate', undefined, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const handleChange = (field: string, value: any) => {
    if (field === 'preferredLanguages') {
      setJobLanguages(value);
    }
    setValue(field as any, value);
  };

  // Convert the timestamp-like object to dayjs for the DateCalendar
  const dateValue = openSinceDate ? dayjs(openSinceDate) : null;

  return (
    <Stack spacing={3} sx={{ maxWidth: '80%' }}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Box component="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 0.5 }}>
            Job Details
          </Box>
          <Box sx={{ color: 'text.secondary' }}>Specify salary, requirements, and technical details</Box>
        </Box>
      </Box>

      <FormControl error={!!errors.openSinceDate && !!dirtyFields.openSinceDate}>
        <FormLabel>Open Since Date</FormLabel>
        <DateCalendar
          value={dateValue}
          onChange={handleDateChange}
          views={['year', 'month', 'day']}
          disableFuture
          sx={{ width: '100%' }}
        />
        {errors.openSinceDate && dirtyFields.openSinceDate && (
          <FormHelperText>{errors.openSinceDate.message}</FormHelperText>
        )}
      </FormControl>

      <Stack spacing={2}>
        <FormLabel>Salary Range</FormLabel>
        <Stack direction="row" spacing={2}>
          <FormControl error={!!errors.salaryRange?.min && !!dirtyFields.salaryRange?.min}>
            <Input
              {...register('salaryRange.min', {
                setValueAs: (value) => (value === '' ? undefined : Number(value)),
              })}
              type="number"
              placeholder="Min"
              startDecorator="€"
            />
            {errors.salaryRange?.min && dirtyFields.salaryRange?.min && (
              <FormHelperText>{errors.salaryRange.min.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={!!errors.salaryRange?.max && !!dirtyFields.salaryRange?.max}>
            <Input
              {...register('salaryRange.max', {
                setValueAs: (value) => (value === '' ? undefined : Number(value)),
              })}
              type="number"
              placeholder="Max"
              startDecorator="€"
            />
            {errors.salaryRange?.max && dirtyFields.salaryRange?.max && (
              <FormHelperText>{errors.salaryRange.max.message}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </Stack>

      <FormControl error={!!errors.feePercentage && !!dirtyFields.feePercentage} required>
        <FormLabel>Fee Percentage</FormLabel>
        <Input
          {...register('feePercentage', {
            setValueAs: (value) => (value === '' ? 0 : Number(value)),
          })}
          type="number"
          endDecorator="%"
          placeholder="Prefilled from company's agreed fee"
        />
        {errors.feePercentage && dirtyFields.feePercentage && (
          <FormHelperText>{errors.feePercentage.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={!!errors.preferredLanguages && !!dirtyFields.preferredLanguages}>
        <FormLabel>Preferred Languages</FormLabel>
        <InsertAutocomplete<{ preferredLanguages: string[] }>
          control={control as any}
          name="preferredLanguages"
          options={languages.map((lang) => lang.name)}
          groupBy={(option) => {
            const language = languages.find((lang) => lang.name === option);
            return language?.region || '';
          }}
          onChange={(value) => {
            const uniqueLanguages = new Set([...jobLanguages, value[value.length - 1]]);
            handleChange('preferredLanguages', Array.from(uniqueLanguages));
          }}
          onSubmit={(value) => {
            const uniqueLanguages = new Set([...jobLanguages, value]);
            handleChange('preferredLanguages', Array.from(uniqueLanguages));
          }}
          onDelete={(value) => {
            const uniqueLanguages = new Set(jobLanguages?.filter((lang) => lang !== value));
            handleChange('preferredLanguages', Array.from(uniqueLanguages));
          }}
        />
        {errors.preferredLanguages && dirtyFields.preferredLanguages && (
          <FormHelperText>{errors.preferredLanguages.message}</FormHelperText>
        )}
      </FormControl>

      <Stack direction="row" spacing={2} width="100%">
        <FormControl sx={{ width: '50%' }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox {...register('relocation')} checked={relocation} />
            <Typography>Offers Relocation</Typography>
          </Stack>
        </FormControl>

        <FormControl sx={{ width: '50%' }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox {...register('visaSponsorship')} checked={visaSponsorship} />
            <Typography>Provides Visa Sponsorship</Typography>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
}

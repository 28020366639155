import { TaskboardEntityType, TaskboardItem } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@bluebird-monorepo/types';

interface UseGetTasksOptions {
  entityType?: TaskboardEntityType;
  entityId?: number;
}

type UseGetTasksQuery = PostgrestFilterBuilder<Database['public'], Record<keyof TaskboardItem, unknown>, TaskboardItem>;

export function useGetTasks(options: UseGetTasksOptions) {
  return useQuery<TaskboardItem[]>({
    queryKey: ['tasks', options],
    queryFn: async () => {
      const query = supabase.from('tasks').select('*').returns<TaskboardItem>() as UseGetTasksQuery;
      applyEntityFilters(query, options);
      const { data } = await query;
      return data ? toCamelCase<TaskboardItem[]>(data) : [];
    },
  });
}

function applyEntityFilters(query: UseGetTasksQuery, options: UseGetTasksOptions) {
  const { entityType, entityId } = options;
  if (entityType) {
    query.eq('entity_type', entityType);
  }
  if (entityId) {
    query.eq('entity_id', entityId);
  }
  return query;
}

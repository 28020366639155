import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Option from '@mui/joy/Option';
import Select, { selectClasses } from '@mui/joy/Select';
import { useEffect, useRef, useState } from 'react';

import { useUpsertJob } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import './ResponsibleColumn.css';
import { parseResponsibleEmail } from '@bluebird-monorepo/shared';

const responsibleMapping = {
  Dennis: {
    colorClass: 'mint-green',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U05QQC9SJ1W-8fccbf482c28-512',
    name: 'Dennis',
    email: 'dennis@bluebirdrecruitment.com',
  },
  Jacob: {
    colorClass: 'blush-pink',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U06T7S16K6W-ab5ce9b172f2-192',
    name: 'Jacob',
    email: 'jacob@bluebirdrecruitment.com',
  },
  Mitchel: {
    colorClass: 'pale-lavender',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U07RZ07JSDP-d3370936361e-192',
    name: 'Mitchel',
    email: 'mitchel@bluebirdrecruitment.com',
  },
  Pontus: {
    colorClass: 'powder-blue',
    imgUrl: 'https://ca.slack-edge.com/T015Y24FVU0-U075S4N9M6V-dec8ff679f17-192',
    name: 'Pontus',
    email: 'pontus@bluebirdrecruitment.com',
  },
} as const;

type ResponsibleName = keyof typeof responsibleMapping;

export function ResponsibleColumn({ job }: { job: Job }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Handle both string and array values
  const currentResponsible = (() => {
    if (Array.isArray(job.responsible)) {
      return job.responsible;
    }
    // Handle legacy comma-separated values
    const responsible = job.responsible?.split(',');
    return responsible ? responsible.map((responsible) => responsible.trim()) : [];
  })();

  const { mutate: updateJobMutation } = useUpsertJob();
  // const possibleResponsibles: any[] = [];
  const ref = useRef<HTMLTableCellElement>(null);
  const selectRef = useRef<HTMLButtonElement>(null);
  const listboxRef = useRef<HTMLUListElement | null>(null);

  const handleChipClick = () => {
    setIsEditing(true);
  };

  const handleResponsibleChange = async (_event: any, newValue: string[] | null) => {
    if ((newValue && newValue.length > 0) || !!currentResponsible[0]) {
      await updateJobMutation({
        id: job.id,
        responsible: newValue?.filter((responsible) => responsible !== '').join(','),
      });
    }
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (!isEditing) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [isEditing]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isEditing) return;

      setTimeout(() => {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          selectRef.current &&
          !selectRef.current.contains(event.target as Node) &&
          listboxRef.current &&
          !listboxRef.current.contains(event.target as Node)
        ) {
          setIsEditing(false);
        }
      }, 100);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const listbox = document.querySelector('[role="listbox"]') as HTMLUListElement;
          if (listbox) {
            listboxRef.current = listbox;
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <span ref={ref}>
      {isEditing ? (
        <Select
          disabled={isLoading}
          multiple
          component="button"
          autoFocus
          listboxOpen={isEditing}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={handleResponsibleChange}
          onClose={handleClose}
          ref={selectRef}
          size="sm"
          value={currentResponsible}
          placeholder="None"
          slotProps={{
            listbox: {
              ref: listboxRef,
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
              },
            },
          }}
          sx={{
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
          }}
        >
          {Object.values(responsibleMapping).map((responsible) => (
            <Option key={responsible.name} value={responsible.name}>
              {responsible.name}
            </Option>
          ))}
        </Select>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, pl: '1rem' }}>
          <div className="avatar-stack">
            {currentResponsible.length > 0
              ? currentResponsible.map((responsible) => {
                  const responsibleName = responsibleMapping[responsible as ResponsibleName]
                    ? responsible
                    : parseResponsibleEmail(responsible);
                  return responsibleMapping[responsibleName as ResponsibleName] ? (
                    <img
                      key={responsible}
                      alt={responsibleMapping[responsibleName as ResponsibleName]?.name}
                      onClick={handleChipClick}
                      src={responsibleMapping[responsibleName as ResponsibleName]?.imgUrl}
                    />
                  ) : (
                    <Chip
                      key={responsible}
                      onClick={handleChipClick}
                      size="sm"
                      sx={{ cursor: 'pointer' }}
                      variant="outlined"
                    >
                      {parseResponsibleEmail(responsible) || 'None'}
                    </Chip>
                  );
                })
              : null}
          </div>
        </Box>
      )}
    </span>
  );
}

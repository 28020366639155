import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Paper, Button, Box, styled, MenuItem, Menu, IconButton, Divider } from '@mui/material';
import { useSelectionStore } from '../store/useSelectionStore';

const MenuContainer = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: 24,
  minWidth: 320,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  gap: theme.spacing(2),
}));

const SelectionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[50],
  overflow: 'hidden',
}));
interface SelectionActionMenuProps {
  selectedCount?: number;
  columnTitles: string[];
  onStatusChange: (newStatus: string) => void;
}

const SelectionActionMenu: React.FC<SelectionActionMenuProps> = ({ selectedCount, onStatusChange, columnTitles }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const clearSelection = useSelectionStore((state) => state.clearSelection);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuContainer>
      <ContentWrapper>
        <SelectionBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              px: 1.5,
              py: 0.5,
              fontSize: '0.875rem',
            }}
          >
            <span style={{ fontWeight: 500 }}>{selectedCount}</span>
            <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Selected</span>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ my: 0.5 }} />
          <IconButton
            sx={{
              borderRadius: 0,
              px: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
            size="small"
            onClick={clearSelection}
            aria-label="clear selection"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </SelectionBox>

        <Divider orientation="vertical" flexItem />

        <Button
          id="basic-button"
          variant="contained"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
            textTransform: 'none',
            px: 2,
            py: 0.75,
            borderRadius: 2,
          }}
        >
          Move to status
          <AddCircleIcon fontSize="small" />
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {columnTitles.map((title) => (
            <MenuItem
              key={title}
              onClick={() => {
                onStatusChange(title);
                handleClose();
              }}
            >
              {title}
            </MenuItem>
          ))}
        </Menu>
      </ContentWrapper>
    </MenuContainer>
  );
};

export default SelectionActionMenu;

import { Note } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertNote() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (note: Partial<Note>) => {
      const { data, error } = await supabase
        .from('notes')
        .upsert(toSnakeCase(note), {
          onConflict: 'id',
        })
        .select()
        .single();
      if (error) throw error;
      return toCamelCase<Note>(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes'] });
    },
  });
}

import { User } from '@bluebird-monorepo/types';
import { Box, Option, Select } from '@mui/joy';
import { memo, useCallback, useMemo } from 'react';
import { useGetUsers } from '@bluebird-monorepo/users';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { UserAvatarStack } from '../UserAvatarStack/UserAvatarStack';

type SingleSelectProps = {
  multiple?: false;
  value: User | string | null;
  onChange: (value: string | null) => void;
  size?: number;
};

type MultiSelectProps = {
  multiple: true;
  value: (User | string | null)[];
  onChange: (value: string[]) => void;
  size?: number;
};

export type UserSelectProps = SingleSelectProps | MultiSelectProps;

function UserSelectComponent(props: UserSelectProps) {
  const { data: users = [] } = useGetUsers();
  const { value, onChange, multiple = false, size = 40 } = props;

  const handleChange = useCallback(
    (_: any, newValue: string | string[] | null) => {
      console.log('UserSelect - handleChange:', newValue);
      if (multiple) {
        (onChange as MultiSelectProps['onChange'])((newValue as string[]) || []);
      } else {
        (onChange as SingleSelectProps['onChange'])(newValue as string | null);
      }
    },
    [onChange, multiple],
  );

  const currentValue = useMemo(() => {
    if (multiple) {
      const values = Array.isArray(value) ? value : [];
      return values
        .map((user) => (typeof user === 'string' ? user : user?.displayName || user?.email || ''))
        .filter(Boolean);
    }

    const singleValue = Array.isArray(value) ? value[0] : value;
    return singleValue
      ? typeof singleValue === 'string'
        ? singleValue
        : singleValue.displayName || singleValue.email || ''
      : '';
  }, [value, multiple]);

  const renderValue = useCallback(() => {
    if (multiple) {
      const values = Array.isArray(value) ? value : [];
      return <UserAvatarStack users={values} size={size} />;
    }
    return <UserAvatar user={Array.isArray(value) ? value[0] : value} size={size} />;
  }, [value, multiple, size]);

  return (
    <Select
      multiple={multiple}
      renderValue={renderValue}
      onChange={handleChange}
      value={currentValue}
      variant="plain"
      sx={{ minWidth: 'unset' }}
    >
      {!multiple && <Option value="">None</Option>}
      {users.map((user) => (
        <Option key={user.id} value={user.displayName || user.email}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <UserAvatar user={user} size={24} />
            {user.displayName || user.email}
          </Box>
        </Option>
      ))}
    </Select>
  );
}

export const UserSelect = memo(UserSelectComponent);

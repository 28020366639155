import { Job } from '@bluebird-monorepo/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SortField = keyof Pick<Job, 'jobTitle' | 'responsible' | 'status' | 'priorityOverride'>;

export type PriorityFilterValue = '' | '1' | '2' | '3' | '4';

export interface SortConfig {
  field: SortField;
  direction: 'asc' | 'desc';
}

export interface JobsTableFilters {
  companyName: string;
  feeGt: string;
  feeLt: string;
  filterName: string;
  location: string;
  responsible: string;
  salaryGt: string;
  salaryLt: string;
  statusName: string[];
  workingOn: string[];
  vertical: string;
  priority: PriorityFilterValue;
}

export interface JobsTableState {
  sortConfig: SortConfig;
  setSortConfig: (sortConfig: SortConfig) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  search: string;
  setSearch: (search: string) => void;
  page: number;
  setPage: (page: number) => void;
  filters: JobsTableFilters;
  setFilters: (filters: JobsTableFilters) => void;
}

export const useJobsTableStore = create<JobsTableState>()(
  persist(
    (set) => ({
      sortConfig: {
        field: 'priorityOverride',
        direction: 'asc',
      },
      setSortConfig: (sortConfig: SortConfig) => set({ sortConfig }),
      rowsPerPage: 10,
      setRowsPerPage: (rowsPerPage: number) => set({ rowsPerPage }),
      search: '',
      setSearch: (search: string) => set({ search }),
      page: 1,
      setPage: (page: number) => set({ page }),
      filters: {
        companyName: '',
        feeGt: '',
        feeLt: '',
        filterName: '',
        location: '',
        responsible: '',
        salaryGt: '',
        salaryLt: '',
        statusName: [],
        workingOn: [],
        vertical: '',
        priority: '',
      },
      setFilters: (filters) => set({ filters }),
    }),
    {
      name: 'jobs-table',
    },
  ),
);

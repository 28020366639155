import { useGetCurrentUser, useUpsertUser } from '@bluebird-monorepo/users';
import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Typography } from '@mui/joy';
import { FC, useEffect, useState } from 'react';
import { Layout } from '../../../layout/Layout';
import { User } from '@bluebird-monorepo/types';

const UserSettingsPage: FC = () => {
  const [error, setError] = useState<null | string>(null);
  const { data: user, isLoading } = useGetCurrentUser();
  const { mutate: upsertUser, isPending: isUpserting } = useUpsertUser();

  const [localStateUser, setLocalStateUser] = useState<User | null>(user || null);
  const { displayName, recruitCrmId, calendlyUrl, firefliesApiKey, answerNumber, email } = localStateUser || {};

  useEffect(() => {
    console.log('user', user);
    setLocalStateUser(user || null);
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!localStateUser) return;
    setLocalStateUser({ ...localStateUser, [name]: value });
  };

  const handleSave = async () => {
    console.log(user);
    if (!user?.id) return;

    try {
      const data = {
        displayName: displayName || '',
        recruitCrmId: recruitCrmId || '',
        calendlyUrl: calendlyUrl || '',
        firefliesApiKey: firefliesApiKey || '',
        answerNumber: answerNumber || '',
        email: email || '',
      };
      await upsertUser({
        id: user.id,
        ...data,
      });
    } catch (error) {
      setError('Failed to update user settings.');
      console.error(error);
    }
  };

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="danger">{error}</Typography>;

  return (
    <Layout title="My Settings">
      <Box sx={{ maxWidth: 600, mt: 4 }}>
        {user && (
          <form>
            <FormControl>
              <FormLabel>Display Name</FormLabel>
              <Input
                name="displayName"
                onChange={handleInputChange}
                placeholder="Enter your display name..."
                value={displayName || ''}
              />
            </FormControl>

            <FormControl sx={{ my: 2 }}>
              <FormLabel>Recruit CRM ID</FormLabel>
              <Input
                name="recruitCrmId"
                onChange={handleInputChange}
                placeholder="Enter your Recruit CRM ID..."
                value={recruitCrmId || ''}
              />
              <FormHelperText>Make sure this is correct, if not - there is no syncing into RecruitCRM.</FormHelperText>
            </FormControl>

            <FormControl sx={{ my: 2 }}>
              <FormLabel>Calendly URL</FormLabel>
              <Input
                name="calendlyUrl"
                onChange={handleInputChange}
                placeholder="Enter your Calendly URL..."
                value={calendlyUrl || ''}
              />
              <FormHelperText>Your Calendly URL for scheduling meetings</FormHelperText>
            </FormControl>

            <FormControl sx={{ my: 2 }}>
              <FormLabel>Answer Number</FormLabel>
              <Input
                name="answerNumber"
                onChange={handleInputChange}
                placeholder="Enter your [a][number]... example a1"
                value={answerNumber || ''}
              />
              <FormHelperText>
                In Calendly, go to Event Types → Click on the event type → Click on booking page options → Your
                questions are numbered by Q[number]. You should use [a][number], so Q1 becomes a1 and so forth.
              </FormHelperText>
            </FormControl>

            <FormControl sx={{ my: 2 }}>
              <FormLabel>Fireflies API Key</FormLabel>
              <Input
                name="firefliesApiKey"
                type="password"
                onChange={handleInputChange}
                placeholder="Enter your Fireflies API key..."
                value={firefliesApiKey || ''}
              />
              <FormHelperText>API key for Fireflies.ai integration</FormHelperText>
            </FormControl>

            <Button color="primary" onClick={handleSave} loading={isUpserting} disabled={isUpserting}>
              Save Changes
            </Button>
          </form>
        )}
      </Box>
    </Layout>
  );
};

export default UserSettingsPage;

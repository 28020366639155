import { useEffect } from 'react';
import { supabase } from '@bluebird-monorepo/supabase';
import { useGetUnreadLogsCount } from './useGetLogsRead';
import { useGetLogsWithRelated } from './useGetLogs';

export function useSubscribeLogs() {
  const { refetch: refetchCount } = useGetUnreadLogsCount();
  const { refetch: refetchLogs } = useGetLogsWithRelated();
  useEffect(() => {
    const channel = supabase
      .channel('table_db_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'logs',
        },
        (payload) => {
          refetchCount();
          refetchLogs();
        },
      )
      .subscribe();
    return () => {
      channel.unsubscribe();
    };
  }, []);
}

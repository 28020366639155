import { Candidate } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';

export function useGetCandidateById(id: number) {
  return useQuery<Candidate | null>({
    enabled: !!id,
    queryKey: ['candidateById', id],
    queryFn: async () => {
      const { data } = await supabase.from('candidates').select('*').eq('id', id).single();
      const camelCaseData = data ? toCamelCase<Candidate>(data) : null;
      return fixStringsToArrays(camelCaseData);
    },
  });
}

export function useGetCandidateByFirstAndLastName(firstName: string, lastName: string) {
  return useQuery<Candidate | null>({
    enabled: !!firstName && !!lastName,
    queryKey: ['candidateByFirstAndLastName', firstName, lastName],
    queryFn: async () => {
      const { data } = await supabase
        .from('candidates')
        .select('*')
        .ilike('first_name', `%${firstName}%`)
        .ilike('last_name', `%${lastName}%`)
        .single();
      const camelCaseData = data ? toCamelCase<Candidate>(data) : null;
      return fixStringsToArrays(camelCaseData);
    },
  });
}

function fixStringsToArrays(candidate: Candidate | null) {
  if (!candidate) return null;
  if (typeof candidate?.potentialRoles === 'string' && candidate.potentialRoles !== 'null') {
    const validRoles = (candidate.potentialRoles as string).match(/[a-zA-Z0-9_\- ]+/g);
    candidate.potentialRoles = validRoles || [];
  } else {
    candidate.potentialRoles = [];
  }

  if (typeof candidate?.techStack === 'string' && candidate.techStack !== 'null') {
    const validTech = (candidate.techStack as string).match(/[a-zA-Z0-9_\- ]+/g);
    candidate.techStack = validTech || [];
  } else {
    candidate.techStack = [];
  }
  return candidate;
}

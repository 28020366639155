import { Introduction } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@bluebird-monorepo/types';
import { snakeCase } from 'lodash';

interface PaginatedResponse {
  introductions: Introduction[];
  count: number;
}

interface UseGetIntroductionsOptions {
  page: number;
  pageSize: number;
  sortField?: keyof Pick<Introduction, 'createdAt'>;
  sortDirection?: 'asc' | 'desc';
  candidateId?: number;
  jobId?: number;
  authorId?: number | null;
  search?: string;
}

type UseGetIntroductionsQuery = PostgrestFilterBuilder<
  Database['public'],
  Record<keyof Introduction, unknown>,
  Introduction
>;

export function useGetIntroductions(options: UseGetIntroductionsOptions) {
  return useQuery<PaginatedResponse>({
    queryKey: ['introductions', { ...options }],
    refetchOnMount: true,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const selectQuery = getSelectQuery(options);
      const countQuery = getCountQuery(options);
      const [{ data }, { count }] = await Promise.all([selectQuery, countQuery]);
      return {
        introductions: data ? toCamelCase<Introduction[]>(data) : [],
        count: count || 0,
      };
    },
  });
}

// Base query for introductions
function getIntroductionsQuery(selectOptions?: { count: 'exact' | 'planned' | 'estimated'; head: boolean }) {
  return supabase.from('introductions').select('*', selectOptions).returns<Introduction>() as UseGetIntroductionsQuery;
}

function getSelectQuery(options: UseGetIntroductionsOptions) {
  const selectQuery = getIntroductionsQuery();
  applyFilters(selectQuery, options);
  applySorting(selectQuery, options);
  applyPagination(selectQuery, options);
  return selectQuery;
}

function getCountQuery(options: UseGetIntroductionsOptions) {
  const countQuery = getIntroductionsQuery({ count: 'exact', head: true });
  applyFilters(countQuery, options);
  return countQuery;
}

function applyFilters(query: UseGetIntroductionsQuery, options: UseGetIntroductionsOptions) {
  const { candidateId, jobId, authorId, search } = options;
  if (candidateId) {
    query.eq('candidate_id', candidateId);
  }
  if (jobId) {
    query.eq('job_id', jobId);
  }
  if (authorId) {
    query.eq('author_id', authorId);
  }
  if (search) {
    query.or(`content.ilike.%${search}%`);
  }
  return query;
}

function applySorting(query: UseGetIntroductionsQuery, options: UseGetIntroductionsOptions) {
  const { sortField, sortDirection } = options;
  if (sortField && sortDirection) {
    const field = snakeCase(sortField);
    query.order(field, { ascending: sortDirection === 'asc' });
    query.not(field, 'is', null);
  }
}

function applyPagination(query: UseGetIntroductionsQuery, options: UseGetIntroductionsOptions) {
  const { page, pageSize } = options;
  const start = (page - 1) * pageSize;
  const end = start + pageSize - 1;
  query.range(start, end);
}

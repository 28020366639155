import { Box, IconButton, Sheet, Typography, Tooltip, CircularProgress } from '@mui/joy';
import { Close } from '@mui/icons-material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { FC, useCallback } from 'react';
import { NotificationCard } from './NotificationCard';
import { useGetLogsWithRelated } from '../../api/useGetLogs';
import { useNavigate, useLocation } from 'react-router-dom';
import { Log, LogRelated } from '@bluebird-monorepo/types';
import { useMarkLogRead, useMarkAllLogsRead, useMarkRelatedLogsRead } from '../../api/useUpsertLogsRead';
import { useNotificationDrawerStore } from '../../store/notification-drawer.store';
import { useInView } from 'react-intersection-observer';
import { useGetUnreadLogsCount } from '../../api/useGetLogsRead';

interface NotificationDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const NotificationDrawer: FC<NotificationDrawerProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: markLogRead } = useMarkLogRead();
  const { mutate: markAllRead, isLoading: isMarkingAllRead } = useMarkAllLogsRead();
  const { mutate: markRelatedLogsRead } = useMarkRelatedLogsRead();
  const { expandedId, activeId, setExpandedId, setActiveId, closeDrawer } = useNotificationDrawerStore();
  const { data: unreadCount = 0 } = useGetUnreadLogsCount();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, isLoading } = useGetLogsWithRelated();

  // Setup intersection observer for infinite scrolling
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  // Fetch next page when the loadMoreRef element comes into view
  const handleLoadMore = useCallback(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  if (inView) {
    handleLoadMore();
  }

  const handleNavigateToEntity = (log: Log) => {
    const routeMap: Record<string, string> = {
      jobs: '/jobs',
      candidates: '/candidates',
      companies: '/companies',
      teams: '/teams',
      users: '/users',
      introductions: '/introductions',
      placements: '/placements',
      assignments: '/pipeline/team',
      calendar_events: '/calendar',
      tasks: '/tasks',
    };
    const baseRoute = routeMap[log.tableName];
    if (baseRoute) {
      // For calendar_events and tasks, we don't have detail pages
      if (log.tableName === 'calendar_events' || log.tableName === 'tasks' || log.tableName === 'assignments') {
        // Only navigate if we're not already on the base route
        if (location.pathname !== baseRoute) {
          navigate(baseRoute);
        }
      } else {
        // For other entities, check if we're already on the detail page
        const targetPath = `${baseRoute}/${log.entityId}`;
        // check that the path includes the targetPath
        if (!location.pathname.includes(targetPath)) {
          navigate(targetPath);
        }
      }
    } else {
      console.warn(`No route mapping found for table: ${log.tableName}`);
    }
  };

  const handleNotificationClick = (log: Log | LogRelated) => {
    setActiveId(log.id);
    setExpandedId(expandedId === log.id ? null : log.id);
    if ((log as LogRelated).logIds) {
      markRelatedLogsRead([log.id, ...((log as LogRelated)?.logIds || [])]);
    } else if (log.id) {
      markLogRead(log.id);
    }
    handleNavigateToEntity(log);
  };

  const handleMarkAllRead = async () => {
    await markAllRead();
    setTimeout(() => closeDrawer(), 500);
  };

  const handleClose = () => {
    onClose();
    setActiveId(null);
    setExpandedId(null);
  };

  // Flatten the pages data for rendering
  const allLogs = data?.pages.flatMap((page) => page.logs) || [];
  const totalCount = data?.pages[0]?.totalCount || 0;

  return (
    <Sheet
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        width: 400,
        bgcolor: 'background.surface',
        borderLeft: '1px solid',
        borderColor: 'divider',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        m: 0,
        p: 0,
        transform: open ? 'translateX(0)' : 'translateX(100%)',
        transition: 'transform 0.2s ease',
        visibility: open ? 'visible' : 'hidden',
      }}
    >
      <Sheet
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
          height: 64,
          borderRadius: 0,
          m: 0,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography level="title-sm">Inbox {unreadCount && unreadCount > 0 ? `(${unreadCount})` : ''}</Typography>
          <Tooltip title="Mark all as read" placement="bottom">
            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              onClick={handleMarkAllRead}
              disabled={isMarkingAllRead}
            >
              <DoneAllIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          {/* <IconButton variant="plain" color="neutral" size="sm">
            <FilterListIcon />
          </IconButton>
          <IconButton variant="plain" color="neutral" size="sm">
            <TuneIcon />
          </IconButton> */}
          <IconButton
            onClick={handleClose}
            variant="plain"
            color="neutral"
            size="sm"
            sx={{
              borderRadius: '50%',
              '&:hover': { bgcolor: 'background.level1' },
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Sheet>

      <Box
        sx={{
          overflow: 'auto',
          flexGrow: 1,
          m: 0,
          p: 0,
        }}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress size="md" />
          </Box>
        ) : status === 'error' ? (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography level="body-md" sx={{ color: 'text.secondary' }}>
              Error loading notifications
            </Typography>
          </Box>
        ) : allLogs.length > 0 ? (
          <>
            {allLogs.map((log) => (
              <NotificationCard
                key={log.id}
                notification={log}
                expanded={expandedId === log.id}
                active={activeId === log.id}
                onClick={() => handleNotificationClick(log)}
              />
            ))}

            {/* Load more indicator */}
            {hasNextPage && (
              <Box
                ref={loadMoreRef}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 2,
                  height: '60px',
                }}
              >
                {isFetchingNextPage ? (
                  <CircularProgress size="sm" />
                ) : (
                  <Typography level="body-sm" sx={{ color: 'text.tertiary' }}>
                    Scroll to load more
                  </Typography>
                )}
              </Box>
            )}

            {!hasNextPage && allLogs.length < totalCount && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography level="body-sm" sx={{ color: 'text.tertiary' }}>
                  Showing {allLogs.length} of {totalCount} notifications
                </Typography>
              </Box>
            )}

            {!hasNextPage && allLogs.length === totalCount && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography level="body-sm" sx={{ color: 'text.tertiary' }}>
                  All notifications loaded
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography level="body-md" sx={{ color: 'text.secondary' }}>
              No notifications to display
            </Typography>
          </Box>
        )}
      </Box>
    </Sheet>
  );
};

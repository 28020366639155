import { CompaniesTable } from '@bluebird-monorepo/companies';
import { Box } from '@mui/joy';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  LayoutContentHeader,
  LayoutTitleBox,
  LayoutTitle,
  LayoutSubtitle,
  LayoutActionButtonBox,
  LayoutActionButton,
} from '../../../layout/Layout';
import { useGetCompanies } from '@bluebird-monorepo/companies';
import { debug } from '../../config/environment';

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
  });
}

const CompaniesPage: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useGetCompanies();
  const companiesSize = data?.count || 0;

  return (
    <Layout areCompoundComponents>
      <LayoutContentHeader>
        <LayoutTitleBox>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <LayoutTitle>Companies</LayoutTitle>
            {`(${companiesSize})`}
          </Box>
          <LayoutSubtitle>Manage your client companies and their job listings</LayoutSubtitle>
        </LayoutTitleBox>
        <LayoutActionButtonBox>
          <LayoutActionButton onClick={() => navigate('/companies/create')}>Create new company</LayoutActionButton>
        </LayoutActionButtonBox>
      </LayoutContentHeader>
      <CompaniesTable />
    </Layout>
  );
};

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  CompaniesPage.whyDidYouRender = true;
}

export default CompaniesPage;

// views/ConnectGoogleCalendar.tsx
import { GoogleIcon } from '@bluebird-monorepo/bluebird-ui';
import Button from '@mui/joy/Button';
import React from 'react';
import { useGetCurrentUser } from '@bluebird-monorepo/users';

interface ConnectGoogleCalendarProps {
  setIsLoading?: (loading: boolean) => void;
  variant?: 'solid' | 'soft' | 'outlined' | 'plain';
  color?: 'primary' | 'neutral' | 'danger' | 'success' | 'warning';
  size?: 'sm' | 'md' | 'lg';
}

const ConnectGoogleCalendar: React.FC<ConnectGoogleCalendarProps> = ({
  setIsLoading,
  variant = 'solid',
  color = 'success',
  size = 'sm',
}) => {
  const { data: currentUser } = useGetCurrentUser();
  const handleConnect = async () => {
    console.log('handleConnect');
    setIsLoading?.(true);
    const user = currentUser;

    console.log('TODO: connect to google calendar');

    // if (user) {
    //   const initiateGoogleOAuth = httpsCallable<{ authUrl: string }, { authUrl: string }>(
    //     functions,
    //     'initiateGoogleOAuth',
    //   );

    //   try {
    //     const response = await initiateGoogleOAuth();
    //     console.log('response', response);
    //     const authUrl = response.data.authUrl;

    //     // Redirect to Google OAuth login page
    //     window.location.href = authUrl;
    //     setIsLoading?.(false);
    //   } catch (error) {
    //     console.error('Error connecting to Google Calendar:', error);
    //   }
    // } else {
    //   console.error('User not logged in');
    // }
  };

  return (
    <Button color={color} onClick={handleConnect} size={size} startDecorator={<GoogleIcon />} variant={variant}>
      Connect Google Calendar
    </Button>
  );
};

export { ConnectGoogleCalendar };

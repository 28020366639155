export const extractID = (text: string) => {
  // Match all standalone 4-digit numbers
  const matches = text?.match(/\b\d{4}\b/g);

  if (matches != null) {
    // Filter matches to exclude numbers within URLs or email-like patterns
    const filteredMatches = matches.filter((num) => {
      const regexForExclusion = new RegExp(`https?:\\/\\/.*?\\b${num}\\b|@[a-zA-Z0-9.]+\\b${num}\\b`);
      return !regexForExclusion.test(text);
    });

    // Further refine by context (e.g., "ID" nearby or ":" precedes it)
    const targetNumber = filteredMatches.find((num) => {
      const contextRegex = new RegExp(`\\bID.*?[:\\-]?\\s*${num}\\b`, 'i'); // Look for "ID" before the number
      return contextRegex.test(text);
    });

    return targetNumber;
  }
  return null;
};

import { Candidate } from '@bluebird-monorepo/types';
import { Box, FormControl, FormHelperText, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

export const WorkDetailsStep = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<Candidate>();

  return (
    <Box>
      <Typography level="h4" sx={{ mb: 2 }}>
        Work Experience
      </Typography>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <FormControl error={Boolean(errors.currentOrganization)} sx={{ width: '50%' }}>
            <FormLabel>Current Organization</FormLabel>
            <Input
              {...register('currentOrganization')}
              slotProps={{
                input: {
                  'aria-label': 'Current Organization',
                },
              }}
            />
            <FormHelperText>{errors.currentOrganization?.message}</FormHelperText>
          </FormControl>

          <FormControl error={Boolean(errors.currentPosition)} sx={{ width: '50%' }}>
            <FormLabel>Current Position</FormLabel>
            <Input
              {...register('currentPosition', {
                setValueAs: (value: string) => value || '',
              })}
              slotProps={{
                input: {
                  'aria-label': 'Current Position',
                },
              }}
            />
            <FormHelperText>{errors.currentPosition?.message}</FormHelperText>
          </FormControl>
        </Stack>

        <FormControl error={Boolean(errors.workExperienceYears)}>
          <FormLabel>Years of Experience</FormLabel>
          <Input
            type="number"
            {...register('workExperienceYears', {
              valueAsNumber: true,
              min: { value: 0, message: 'Experience years must be positive' },
              setValueAs: (value) => (value === '' ? undefined : parseInt(value)),
            })}
            slotProps={{
              input: {
                'aria-label': 'Years of Experience',
              },
            }}
          />
          <FormHelperText>{errors.workExperienceYears?.message}</FormHelperText>
        </FormControl>

        <FormControl error={Boolean(errors.customSource)}>
          <FormLabel>Source</FormLabel>
          <Input
            {...register('customSource')}
            slotProps={{
              input: {
                'aria-label': 'Source',
              },
            }}
          />
          <FormHelperText>{errors.customSource?.message}</FormHelperText>
        </FormControl>

        <FormControl error={Boolean(errors.resumeUrl)}>
          <FormLabel>Resume URL</FormLabel>
          <Input
            {...register('resumeUrl')}
            slotProps={{
              input: {
                'aria-label': 'Resume URL',
              },
            }}
          />
          <FormHelperText>{errors.resumeUrl?.message}</FormHelperText>
        </FormControl>

        <FormControl error={Boolean(errors.linkedin)}>
          <FormLabel>LinkedIn Profile</FormLabel>
          <Input
            {...register('linkedin')}
            slotProps={{
              input: {
                'aria-label': 'LinkedIn Profile',
              },
            }}
          />
          <FormHelperText>{errors.linkedin?.message}</FormHelperText>
        </FormControl>
      </Stack>
    </Box>
  );
};

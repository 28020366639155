import { BluebirdCard } from '@bluebird-monorepo/bluebird-ui';
import { CreateTeamWizard } from '@bluebird-monorepo/users';
import { Avatar, Box, Grid, Stack, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';
import { useGetUsers, useGetTeams } from '@bluebird-monorepo/users';

const TeamDetailPage: React.FC = () => {
  const { teamId: teamIdString } = useParams<{ teamId: string }>();
  const teamId = Number(teamIdString);
  const { data: teams, isLoading: isLoadingTeams } = useGetTeams();
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();
  const [isEditing, setIsEditing] = useState(false);

  const team = teams?.find((t) => t.id === teamId);
  const teamLeader = users?.find((user) => user.id === team?.leaderId);
  const teamMembers = users?.filter((user) => team?.members?.includes(user.id));

  if (isLoadingTeams || isLoadingUsers) {
    return (
      <Layout title="Loading...">
        <Box>Loading team details...</Box>
      </Layout>
    );
  }

  if (!team) {
    return (
      <Layout title="Error">
        <Box>Team not found</Box>
      </Layout>
    );
  }

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleEditComplete = () => {
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <Layout title={`Edit ${team.name}`}>
        <CreateTeamWizard mode="edit" initialData={team} onComplete={handleEditComplete} onCancel={handleEditCancel} />
      </Layout>
    );
  }

  return (
    <Layout
      title={team.name}
      subtitle={team.description || 'No description'}
      actionButton={{
        label: 'Edit Team',
        onClick: handleStartEdit,
        variant: 'create',
      }}
    >
      <Grid container spacing={2} sx={{ width: '100%' }}>
        {/* Team Information Card */}
        <Grid xs={12} md={6}>
          <BluebirdCard>
            <Typography level="h4" sx={{ mb: 2 }}>
              Team Information
            </Typography>
            <Stack spacing={2}>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Team Name
                </Typography>
                <Typography>{team.name}</Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Description
                </Typography>
                <Typography>{team.description || 'No description'}</Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Team Leader
                </Typography>
                {teamLeader ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                    <Avatar src={teamLeader.photoUrl} size="sm">
                      {teamLeader.displayName?.[0] || teamLeader.email[0]}
                    </Avatar>
                    <Box>
                      <Typography>{teamLeader.displayName || teamLeader.email}</Typography>
                      <Typography level="body-sm" textColor="text.secondary">
                        {teamLeader.title || 'No title'}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Typography>No team leader assigned</Typography>
                )}
              </Box>
              {team.verticals && team.verticals.length > 0 && (
                <Box>
                  <Typography level="body-sm" textColor="text.secondary">
                    Verticals
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
                    {team.verticals.map((vertical) => (
                      <Typography
                        key={vertical}
                        sx={{
                          px: 1,
                          py: 0.5,
                          borderRadius: 'sm',
                          bgcolor: 'background.level2',
                          fontSize: 'sm',
                        }}
                      >
                        {vertical}
                      </Typography>
                    ))}
                  </Stack>
                </Box>
              )}
            </Stack>
          </BluebirdCard>
        </Grid>

        {/* Team Members Card */}
        <Grid xs={12} md={6}>
          <BluebirdCard>
            <Typography level="h4" sx={{ mb: 2 }}>
              Team Members
            </Typography>
            <Stack spacing={2}>
              {teamMembers && teamMembers.length > 0 ? (
                teamMembers.map((member) => (
                  <Box key={member.id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Avatar src={member.photoUrl} size="sm">
                      {member.displayName?.[0] || member.email[0]}
                    </Avatar>
                    <Box>
                      <Typography>{member.displayName || member.email}</Typography>
                      <Typography level="body-sm" textColor="text.secondary">
                        {member.title || 'No title'}
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography>No team members</Typography>
              )}
            </Stack>
          </BluebirdCard>
        </Grid>

        {/* Statistics Card */}
        <Grid xs={12} md={6}>
          <BluebirdCard>
            <Typography level="h4" sx={{ mb: 2 }}>
              Statistics
            </Typography>
            <Stack spacing={2}>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Total Members
                </Typography>
                <Typography>{team.members?.length || 0} members</Typography>
              </Box>
              <Box>
                <Typography level="body-sm" textColor="text.secondary">
                  Created At
                </Typography>
                <Typography>
                  {team.createdAt ? new Date(team.createdAt).toLocaleDateString() : 'Not available'}
                </Typography>
              </Box>
            </Stack>
          </BluebirdCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TeamDetailPage;

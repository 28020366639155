import { ColumnDefinition } from '@bluebird-monorepo/bluebird-ui';
import { Typography, Box, Avatar } from '@mui/joy';
import { User } from '@bluebird-monorepo/types';

interface CreateColumnsProps {
  users?: User[];
  onNavigate: (path: string) => void;
}

export const createColumns = ({ users, onNavigate }: CreateColumnsProps): ColumnDefinition[] => [
  {
    field: 'firstName',
    headerName: 'Name',
    width: '20%',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography
        level="body-sm"
        sx={{
          cursor: 'pointer',
          color: 'primary.main',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        onClick={() => onNavigate(`/candidates/${row.id}`)}
      >
        {row.firstName} {row.lastName}
      </Typography>
    ),
  },
  {
    field: 'owner',
    headerName: 'Owner',
    width: '15%',
    sortable: true,
    renderCell: ({ row }) => {
      if (!row.owner) return <Typography level="body-sm">Unassigned</Typography>;

      const ownerUser = users?.find((user) => user.email === row.owner);
      if (!ownerUser) {
        return <Typography level="body-sm">{row.owner.split('@')[0]}</Typography>;
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={ownerUser.photoUrl} size="sm">
            {ownerUser.displayName?.[0] || ownerUser.email[0]}
          </Avatar>
          <Box>
            <Typography
              level="body-sm"
              sx={{
                cursor: 'pointer',
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => onNavigate(`/users/${ownerUser.id}`)}
            >
              {ownerUser.displayName || ownerUser.email.split('@')[0]}
            </Typography>
            {ownerUser.title && (
              <Typography level="body-xs" textColor="text.secondary">
                {ownerUser.title}
              </Typography>
            )}
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'currentPosition',
    headerName: 'Current Position',
    width: '20%',
    sortable: true,
    renderCell: ({ row }) => <Typography level="body-sm">{row.currentPosition || 'N/A'}</Typography>,
  },
  {
    field: 'currentOrganization',
    headerName: 'Current Organization',
    width: '20%',
    sortable: true,
    renderCell: ({ row }) => <Typography level="body-sm">{row.currentOrganization || 'N/A'}</Typography>,
  },
  {
    field: 'city',
    headerName: 'Location',
    width: '15%',
    sortable: true,
    renderCell: ({ row }) => <Typography level="body-sm">{row.city || 'N/A'}</Typography>,
  },
  {
    field: 'score',
    headerName: 'Score',
    width: '10%',
    sortable: true,
    renderCell: ({ row }) => <Typography level="body-sm">{row.score || 'N/A'}</Typography>,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: '10%',
    sortable: true,
    renderCell: ({ row }) => <Typography level="body-sm">{row.status || 'New'}</Typography>,
  },
];

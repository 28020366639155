import { useQuery } from '@tanstack/react-query';

export const useJobEvents = (jobId: number) => {
  const {
    data: events,
    isLoading: eventsLoading,
    refetch: refetchEvents,
  } = useQuery({
    queryKey: ['job-events', jobId],
    queryFn: async () => {
      return [];
    },
    enabled: !!jobId,
  });

  const {
    data: stats,
    isLoading: statsLoading,
    refetch: refetchStats,
  } = useQuery({
    queryKey: ['job-events-stats', jobId],
    queryFn: async () => {
      return { total: 0, scheduled: 0 };
    },
    enabled: !!jobId,
  });

  const refetch = async () => {
    await Promise.all([refetchEvents(), refetchStats()]);
  };

  return {
    events: events || [],
    stats,
    isLoading: eventsLoading || statsLoading,
    refetch,
  };
};

import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertAssignment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignment: Partial<CandidateJobAssignment>) => {
      const { data, error } = await supabase
        .from('assignments')
        .upsert(toSnakeCase(assignment), {
          onConflict: 'id',
        })
        .select()
        .single();

      if (error) throw error;
      return toCamelCase<CandidateJobAssignment>(data);
    },
    onSuccess: (assignment: CandidateJobAssignment) => {
      queryClient.invalidateQueries({ queryKey: ['candidatesByJob', Number(assignment.jobId)] });
      queryClient.invalidateQueries({ queryKey: ['assignments'] });
      queryClient.invalidateQueries({ queryKey: ['assignmentsByOwner', assignment.owner] });
      queryClient.invalidateQueries({ queryKey: ['teamAssignmentsByOwner', assignment.owner] });
      // TODO: Remove cast to number once we have a proper candidate id in the database
      queryClient.invalidateQueries({ queryKey: ['assignmentsByJobId', Number(assignment.jobId)] });
      queryClient.invalidateQueries({ queryKey: ['assignmentsByCandidateId', Number(assignment.candidateId)] });
    },
  });
}

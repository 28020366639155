import { Team } from '@bluebird-monorepo/types';
import { useGetUsers } from '@bluebird-monorepo/users';
import { FormControl, FormHelperText, FormLabel, Option, Select, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';

export function TeamLeaderStep() {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<Team>();
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();
  const currentLeaderId = watch('leaderId');

  const handleLeaderChange = (event: any, value: number | null) => {
    setValue('leaderId', value || 0);
  };

  return (
    <Stack spacing={3}>
      <Typography level="title-lg">Team Leader</Typography>

      <FormControl error={!!errors.leaderId}>
        <FormLabel>Team Leader (Optional)</FormLabel>
        <Select
          {...register('leaderId')}
          value={currentLeaderId}
          onChange={handleLeaderChange}
          placeholder="Select team leader"
          disabled={isLoadingUsers}
        >
          <Option value="">No Leader</Option>
          {users?.map((user) => (
            <Option key={user.id} value={user.id}>
              {user.displayName || user.email}
            </Option>
          ))}
        </Select>
        {errors.leaderId && <FormHelperText>{errors.leaderId.message as string}</FormHelperText>}
      </FormControl>

      <Typography level="body-sm" textColor="text.secondary">
        Assign a team leader to manage the team. You can change this later.
      </Typography>
    </Stack>
  );
}

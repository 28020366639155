import { useUpsertTask } from '@bluebird-monorepo/tasks';
import {
  CreateTaskboardItemDto,
  TaskboardEntityType,
  TaskboardPriority,
  TaskboardStatus,
} from '@bluebird-monorepo/types';
import { useGetUsers } from '@bluebird-monorepo/users';
import { Button, FormControl, FormLabel, Input, Modal, ModalClose, ModalDialog, Option, Select, Stack } from '@mui/joy';
import { FC, useState } from 'react';

interface CompactCreateTaskModalProps {
  open: boolean;
  onClose: () => void;
  entityType: TaskboardEntityType;
  entityId: number;
}

export const CompactCreateTaskModal: FC<CompactCreateTaskModalProps> = ({ open, onClose, entityType, entityId }) => {
  const { data: users } = useGetUsers();
  const { mutateAsync: upsertTask } = useUpsertTask();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState<Partial<CreateTaskboardItemDto>>({
    title: '',
    status: 'todo' as TaskboardStatus,
    priority: 'medium' as TaskboardPriority,
    dueDate: new Date(),
    assignedTo: 0,
    createdBy: 0,
    entityType,
    entityId,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.title || !formData.assignedTo) return;

    setIsSubmitting(true);
    try {
      await upsertTask(formData as CreateTaskboardItemDto);
      onClose();
    } catch (error) {
      console.error('Failed to create task:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog size="sm">
        <ModalClose />
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FormControl required>
              <FormLabel>Title</FormLabel>
              <Input
                placeholder="Enter task title"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              />
            </FormControl>

            <FormControl required>
              <FormLabel>Assignee</FormLabel>
              <Select
                value={formData.assignedTo}
                onChange={(_, value) => setFormData({ ...formData, assignedTo: value as number })}
              >
                {users?.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.displayName || user.email}
                  </Option>
                ))}
              </Select>
            </FormControl>

            <FormControl required>
              <FormLabel>Priority</FormLabel>
              <Select
                value={formData.priority}
                onChange={(_, value) => setFormData({ ...formData, priority: value as TaskboardPriority })}
              >
                <Option value="low">Low</Option>
                <Option value="medium">Medium</Option>
                <Option value="high">High</Option>
              </Select>
            </FormControl>

            <FormControl required>
              <FormLabel>Due Date</FormLabel>
              <Input
                type="datetime-local"
                value={new Date(Date.now()).toISOString().slice(0, 16)}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    dueDate: new Date(e.target.value),
                  })
                }
              />
            </FormControl>

            <Button type="submit" loading={isSubmitting}>
              Create Task
            </Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

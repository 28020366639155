import { Search } from '@mui/icons-material';
import { Box, FormControl, FormLabel, Input, Option, Select, Stack, Typography } from '@mui/joy';
import React from 'react';
import { Controller } from 'react-hook-form';

const STATUS_OPTIONS = [
  {
    value: 'client',
    label: 'Client',
    description: 'Active business relationship',
  },
  {
    value: 'prospect',
    label: 'Prospect',
    description: 'Potential client',
  },
  {
    value: 'partner',
    label: 'Partner',
    description: 'Strategic partner',
  },
];

interface CompaniesTableFiltersProps {
  control: any;
  onResetPage: () => void;
}

const CompaniesTableFilters: React.FC<CompaniesTableFiltersProps> = ({ control, onResetPage }) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'flex-end' }}>
      <FormControl size="sm">
        <FormLabel>Search Companies</FormLabel>
        <Controller
          control={control}
          name="filterName"
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Search by company name"
              size="sm"
              startDecorator={<Search />}
              onChange={(e) => {
                field.onChange(e);
                onResetPage();
              }}
            />
          )}
        />
      </FormControl>

      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <Controller
          control={control}
          name="statusFilter"
          render={({ field }) => (
            <Select
              {...field}
              placeholder="All statuses"
              size="sm"
              onChange={(e, value) => {
                field.onChange(value);
                onResetPage();
              }}
            >
              <Option value="">
                <Typography>All statuses</Typography>
              </Option>
              {STATUS_OPTIONS.map((option) => (
                <Option key={option.value} value={option.value}>
                  <Stack>
                    <Typography>{option.label}</Typography>
                    <Typography level="body-xs">{option.description}</Typography>
                  </Stack>
                </Option>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  );
};

export default CompaniesTableFilters;

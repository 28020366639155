import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Stack, Step, StepIndicator, Stepper } from '@mui/joy';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { BasicInfoStep } from './steps/BasicInfoStep';
import { PreferencesStep } from './steps/PreferencesStep';
import { ReviewStep } from './steps/ReviewStep';
import { SkillsStep } from './steps/SkillsStep';
import { WorkDetailsStep } from './steps/WorkDetailsStep';
import { useNavigate } from 'react-router-dom';
import { Candidate } from '@bluebird-monorepo/types';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { useUpsertCandidate } from '../../api/candidates/useUpsertCandidate';

const candidateSchema = z.object({
  // Basic Info
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email address').optional(),
  phone: z.string().optional(),
  city: z.string().optional(),
  currentCountryResidingIn: z.string().optional(),

  // Work Details
  currentOrganization: z.string().optional(),
  currentPosition: z.string().optional(),
  workExperienceYears: z.number().min(0).optional(),
  customSource: z.string().optional(),
  resumeUrl: z.string().optional(),

  // Skills & Languages
  skills: z.array(z.string()).optional(),
  potentialRoles: z.array(z.string()).optional(),
  languagesCandidateSpeak: z.array(z.string()),
  techStack: z.array(z.string()).optional(),

  // Preferences & Requirements
  isOpenToRelocate: z.boolean(),
  isOpenToWorkRemotely: z.boolean(),
  requiresVisa: z.boolean(),
  inOfficeExpectations: z.string().optional(),

  // Additional fields
  availability: z.string().optional(),
  avatar: z.string().default(''),
  notes: z.array(z.string()).optional(),
  linkedin: z.string().optional(),

  // Salary Information
  salaryInformation: z
    .object({
      preferNotToDisclose: z.boolean().optional(),
      reasonForNonDisclosure: z.string().optional(),
      currentSalary: z
        .object({
          currency: z.string(),
          monthly: z.number().optional(),
          yearly: z.number().optional(),
          bonus: z.number().optional(),
          totalOTE: z.number().optional(),
          hasEquity: z.boolean().optional(),
        })
        .optional(),
      salaryExpectationRange: z
        .object({
          currency: z.string(),
          min: z.number(),
          max: z.number(),
        })
        .optional(),
    })
    .optional(),
});

type CandidateFormData = z.infer<typeof candidateSchema>;

const steps = [
  {
    label: 'Basic Info',
    description: 'Personal and contact information',
    component: BasicInfoStep,
  },
  {
    label: 'Work Details',
    description: 'Current work and experience',
    component: WorkDetailsStep,
  },
  {
    label: 'Skills',
    description: 'Skills and qualifications',
    component: SkillsStep,
  },
  {
    label: 'Preferences',
    description: 'Work preferences and requirements',
    component: PreferencesStep,
  },
  {
    label: 'Review',
    description: 'Review and create candidate',
    component: ReviewStep,
  },
];

const stepValidationFields = {
  0: ['firstName', 'lastName', 'email'],
  1: [], // Work details are optional
  2: ['skills', 'potentialRoles'],
  3: [], // Preferences are optional
  4: [], // Review step doesn't have its own fields
} as const;

interface CreateCandidateWizardProps {
  initialData?: Partial<Candidate>;
}

export function CreateCandidateWizard({ initialData }: CreateCandidateWizardProps) {
  const [activeStep, setActiveStep] = useState(0);
  const { mutate: createCandidate } = useUpsertCandidate();
  const navigate = useNavigate();
  const { data: currentUser } = useGetCurrentUser();

  const methods = useForm<CandidateFormData>({
    resolver: zodResolver(candidateSchema),
    defaultValues: initialData
      ? initialData
      : {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          skills: [],
          potentialRoles: [],
          languagesCandidateSpeak: [],
          isOpenToRelocate: false,
          isOpenToWorkRemotely: false,
          requiresVisa: false,
        },
  });

  const onComplete = () => {
    if (initialData?.id) {
      navigate(`/candidates/${initialData.id}`);
    } else {
      navigate('/candidates');
    }
  };

  const onSubmit = async (formData: CandidateFormData) => {
    try {
      // Transform EntityOption arrays to string arrays
      const data = {
        ...formData,
        skills: formData.skills,
        techStack: formData.techStack,
        owner: currentUser?.email,
        email: formData.email,
        phone: formData.phone,
      };

      if (initialData?.id) {
        (data as Partial<Candidate>).id = initialData.id;
      }

      createCandidate(data, {
        onSuccess: () => {
          onComplete();
        },
        onError: (error) => {
          // TODO: add toast
          console.error('Failed to save candidate:', error);
        },
      });
    } catch (error) {
      console.error('Failed to save candidate:', error);
    }
  };

  const handleNext = async () => {
    const fieldsToValidate = stepValidationFields[activeStep as keyof typeof stepValidationFields];

    const isValid = await methods.trigger(fieldsToValidate);
    if (!isValid) {
      console.log('Form validation failed:', methods.formState.errors);
      return;
    }

    if (activeStep === steps.length - 1) {
      const formData = methods.getValues();
      onSubmit(formData);
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const CurrentStepComponent = steps[activeStep].component;

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: 4,
          px: 3,
          py: 2,
          borderRadius: 'sm',
          position: 'relative',
        }}
      >
        {/* Left side - Stepper */}
        <Box
          sx={{
            width: '280px',
            borderRight: '1px solid',
            borderColor: 'divider',
            pr: 3,
          }}
        >
          <Stepper
            component="nav"
            orientation="vertical"
            sx={{
              '--Stepper-spacing': '1rem',
              '--StepIndicator-size': '2rem',
              '--Step-gap': '1rem',
              '--Step-connectorThickness': '2px',
              '--Step-connectorRadius': '1px',
              minHeight: 400,
            }}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                completed={index < activeStep}
                indicator={
                  <StepIndicator
                    variant={activeStep === index ? 'solid' : 'soft'}
                    color={activeStep === index ? 'primary' : 'neutral'}
                  >
                    {index + 1}
                  </StepIndicator>
                }
              >
                <Box>
                  <Box sx={{ fontWeight: 'bold' }}>{step.label}</Box>
                  <Box sx={{ color: 'text.secondary', fontSize: 'sm' }}>{step.description}</Box>
                </Box>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* Right side - Content */}
        <Box sx={{ width: '100%', maxWidth: '800px' }}>
          <form onSubmit={(e) => e.preventDefault()}>
            {/* Navigation buttons */}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" onClick={handleBack} sx={{ minWidth: 100 }} disabled={activeStep === 0}>
                  Back
                </Button>
                <Button onClick={handleNext} sx={{ minWidth: 100 }} disabled={activeStep === steps.length - 1}>
                  Next
                </Button>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" color="neutral" onClick={onComplete} sx={{ minWidth: 100 }}>
                  Cancel
                </Button>
                <Button
                  onClick={() => onSubmit(methods.getValues())}
                  variant="solid"
                  color="primary"
                  sx={{ minWidth: 100 }}
                >
                  Save
                </Button>
                {/* Close button */}
                <IconButton
                  onClick={onComplete}
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'text.primary',
                    },
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            </Stack>
            <Divider sx={{ my: 4 }} />
            <CurrentStepComponent />
          </form>
        </Box>
      </Box>
    </FormProvider>
  );
}

export default CreateCandidateWizard;

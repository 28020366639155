import { User } from '@bluebird-monorepo/types';
import { Avatar } from '@mui/joy';
import { memo } from 'react';

export interface UserAvatarProps {
  /**
   * The user to display (User object, email, or name)
   */
  user: User | string | null;
  /**
   * Optional size override
   */
  size?: number;
}

function UserAvatarComponent({ user, size = 40 }: UserAvatarProps) {
  if (!user) {
    return (
      <Avatar
        alt="None"
        sx={{
          '--Avatar-size': `${size}px`,
        }}
      >
        ?
      </Avatar>
    );
  }

  if (typeof user === 'string') {
    return (
      <Avatar
        alt={user}
        sx={{
          '--Avatar-size': `${size}px`,
        }}
      >
        {user[0]?.toUpperCase() || '?'}
      </Avatar>
    );
  }

  return (
    <Avatar
      alt={user.displayName || user.email || 'Unknown'}
      src={user.photoUrl || undefined}
      sx={{
        '--Avatar-size': `${size}px`,
      }}
    >
      {(user.displayName || user.email || '?')[0]?.toUpperCase()}
    </Avatar>
  );
}

export const UserAvatar = memo(UserAvatarComponent);

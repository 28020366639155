import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Stack,
} from '@mui/joy';
import { useState } from 'react';
import { useGetTeams } from '../api/teams/useGetTeams';
import { useAddTeamMembers } from '../api/teams/useUpsertTeam';

interface TeamAssignmentDialogProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  selectedUserIds: number[];
}

export function TeamAssignmentDialog({ open, onClose, onComplete, selectedUserIds }: TeamAssignmentDialogProps) {
  const [selectedTeamId, setSelectedTeamId] = useState(0);
  const { mutateAsync: addTeamMembers } = useAddTeamMembers();
  const { data: teams } = useGetTeams();

  const handleTeamChange = (_: any, value: number | null) => {
    setSelectedTeamId(value || 0);
  };

  const handleAssign = async () => {
    if (!selectedTeamId) return;

    try {
      await addTeamMembers({
        teamId: selectedTeamId,
        userIds: selectedUserIds,
      });
      onComplete();
    } catch (error) {
      console.error('Failed to assign users to team:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <DialogTitle>Assign Users to Team</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Select Team</FormLabel>
              <Select value={selectedTeamId} onChange={handleTeamChange} placeholder="Choose a team">
                {teams?.map((team) => (
                  <Option key={team.id} value={team.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {team.name}
                      <Box component="span" sx={{ fontSize: 'sm', color: 'text.secondary' }}>
                        ({team.members?.length || 0} members)
                      </Box>
                    </Box>
                  </Option>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="outlined" color="neutral" onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={handleAssign} disabled={!selectedTeamId}>
                Assign {selectedUserIds.length} Users
              </Button>
            </Box>
          </Stack>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}

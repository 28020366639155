import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface CandidateTimelineTableSettings {
  hiddenColumnIds: string[];
  toggleColumnVisibility: (columnId: string) => void;
  resetSettings: () => void;
}

export const useCandidateTimelineTableSettings = create<CandidateTimelineTableSettings>()(
  persist(
    (set) => ({
      hiddenColumnIds: ['content', 'tableName', 'action', 'entityId'],
      toggleColumnVisibility: (columnId) =>
        set((state) => ({
          hiddenColumnIds: state.hiddenColumnIds.includes(columnId)
            ? state.hiddenColumnIds.filter((id) => id !== columnId)
            : [...state.hiddenColumnIds, columnId],
        })),
      resetSettings: () =>
        set(() => ({
          hiddenColumnIds: [],
        })),
    }),
    {
      name: 'candidate-timeline-table-settings',
    },
  ),
);

import { Box, FormControl, Input, Switch, Typography } from '@mui/joy';
import { Control, Controller } from 'react-hook-form';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { CandidatesFilterPopup } from '@bluebird-monorepo/candidates';
import { CandidateFiltersGroup } from '../../store/candidatesTable.store';

interface CandidatesTableFiltersProps {
  control: Control<any>;
  onResetPage: () => void;
  onFiltersChange: (filters: CandidateFiltersGroup[]) => void;
}

export function CandidatesTableFilters({ control, onResetPage, onFiltersChange }: CandidatesTableFiltersProps) {
  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
      <FormControl sx={{ flex: 1 }}>
        <Controller
          name="filterName"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Search candidates..."
              startDecorator={<SearchRoundedIcon />}
              onChange={(e) => {
                field.onChange(e);
                onResetPage();
              }}
            />
          )}
        />
      </FormControl>

      <FormControl orientation="horizontal" sx={{ gap: 1 }}>
        <Controller
          name="showOnlyMine"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value}
              onChange={(event) => {
                field.onChange(event.target.checked);
                onResetPage();
              }}
            />
          )}
        />
        <Typography level="body-sm">Show only my candidates</Typography>
      </FormControl>
      <CandidatesFilterPopup onFiltersChange={onFiltersChange} />
    </Box>
  );
}
export default CandidatesTableFilters;

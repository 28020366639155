import { Job } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';

export function useGetJobById(id: number | undefined) {
  return useQuery<Job | null>({
    enabled: !!id,
    queryKey: ['jobById', id],
    queryFn: async () => {
      const { data } = await supabase.from('jobs').select('*').eq('id', id).single();
      return data ? toCamelCase<Job>(data) : null;
    },
  });
}

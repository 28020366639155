import { Placement } from '@bluebird-monorepo/types';

export function getDateFromPlacementDate(placementDate: Date | string | number | null): Date {
  if (!placementDate) return new Date(0); // Default to epoch if date is missing

  if (placementDate instanceof Date) {
    return placementDate;
  }

  if (typeof placementDate === 'object' && placementDate !== null && '_seconds' in placementDate) {
    return new Date((placementDate as { seconds: number }).seconds * 1000);
  }

  return new Date(placementDate);
}

export function sortPlacementsByDate(placements: Placement[]): Placement[] {
  return [...placements].sort((a, b) => {
    const dateA = getDateFromPlacementDate(a.placementdate);
    const dateB = getDateFromPlacementDate(b.placementdate);
    return dateA.getTime() - dateB.getTime(); // Sort in descending order (newest first)
  });
}

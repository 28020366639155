import { TaskboardPriority } from '@bluebird-monorepo/types';
import { useGetUsers } from '@bluebird-monorepo/users';
import { parseResponsibleEmail } from '@bluebird-monorepo/shared';
import { Box, FormControl, FormLabel, Option, Select } from '@mui/joy';
import { FC } from 'react';

interface TaskFiltersProps {
  filters: {
    assigneeId: number | null;
    priority: string;
  };
  onFiltersChange: (filters: { assigneeId: number | null; priority: string }) => void;
  sx?: any;
}

export const TaskFilters: FC<TaskFiltersProps> = ({ filters, onFiltersChange, sx }) => {
  const { data: users } = useGetUsers();

  const priorities: TaskboardPriority[] = ['low', 'medium', 'high'];

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        ...sx,
      }}
    >
      <FormControl sx={{ minWidth: 200 }}>
        <FormLabel>Assignee</FormLabel>
        <Select
          value={filters.assigneeId}
          onChange={(_, value) => onFiltersChange({ ...filters, assigneeId: value as number | null })}
        >
          <Option key="All" value={null}>
            All
          </Option>
          {users?.map((user) => (
            <Option key={user.id} value={user.id}>
              {user.displayName || parseResponsibleEmail(user.email)}
            </Option>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 200 }}>
        <FormLabel>Priority</FormLabel>
        <Select
          value={filters.priority}
          onChange={(_, value) => onFiltersChange({ ...filters, priority: value as string })}
        >
          <Option key="All" value="">
            All
          </Option>
          {priorities.map((priority) => (
            <Option key={priority} value={priority}>
              {priority.charAt(0).toUpperCase() + priority.slice(1)}
            </Option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

import { UploadedFile, useUpsertStorageFile } from '@bluebird-monorepo/supabase';
import { Team } from '@bluebird-monorepo/types';
import { CloudUpload } from '@mui/icons-material';
import { Avatar, Box, Button, FormControl, FormHelperText, FormLabel, Stack, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export function AvatarStep() {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<Team>();
  const [isUploading, setIsUploading] = useState(false);
  const currentAvatar = watch('avatar');
  const teamName = watch('name');
  const UPLOADS_PATH = `teams/${teamName}/avatars`;
  const { mutate: uploadStorageFile } = useUpsertStorageFile('uploads', UPLOADS_PATH);

  const handleFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      try {
        setIsUploading(true);
        const fileData = (await uploadStorageFile({ file, fileName: file.name })) as unknown as UploadedFile;
        setValue('avatar', fileData.fullPath);
      } catch (error) {
        console.error('Failed to upload avatar:', error);
      } finally {
        setIsUploading(false);
      }
    },
    [uploadStorageFile, setValue],
  );

  return (
    <Stack spacing={3}>
      <Typography level="title-lg">Team Avatar</Typography>

      <FormControl error={!!errors.avatar}>
        <FormLabel>Upload Team Avatar (Optional)</FormLabel>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Avatar
            src={currentAvatar}
            sx={{
              '--Avatar-size': '64px',
            }}
          />
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            variant="outlined"
            color="neutral"
            startDecorator={<CloudUpload />}
            disabled={isUploading}
          >
            Upload file
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              style={{
                clip: 'rect(0 0 0 0)',
                clipPath: 'inset(50%)',
                height: 1,
                overflow: 'hidden',
                position: 'absolute',
                bottom: 0,
                left: 0,
                whiteSpace: 'nowrap',
                width: 1,
              }}
            />
          </Button>
        </Box>
        {errors.avatar && <FormHelperText>{errors.avatar.message as string}</FormHelperText>}
      </FormControl>

      <Typography level="body-sm" textColor="text.secondary">
        Upload a team avatar to help identify your team. The image will be displayed in various places throughout the
        application.
      </Typography>
    </Stack>
  );
}

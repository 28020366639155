import { Team } from '@bluebird-monorepo/types';
import { CreateTeamWizard, CreateUserWizard, useGetUsers, UsersTable, useGetTeams } from '@bluebird-monorepo/users';
import { Box, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import { useState } from 'react';
import { Layout } from '../../../layout/Layout';
import { TeamsTable } from './TeamsTable';

type View = 'list' | 'createUser' | 'createTeam' | 'editTeam';

interface ViewState {
  type: View;
  data?: {
    team?: Team;
  };
}

function UsersPage() {
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();
  const { data: teams, isLoading: isLoadingTeams } = useGetTeams();
  const [currentView, setCurrentView] = useState<ViewState>({ type: 'list' });
  const [activeTab, setActiveTab] = useState<'users' | 'teams'>('users');

  const handleCreateUserClick = () => setCurrentView({ type: 'createUser' });
  const handleCreateTeamClick = () => setCurrentView({ type: 'createTeam' });
  const handleEditTeam = (teamId: number) => {
    const team = teams?.find((t) => t.id === teamId);
    if (team) {
      setCurrentView({ type: 'editTeam', data: { team } });
    }
  };
  const handleWizardComplete = () => setCurrentView({ type: 'list' });
  const handleWizardCancel = () => setCurrentView({ type: 'list' });

  if (currentView.type === 'createUser') {
    return (
      <Layout title="Create User" subtitle="Add a new user to the system">
        <CreateUserWizard onComplete={handleWizardComplete} onCancel={handleWizardCancel} />
      </Layout>
    );
  }

  if (currentView.type === 'createTeam') {
    return (
      <Layout title="Create Team" subtitle="Create a new team">
        <CreateTeamWizard onComplete={handleWizardComplete} onCancel={handleWizardCancel} />
      </Layout>
    );
  }

  if (currentView.type === 'editTeam' && currentView.data?.team) {
    return (
      <Layout title="Edit Team" subtitle={`Edit ${currentView.data.team.name}`}>
        <CreateTeamWizard
          mode="edit"
          initialData={currentView.data.team}
          onComplete={handleWizardComplete}
          onCancel={handleWizardCancel}
        />
      </Layout>
    );
  }

  return (
    <Layout
      title="Users & Teams"
      actionButton={
        activeTab === 'users'
          ? {
              label: 'Create User',
              onClick: handleCreateUserClick,
              variant: 'create',
            }
          : {
              label: 'Create Team',
              onClick: handleCreateTeamClick,
              variant: 'create',
            }
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        <Tabs
          value={activeTab}
          onChange={(_, value) => setActiveTab(value as 'users' | 'teams')}
          sx={{ borderRadius: 'lg' }}
        >
          <TabList>
            <Tab value="users">Users</Tab>
            <Tab value="teams">Teams</Tab>
          </TabList>
          <TabPanel value="users">
            <UsersTable users={users || []} isLoading={isLoadingUsers} onEditTeam={handleEditTeam} />
          </TabPanel>
          <TabPanel value="teams">
            <TeamsTable teams={teams || []} isLoading={isLoadingTeams} onEditTeam={handleEditTeam} />
          </TabPanel>
        </Tabs>
      </Box>
    </Layout>
  );
}

export default UsersPage;

import React from 'react';
import { CreateCompanyWizard } from '@bluebird-monorepo/companies';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { Box } from '@mui/joy';
import {
  Layout,
  LayoutContentHeader,
  LayoutTitleBox,
  LayoutTitle,
  LayoutSubtitle,
  LayoutActionButtonBox,
} from '../../../layout/Layout';
import { debug } from '../../config/environment';

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
  });
}

const CreateCompanyPage: React.FC = () => {
  return (
    <Layout areCompoundComponents>
      <LayoutContentHeader>
        <LayoutTitleBox>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <LayoutTitle>Create Company</LayoutTitle>
          </Box>
          <LayoutSubtitle>Create a new company</LayoutSubtitle>
        </LayoutTitleBox>
        <LayoutActionButtonBox />
      </LayoutContentHeader>
      <CreateCompanyWizard />
    </Layout>
  );
};

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  CreateCompanyPage.whyDidYouRender = true;
}

export default CreateCompanyPage;

import { InsertAutocomplete } from '@bluebird-monorepo/bluebird-forms';
import { Candidate } from '@bluebird-monorepo/types';
import { languages } from '@bluebird-monorepo/utils';
import { Box, FormControl, FormHelperText, FormLabel, Input, Stack, Typography, Tooltip } from '@mui/joy';
import { Info } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetRoles } from '../../../api/roles/useGetRoles';
import { upsertRole } from '../../../api/roles/useUpsertRoles';
import { useGetSkills } from '../../../api/skills/useGetSkills';
import { upsertSkill } from '../../../api/skills/useUpsertSkills';

export const SkillsStep = () => {
  const {
    setValue,
    control,
    register,
    formState: { errors },
    getValues,
  } = useFormContext<Candidate>();
  const [candidateSkills, setCandidateSkills] = useState<string[]>([]);
  const [candidatePotentialRoles, setCandidatePotentialRoles] = useState<string[]>([]);
  const [candidateLanguages, setCandidateLanguages] = useState<string[]>([]);
  const [skillSearch, setSkillSearch] = useState('');
  const [roleSearch, setRoleSearch] = useState('');
  const { data: potentialSkills, isLoading: isLoadingSkills } = useGetSkills(skillSearch);
  const { data: potentialRoles, isLoading: isLoadingRoles } = useGetRoles(roleSearch);

  useEffect(() => {
    setCandidateSkills(getValues('skills') || []);
    setCandidatePotentialRoles(getValues('potentialRoles') || []);
    setCandidateLanguages(getValues('languagesCandidateSpeak') || []);
  }, []);

  const handleChange = (field: string, value: any) => {
    if (field === 'skills') setCandidateSkills(value);
    if (field === 'potentialRoles') setCandidatePotentialRoles(value);
    if (field === 'languagesCandidateSpeak') setCandidateLanguages(value);
    setValue(field as any, value);
  };

  const getScoreMessage = () => {
    const score = getValues('score');
    if (score === undefined) return 'Enter a score between 0 and 100';
    if (score < 0 || score > 100) return 'Score must be between 0 and 100';
    if (score >= 70) return '👍 Strong candidate';
    if (score >= 40) return '👋 Potential candidate';
    return '⚠️ May need further assessment';
  };

  return (
    <Box>
      <Typography level="h4" sx={{ mb: 2 }}>
        Skills & Qualifications
      </Typography>
      <Stack spacing={2}>
        <FormControl error={Boolean(errors.score)}>
          <FormLabel>
            Candidate Score (0-100)
            <Tooltip
              title={
                <Box sx={{ p: 1 }}>
                  <Typography level="body-sm" sx={{ mb: 1 }}>
                    Score Guidelines:
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Typography level="body-xs" sx={{ color: 'success.main' }}>
                      • 70-100: Strong match for the role
                    </Typography>
                    <Typography level="body-xs" sx={{ color: 'warning.main' }}>
                      • 40-69: Potential candidate, needs discussion
                    </Typography>
                    <Typography level="body-xs" sx={{ color: 'danger.main' }}>
                      • 0-39: May not be suitable at this time
                    </Typography>
                  </Box>
                </Box>
              }
              placement="top"
              arrow
              sx={{
                bgcolor: 'background.surface',
                border: '1px solid',
                borderColor: 'divider',
                boxShadow: 'sm',
              }}
            >
              <Info sx={{ ml: 1, fontSize: 16, color: 'text.secondary', cursor: 'help' }} />
            </Tooltip>
          </FormLabel>
          <Input
            type="number"
            {...register('score', {
              min: { value: 0, message: 'Score must be between 0 and 100' },
              max: { value: 100, message: 'Score must be between 0 and 100' },
            })}
            endDecorator={
              <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                / 100
              </Typography>
            }
          />
          <FormHelperText>{errors.score?.message || getScoreMessage()}</FormHelperText>
        </FormControl>

        <FormControl error={Boolean(errors.skills)}>
          <FormLabel>Skills</FormLabel>
          <InsertAutocomplete<{ skills: string[] }>
            control={control as any}
            name="skills"
            options={potentialSkills?.map((skill) => skill.name).filter((name): name is string => !!name) || []}
            loading={isLoadingSkills}
            onInputChange={(value) => {
              if (value.length >= 2) {
                setSkillSearch(value);
              }
            }}
            onChange={async (value) => {
              const newSkill = await upsertSkill({ name: value[value.length - 1] });
              if (newSkill?.name) {
                const uniqueSkills = new Set([...candidateSkills, newSkill.name]);
                handleChange('skills', Array.from(uniqueSkills));
              }
            }}
            onSubmit={async (value) => {
              const newSkill = await upsertSkill({ name: value });
              if (newSkill?.name) {
                const uniqueSkills = new Set([...candidateSkills, newSkill.name]);
                handleChange('skills', Array.from(uniqueSkills));
              }
            }}
            onDelete={(value) => {
              const uniqueSkills = new Set(candidateSkills?.filter((skill) => skill !== value));
              handleChange('skills', Array.from(uniqueSkills));
            }}
          />
          <FormHelperText>{errors.skills?.message}</FormHelperText>
        </FormControl>
        <FormControl error={Boolean(errors.potentialRoles)}>
          <FormLabel>Potential Roles</FormLabel>
          <InsertAutocomplete<{ potentialRoles: string[] }>
            control={control as any}
            name="potentialRoles"
            options={potentialRoles?.map((role) => role.name) || []}
            loading={isLoadingRoles}
            onInputChange={(value) => {
              if (value.length >= 2) {
                setRoleSearch(value);
              }
            }}
            onChange={async (value) => {
              const newRole = await upsertRole({ name: value[value.length - 1] });
              if (newRole?.name) {
                const uniqueRoles = new Set([...candidatePotentialRoles, newRole.name]);
                handleChange('potentialRoles', Array.from(uniqueRoles));
              }
            }}
            onSubmit={async (value) => {
              const newRole = await upsertRole({ name: value });
              if (newRole?.name) {
                const uniqueRoles = new Set([...candidatePotentialRoles, newRole.name]);
                handleChange('potentialRoles', Array.from(uniqueRoles));
              }
            }}
            onDelete={(value) => {
              const uniqueRoles = new Set(candidatePotentialRoles?.filter((role) => role !== value));
              handleChange('potentialRoles', Array.from(uniqueRoles));
            }}
          />
          <FormHelperText>{errors.potentialRoles?.message}</FormHelperText>
        </FormControl>

        <FormControl error={Boolean(errors.languagesCandidateSpeak)}>
          <FormLabel>Languages</FormLabel>
          <InsertAutocomplete<{ languagesCandidateSpeak: string[] }>
            control={control as any}
            name="languagesCandidateSpeak"
            options={languages.map((lang) => lang.name)}
            groupBy={(option) => {
              const language = languages.find((lang) => lang.name === option);
              return language?.region || '';
            }}
            onChange={(value) => {
              const uniqueLanguages = new Set([...candidateLanguages, value[value.length - 1]]);
              handleChange('languagesCandidateSpeak', Array.from(uniqueLanguages));
            }}
            onSubmit={(value) => {
              const uniqueLanguages = new Set([...candidateLanguages, value]);
              handleChange('languagesCandidateSpeak', Array.from(uniqueLanguages));
            }}
            onDelete={(value) => {
              const uniqueLanguages = new Set(candidateLanguages?.filter((lang) => lang !== value));
              handleChange('languagesCandidateSpeak', Array.from(uniqueLanguages));
            }}
          />
          <FormHelperText>{errors.languagesCandidateSpeak?.message}</FormHelperText>
        </FormControl>
      </Stack>
    </Box>
  );
};

import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteInputChangeReason,
  Chip,
  FormControl,
  FormControlProps,
  ListSubheader,
  styled,
} from '@mui/joy';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { KeyboardEvent, MouseEvent, SyntheticEvent, useCallback, useState, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';

type BaseAutocompleteProps = AutocompleteProps<string, true, false, true>;

interface InsertAutocompleteProps<T extends FieldValues>
  extends Omit<BaseAutocompleteProps, 'value' | 'onChange' | 'onSubmit' | 'onInputChange'> {
  control: Control<T>;
  name: Path<T>;
  options: string[];
  loading?: boolean;
  onChange?: (value: string[]) => void;
  onInputChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  onDelete?: (value: string) => void;
  FormControlProps?: Omit<FormControlProps, 'children'>;
}

const StyledOption = styled('li')(({ theme }) => ({
  padding: '0.5rem 1rem',
  '&[aria-selected="true"]': {
    backgroundColor: 'rgba(237, 242, 247, 0.5)',
  },
  '&:hover, &.Mui-focused': {
    backgroundColor: 'rgba(237, 242, 247, 0.9)',
  },
}));

export function InsertAutocomplete<T extends FieldValues>({
  control,
  name,
  options,
  loading,
  onChange,
  onInputChange,
  onSubmit,
  onDelete,
  FormControlProps,
  ...props
}: InsertAutocompleteProps<T>) {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const highlightedIndexRef = useRef<number | null>(null);

  const debouncedOnInputChange = useCallback(
    (value: string) => {
      onInputChange?.(value);
    },
    [onInputChange],
  );

  const debouncedSearch = useMemo(() => debounce(debouncedOnInputChange, 300), [debouncedOnInputChange]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && inputValue.trim() && onSubmit && !isOpen && highlightedIndexRef.current === null) {
      onSubmit(inputValue.trim());
    }
    if (event.key === 'Enter') {
      setInputValue('');
      highlightedIndexRef.current = null;
    }
  };

  const handleInputChange = (event: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    setInputValue(value);
    debouncedSearch(value);
  };

  const handleChange = (event: SyntheticEvent, value: string[]) => {
    onChange?.(value);
    setInputValue('');
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} {...FormControlProps}>
          <Autocomplete
            {...props}
            multiple
            freeSolo
            loading={loading}
            options={options}
            value={value || []}
            inputValue={inputValue}
            onChange={(_, newValue) => {
              onChange(newValue);
              handleChange(_, newValue);
            }}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onHighlightChange={(_, option, reason) => {
              highlightedIndexRef.current = option ? options.indexOf(option) : null;
            }}
            slotProps={{
              listbox: {
                sx: { maxHeight: 300, overflow: 'auto' },
              },
            }}
            renderGroup={(params) => (
              <div key={params.key}>
                <ListSubheader
                  sx={{
                    backgroundColor: 'rgba(237, 242, 247, 0.7)',
                    color: 'var(--joy-palette-text-secondary)',
                    fontWeight: 'md',
                    fontSize: 'sm',
                    px: 2,
                    py: 1,
                    textTransform: 'uppercase',
                    letterSpacing: '0.1em',
                  }}
                >
                  {params.group}
                </ListSubheader>
                {params.children}
              </div>
            )}
            renderOption={(props, option) => <StyledOption {...props}>{option}</StyledOption>}
            renderTags={(tags, getTagProps) =>
              tags.map((tag, index) => {
                const tagProps = getTagProps({ index });
                return (
                  <Chip
                    key={tagProps.key}
                    variant="soft"
                    color="primary"
                    disabled={tagProps.disabled}
                    data-tag-index={tagProps['data-tag-index']}
                    tabIndex={tagProps.tabIndex}
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      onDelete?.(tag);
                    }}
                    endDecorator={<span style={{ fontSize: '20px', fontWeight: 300 }}>×</span>}
                  >
                    {tag}
                  </Chip>
                );
              })
            }
          />
        </FormControl>
      )}
    />
  );
}

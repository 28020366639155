import { DayColumn, Dot } from '@bluebird-monorepo/bluebird-ui';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { Box, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { EventCard } from './EventCard';

interface WeekViewProps {
  groupedEvents: Record<string, CalendarEvent[]>;
  currentWeek: dayjs.Dayjs;
  onEventClick: (event: CalendarEvent) => void;
  onRemoveEvent: (event: React.MouseEvent, eventToBeRemoved: CalendarEvent) => void;
  getJobTitle: (jobId: number) => string | undefined;
}

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export function WeekView({ groupedEvents, currentWeek, onEventClick, onRemoveEvent, getJobTitle }: WeekViewProps) {
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {WEEKDAYS.map((day, index) => (
        <DayColumn key={day}>
          {/* Day Header */}
          <Typography level="title-lg" sx={{ alignItems: 'center', display: 'flex', gap: 1, mb: 1 }}>
            {day} <Dot bgColor="#ff8300cc" height={7.5} width={7.5} />
          </Typography>
          <Typography fontSize="sm" sx={{ color: '#888', mb: 2 }}>
            {currentWeek.day(index + 1).format('MMM DD')}
          </Typography>

          {/* Event Cards */}
          {groupedEvents[day]?.length > 0 ? (
            groupedEvents[day].map((calendarEvent) => (
              <EventCard
                event={calendarEvent}
                key={calendarEvent.id}
                onEventClick={(calendarEvent) => onEventClick(calendarEvent)}
                onRemoveEvent={(e, calendarEvent) => onRemoveEvent(e, calendarEvent)}
                jobTitle={calendarEvent.jobId ? getJobTitle(calendarEvent.jobId) : undefined}
              />
            ))
          ) : (
            <Typography fontSize="sm" sx={{ color: '#888' }}>
              No meetings for this day
            </Typography>
          )}
        </DayColumn>
      ))}
    </Box>
  );
}

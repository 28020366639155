import { Placement } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@bluebird-monorepo/types';

interface PaginatedResponse {
  placements: Placement[];
  count: number;
}

interface UseGetPlacementsOptions {
  page: number;
  pageSize: number;
}

type UseGetPlacementsQuery = PostgrestFilterBuilder<Database['public'], Record<keyof Placement, unknown>, Placement>;

const DEFAULT_OPTIONS: UseGetPlacementsOptions = {
  page: 1,
  pageSize: 10,
};

export function useGetPlacements(optionsArg?: UseGetPlacementsOptions) {
  const options = { ...DEFAULT_OPTIONS, ...optionsArg };
  return useQuery<PaginatedResponse>({
    queryKey: ['placements', options],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const selectQuery = getSelectQuery(options);
      const countQuery = getCountQuery();
      const [{ data }, { count }] = await Promise.all([selectQuery, countQuery]);
      return {
        placements: data ? toCamelCase<Placement[]>(data) : [],
        count: count || 0,
      };
    },
  });
}

function getSelectQuery(options: UseGetPlacementsOptions) {
  const { page, pageSize } = options;
  const selectQuery = supabase.from('placements').select('*').returns<Placement>() as UseGetPlacementsQuery;
  // Pagination
  const start = (page - 1) * pageSize;
  const end = start + pageSize - 1;
  selectQuery.range(start, end);
  return selectQuery;
}

function getCountQuery() {
  return supabase
    .from('placements')
    .select('*', { count: 'exact', head: true })
    .returns<Placement>() as UseGetPlacementsQuery;
}

import {
  FormControl,
  FormHelperText,
  SelectOption,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Typography,
  Button,
  Box,
} from '@mui/joy';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { toast } from 'react-toastify';

const STATUS_OPTIONS = [
  {
    value: 'client',
    label: 'Client',
    description: 'Active business relationship with signed agreements',
  },
  {
    value: 'prospect',
    label: 'Prospect',
    description: 'Potential client in discussion phase',
  },
  {
    value: 'partner',
    label: 'Partner',
    description: 'Strategic business partner or affiliate',
  },
];

export function AboutStep() {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const status = watch('status');
  const [previewLogoUrl, setPreviewLogoUrl] = useState<string | null>(null);
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);

  const handleFetchLogo = async () => {
    const website = watch('website');
    if (!website) return;

    setIsLoadingLogo(true);
    try {
      // Extract domain first
      const url = website.startsWith('http') ? website : `https://${website}`;
      const targetDomain = new URL(url).hostname.toLowerCase();

      const searchResponse = await fetch(`https://api.logo.dev/search?q=${encodeURIComponent(targetDomain)}`, {
        headers: {
          Authorization: `Bearer: sk_b_NetEdwSTeWzDrH2OZhmQ`,
        },
      });

      if (!searchResponse.ok) throw new Error('Failed to search for company logo');

      const searchData = await searchResponse.json();

      if (!searchData || !Array.isArray(searchData) || searchData.length === 0) {
        setPreviewLogoUrl(null);
        toast.warning('No logo found for this domain.');
        return;
      }

      // Find exact domain match
      const bestMatch = searchData.find((item) => item.domain?.toLowerCase() === targetDomain);

      if (bestMatch?.logo_url) {
        setPreviewLogoUrl(bestMatch.logo_url);
      } else {
        setPreviewLogoUrl(null);
        toast.warning(`No logo found for domain "${targetDomain}"`);
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
      setPreviewLogoUrl(null);
      toast.error('Failed to fetch logo. Please try again.');
    } finally {
      setIsLoadingLogo(false);
    }
  };

  return (
    <Stack spacing={3}>
      <Typography level="h4">About the Company</Typography>

      <FormControl error={!!errors['name']} required>
        <FormLabel>Company Name</FormLabel>
        <Input {...register('name')} placeholder="Enter company name" />
        {errors['name'] && <FormHelperText>{errors['name'].message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors['industry']}>
        <FormLabel>Industry (Optional)</FormLabel>
        <Input {...register('industry')} placeholder="e.g., Technology, Healthcare, Finance" />
        {errors['industry'] && <FormHelperText>{errors['industry'].message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors['location']}>
        <FormLabel>Location (Optional)</FormLabel>
        <Input {...register('location')} placeholder="Primary business location" />
        {errors['location'] && <FormHelperText>{errors['location'].message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors['website']}>
        <FormLabel>Website (Optional)</FormLabel>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
          <Input {...register('website')} placeholder="e.g., company.com" sx={{ flexGrow: 1, maxWidth: '60%' }} />
          <Button onClick={handleFetchLogo} loading={isLoadingLogo} disabled={!watch('website') || !watch('name')}>
            Fetch Logo
          </Button>
          {previewLogoUrl && (
            <Box
              component="img"
              src={previewLogoUrl}
              alt="Company logo preview"
              sx={{
                width: 37,
                height: 37,
                border: '1px solid',
                borderColor: 'divider',
              }}
            />
          )}
        </Box>
        {errors['website'] && <FormHelperText>{errors['website'].message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors['status']}>
        <FormLabel>Company Status</FormLabel>
        <Select
          value={status}
          onChange={(_, value) => setValue('status', value)}
          placeholder="Select company status"
          renderValue={(option: SelectOption<string> | null) => {
            const selectedOption = STATUS_OPTIONS.find((opt) => opt.value === option?.value);
            return selectedOption ? selectedOption.label : '';
          }}
        >
          {STATUS_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              <Stack>
                <Typography>{option.label}</Typography>
                <Typography level="body-xs">{option.description}</Typography>
              </Stack>
            </Option>
          ))}
        </Select>
        {errors['status'] && <FormHelperText>{errors['status'].message as string}</FormHelperText>}
      </FormControl>

      <FormControl error={!!errors['agreedFee']} required>
        <FormLabel>Agreed Fee Percentage</FormLabel>
        <Input
          type="number"
          {...register('agreedFee', { valueAsNumber: true })}
          placeholder="Enter fee percentage"
          endDecorator="%"
        />
        {errors['agreedFee'] && <FormHelperText>{errors['agreedFee'].message as string}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

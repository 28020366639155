import { Box, Button, Card, IconButton, Stack, Textarea, Typography } from '@mui/joy';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useState } from 'react';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { Note } from '@bluebird-monorepo/types';
import { useGetNotes, useUpsertNote, useDeleteNote } from '../api';

interface NotesTabProps {
  entityId: number;
  entityType: string;
}

export const NotesTab: React.FC<NotesTabProps> = ({ entityId, entityType }) => {
  const [noteContent, setNoteContent] = useState('');
  const { data: notes = [], isLoading } = useGetNotes({ entityId, entityType });
  const { mutate: createNote } = useUpsertNote();
  const { mutate: deleteNote } = useDeleteNote();
  const { data: currentUser } = useGetCurrentUser();

  const handleCreateNote = async () => {
    if (!noteContent.trim() || !currentUser) return;

    createNote({
      content: noteContent.trim(),
      entityId: entityId,
      entityType,
      createdById: currentUser.id,
    });

    // const timelineEntry: Omit<NoteTimelineEntry, 'id'> = {
    //   type: 'note',
    //   content: noteContent.trim(),
    //   visibility: 'public',
    //   timestamp: new Date(),
    //   createdBy: {
    //     id: currentUser?.id || 0,
    //     name: currentUser?.displayName || currentUser?.email || '',
    //     avatar: currentUser?.photoUrl,
    //   },
    //   entityType: entityType as 'candidate' | 'job' | 'company',
    //   entityId: String(entityId),
    // };

    // addTimelineEntry({
    //   candidateId: entityId,
    //   entry: timelineEntry,
    // });

    setNoteContent('');
  };

  const handleDeleteNote = (noteId: number) => {
    deleteNote(noteId);
  };

  if (isLoading) {
    return <Box>Loading notes...</Box>;
  }

  return (
    <Stack spacing={2}>
      <Card>
        <Textarea
          placeholder="Add a new note..."
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          minRows={3}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Button onClick={handleCreateNote} disabled={!noteContent.trim()}>
            Add Note
          </Button>
        </Box>
      </Card>

      {notes?.map((note: Note) => (
        <Card key={note.id}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Typography>{note.content}</Typography>
            <Box>
              <IconButton size="sm" variant="plain" color="danger" onClick={() => handleDeleteNote(note.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography level="body-xs" sx={{ mt: 1 }}>
            {new Date(note.createdAt).toLocaleString()}
          </Typography>
        </Card>
      ))}
    </Stack>
  );
};

import { PlacementDetails, useGetPlacements } from '@bluebird-monorepo/placements';
import { Box, Stack, Typography } from '@mui/joy';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../layout/Layout';

const PlacementDetailPage: React.FC = () => {
  const { placementId: placementIdString } = useParams<{ placementId: string }>();
  const placementId = Number(placementIdString);
  const { data, isLoading } = useGetPlacements();
  const { placements } = data || { placements: [], count: 0 };

  const placement = placements.find((p) => p.id === placementId);

  if (isLoading) {
    return (
      <Layout title="Placement Detail">
        <Typography level="h4">Loading...</Typography>
      </Layout>
    );
  }

  if (!placement) {
    return (
      <Layout title="Placement Detail">
        <Typography level="h4">Placement not found</Typography>
      </Layout>
    );
  }

  return (
    <Layout title="Placement Detail">
      <Box
        sx={{
          alignItems: { sm: 'center', xs: 'start' },
          display: 'flex',
          flexDirection: { sm: 'row', xs: 'column' },
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'space-between',
          mb: 1,
        }}
      >
        <Stack gap={4} width="100%">
          <PlacementDetails placement={placement} />
        </Stack>
      </Box>
    </Layout>
  );
};

export default PlacementDetailPage;

export type Stage = {
  color: string;
  createdAt: Date;
  id: number;
  name: string;
  order: number;
  rcrmStageId: number;
  updatedAt: Date;
};

const STAGES: Stage[] = [
  {
    color: '#E6E6FA',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 1,
    name: 'Applied',
    order: 0,
    rcrmStageId: 10,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#fff',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 2,
    name: 'Assigned',
    order: 1,
    rcrmStageId: 1,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#FFE4E1',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 3,
    name: 'Write Introduction',
    order: 2,
    rcrmStageId: 3,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#B3D1FF',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 4,
    name: 'Introduced to Client',
    order: 3,
    rcrmStageId: 409861,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#F0FFF0',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 5,
    name: 'Interview Stage NL',
    order: 4,
    rcrmStageId: 409859,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#F5F5DC',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 6,
    name: 'Interview Stage with Client',
    order: 5,
    rcrmStageId: 409860,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#FFF2CC',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 7,
    name: 'First Interview',
    order: 6,
    rcrmStageId: 81008,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#FFD8A8',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 8,
    name: 'Second Interview',
    order: 7,
    rcrmStageId: 81009,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#FFE4CC',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 9,
    name: 'Third Interview',
    order: 8,
    rcrmStageId: 81010,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#D7C9FF',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 10,
    name: 'Technical Assignment',
    order: 9,
    rcrmStageId: 409862,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#D7C9FF',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 11,
    name: 'Business Case',
    order: 10,
    rcrmStageId: 81011,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#FFCCE5',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 12,
    name: 'Final Interview',
    order: 11,
    rcrmStageId: 81012,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#FFF7CC',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 13,
    name: 'Offered',
    order: 12,
    rcrmStageId: 89915,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#CFF2E8',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 14,
    name: 'Accepted',
    order: 13,
    rcrmStageId: 120679,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#B5E7A0',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 15,
    name: 'Placed',
    order: 14,
    rcrmStageId: 8,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#FFC1C1',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 16,
    name: 'Rejected',
    order: 15,
    rcrmStageId: 89916,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#E0B3B3',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 17,
    name: 'Did Not Join',
    order: 16,
    rcrmStageId: 81015,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
  {
    color: '#D3D3D3',
    createdAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
    id: 18,
    name: 'On Hold',
    order: 17,
    rcrmStageId: 120678,
    updatedAt: new Date('1 December 2024 at 18:40:14 UTC+1'),
  },
];

export const useStages = () => {
  return {
    stages: STAGES,
  };
};

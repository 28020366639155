import { Candidate } from '@bluebird-monorepo/types';
import { Add, Business, CalendarMonth, Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  Sheet,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import React, { useState } from 'react';

interface WorkExperience {
  id: string;
  title: string;
  company: string;
  location: string;
  description: string;
  startDate: string;
  endDate: string;
}

interface CandidateExperienceTabProps {
  candidate: Candidate;
}

export const CandidateExperienceTab: React.FC<CandidateExperienceTabProps> = ({ candidate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingExperience, setEditingExperience] = useState<WorkExperience | null>(null);

  // Mock data - replace with actual data from candidate object once the schema is updated
  const [experiences, setExperiences] = useState<WorkExperience[]>([
    {
      id: '1',
      title: 'Senior Software Engineer',
      company: 'Tech Corp',
      location: 'Amsterdam, Netherlands',
      description: 'Led development of microservices architecture...',
      startDate: '2020-01',
      endDate: '2023-06',
    },
    {
      id: '2',
      title: 'Software Engineer',
      company: 'Startup Inc',
      location: 'Rotterdam, Netherlands',
      description: 'Full-stack development using React and Node.js...',
      startDate: '2018-03',
      endDate: '2019-12',
    },
  ]);

  const handleAddExperience = () => {
    setEditingExperience(null);
    setIsModalOpen(true);
  };

  const handleEditExperience = (experience: WorkExperience) => {
    setEditingExperience(experience);
    setIsModalOpen(true);
  };

  const handleDeleteExperience = (experienceId: string) => {
    setExperiences(experiences.filter((exp) => exp.id !== experienceId));
    // TODO: Update candidate object with new experiences
  };

  const handleSaveExperience = (formData: WorkExperience) => {
    if (editingExperience) {
      setExperiences(experiences.map((exp) => (exp.id === editingExperience.id ? { ...formData, id: exp.id } : exp)));
    } else {
      setExperiences([...experiences, { ...formData, id: Date.now().toString() }]);
    }
    setIsModalOpen(false);
    // TODO: Update candidate object with new experiences
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  };

  return (
    <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography level="h3">Work Experience</Typography>
        <Button
          startDecorator={<Add />}
          onClick={handleAddExperience}
          sx={{
            background: 'linear-gradient(45deg, var(--joy-palette-primary-500), var(--joy-palette-primary-600))',
          }}
        >
          Add Experience
        </Button>
      </Box>

      <Stack spacing={2}>
        {experiences.map((experience, index) => (
          <Card
            key={experience.id}
            sx={{
              position: 'relative',
              '&:hover': {
                '& .experience-actions': {
                  opacity: 1,
                },
              },
            }}
          >
            <Box
              className="experience-actions"
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                opacity: 0,
                transition: 'opacity 0.2s',
                display: 'flex',
                gap: 1,
              }}
            >
              <IconButton size="sm" variant="plain" color="neutral" onClick={() => handleEditExperience(experience)}>
                <Edit />
              </IconButton>
              <IconButton
                size="sm"
                variant="plain"
                color="danger"
                onClick={() => handleDeleteExperience(experience.id)}
              >
                <Delete />
              </IconButton>
            </Box>

            <Stack spacing={1}>
              <Typography level="title-lg">{experience.title}</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Business sx={{ color: 'text.secondary' }} />
                <Typography level="body-sm">{experience.company}</Typography>
                <Divider orientation="vertical" />
                <Typography level="body-sm">{experience.location}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <CalendarMonth sx={{ color: 'text.secondary' }} />
                <Typography level="body-sm">
                  {formatDate(experience.startDate)} - {formatDate(experience.endDate)}
                </Typography>
              </Stack>
              <Typography level="body-sm" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
                {experience.description}
              </Typography>
            </Stack>
          </Card>
        ))}
      </Stack>

      <Modal
        aria-labelledby="experience-modal-title"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: '500px',
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ExperienceForm
            initialData={editingExperience}
            onSave={handleSaveExperience}
            onCancel={() => setIsModalOpen(false)}
          />
        </Sheet>
      </Modal>
    </Box>
  );
};

interface ExperienceFormProps {
  initialData: WorkExperience | null;
  onSave: (data: WorkExperience) => void;
  onCancel: () => void;
}

const ExperienceForm: React.FC<ExperienceFormProps> = ({ initialData, onSave, onCancel }) => {
  const [formData, setFormData] = useState<WorkExperience>(
    initialData || {
      id: '',
      title: '',
      company: '',
      location: '',
      description: '',
      startDate: '',
      endDate: '',
    },
  );

  const handleChange = (field: keyof WorkExperience, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography component="h2" id="experience-modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={2}>
        {initialData ? 'Edit Experience' : 'Add Experience'}
      </Typography>
      <Stack spacing={2}>
        <FormControl required>
          <FormLabel>Job Title</FormLabel>
          <Input
            value={formData.title}
            onChange={(e) => handleChange('title', e.target.value)}
            placeholder="e.g. Senior Software Engineer"
          />
        </FormControl>

        <FormControl required>
          <FormLabel>Company</FormLabel>
          <Input
            value={formData.company}
            onChange={(e) => handleChange('company', e.target.value)}
            placeholder="e.g. Tech Corp"
          />
        </FormControl>

        <FormControl required>
          <FormLabel>Location</FormLabel>
          <Input
            value={formData.location}
            onChange={(e) => handleChange('location', e.target.value)}
            placeholder="e.g. Amsterdam, Netherlands"
          />
        </FormControl>

        <Stack direction="row" spacing={2}>
          <FormControl required>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="month"
              value={formData.startDate}
              onChange={(e) => handleChange('startDate', e.target.value)}
            />
          </FormControl>

          <FormControl required>
            <FormLabel>End Date</FormLabel>
            <Input type="month" value={formData.endDate} onChange={(e) => handleChange('endDate', e.target.value)} />
          </FormControl>
        </Stack>

        <FormControl required>
          <FormLabel>Description</FormLabel>
          <Textarea
            minRows={3}
            value={formData.description}
            onChange={(e) => handleChange('description', e.target.value)}
            placeholder="Describe your responsibilities and achievements..."
          />
        </FormControl>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="plain" color="neutral" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default CandidateExperienceTab;

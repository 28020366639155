//import { Alert, CircularProgress } from '@mui/joy';
import { useStages } from '@bluebird-monorepo/shared';
import { useKanbanSettings } from '../store/useKanbanSettings';
import { KanbanColumn } from '../types';
import KanbanBoard from './KanbanBoard';

interface KanbanBoardContainerProps {
  initial: KanbanColumn;
  isCombineEnabled?: boolean;
  onStatusUpdate?: (candidateId: number, statusId: number, status: string, jobId: number) => void;
  useClone?: boolean;
  withScrollableColumns?: boolean;
  containerStyle?: React.CSSProperties;
}

export function KanbanBoardContainer(props: KanbanBoardContainerProps) {
  const { stages } = useStages();
  const { hiddenStageIds } = useKanbanSettings();

  // Filter out hidden stages
  const visibleStages = stages.filter((stage) => !hiddenStageIds.includes(stage.id));

  return <KanbanBoard {...props} stages={visibleStages} />;
}

import { CustomCheckbox } from '@bluebird-monorepo/bluebird-forms';
import { BluebirdCard, RawHTML } from '@bluebird-monorepo/bluebird-ui';
import { useUpsertJob } from '@bluebird-monorepo/jobs';
import { Job } from '@bluebird-monorepo/types';
import { languages, languageCountryMap } from '@bluebird-monorepo/utils';
import { Box, CardContent, Divider, List, ListItem, Stack, Typography } from '@mui/joy';
import { FC, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

interface JobInfoCardProps {
  job: Job;
}

const JobInfoCard: FC<JobInfoCardProps> = ({ job }) => {
  const [relocation, setRelocation] = useState(job?.relocation || false);
  const [visaSponsorship, setVisaSponsorship] = useState(job?.visaSponsorship || false);
  const { mutate: upsertJob } = useUpsertJob();

  const handleCheckboxChange = (field: keyof Job, checked: boolean) => {
    if (field === 'relocation') setRelocation(checked);
    if (field === 'visaSponsorship') setVisaSponsorship(checked);
    upsertJob({
      ...job,
      [field]: checked,
    });
  };

  // Layout configuration based on screen size
  const layoutConfig = {
    gridColumns: {
      xs: '1fr',
      sm: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)',
    },
    spacing: {
      xs: 2,
      sm: 3,
      md: 4,
    },
    fontSize: {
      title: {
        xs: 14,
        sm: 15,
        md: 16,
      },
      body: {
        xs: 13,
        sm: 14,
        md: 16,
      },
      subtitle: {
        xs: 11,
        sm: 12,
        md: 12.8,
      },
    },
  };

  function getCountryCodeForLanguage(languageName: string): string {
    // Find the language code for the given language name
    const language = languages.find((lang) => lang.name === languageName);
    if (!language) return '';

    // Find the first country that uses this language code
    const countryMapping = languageCountryMap.find((mapping) => mapping.languageCode === language.code);
    return countryMapping?.countryCode || '';
  }

  return (
    <BluebirdCard>
      <CardContent>
        <Stack gap={layoutConfig.spacing}>
          {/* Description Sections */}
          <Stack spacing={{ xs: 0.5, sm: 1, md: 2 }}>
            {/* Role Description */}
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: layoutConfig.fontSize.title,
                  fontWeight: 'bold',
                  mb: { xs: 1, sm: 1.5, md: 2 },
                }}
              >
                Role description
              </Typography>
              <Typography
                level="body-md"
                sx={{
                  color: '#212529bf',
                  fontSize: layoutConfig.fontSize.body,
                }}
              >
                <RawHTML children={job?.roleDescription || 'No role description'} />
              </Typography>
            </Box>

            {/* Product Description */}
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: layoutConfig.fontSize.title,
                  fontWeight: 'bold',
                  mb: { xs: 1, sm: 1.5, md: 2 },
                }}
              >
                Product
              </Typography>
              <Typography
                level="body-md"
                sx={{
                  color: '#212529bf',
                  fontSize: layoutConfig.fontSize.body,
                }}
              >
                <RawHTML children={job?.productDescription || 'No product description'} />
              </Typography>
            </Box>

            {/* Preferred Languages */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 2, sm: 4, md: 8 },
                mt: 2,
              }}
            >
              <Box sx={{ flex: { xs: '1', sm: '0 0 200px' } }}>
                <Typography
                  level="h4"
                  sx={{
                    fontSize: layoutConfig.fontSize.title,
                    fontWeight: 'bold',
                    mb: 1,
                  }}
                >
                  Preferred Languages
                </Typography>
                <Typography
                  sx={{
                    color: '#212529bf',
                    fontSize: layoutConfig.fontSize.subtitle,
                  }}
                >
                  What are the preferred languages for this role?
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <List>
                  {job?.preferredLanguages?.map((languageName, index) => {
                    const countryCode = getCountryCodeForLanguage(languageName);
                    return (
                      <ListItem key={index} sx={{ gap: 1 }}>
                        {countryCode && <ReactCountryFlag countryCode={countryCode} svg />}
                        {languageName}
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>

            <Divider />

            {/* Company Info */}
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: layoutConfig.fontSize.title,
                  fontWeight: 'bold',
                  mb: { xs: 1, sm: 1.5, md: 2 },
                }}
              >
                Unique about the company
              </Typography>
              <Typography
                level="body-md"
                sx={{
                  color: '#212529bf',
                  fontSize: layoutConfig.fontSize.body,
                }}
              >
                <RawHTML children={job?.uniqueAboutCompany || 'No unique about the company'} />
              </Typography>
            </Box>

            {/* Assistance Section */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 2, sm: 4, md: 8 },
                mt: 2,
              }}
            >
              <Box sx={{ flex: { xs: '1', sm: '0 0 200px' } }}>
                <Typography
                  level="h4"
                  sx={{
                    fontSize: layoutConfig.fontSize.title,
                    fontWeight: 'bold',
                    mb: 1,
                  }}
                >
                  Assistance
                </Typography>
                <Typography
                  sx={{
                    color: '#212529bf',
                    fontSize: layoutConfig.fontSize.subtitle,
                  }}
                >
                  Does the company offer the following services?
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Stack spacing={1}>
                  <CustomCheckbox
                    checked={relocation}
                    color={job?.relocation ? 'default' : 'red'}
                    label="Offers relocation"
                    onChange={() => {
                      handleCheckboxChange('relocation', !relocation);
                    }}
                  />
                  <CustomCheckbox
                    checked={visaSponsorship}
                    color={job?.visaSponsorship ? 'default' : 'red'}
                    label="Provides visa sponsorship"
                    onChange={() => {
                      handleCheckboxChange('visaSponsorship', !visaSponsorship);
                    }}
                  />
                </Stack>
              </Box>
            </Box>

            <Divider />

            {/* Extra Information */}
            {job?.extraInformation && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  level="h4"
                  sx={{
                    fontSize: layoutConfig.fontSize.title,
                    fontWeight: 'bold',
                    mb: { xs: 1, sm: 1.5, md: 2 },
                  }}
                >
                  Extra information you have to know
                </Typography>
                <RawHTML
                  style={{
                    color: '#212529bf',
                    fontSize: 'var(--joy-fontSize-md)',
                  }}
                  children={job?.extraInformation}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </BluebirdCard>
  );
};

export default JobInfoCard;

import { User } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';

export function useGetUsers() {
  return useQuery<User[]>({
    // TODO: change query key to users
    queryKey: ['useGetUsers'],
    queryFn: async () => {
      const { data } = await supabase.from('users').select('*');
      return data ? toCamelCase<User[]>(data) : [];
    },
  });
}

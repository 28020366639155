import { Box, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../layout';
import { CreateCompanyWizard, useGetCompanyById } from '@bluebird-monorepo/companies';
import { debug } from '../../config/environment';

const EditCompanyPage = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { data: company, isLoading, error } = useGetCompanyById(Number(companyId));

  if (isLoading) {
    return (
      <Layout title="Edit Company">
        <Typography level="h4">Loading...</Typography>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title="Edit Company">
        <Typography level="h4">Error: {error.message}</Typography>
      </Layout>
    );
  }

  if (!company) {
    return (
      <Layout title="Edit Company">
        <Typography level="h4">Company not found</Typography>
      </Layout>
    );
  }

  return (
    <Layout title={`Edit ${company.name}`}>
      <Box sx={{ width: '100%' }}>
        <CreateCompanyWizard initialData={company} />
      </Box>
    </Layout>
  );
};

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  EditCompanyPage.whyDidYouRender = true;
}

export default EditCompanyPage;

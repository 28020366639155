import { Company } from '@bluebird-monorepo/types';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import {
  Button,
  AspectRatio,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  Input,
  Alert,
  Avatar,
} from '@mui/joy';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TimelineCompact } from '../Timeline';
import { Description, Edit, Image, Close } from '@mui/icons-material';
import { CompanyContractsUpload } from './CompanyContractsUpload';
import { useCompanyWebsiteAndLogo } from '../../hooks/useCompanyWebsiteAndLogo';

export interface CompanyHeaderProps {
  company: Company;
}

export const CompanyHeader: React.FC<CompanyHeaderProps> = ({ company }) => {
  const [showFullTimeline, setShowFullTimeline] = useState(false);
  const [openContractsModal, setOpenContractsModal] = useState(false);
  const navigate = useNavigate();

  const {
    isEditingDomain,
    startEditingDomain,
    cancelEditingDomain,
    domain,
    setDomain,
    fetchLogoAndSaveDomain,
    isLoading,
    error,
  } = useCompanyWebsiteAndLogo({
    company: {
      id: company.id,
      name: company.name,
      website: company.website,
    },
  });

  // Handle Enter key press in the domain input
  const handleDomainKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchLogoAndSaveDomain();
    } else if (e.key === 'Escape') {
      cancelEditingDomain();
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          mb: 2,
          background:
            'linear-gradient(to right, var(--joy-palette-background-level1), var(--joy-palette-background-surface))',
        }}
      >
        <CardContent>
          {error && (
            <Alert color="danger" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Breadcrumbs size="sm" sx={{ pl: 1 }}>
              <Typography component={RouterLink} to="/companies" color="neutral">
                Companies
              </Typography>
              <Typography>{company.name}</Typography>
            </Breadcrumbs>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => setOpenContractsModal(true)}
                startDecorator={<Description />}
                size="sm"
              >
                Contracts
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => navigate(`/companies/${company.id}/edit`)}
                startDecorator={<Edit />}
                size="sm"
              >
                Edit
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3, alignItems: 'flex-start' }}>
            <AspectRatio
              ratio="1"
              sx={{
                width: 40,
                marginLeft: 1,
                borderRadius: 'md',
                bgcolor: 'background.surface',
                flexShrink: 0,
              }}
            >
              {company.logoUrl && company.logoUrl !== 'noLogo' ? (
                <Avatar
                  alt={company.name}
                  src={company.logoUrl}
                  size="lg"
                  variant="outlined"
                  sx={{
                    width: 40,
                    height: 40,
                    '--Avatar-size': `40px`,
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                />
              ) : (
                <Typography level="h2" sx={{ color: 'text.secondary' }}>
                  {company.name.charAt(0)}
                </Typography>
              )}
            </AspectRatio>

            <Box sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Box>
                  <Typography level="h2">{company.name}</Typography>
                  <Stack direction="row" spacing={1} useFlexGap sx={{ mb: 1 }}>
                    {company.industry && (
                      <Chip size="sm" variant="soft" color="primary">
                        {company.industry}
                      </Chip>
                    )}

                    {company.status && (
                      <Chip size="sm" variant="soft" color="neutral">
                        {company.status.charAt(0).toUpperCase() + company.status.slice(1)}
                      </Chip>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={4} useFlexGap>
                    {company.location && (
                      <Typography level="body-sm" startDecorator={<LocationOnIcon />}>
                        {company.location}
                      </Typography>
                    )}
                    {isEditingDomain ? (
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Input
                          autoFocus
                          placeholder="example.com"
                          onChange={(e) => setDomain(e.target.value)}
                          onKeyDown={handleDomainKeyDown}
                          size="sm"
                          startDecorator={<PublicIcon />}
                          sx={{ minWidth: 200 }}
                          value={domain}
                        />
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <Button
                            size="sm"
                            variant="outlined"
                            color="primary"
                            onClick={() => fetchLogoAndSaveDomain()}
                            loading={isLoading}
                            startDecorator={<Image />}
                            disabled={!domain}
                          >
                            Save & Fetch Logo
                          </Button>
                          <Button
                            size="sm"
                            variant="plain"
                            color="neutral"
                            onClick={cancelEditingDomain}
                            startDecorator={<Close />}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    ) : company.website ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          level="body-sm"
                          startDecorator={<PublicIcon />}
                          component="a"
                          href={company.website}
                          target="_blank"
                          sx={{ color: 'primary.500', textDecoration: 'none' }}
                        >
                          {(() => {
                            try {
                              return new URL(company.website).hostname;
                            } catch (e) {
                              return company.website;
                            }
                          })()}
                        </Typography>
                        <Button
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onClick={startEditingDomain}
                          startDecorator={<Edit />}
                        />
                      </Box>
                    ) : (
                      <Button
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={startEditingDomain}
                        startDecorator={<PublicIcon />}
                      >
                        Add Website
                      </Button>
                    )}
                    {company.financeContact?.email && (
                      <Typography
                        level="body-sm"
                        startDecorator={<EmailIcon />}
                        component="a"
                        href={`mailto:${company.financeContact.email}`}
                        sx={{ color: 'primary.500', textDecoration: 'none' }}
                      >
                        {company.financeContact.email}
                      </Typography>
                    )}
                  </Stack>
                </Box>
                <Box sx={{ marginTop: '-50px', width: '30%' }}>
                  <TimelineCompact
                    entityType="companies"
                    timeline={company.timeline}
                    onViewAll={() => setShowFullTimeline(true)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <CompanyContractsUpload
        company={company}
        open={openContractsModal}
        onClose={() => setOpenContractsModal(false)}
      />
    </>
  );
};

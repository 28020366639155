import { CandidateJobAssignment } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';

export function useGetAssignmentById(id: number | undefined) {
  return useQuery<CandidateJobAssignment | null>({
    enabled: !!id,
    queryKey: ['assignmentById', id],
    queryFn: () => getAssignmentById(id),
  });
}

export async function getAssignmentById(id: number | undefined) {
  const { data } = await supabase.from('assignments').select('*').eq('id', id).single();
  return data ? toCamelCase<CandidateJobAssignment>(data) : null;
}

export function useGetAssignmentsByOwner(email: string | undefined) {
  return useQuery<CandidateJobAssignment[]>({
    enabled: !!email,
    queryKey: ['assignmentsByOwner', email],
    queryFn: async () => {
      const { data } = await supabase.from('assignments').select('*').eq('owner', email);
      return data ? toCamelCase<CandidateJobAssignment[]>(data) : [];
    },
  });
}

export function useGetAssignmentsByCandidateId(candidateId: number | undefined) {
  return useQuery<CandidateJobAssignment[]>({
    enabled: !!candidateId,
    queryKey: ['assignmentsByCandidateId', candidateId],
    queryFn: async () => {
      const { data } = await supabase.from('assignments').select('*').eq('candidate_id', candidateId);
      return data ? toCamelCase<CandidateJobAssignment[]>(data) : [];
    },
  });
}

export function useGetAssignmentsByJobId(jobId: number | undefined) {
  return useQuery<CandidateJobAssignment[]>({
    enabled: !!jobId,
    queryKey: ['assignmentsByJobId', jobId],
    queryFn: async () => {
      const { data } = await supabase.from('assignments').select('*').eq('job_id', jobId);
      return data ? toCamelCase<CandidateJobAssignment[]>(data) : [];
    },
  });
}

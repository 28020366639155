import { User } from '@bluebird-monorepo/types';
import { FormControl, FormHelperText, FormLabel, Option, Select, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';
import { useGetTeams } from '../../../api/teams/useGetTeams';

export function TeamStep() {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<User>();
  const { data: teams, isLoading } = useGetTeams();
  const currentTeamId = watch('teamId');

  const handleTeamChange = (event: any, value: number | null) => {
    setValue('teamId', value || 0);
  };

  return (
    <Stack spacing={3}>
      <Typography level="h4">Team Assignment</Typography>

      <FormControl error={!!errors.teamId}>
        <FormLabel>Select Team (Optional)</FormLabel>
        <Select
          component="select"
          {...register('teamId')}
          value={currentTeamId}
          onChange={handleTeamChange}
          placeholder="Select a team"
          disabled={isLoading}
        >
          <Option value="">No Team</Option>
          {teams?.map((team) => (
            <Option key={team.id} value={team.id}>
              {team.name}
            </Option>
          ))}
        </Select>
        {errors.teamId && <FormHelperText>{errors.teamId.message as string}</FormHelperText>}
      </FormControl>

      <Typography level="body-sm" textColor="text.secondary">
        Assigning a team is optional. Users can be added to a team later.
      </Typography>
    </Stack>
  );
}

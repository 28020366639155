import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Skill } from '@bluebird-monorepo/types';

export function useUpsertSkills() {
  const queryClient = useQueryClient();
  return useMutation<Skill, Error, Partial<Skill>>({
    mutationFn: upsertSkill,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['skills'] });
    },
  });
}

export async function upsertSkill(skill: Partial<Skill>) {
  if (!skill.name) throw new Error('Skill name is required');
  const skillName = skill.name;
  // First find all skills that are similar to the input name
  const { data: similarSkills } = await supabase.from('skills').select('*').ilike('name', `%${skillName}%`);
  if (!similarSkills?.length) {
    // If no similar skills found, insert new skill
    const { data, error } = await supabase.from('skills').insert(toSnakeCase(skill)).select().single();
    if (error) throw error;
    return toCamelCase<Skill>(data);
  }
  // Check for exact lowercase match
  const exactMatch = similarSkills.find(
    (existingSkill) => existingSkill.name.toLowerCase() === skillName.toLowerCase(),
  );
  if (exactMatch) return toCamelCase<Skill>(exactMatch);
  // If no exact match but similar skills exist, insert new skill
  const { data, error } = await supabase.from('skills').insert(toSnakeCase(skill)).select().single();
  if (error) throw error;
  return toCamelCase<Skill>(data);
}

import { Candidate } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertCandidate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (candidate: Partial<Candidate>) => {
      const { data, error } = await supabase
        .from('candidates')
        .upsert(toSnakeCase(candidate), {
          onConflict: 'id',
        })
        .select()
        .single();
      if (error) throw error;
      return toCamelCase<Candidate>(data);
    },
    onSuccess: (candidate: Candidate) => {
      queryClient.invalidateQueries({ queryKey: ['candidates'] });
      queryClient.invalidateQueries({ queryKey: ['candidateById', candidate.id] });
    },
  });
}

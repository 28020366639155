import { StyledEventCard } from '@bluebird-monorepo/bluebird-ui';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { Business, Clear, Person, Schedule, Work } from '@mui/icons-material';
import { Box, Chip, IconButton, Link, Stack, Typography } from '@mui/joy';
import { EVENT_COLORS } from './EventDetailDialog/EventDetailDialog';
import { formatTime } from '../utils/date.utils';

interface EventCardProps {
  event: CalendarEvent;
  onEventClick: (event: CalendarEvent) => void;
  onRemoveEvent: (event: React.MouseEvent, calendarEvent: CalendarEvent) => void;
  jobTitle?: string;
}

export function EventCard({ event, onEventClick, onRemoveEvent, jobTitle }: EventCardProps) {
  const getEventDomain = (eventType: string) => {
    if (eventType?.includes('Candidate')) return 'Candidate';
    if (eventType?.includes('BD Call') || eventType?.includes('Weekly update')) return 'Client';
    return 'Internal';
  };

  const handleJobClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event card click
  };

  const getDomainIcon = (domain: string) => {
    switch (domain) {
      case 'Candidate':
        return <Person sx={{ fontSize: 'small' }} />;
      case 'Client':
        return <Business sx={{ fontSize: 'small' }} />;
      default:
        return null;
    }
  };

  const domain = event?.typeOfEvent ? getEventDomain(event.typeOfEvent) : 'Internal';
  const domainColor = EVENT_COLORS[domain];

  return (
    <StyledEventCard
      onClick={() => onEventClick(event)}
      sx={{
        position: 'relative',
        p: 1.5,
        bgcolor: 'var(--joy-palette-background-surface)',
        borderColor: 'var(--joy-palette-divider)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 'var(--joy-radius-sm)',
        overflow: 'hidden',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          borderColor: 'var(--joy-palette-neutral-300)',
          bgcolor: 'var(--joy-palette-background-level1)',
          transform: 'translateY(-1px)',
          boxShadow: '0 1px 2px 0 var(--joy-palette-neutral-200)',
          '&:before': {
            opacity: 1,
          },
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '3px',
          bgcolor: domainColor,
          opacity: 0.7,
          transition: 'opacity 0.2s ease-in-out',
        },
      }}
    >
      <Stack spacing={0.5}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 0.5 }}>
          <Typography
            level="title-sm"
            sx={{
              fontWeight: 'var(--joy-fontWeight-lg)',
              color: 'var(--joy-palette-text-primary)',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 'var(--joy-lineHeight-sm)',
            }}
          >
            {event?.title}
          </Typography>

          <IconButton
            size="sm"
            variant="plain"
            color="neutral"
            onClick={(e) => onRemoveEvent(e, event)}
            sx={{
              '--IconButton-size': '24px',
              opacity: 0,
              transition: 'opacity 0.2s',
              color: 'var(--joy-palette-text-tertiary)',
              '.MuiStyledEventCard-root:hover &': {
                opacity: 1,
              },
              '&:hover': {
                bgcolor: 'var(--joy-palette-background-level2)',
              },
            }}
          >
            <Clear fontSize="small" />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', color: 'var(--joy-palette-text-secondary)' }}>
          <Schedule sx={{ fontSize: 'var(--joy-fontSize-sm)' }} />
          <Typography level="body-sm" sx={{ fontWeight: 'var(--joy-fontWeight-md)' }}>
            {formatTime(event?.duration?.start as Date)} - {formatTime(event?.duration?.end as Date)}
          </Typography>
        </Box>

        {event?.typeOfEvent && (
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
            <Chip
              color={domainColor}
              size="sm"
              startDecorator={getDomainIcon(domain)}
              sx={{
                '--Chip-minHeight': '20px',
                '--Chip-paddingInline': '6px',
                fontSize: 'var(--joy-fontSize-xs)',
                fontWeight: 'var(--joy-fontWeight-md)',
                letterSpacing: '0.02em',
                borderRadius: 'var(--joy-radius-xs)',
                bgcolor: `${domainColor}15`,
                color: domainColor,
                border: '1px solid',
                borderColor: `${domainColor}30`,
              }}
              variant="soft"
            >
              {event.typeOfEvent}
            </Chip>
          </Box>
        )}

        {event.typeOfEvent === 'Candidate interview' && event.jobId && (
          <Link
            href={`/jobs/${event.jobId}`}
            onClick={handleJobClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              color: 'var(--joy-palette-primary-plainColor)',
              fontSize: 'var(--joy-fontSize-xs)',
              textDecoration: 'none',
              mt: 0.5,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Work sx={{ fontSize: 'var(--joy-fontSize-sm)' }} />
            {jobTitle || 'View job details'}
          </Link>
        )}

        {event.attendees && event.attendees.length > 0 && (
          <Typography
            level="body-xs"
            sx={{
              mt: 0.5,
              color: 'var(--joy-palette-text-tertiary)',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontSize: 'var(--joy-fontSize-xs)',
            }}
          >
            <Person sx={{ fontSize: 'var(--joy-fontSize-sm)' }} />
            {event.attendees.length} attendee{event.attendees.length !== 1 ? 's' : ''}
          </Typography>
        )}
      </Stack>
    </StyledEventCard>
  );
}

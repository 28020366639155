import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Role } from '@bluebird-monorepo/types';

export function useUpsertRoles() {
  const queryClient = useQueryClient();
  return useMutation<Role, Error, Partial<Role>>({
    mutationFn: upsertRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles'] });
    },
  });
}

export async function upsertRole(role: Partial<Role>) {
  if (!role.name) throw new Error('Role name is required');
  const roleName = role.name;
  // First find all roles that are similar to the input name
  const { data: similarRoles } = await supabase.from('roles').select('*').ilike('name', `%${roleName}%`);
  // If no similar roles found, insert new role
  if (!similarRoles?.length) {
    const { data, error } = await supabase.from('roles').insert(toSnakeCase(role)).select().single();
    if (error) throw error;
    return toCamelCase<Role>(data);
  }
  // Check for exact lowercase match
  const exactMatch = similarRoles.find((existingRole) => existingRole.name.toLowerCase() === roleName.toLowerCase());
  if (exactMatch) return toCamelCase<Role>(exactMatch);
  // If no exact match but similar roles exist, insert new role
  const { data, error } = await supabase.from('roles').insert(toSnakeCase(role)).select().single();
  if (error) throw error;
  return toCamelCase<Role>(data);
}

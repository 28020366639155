import { useGetLogsRead } from './useGetLogsRead';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { useUpsertUser } from '@bluebird-monorepo/users';

type LogsReadPayload = {
  logsRead?: number[];
  logsReadBefore?: string;
};

export function useUpsertLogsRead() {
  const queryClient = useQueryClient();
  const { data: currentUser } = useGetCurrentUser();
  const { mutateAsync: upsertUser } = useUpsertUser();
  return useMutation({
    mutationFn: async ({ logsRead, logsReadBefore }: LogsReadPayload) => {
      if (!currentUser?.email) throw new Error('No user found');
      if (!logsReadBefore && !logsRead) throw new Error('logsReadBefore or logsRead is required');
      const newLogsRead = {
        ...(logsReadBefore && { logs_read_before: logsReadBefore }),
        ...(logsRead && { logs_read: logsRead }),
      };
      const user = await upsertUser({
        id: currentUser.id,
        ...newLogsRead,
      });
      return user;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['logsRead', currentUser?.email] });
    },
  });
}

export function useMarkLogRead() {
  const { data: logsReadData } = useGetLogsRead();
  const upsertLogsRead = useUpsertLogsRead();
  return {
    isLoading: upsertLogsRead.isPending,
    error: upsertLogsRead.error,
    mutate: async (logId: number) => {
      const currentLogsRead = logsReadData?.logsRead || [];
      if (!currentLogsRead.includes(logId)) {
        await upsertLogsRead.mutateAsync({
          logsRead: [...currentLogsRead, logId],
        });
      }
    },
  };
}

export function useMarkRelatedLogsRead() {
  const { data: logsReadData } = useGetLogsRead();
  const upsertLogsRead = useUpsertLogsRead();
  return {
    isLoading: upsertLogsRead.isPending,
    error: upsertLogsRead.error,
    mutate: async (logIds: number[]) => {
      const currentLogsRead = logsReadData?.logsRead || [];
      await upsertLogsRead.mutateAsync({
        logsRead: Array.from(new Set([...currentLogsRead, ...logIds])),
      });
    },
  };
}

export function useMarkAllLogsRead() {
  const upsertLogsRead = useUpsertLogsRead();
  return {
    isLoading: upsertLogsRead.isPending,
    error: upsertLogsRead.error,
    mutate: async () => {
      await upsertLogsRead.mutateAsync({
        logsReadBefore: new Date().toISOString(),
      });
    },
  };
}

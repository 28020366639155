import { IntroductionWizard } from '@bluebird-monorepo/candidates';
import { Box, Button, Typography } from '@mui/joy';
import { FC, useState } from 'react';
import { Layout } from '../../../layout';
import { IntroductionTable } from '@bluebird-monorepo/candidates';

export const IntroductionsPage: FC = () => {
  const [showWizard, setShowWizard] = useState(false);
  const [wizardData, setWizardData] = useState<{ candidateId: number; jobId: number } | null>(null);

  const handleStartWizard = () => {
    // TODO: Add logic to get candidateId and jobId
    setWizardData({
      candidateId: 0,
      jobId: 0,
    });
    setShowWizard(true);
  };

  const handleEndWizard = () => {
    setShowWizard(false);
    setWizardData(null);
  };

  return (
    <Layout title="Introductions">
      <Box sx={{ p: 3, height: '100%' }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography level="h2">Introductions</Typography>
          <Button onClick={handleStartWizard} size="lg">
            Create Introduction
          </Button>
        </Box>

        {showWizard && wizardData ? (
          <IntroductionWizard
            candidateId={wizardData.candidateId}
            jobId={wizardData.jobId}
            onComplete={handleEndWizard}
            onCancel={handleEndWizard}
          />
        ) : (
          <IntroductionTable />
        )}
      </Box>
    </Layout>
  );
};

import { Candidate } from '@bluebird-monorepo/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type CandidateFiltersGroup = {
  conditions: CandidateFiltersGroupCondition[];
  id: string;
  operator: 'and' | 'or';
  subgroups: CandidateFiltersGroup[];
};

export type CandidateFiltersGroupCondition = {
  field: string;
  id: string;
  operator: string;
  value: boolean | number | string;
};

type SortField = keyof Pick<
  Candidate,
  'firstName' | 'owner' | 'status' | 'currentPosition' | 'city' | 'score' | 'currentOrganization' | 'id'
>;

interface SortConfig {
  field: SortField | null;
  direction: 'asc' | 'desc';
}

interface CandidatesTableState {
  sortConfig: SortConfig;
  setSortConfig: (sortConfig: SortConfig) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  filters: CandidateFiltersGroup[];
  setFilters: (filters: CandidateFiltersGroup[]) => void;
  search: string;
  setSearch: (search: string) => void;
  showOnlyMine: boolean;
  setShowOnlyMine: (showOnlyMine: boolean) => void;
  page: number;
  setPage: (page: number) => void;
}

export const useCandidatesTableStore = create<CandidatesTableState>()(
  persist(
    (set) => ({
      sortConfig: {
        field: 'id',
        direction: 'desc',
      },
      setSortConfig: (sortConfig) => set({ sortConfig }),
      rowsPerPage: 10,
      setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
      filters: [],
      setFilters: (filters) => set({ filters }),
      search: '',
      setSearch: (search) => set({ search }),
      showOnlyMine: false,
      setShowOnlyMine: (showOnlyMine) => set({ showOnlyMine }),
      page: 1,
      setPage: (page) => set({ page }),
    }),
    {
      name: 'candidates-table',
    },
  ),
);

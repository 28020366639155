import { GoogleIcon } from '@bluebird-monorepo/bluebird-ui';
import { DarkModeRounded, LightModeRounded } from '@mui/icons-material';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import CssBaseline from '@mui/joy/CssBaseline';
import GlobalStyles from '@mui/joy/GlobalStyles';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
      size="sm"
      variant="outlined"
      {...other}
    >
      {mode === 'light' ? <DarkModeRounded /> : <LightModeRounded />}
    </IconButton>
  );
}

export default function SignIn() {
  const navigate = useNavigate();
  const { user, isLoading, isAuthenticated, signIn } = useAuth();

  // If user is already logged in, redirect to dashboard
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate('/');
    }
  }, [isAuthenticated, isLoading, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      await signIn();
      // The actual redirect will be handled by the useEffect above when user data is loaded
    } catch (error) {
      console.error('Error signing in with Google', error);
    }
  };

  // If still checking authentication status, show loading indicator
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          width: { md: '50vw', xs: '100%' },
          zIndex: 1,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            px: 2,
            width: '100%',
          }}
        >
          <Box
            component="header"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              py: 3,
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
              <img alt="Logo" src="./LogoBB.svg" style={{ height: '50px' }} />
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
              borderRadius: 'sm',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxWidth: '100%',
              mx: 'auto',
              my: 'auto',
              pb: 5,
              py: 2,
              width: 400,
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Sign in
                </Typography>
                <Typography level="body-sm">Only members of the organization can access this platform.</Typography>
              </Stack>
              <Button
                color="neutral"
                fullWidth
                onClick={handleGoogleSignIn}
                startDecorator={<GoogleIcon />}
                variant="soft"
              >
                Continue with Google
              </Button>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Bluebird {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: 'background.level1',
          backgroundImage: 'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          bottom: 0,
          height: '100%',
          left: { md: '50vw', xs: 0 },
          position: 'fixed',
          right: 0,
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
          top: 0,
          transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
        })}
      />
    </CssVarsProvider>
  );
}

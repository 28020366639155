import { Chip } from '@mui/joy';

const statusConfig = {
  client: {
    color: 'success' as const,
    label: 'Client',
  },
  prospect: {
    color: 'warning' as const,
    label: 'Prospect',
  },
  partner: {
    color: 'primary' as const,
    label: 'Partner',
  },
  none: {
    color: 'neutral' as const,
    label: 'None',
  },
} as const;

export function StatusChip({ status }: { status: string }) {
  const config = statusConfig[status as keyof typeof statusConfig] || statusConfig.none;

  return (
    <Chip size="sm" variant="soft" color={config.color}>
      {config.label}
    </Chip>
  );
}

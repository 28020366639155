import { CreateJobWizard, JobDetails, JobHeader, useGetJobById, useUpsertJob } from '@bluebird-monorepo/jobs';
import { TaskBoard, useGetTasks } from '@bluebird-monorepo/tasks';
import { Job } from '@bluebird-monorepo/types';
import { Box, Button, Tab, TabList, Tabs, Typography, Tooltip, Snackbar, IconButton } from '@mui/joy';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Outlet, Link as RouterLink, useLocation, useOutletContext, useParams, useNavigate } from 'react-router-dom';
import {
  Layout,
  LayoutContentHeader,
  LayoutTitleBox,
  LayoutTitle,
  LayoutSubtitle,
  LayoutActionButtonBox,
} from '../../../layout';
import { useGetAssignmentsByJobId } from '@bluebird-monorepo/assignments';
import { ContentCopy, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { JobsTimelineTable } from '@bluebird-monorepo/jobs';
import { useGetJobs } from '@bluebird-monorepo/jobs';
import { useJobsTableStore } from '@bluebird-monorepo/jobs';
import { useVerticals } from '@bluebird-monorepo/shared';
import { debug } from '../../config/environment';

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
    onlyLogs: true,
    exclude: [/^CandidateAssignmentSection/],
  });
}

type JobContext = {
  job: Job;
};

export const GeneralTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <JobDetails job={job} />;
};

export const CandidatesTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <JobDetails job={job} />;
};

export const EventsTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <JobDetails job={job} />;
};

export const TimelineTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <JobsTimelineTable jobId={job.id} />;
};

export const TasksTab = () => {
  const { job } = useOutletContext<JobContext>();
  return <TaskBoard entityType="job" entityId={job.id} />;
};

export const JobDetailsPage = () => {
  const { jobId: jobIdString } = useParams<{ jobId: string }>();
  const jobId = Number(jobIdString);
  const { data: job, isLoading, error } = useGetJobById(jobId);
  const { data: assignments } = useGetAssignmentsByJobId(jobId);
  const { data: tasks } = useGetTasks({ entityType: 'job', entityId: jobId });
  const [isEditing, setIsEditing] = useState(false);
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const createJobMutation = useUpsertJob();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { verticals } = useVerticals();

  const { page, setPage, rowsPerPage: rowsPerPageStored, sortConfig, filters, search } = useJobsTableStore();
  useEffect(() => {
    setRowsPerPage(rowsPerPageStored);
  }, []);

  const { data: jobsData } = useGetJobs({
    page,
    pageSize: rowsPerPage,
    sortField: sortConfig.field,
    sortDirection: sortConfig.direction,
    search,
    responsible: filters.responsible,
    statuses: filters.statusName,
    vertical: filters.vertical ? verticals.find((v) => v.name === filters.vertical) : undefined,
    priority: filters.priority || undefined,
    location: filters.location,
    salaryGt: filters.salaryGt ? parseFloat(filters.salaryGt) : undefined,
    salaryLt: filters.salaryLt ? parseFloat(filters.salaryLt) : undefined,
    feeGt: filters.feeGt ? parseFloat(filters.feeGt) : undefined,
    feeLt: filters.feeLt ? parseFloat(filters.feeLt) : undefined,
  });

  // Fetch previous page if not on first page
  const { data: previousPageData } = useGetJobs({
    page: page > 1 ? page - 1 : 1,
    pageSize: rowsPerPage,
    sortField: sortConfig.field,
    sortDirection: sortConfig.direction,
    search,
    responsible: filters.responsible,
    statuses: filters.statusName,
    vertical: filters.vertical ? verticals.find((v) => v.name === filters.vertical) : undefined,
    priority: filters.priority || undefined,
    location: filters.location,
    salaryGt: filters.salaryGt ? parseFloat(filters.salaryGt) : undefined,
    salaryLt: filters.salaryLt ? parseFloat(filters.salaryLt) : undefined,
    feeGt: filters.feeGt ? parseFloat(filters.feeGt) : undefined,
    feeLt: filters.feeLt ? parseFloat(filters.feeLt) : undefined,
  });

  const jobs = [...(previousPageData?.jobs || []), ...(jobsData?.jobs || [])];
  const currentIndex = jobs.findIndex((j) => j.id === jobId);
  const prevJob = currentIndex > 0 ? jobs[currentIndex - 1] : null;
  const nextJob = currentIndex < jobs.length - 1 ? jobs[currentIndex + 1] : null;

  useEffect(() => {
    if (!nextJob) {
      setRowsPerPage(rowsPerPage * (page + 1));
    }
  }, [nextJob]);

  const handleNavigate = (id: number) => {
    navigate(`/jobs/${id}`);
  };

  const handlePreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  const handleStartEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleEditComplete = useCallback(
    async (updatedData: Partial<Job>) => {
      if (!job) return;

      await createJobMutation.mutateAsync({
        ...job,
        ...updatedData,
        salary: typeof updatedData.salary === 'string' ? parseInt(updatedData.salary, 10) : updatedData.salary,
      });

      setIsEditing(false);
    },
    [job, createJobMutation],
  );

  const handleEditCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const getInitialFormData = useCallback(
    (job: Job) => ({
      id: job.id,
      companyId: job.companyId,
      companyName: job.companyName,
      jobTitle: job.jobTitle,
      location: job.location || '',
      contractType: job.contractType || '',
      roleDescription: job.roleDescription || '',
      productDescription: job.productDescription || '',
      salaryRange: job.salaryRange || { min: 0, max: 0 },
      feePercentage: job.feePercentage || 0,
      preferredLanguages: job.preferredLanguages || [],
      techStack: job.techStack || '',
      mustHaves: job.mustHaves || '',
      niceToHaves: job.niceToHaves || '',
      keyResponsibilities: job.keyResponsibilities || '',
      hiringProcess: job.hiringProcess || '',
      howToIntro: job.howToIntro || '',
      openSinceDate: job.openSinceDate || undefined,
    }),
    [],
  );

  const actionButton = useMemo(
    () =>
      job
        ? {
            label: 'Edit',
            onClick: handleStartEdit,
            variant: 'warning' as const,
          }
        : undefined,
    [job, handleStartEdit],
  );

  const tabs = useMemo(() => {
    if (!jobId) return [];
    return [
      { id: 'general', label: 'General', path: 'general' },
      { id: 'candidates', label: `Candidates (${assignments?.length || 0})`, path: 'candidates' },
      { id: 'events', label: 'Events', path: 'events' },
      { id: 'timeline', label: 'Timeline', path: 'timeline' },
      { id: 'tasks', label: `Tasks (${tasks?.length || 0})`, path: 'tasks' },
    ];
  }, [jobId, assignments?.length, tasks?.length]);

  const currentPath = location.pathname;
  const activeTab = tabs.find((tab) => currentPath.endsWith(tab.id)) ?? tabs[0];

  const handleCopyJobDetails = useCallback(() => {
    if (!job) return;

    try {
      // Create a clean copy of the job object for clipboard
      const jobCopy = JSON.stringify(job, null, 2);
      navigator.clipboard.writeText(jobCopy);
      setShowCopySuccess(true);
    } catch (err) {
      console.error('Failed to copy job details:', err);
    }
  }, [job]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Typography level="h4">Loading...</Typography>;
    }

    if (error) {
      return <Typography level="h4">Error: {error.message}</Typography>;
    }

    if (!job) {
      return <Typography level="h4">Job not found</Typography>;
    }

    if (isEditing) {
      return <CreateJobWizard initialData={getInitialFormData(job)} />;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        {job && <JobHeader job={job} />}

        <Tabs value={activeTab.id} sx={{ mb: 2 }}>
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.id} value={tab.id} component={RouterLink} to={tab.path}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        {/* Use Outlet for nested routes */}
        <Outlet context={{ job }} />

        {/* Success notification */}
        <Snackbar
          variant="soft"
          color="success"
          autoHideDuration={2000}
          open={showCopySuccess}
          onClose={() => setShowCopySuccess(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          Job details copied to clipboard!
        </Snackbar>
      </Box>
    );
  }, [
    isLoading,
    error,
    job,
    isEditing,
    getInitialFormData,
    handleEditComplete,
    handleEditCancel,
    activeTab.id,
    tabs,
    handleCopyJobDetails,
    showCopySuccess,
  ]);

  return (
    <Layout areCompoundComponents>
      <LayoutContentHeader>
        <LayoutTitleBox>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <LayoutTitle>{job?.jobTitle}</LayoutTitle>
          </Box>
          <LayoutSubtitle>{job?.companyName}</LayoutSubtitle>
        </LayoutTitleBox>
        <LayoutActionButtonBox>
          <Tooltip title="Copy job details as JSON" placement="top">
            <Button
              variant="soft"
              color="neutral"
              size="sm"
              startDecorator={<ContentCopy />}
              onClick={handleCopyJobDetails}
              sx={{
                borderRadius: 'md',
                transition: 'all 0.2s',
                '&:hover': {
                  backgroundColor: 'neutral.softHoverBg',
                  transform: 'translateY(-1px)',
                },
              }}
            >
              Copy Job Details
            </Button>
          </Tooltip>
          <IconButton
            onClick={() => {
              if (prevJob) {
                handleNavigate(prevJob.id);
              } else if (page > 1) {
                handlePreviousPage();
              }
            }}
            disabled={!prevJob && page <= 1}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() => nextJob && handleNavigate(nextJob.id)}
            disabled={!nextJob}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <ChevronRight />
          </IconButton>
        </LayoutActionButtonBox>
      </LayoutContentHeader>
      {content}
    </Layout>
  );
};

export default JobDetailsPage;

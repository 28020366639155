import { User } from '@bluebird-monorepo/types';
import { Box, Divider, Stack, Typography } from '@mui/joy';
import { useFormContext } from 'react-hook-form';
import { useGetTeams } from '../../../api/teams/useGetTeams';

export function ReviewStep() {
  const { watch } = useFormContext<User>();
  const values = watch();
  const { data: teams } = useGetTeams();

  const selectedTeam = teams?.find((team) => team.id === Number(values.teamId));

  const InfoRow = ({ label, value }: { label: string; value?: string | boolean }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
      <Typography level="body-sm" textColor="text.secondary">
        {label}
      </Typography>
      <Typography level="body-sm">{value?.toString() || 'Not provided'}</Typography>
    </Box>
  );

  return (
    <Stack spacing={3}>
      <Typography level="h4">Review Information</Typography>

      <Box sx={{ bgcolor: 'background.level1', p: 2, borderRadius: 'sm' }}>
        <Typography level="title-sm" sx={{ mb: 1 }}>
          Basic Information
        </Typography>
        <InfoRow label="Email" value={values.email} />
        <InfoRow label="Display Name" value={values.displayName} />
        <InfoRow label="Phone Number" value={values.phoneNumber} />
        <InfoRow label="Title" value={values.title} />

        <Divider sx={{ my: 2 }} />

        <Typography level="title-sm" sx={{ mb: 1 }}>
          Role & Access
        </Typography>
        <InfoRow label="Role" value={values.role} />
        <InfoRow label="Status" value={values.isActive ? 'Active' : 'Inactive'} />

        <Divider sx={{ my: 2 }} />

        <Typography level="title-sm" sx={{ mb: 1 }}>
          Team
        </Typography>
        <InfoRow label="Team" value={selectedTeam?.name} />
      </Box>

      <Typography level="body-sm" textColor="text.secondary">
        Please review the information above before creating the user.
      </Typography>
    </Stack>
  );
}

import { Company } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { useQuery } from '@tanstack/react-query';

export function useGetCompanyById(id: number) {
  return useQuery<Company | null>({
    enabled: !!id,
    queryKey: ['companyById', id],
    queryFn: async () => {
      const { data } = await supabase.from('companies').select('*').eq('id', id).single();
      return data ? toCamelCase<Company>(data) : null;
    },
  });
}

export interface LogTableColumn {
  id: string;
  name: string;
}

// Define all available columns
export const LOG_TABLE_COLUMNS: LogTableColumn[] = [
  { id: 'content', name: 'Content' },
  { id: 'changedFields', name: 'Changed Fields' },
  { id: 'tableName', name: 'Table' },
  { id: 'action', name: 'Action' },
  { id: 'entityId', name: 'Entity ID' },
  { id: 'authorId', name: 'Author' },
  { id: 'createdAt', name: 'Created At' },
];

import { BluebirdTable, ColumnDefinition } from '@bluebird-monorepo/bluebird-ui';
import { Team } from '@bluebird-monorepo/types';
import { useGetUsers } from '@bluebird-monorepo/users';
import { Avatar, Box, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

interface TeamsTableProps {
  teams: Team[];
  isLoading?: boolean;
  onEditTeam?: (teamId: number) => void;
}

export function TeamsTable({ teams }: TeamsTableProps) {
  const { data: users } = useGetUsers();
  const navigate = useNavigate();

  const handleTeamClick = (teamId: number) => {
    navigate(`/teams/${teamId}`);
  };

  const columns: ColumnDefinition<Team>[] = [
    {
      field: 'name',
      headerName: 'Team',
      width: '35%',
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={row.avatar} size="sm">
            {row.name[0]}
          </Avatar>
          <Box>
            <Box
              onClick={() => handleTeamClick(row.id)}
              sx={{
                cursor: 'pointer',
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {row.name}
            </Box>
            <Typography level="body-sm" textColor="text.secondary">
              {row.description || 'No description'}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'leader',
      headerName: 'Team Leader',
      width: '30%',
      renderCell: ({ row }) => {
        const leader = users?.find((user) => user.id === row.leaderId);
        return leader ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar src={leader.photoUrl} size="sm">
              {leader.displayName?.[0] || leader.email[0]}
            </Avatar>
            <Box>
              <Box>{leader.displayName || leader.email}</Box>
              <Typography level="body-sm" textColor="text.secondary">
                {leader.title || 'No title'}
              </Typography>
            </Box>
          </Box>
        ) : (
          'No leader assigned'
        );
      },
    },
    {
      field: 'members',
      headerName: 'Members',
      width: '20%',
      renderCell: ({ row }) => {
        const memberCount = row.members?.length || 0;
        const displayedMembers = row.members
          ?.slice(0, 3)
          .map((memberId) => users?.find((user) => user.id === memberId))
          .filter(Boolean);

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {displayedMembers?.map((member, index) => (
                <Avatar
                  key={member?.id}
                  src={member?.photoUrl}
                  size="sm"
                  sx={{
                    ml: index > 0 ? -1 : 0,
                    border: '2px solid',
                    borderColor: 'background.surface',
                  }}
                >
                  {member?.displayName?.[0] || member?.email[0]}
                </Avatar>
              ))}
            </Box>
            <Typography level="body-sm">
              {memberCount} member{memberCount !== 1 ? 's' : ''}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: '15%',
      renderCell: ({ row }) => new Date(row.createdAt || '').toLocaleDateString(),
    },
  ];

  return (
    <BluebirdTable
      data={teams}
      columns={columns}
      defaultRowsPerPage={10}
      rowsPerPageOptions={[10, 25, 50]}
      tableProps={{
        variant: 'outlined',
        sx: {
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '12px',
          '--TableCell-paddingX': '12px',
        },
      }}
    />
  );
}

export interface Responsible {
  id: number;
  name: string;
}

const RESPONSIBLES: Responsible[] = [
  {
    id: 0,
    name: 'None',
  },
  {
    id: 1,
    name: 'Dennis',
  },
  {
    id: 2,
    name: 'Jacob',
  },
  {
    id: 3,
    name: 'Mitchel',
  },
  {
    id: 4,
    name: 'Pontus',
  },
];

export const useResponsibles = () => {
  return {
    responsibles: RESPONSIBLES,
  };
};

import { useState, useEffect } from 'react';
import { useUpsertCompany } from '../api/companies/useUpsertCompany';
import { useUpsertStorageFile } from '@bluebird-monorepo/supabase';
import { fetchAndDownloadCompanyLogo } from '../api/logos/logoDevAPI';
import { supabase } from '@bluebird-monorepo/supabase';
import { toast } from 'react-toastify';

interface UseCompanyWebsiteAndLogoProps {
  company: {
    id: number;
    name: string;
    website?: string | null;
  };
}

export function useCompanyWebsiteAndLogo({ company }: UseCompanyWebsiteAndLogoProps) {
  const [isEditingDomain, setIsEditingDomain] = useState(false);
  const [domain, setDomain] = useState(() => {
    try {
      return company.website && company.website !== 'null' ? new URL(company.website).hostname : '';
    } catch (e) {
      // If URL parsing fails, return the raw website or empty string
      return company.website && company.website !== 'null' ? company.website : '';
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutateAsync: upsertCompany } = useUpsertCompany();
  const { mutateAsync: uploadStorageFile } = useUpsertStorageFile('uploads-public', 'company-logos');

  // Update domain when company website changes
  useEffect(() => {
    try {
      if (company.website && company.website !== 'null') {
        setDomain(new URL(company.website).hostname);
      }
    } catch (e) {
      // If URL parsing fails, use the raw website
      if (company.website && company.website !== 'null') {
        setDomain(company.website);
      }
    }
  }, [company.website]);

  // Start editing the domain
  const startEditingDomain = () => {
    setIsEditingDomain(true);
    setError(null);
  };

  // Cancel editing
  const cancelEditingDomain = () => {
    setIsEditingDomain(false);
    try {
      setDomain(company.website && company.website !== 'null' ? new URL(company.website).hostname : '');
    } catch (e) {
      // If URL parsing fails, use the raw website or empty string
      setDomain(company.website && company.website !== 'null' ? company.website : '');
    }
    setError(null);
  };

  // Format domain for storage
  const formatDomain = (input: string): string => {
    let formatted = input.trim();

    // Remove protocol if present
    if (formatted.includes('://')) {
      formatted = formatted.split('://')[1];
    }

    // Add default TLD if missing
    if (!formatted.includes('.')) {
      formatted = `${formatted}.com`;
    }

    return formatted;
  };

  // Fetch logo and save domain (combined functionality)
  const fetchLogoAndSaveDomain = async (skipCompanyUpdate = false) => {
    if (!domain) return;

    setIsLoading(true);
    setError(null);

    try {
      // Format the domain for consistency
      const formattedDomain = formatDomain(domain);
      const websiteUrl = `https://${formattedDomain}`;

      // If we're not skipping company updates, update the website URL first
      if (!skipCompanyUpdate) {
        await upsertCompany({
          id: company.id,
          website: websiteUrl,
        });
      }

      console.log('Fetching logo for', websiteUrl);
      // Fetch logo using company name as search term
      const { logoPath, domainMatched } = await fetchAndDownloadCompanyLogo(company.id, websiteUrl, uploadStorageFile);

      // Show appropriate toast message based on domain match
      if (!domainMatched) {
        if (!skipCompanyUpdate) {
          // Set logoUrl to 'noLogo' when no domain match is found
          await upsertCompany({
            id: company.id,
            logoUrl: 'noLogo',
          });
        }
        toast.warning(`No logo found for domain "${formattedDomain}".`);
        setIsEditingDomain(false);
        return null;
      }

      // If we got a logo URL, get the public URL using the supabase service
      if (logoPath) {
        // Parse the path from the fullPath
        const pathParts = logoPath.split('/');
        const bucket = pathParts[0];
        const path = pathParts.slice(1).join('/');

        // Get the public URL using the supabase service directly
        const { data } = supabase.storage.from(bucket).getPublicUrl(path);
        const publicLogoUrl = data.publicUrl;

        // Update the company with the logo URL if not skipping updates
        if (!skipCompanyUpdate) {
          await upsertCompany({
            id: company.id,
            logoUrl: publicLogoUrl,
          });
        }

        toast.success(`Logo found for ${company.name}`);
        setIsEditingDomain(false);
        return publicLogoUrl;
      } else {
        toast.info(`No logo was found.`);
        return null;
      }
    } catch (err) {
      setError('Failed to fetch logo or save website');
      console.error('Error fetching logo:', err);
      toast.error('Failed to fetch logo. Please try again.');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isEditingDomain,
    startEditingDomain,
    cancelEditingDomain,
    domain,
    setDomain,
    fetchLogoAndSaveDomain,
    isLoading,
    error,
  };
}

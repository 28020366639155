import { Company } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase } from '@bluebird-monorepo/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { snakeCase } from 'lodash';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@bluebird-monorepo/types';

export function useGetCompaniesByName(name: string) {
  return useQuery<Company[]>({
    enabled: !!name,
    queryKey: ['companiesByName', name],
    queryFn: async () => {
      const { data } = await supabase.from('companies').select('*').ilike('name', `%${name}%`);
      return data ? toCamelCase<Company[]>(data) : [];
    },
  });
}

/*
  Get companies with pagination, sorting, and filtering
*/

interface PaginatedResponse {
  companies: Company[];
  count: number;
}

interface UseGetCompaniesOptions {
  page: number;
  pageSize: number;
  sortField?: keyof Pick<Company, 'name' | 'owner' | 'status' | 'jobCount'>;
  sortDirection?: 'asc' | 'desc';
  search?: string;
  status?: string;
}

type UseGetCompaniesQuery = PostgrestFilterBuilder<Database['public'], Record<keyof Company, unknown>, Company>;

const DEFAULT_OPTIONS: UseGetCompaniesOptions = {
  page: 1,
  pageSize: 10,
};

export function useGetCompanies(optionsArg?: UseGetCompaniesOptions) {
  const options = { ...DEFAULT_OPTIONS, ...optionsArg };
  return useQuery<PaginatedResponse>({
    queryKey: ['companies', options],
    refetchOnMount: true,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const selectQuery = getSelectQuery(options);
      const countQuery = getCountQuery(options);
      const [{ data }, { count }] = await Promise.all([selectQuery, countQuery]);
      return {
        companies: data ? toCamelCase<Company[]>(data) : [],
        count: count || 0,
      };
    },
  });
}

function getSelectQuery(options: UseGetCompaniesOptions) {
  const { page, pageSize, sortField, sortDirection, search, status } = options;
  const selectQuery = supabase.from('companies').select('*').returns<Company>() as UseGetCompaniesQuery;
  // Search
  if (search) {
    selectQuery.ilike('name', `%${search}%`);
  }
  // Sorting
  if (sortField && sortDirection) {
    selectQuery.order(snakeCase(sortField), { ascending: sortDirection === 'asc' });
  }
  // Status
  if (status) {
    selectQuery.eq('status', getStatusFilter(status));
  }
  // Pagination
  const start = (page - 1) * pageSize;
  const end = start + pageSize - 1;
  selectQuery.range(start, end);
  return selectQuery;
}

function getCountQuery(options: UseGetCompaniesOptions) {
  const { search, status } = options;
  const countQuery = supabase
    .from('companies')
    .select('*', { count: 'exact', head: true })
    .returns<Company>() as UseGetCompaniesQuery;
  if (search) {
    countQuery.ilike('name', `%${search}%`);
  }
  if (status) {
    countQuery.eq('status', getStatusFilter(status));
  }
  return countQuery;
}

function getStatusFilter(status: string) {
  return status ? `"${status}"` : ''; // Quotes are present in database values for status
}

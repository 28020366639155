// import { toMillis } from '@bluebird-monorepo/calendar';
import { Job } from '@bluebird-monorepo/types';

export function calculateDataIntegrityAndFlags(job: Job) {
  const dataIntegrity = {
    missingOpenSinceDate: !job.openSinceDate,
    missingCandidatesData: job.candidates === undefined,
    missingEventStats: !job.eventStats,
    missingTimeline: !job.timeline?.entries?.length,
  };

  return {
    dataIntegrity,
    flags: {
      noActivity: getDaysSinceLastActivity(job) > 14,
      noCandidates:
        !job.candidates || (Array.isArray(job.candidates) ? job.candidates.length === 0 : job.candidates === 0),
    },
  };
}

function getDaysSinceLastActivity(job: Job): number {
  const lastActivity = job.timeline?.entries[0]?.timestamp;
  if (!lastActivity || typeof lastActivity !== 'string') return Infinity;
  const unixLastActivity = lastActivity ? Math.floor(new Date(lastActivity).getTime() / 1000) : 0;

  return Math.floor((Date.now() - unixLastActivity * 1000) / (1000 * 60 * 60 * 24));
}

import { useGetCalendarEvents } from '@bluebird-monorepo/calendar';
import { CalendarEvent } from '@bluebird-monorepo/types';
import { Box, CircularProgress, List, ListItem, ListItemButton, Typography } from '@mui/joy';
import { format, isToday, isYesterday, startOfDay, subDays } from 'date-fns';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

interface CalendarStepProps {
  onNext: () => void;
}

export const CalendarStep: FC<CalendarStepProps> = ({ onNext }) => {
  const { setValue, watch } = useFormContext();
  const selectedEvent = watch('calendarEvent');

  // Memoize the date range to prevent infinite loop
  const dateRange = useMemo(
    () => ({
      start: startOfDay(subDays(new Date(), 14)),
      end: new Date(),
    }),
    [],
  );

  const { data: events, isLoading } = useGetCalendarEvents({
    startDate: dateRange.start,
    endDate: dateRange.end,
  });

  // Group events by date
  const groupedEvents = useMemo(() => {
    const groups = new Map<string, CalendarEvent[]>();

    events?.forEach((event) => {
      const date = format((event?.duration?.start as Date) || new Date(), 'yyyy-MM-dd');
      const existing = groups.get(date) || [];
      groups.set(date, [...existing, event]);
    });

    return groups;
  }, [events]);

  // Sort dates in reverse chronological order
  const sortedDates = useMemo(() => {
    return Array.from(groupedEvents.keys()).sort((a, b) => {
      return new Date(b).getTime() - new Date(a).getTime();
    });
  }, [groupedEvents]);

  const handleEventSelect = (event: CalendarEvent) => {
    setValue('calendarEvent', event, { shouldValidate: true });
    // Automatically proceed to next step
    onNext();
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography level="h4" sx={{ mb: 2 }}>
        Select Calendar Event
      </Typography>
      <Typography level="body-sm" sx={{ mb: 3, color: 'neutral.500' }}>
        Choose a relevant calendar event from the last 2 weeks
      </Typography>

      <List>
        {sortedDates.map((date) => {
          const eventsForDate = groupedEvents.get(date) || [];
          const dateObj = new Date(date);

          let dateLabel = format(dateObj, 'EEEE, MMMM d');
          if (isToday(dateObj)) dateLabel = 'Today';
          if (isYesterday(dateObj)) dateLabel = 'Yesterday';

          return (
            <Box key={date} sx={{ mb: 2 }}>
              <Typography
                level="title-sm"
                sx={{
                  px: 2,
                  py: 1,
                  bgcolor: 'background.level1',
                  borderRadius: 'sm',
                }}
              >
                {dateLabel}
              </Typography>
              <List>
                {eventsForDate.map((event) => (
                  <ListItem key={event.id}>
                    <ListItemButton
                      selected={selectedEvent?.id === event.id}
                      onClick={() => handleEventSelect(event)}
                      sx={{
                        borderRadius: 'sm',
                        gap: 2,
                      }}
                    >
                      <Typography level="body-sm" sx={{ minWidth: 75 }}>
                        {format((event?.duration?.start as Date) || new Date(), 'h:mm a')}
                      </Typography>
                      <Box sx={{ flex: 1 }}>
                        <Typography>{event.title}</Typography>
                        <Typography level="body-xs" sx={{ color: 'neutral.500' }}>
                          {event.attendees?.length || 0} attendee{event.attendees?.length !== 1 ? 's' : ''}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          );
        })}
      </List>
    </Box>
  );
};

import React from 'react';
import {
  deleteStorageFile,
  downloadStorageFile,
  useGetStorageFiles,
  getStorageFileSignedURL,
} from '@bluebird-monorepo/supabase';
import { Candidate } from '@bluebird-monorepo/types';
import { Delete, Download, FileOpen, Visibility } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, List, ListItem, ListItemContent, Typography } from '@mui/joy';
import { usePDFPreview, PDFPreviewDrawer } from '@bluebird-monorepo/shared';
import { toast } from 'react-toastify';

interface CandidateFilesListProps {
  candidate: Candidate;
  isUploading: boolean;
}

export const CandidateFilesList: React.FC<CandidateFilesListProps> = ({ candidate, isUploading }) => {
  const UPLOADS_PATH = `candidates/${candidate.id}/files`;
  const { data: files, isLoading, refetch } = useGetStorageFiles('uploads', UPLOADS_PATH);
  const { isOpen, file, title, openPdfPreview, closePdfPreview } = usePDFPreview();

  async function handleDeleteUploadsFile(filePath: string) {
    await deleteStorageFile('uploads', filePath);
    refetch();
  }

  const handlePreviewFile = async (filePath: string, fileName: string) => {
    // Check if the file is a PDF
    if (fileName.toLowerCase().endsWith('.pdf')) {
      try {
        // Get the signed URL for the file
        const fileUrl = await getStorageFileSignedURL('uploads', filePath);
        if (fileUrl) {
          // Open the PDF preview with the file URL
          openPdfPreview(fileUrl, fileName);
        }
      } catch (error) {
        console.error('Error getting file URL for preview:', error);
        toast.error('Failed to load PDF for preview');
      }
    } else {
      // For non-PDF files, download them as before
      downloadStorageFile('uploads', filePath);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!files?.length && isUploading) {
    return null;
  }

  if (!files?.length) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography level="body-md" color="neutral">
          No files uploaded yet
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <List>
        {files.map((file) => {
          const filePath = `${UPLOADS_PATH}/${file.name}`;
          const isPdf = file.name.toLowerCase().endsWith('.pdf');

          return (
            <ListItem
              key={file.name}
              sx={{
                '&:hover': {
                  bgcolor: 'background.level1',
                },
                cursor: 'pointer',
              }}
              onClick={() => handlePreviewFile(filePath, file.name)}
            >
              <ListItemContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FileOpen />
                  <Typography level="body-md">{file.name}</Typography>
                </Box>
              </ListItemContent>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {isPdf && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePreviewFile(filePath, file.name);
                    }}
                    variant="outlined"
                    color="primary"
                    title="Preview PDF"
                  >
                    <Visibility />
                  </IconButton>
                )}
                <IconButton
                  component="a"
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadStorageFile('uploads', filePath);
                  }}
                  variant="outlined"
                  color="neutral"
                  title="Download"
                >
                  <Download />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteUploadsFile(filePath);
                  }}
                  variant="outlined"
                  color="danger"
                  title="Delete"
                >
                  <Delete />
                </IconButton>
              </Box>
            </ListItem>
          );
        })}
      </List>

      <PDFPreviewDrawer open={isOpen} file={file} title={title} onClose={closePdfPreview} width="80%" />
    </div>
  );
};

import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCurrentUser } from '@bluebird-monorepo/users';

export function usePipelineQueryInvalidation() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const { data: currentUser } = useGetCurrentUser();

  useEffect(() => {
    // Check if we're on any pipeline route
    if (location.pathname.startsWith('/pipeline') && currentUser?.email) {
      // Invalidate both personal and team assignments queries
      queryClient.invalidateQueries({ queryKey: ['assignmentsByOwner', currentUser.email] });
      queryClient.invalidateQueries({ queryKey: ['teamAssignmentsByOwner', currentUser.email] });
    }
  }, [location.pathname, currentUser?.email, queryClient]);
}

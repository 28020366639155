import { JobAssignmentSection } from '@bluebird-monorepo/assignments';
import {
  CandidateExperienceTab,
  CandidateHeader,
  CandidateProfileTab,
  useGetCandidateById,
  CandidateTimelineTable,
  useGetCandidates,
  useCandidatesTableStore,
} from '@bluebird-monorepo/candidates';
import { Box, IconButton, Skeleton, Tab, TabList, Tabs, Typography } from '@mui/joy';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { useEffect, useState, useCallback } from 'react';
import { Navigate, Route, Link as RouterLink, Routes, useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  Layout,
  LayoutContentHeader,
  LayoutTitleBox,
  LayoutTitle,
  LayoutSubtitle,
  LayoutActionButtonBox,
} from '../../../layout/Layout';
import { CandidateTasksTab } from './CandidateTasksTab';
import { NotesTab } from '@bluebird-monorepo/notes';
import { useGetTasks } from '@bluebird-monorepo/tasks';
import { useGetCurrentUser } from '@bluebird-monorepo/users';
import { debug } from '../../config/environment';

// TODO: Add owner badge

const LoadingSkeleton = () => (
  <Box sx={{ width: '100%' }}>
    <Skeleton variant="rectangular" width="100%" height={200} />
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={48} />
    </Box>
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={400} />
    </Box>
  </Box>
);

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
  });
}

const CandidateDetailPage: React.FC = () => {
  const { data: currentUser } = useGetCurrentUser();
  const { candidateId } = useParams<{ candidateId: string }>();
  const navigate = useNavigate();
  const candidateIdNumber = isNaN(Number(candidateId)) ? 0 : Number(candidateId);
  const { data: candidate, isLoading, error } = useGetCandidateById(candidateIdNumber);
  const { data: tasks } = useGetTasks({ entityType: 'candidate', entityId: candidateIdNumber });
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    page,
    setPage,
    rowsPerPage: rowsPerPageStored,
    sortConfig,
    filters,
    search,
    showOnlyMine,
  } = useCandidatesTableStore();
  useEffect(() => {
    setRowsPerPage(rowsPerPageStored);
  }, []);

  const { data: candidatesData } = useGetCandidates({
    page,
    pageSize: rowsPerPage,
    sortField: sortConfig.field ?? undefined,
    sortDirection: sortConfig.direction,
    search,
    owner: showOnlyMine ? currentUser?.email : undefined,
    filters,
  });

  // Fetch previous page if not on first page
  const { data: previousPageData } = useGetCandidates({
    page: page > 1 ? page - 1 : 1,
    pageSize: rowsPerPage,
    sortField: sortConfig.field ?? undefined,
    sortDirection: sortConfig.direction,
    search,
    owner: showOnlyMine ? currentUser?.email : undefined,
    filters,
  });

  const candidates = [...(previousPageData?.candidates || []), ...(candidatesData?.candidates || [])];
  const currentIndex = candidates.findIndex((c) => c.id === candidateIdNumber);
  const prevCandidate = currentIndex > 0 ? candidates[currentIndex - 1] : null;
  const nextCandidate = currentIndex < candidates.length - 1 ? candidates[currentIndex + 1] : null;

  useEffect(() => {
    if (!nextCandidate) {
      setRowsPerPage(rowsPerPage * (page + 1));
    }
  }, [nextCandidate]);

  const handleNavigate = (id: number) => {
    navigate(`/candidates/${id}`);
  };

  const handlePreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  if (!candidateId) {
    return <div>No candidate ID provided</div>;
  }

  if (isLoading) {
    return (
      <Layout title="Loading...">
        <LoadingSkeleton />
      </Layout>
    );
  }
  if (error)
    return (
      <Layout title="Error">
        <Typography color="danger">{error.message}</Typography>
      </Layout>
    );

  if (!candidate) {
    return (
      <Layout title="Not Found">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <Typography level="h3" color="danger">
            Candidate not found
          </Typography>
        </Box>
      </Layout>
    );
  }

  const tabs = [
    { id: 'profile', label: 'Profile', path: `profile` },
    { id: 'experience', label: 'Experience', path: `experience` },
    { id: 'jobs', label: 'Jobs', path: `jobs` },
    { id: 'notes', label: 'Notes', path: `notes` },
    { id: 'timeline', label: 'Timeline', path: `timeline` },
    { id: 'tasks', label: `Tasks (${tasks?.length || 0})`, path: `tasks` },
  ];

  const currentPath = location.pathname;
  const activeTab = tabs.find((tab) => currentPath.endsWith(tab.id)) ?? tabs[0];

  return (
    <Layout areCompoundComponents>
      <LayoutContentHeader>
        <LayoutTitleBox>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <LayoutTitle>{`${candidate.firstName} ${candidate.lastName}`}</LayoutTitle>
          </Box>
          <LayoutSubtitle>View and manage candidate details</LayoutSubtitle>
        </LayoutTitleBox>
        <LayoutActionButtonBox>
          <IconButton
            onClick={() => {
              if (prevCandidate) {
                handleNavigate(prevCandidate.id);
              } else if (page > 1) {
                handlePreviousPage();
              }
            }}
            disabled={!prevCandidate && page <= 1}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() => nextCandidate && handleNavigate(nextCandidate.id)}
            disabled={!nextCandidate}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <ChevronRight />
          </IconButton>
        </LayoutActionButtonBox>
      </LayoutContentHeader>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        <CandidateHeader candidate={candidate} />

        <Tabs value={activeTab.id} sx={{ mb: 2 }}>
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.id} value={tab.id} component={RouterLink} to={tab.path}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="profile" replace />} />
          <Route path="profile" element={<CandidateProfileTab candidate={candidate} />} />
          <Route path="experience" element={<CandidateExperienceTab candidate={candidate} />} />
          <Route path="jobs" element={<JobAssignmentSection candidate={candidate} />} />
          <Route path="notes" element={<NotesTab entityId={candidate.id} entityType="candidate" />} />
          <Route path="timeline" element={<CandidateTimelineTable candidateId={candidate.id} />} />
          <Route path="tasks" element={<CandidateTasksTab candidate={candidate} />} />
          <Route path="*" element={<Navigate to="profile" replace />} />
        </Routes>
      </Box>
    </Layout>
  );
};

if (process.env.NODE_ENV === 'development' && debug.isRenderDebugEnabled) {
  CandidateDetailPage.whyDidYouRender = true;
}
export default CandidateDetailPage;

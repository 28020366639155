import { Company } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertCompany() {
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: (company: Company) => {
      queryClient.invalidateQueries({ queryKey: ['companies'] });
      queryClient.invalidateQueries({ queryKey: ['company', company.id] });
      queryClient.invalidateQueries({ queryKey: ['companyById', company.id] });
    },
    mutationFn: async (company: Partial<Company>) => {
      const { data, error } = await supabase
        .from('companies')
        .upsert(toSnakeCase(company), {
          onConflict: 'id',
        })
        .select()
        .single();
      if (error) throw error;
      return toCamelCase<Company>(data);
    },
  });
}

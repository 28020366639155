import { camelCase, mapKeys, snakeCase } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toCamelCase = <T>(obj: Record<string, any>): T => {
  if (Array.isArray(obj)) {
    return obj.map((item) => toCamelCase<T>(item)) as T;
  }
  return mapKeys(obj, (v, k) => camelCase(k)) as T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toSnakeCase = <T>(obj: Record<string, any>): T => {
  if (Array.isArray(obj)) {
    return obj.map((item) => toSnakeCase<T>(item)) as T;
  }
  return mapKeys(obj, (v, k) => snakeCase(k)) as T;
};

import { Avatar } from '@mui/joy';
import { useGetCompanyLogo } from '../../../api/logos/useGetCompanyLogo';

const avatarSizes = {
  sm: 24,
  md: 32,
  lg: 40,
};

export function CompanyAvatar({
  name,
  logoUrl,
  size = 'sm',
}: {
  name: string;
  logoUrl: string;
  size?: 'sm' | 'md' | 'lg';
}) {
  const fullLogoUrl = useGetCompanyLogo(logoUrl);
  return (
    <Avatar
      alt={name}
      src={fullLogoUrl || logoUrl}
      size={size}
      variant="outlined"
      sx={{
        width: avatarSizes[size],
        height: avatarSizes[size],
        '--Avatar-size': `${avatarSizes[size]}px`,
        border: '1px solid',
        borderColor: 'divider',
      }}
    />
  );
}

import { Edit } from '@mui/icons-material';
import { Avatar, Box, Card, Chip, IconButton, Stack, Typography } from '@mui/joy';
import React from 'react';

interface PersonCardProps {
  person: {
    email: string;
    id: string;
    isContactPerson?: boolean;
    isHiringManager?: boolean;
    name: string;
    phone?: string;
    responsibleForJobs?: string[];
  };
}

const PersonCard: React.FC<PersonCardProps> = ({ person }) => {
  return (
    <Card
      sx={{
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        padding: 2,
        position: 'relative',
      }}
    >
      <IconButton
        sx={{
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <Edit />
      </IconButton>

      <Stack direction="row" gap={2} width="300px">
        <Avatar>{person.name.charAt(0)}</Avatar>
        <Box>
          <Box>
            <Typography fontWeight="bold">{person.name}</Typography>
            <Typography fontSize="small">{person.email}</Typography>
            <Typography fontSize="small">{person.phone || 'No phone provided'}</Typography>
            <Box display="flex" gap={1} mt={1}>
              {person.isContactPerson && (
                <Chip color="primary" size="sm">
                  Contact Person
                </Chip>
              )}
              {person.isHiringManager && (
                <Chip color="success" size="sm">
                  Hiring Manager
                </Chip>
              )}
            </Box>
          </Box>
          <Box>
            {person.isHiringManager && person.responsibleForJobs && (
              <Typography color="neutral" fontSize="small" mt={1}>
                Responsible for {person.responsibleForJobs.length} jobs
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>
    </Card>
  );
};

export { PersonCard };

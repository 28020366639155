import { CalendarEvent } from '@bluebird-monorepo/types';
import { supabase } from '@bluebird-monorepo/supabase';
import { toCamelCase, toSnakeCase } from '@bluebird-monorepo/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertCalendarEvent() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (event: Partial<CalendarEvent>) => {
      const { data, error } = await supabase
        .from('calendar_events')
        .upsert(toSnakeCase(event), {
          onConflict: 'id',
        })
        .select()
        .single();
      if (error) throw error;
      return toCamelCase<CalendarEvent>(data);
    },
    onSuccess: (event: CalendarEvent) => {
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
      queryClient.invalidateQueries({ queryKey: ['company', event.companyId] });
      queryClient.invalidateQueries({ queryKey: ['job', event.jobId] });
    },
  });
}

import axios from 'axios';

interface WeFactConfig {
  apiKey: string;
  apiUrl: string;
}

interface WeFactListResponse<T> {
  status: 'success' | 'error';
  message?: string;
  date?: string;
  errors?: string[];
  controller: string;
  action: string;
  currentresults: number;
  totalresults: number;
  offset: number;
  invoices?: T[];
}

interface WeFactSingleResponse<T> {
  status: 'success' | 'error';
  message?: string;
  date?: string;
  errors?: string[];
  data?: T;
}

type WeFactResponse<T> = WeFactListResponse<T> | WeFactSingleResponse<T>;

export interface Invoice {
  Identifier: string;
  InvoiceCode: string;
  Debtor: string;
  DebtorCode: string;
  CompanyName: string;
  Initials: string;
  SurName: string;
  AmountExcl: string;
  AmountIncl: string;
  AmountPaid: string;
  AmountOutstanding: string;
  Currency: string;
  Date: string;
  Term: string;
  Status: string;
  SubStatus: string;
  Sent: string;
  SentDate: string;
  Reminders: string;
  ReminderDate: string;
  Summations: string;
  SummationDate: string;
  Modified: string;
  PayBefore: string;
  InvoiceNumber: string;
  Description?: string;
  PaymentMethod?: string;
  CustomerCode?: string;
}

interface RequestParams {
  [key: string]: string | number | boolean | undefined;
}

class WeFactService {
  private config: WeFactConfig;

  constructor(config: WeFactConfig) {
    this.config = config;
  }

  private async makeRequest<T>(controller: string, action: string, params: RequestParams = {}): Promise<T | T[]> {
    try {
      const response = await axios.post<WeFactResponse<T>>(
        this.config.apiUrl,
        {
          api_key: this.config.apiKey,
          controller,
          action,
          ...params,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      if (response.data.status === 'success') {
        // Handle list response
        if ('invoices' in response.data && response.data.invoices) {
          return response.data.invoices;
        }
        // Handle single item response
        if ('data' in response.data && response.data.data) {
          return response.data.data;
        }
        throw new Error('Unexpected response format');
      }

      throw new Error(response.data.errors?.[0] || response.data.message || 'Unknown error occurred');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('WeFact API Error:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message,
        });
        throw new Error(`API request failed: ${error.message}`);
      }
      throw error;
    }
  }

  async listInvoices(): Promise<Invoice[]> {
    const response = await this.makeRequest<Invoice>('invoice', 'list');
    if (!Array.isArray(response)) {
      throw new Error('Expected array response for list invoices');
    }
    return response;
  }

  async getInvoice(invoiceCode: string): Promise<Invoice> {
    const response = await this.makeRequest<Invoice>('invoice', 'show', { invoice_code: invoiceCode });
    if (Array.isArray(response)) {
      throw new Error('Unexpected array response for single invoice');
    }
    return response;
  }

  async createInvoice(invoiceData: Partial<Invoice>): Promise<Invoice> {
    const params: RequestParams = Object.entries(invoiceData).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {} as RequestParams);

    const response = await this.makeRequest<Invoice>('invoice', 'add', params);
    if (Array.isArray(response)) {
      throw new Error('Unexpected array response for create invoice');
    }
    return response;
  }

  async updateInvoice(invoiceCode: string, invoiceData: Partial<Invoice>): Promise<Invoice> {
    const params: RequestParams = {
      invoice_code: invoiceCode,
      ...Object.entries(invoiceData).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      }, {} as RequestParams),
    };

    const response = await this.makeRequest<Invoice>('invoice', 'edit', params);
    if (Array.isArray(response)) {
      throw new Error('Unexpected array response for update invoice');
    }
    return response;
  }

  async deleteInvoice(invoiceCode: string): Promise<void> {
    await this.makeRequest('invoice', 'delete', { invoice_code: invoiceCode });
  }
}

// Create and export a singleton instance
export const weFactService = new WeFactService({
  apiKey: import.meta.env.VITE_WEFACT_API_KEY || '',
  apiUrl: import.meta.env.VITE_WEFACT_API_URL || '',
});

export default weFactService;

import { Box, Button, FormControl, FormLabel, Radio, RadioGroup, Textarea, Typography } from '@mui/joy';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAI } from '../../../hooks/useAI';
import { IntroductionFormData } from '../IntroductionWizard';

interface AIPromptStepProps {
  onNext: () => void;
}

export const AIPromptStep: FC<AIPromptStepProps> = ({ onNext }) => {
  const { register, watch, setValue } = useFormContext<IntroductionFormData>();
  const { generateIntroduction, isGenerating, error } = useAI();

  const provider = watch('aiConfig.provider');
  const customPrompt = watch('aiConfig.customPrompt');
  const calendarEvent = watch('calendarEvent');
  const conversations = watch('conversations');
  const resumeUrl = watch('resumeUrl');

  useEffect(() => {
    // Set default provider if not set
    if (!provider) {
      setValue('aiConfig.provider', 'openai');
    }
  }, [provider, setValue]);

  const handleGenerate = async () => {
    const content = await generateIntroduction({
      provider,
      prompt: customPrompt || 'Please create a professional introduction based on the provided context.',
      calendarEvent,
      conversations,
      resumeUrl,
    });

    if (content) {
      setValue('generatedContent', content);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <FormControl>
        <FormLabel>Select AI Provider</FormLabel>
        <RadioGroup
          value={provider}
          onChange={(e) => setValue('aiConfig.provider', e.target.value as 'openai' | 'anthropic')}
        >
          <Radio value="openai" label="OpenAI GPT-4 Turbo" />
          <Radio value="anthropic" label="Anthropic Claude 3 Sonnet" />
        </RadioGroup>
      </FormControl>

      <FormControl sx={{ mt: 3 }}>
        <FormLabel>Custom Prompt (Optional)</FormLabel>
        <Textarea minRows={4} placeholder="Enter your custom prompt here..." {...register('aiConfig.customPrompt')} />
      </FormControl>

      {error && <Typography sx={{ mt: 2, color: 'danger.500' }}>{error}</Typography>}

      <Button loading={isGenerating} onClick={handleGenerate} sx={{ mt: 3 }} fullWidth>
        Generate Introduction
      </Button>

      {watch('generatedContent') && (
        <Box sx={{ mt: 3 }}>
          <FormControl>
            <FormLabel>Generated Introduction</FormLabel>
            <Textarea
              minRows={6}
              value={watch('generatedContent')}
              onChange={(e) => setValue('generatedContent', e.target.value)}
            />
          </FormControl>
        </Box>
      )}
    </Box>
  );
};
